import "../scss/CustomPeriodSelector.scss";
import { useState, useEffect } from 'react';
import { Dropdown } from '@fluentui/react-northstar';
import { Flex } from "@fluentui/react-northstar";
import { UserType } from '../constants/AppEnum';
import { IMonthlyAllowedPeriod } from "../interfaces/IMonthlyAllowedPeriod";


const CustomPeriodSelector = (props: any) => {

    const months = [
        {
            key: 1,
            header: 'Gennaio',
        },
        {
            key: 2,
            header: 'Febbraio',
        },
        {
            key: 3,
            header: 'Marzo',
        },
        {
            key: 4,
            header: 'Aprile',
        },
        {
            key: 5,
            header: 'Maggio',
        },
        {
            key: 6,
            header: 'Giugno',
        },
        {
            key: 7,
            header: 'Luglio',
        },
        {
            key: 8,
            header: 'Agosto',
        },
        {
            key: 9,
            header: 'Settembre',
        },
        {
            key: 10,
            header: 'Ottobre',
        },
        {
            key: 11,
            header: 'Novembre',
        },
        {
            key: 12,
            header: 'Dicembre',
        },
    ];

    const today: Date = new Date();
    const defaultYear = { key: today.getFullYear(), header: `${today.getFullYear()}` };
    const [years, setYears] = useState<any[]>([defaultYear]);
    const [selectedMonth, setSelectedMonth] = useState(props.showFirstEmprty ? null : today.getMonth() + 1);
    const [selectedYear, setSelectedYear] = useState(props.showFirstEmprty ? null : today.getFullYear());

    const calcYears = (userType: UserType): any[] => {
        let result: any[] = [];

        if (userType === UserType.User) {
            result.push({
                key: today.getFullYear() - 1,
                header: `${today.getFullYear() - 1}`
            });
            result.push({
                key: today.getFullYear(),
                header: `${today.getFullYear()}`
            });
            result.push({
                key: today.getFullYear() + 1,
                header: `${today.getFullYear() + 1}`
            });
        } else {
            //Imposto gli ultimi 5 anni
            for (let index = today.getFullYear() - 4; index <= today.getFullYear() + 1; index++) {
                result.push({
                    key: index,
                    header: `${index}`
                });
            }
        }
        return result;
    }

    useEffect(() => {
        setYears(calcYears(props.userType));
    }, [props.userType]);


    return <Flex className="custom-period-container" gap="gap.small" padding="padding.medium" wrap >
        <div className="period-dd">
            <Dropdown
                disabled={(props.disabled !== null && props.disabled !== undefined) ? props.disabled : false}
                items={months}
                fluid
                placeholder="Seleziona il mese"
                defaultValue={props.showFirstEmprty ? null : months.filter((element) => { return element.key === new Date().getMonth() + 1; })[0]}
                onChange={(event: any, data: any) => {
                    const month: number = data.value ? data.value.key as number : today.getMonth() + 1;
                    const period = {
                        month: month,
                        year: selectedYear
                    } as IMonthlyAllowedPeriod;
                    setSelectedMonth(month);
                    props.onSelectedPeriodChange(period);
                }}
            />
        </div>
        <div className="period-dd">
            <Dropdown
                fluid
                disabled={(props.disabled !== null && props.disabled !== undefined) ? props.disabled : false}
                items={years}
                placeholder="Seleziona l'anno"
                defaultValue={props.showFirstEmprty ? null : defaultYear}
                onChange={(event: any, data: any) => {
                    const year: number = data.value ? data.value.key as number : today.getFullYear();
                    const period = {
                        month: selectedMonth,
                        year: year
                    } as IMonthlyAllowedPeriod;
                    setSelectedYear(year);
                    props.onSelectedPeriodChange(period);
                }}
            />
        </div>
    </Flex>

}

export default CustomPeriodSelector;