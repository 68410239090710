/* IMPORT CSS / SASS */
import '../scss/BadgeMobile.scss';

/* IMPORT REACT & THIRD PARTY COMPONENTS */
import React from 'react';
import { Button, Flex, Loader, Divider } from '@fluentui/react-northstar';
import { useSelector, useDispatch } from 'react-redux';

/* IMPORT CUSTOM COMPONENTS */
import { IEmployee } from '../interfaces/IEmployee';
import { capitalizeWords } from '../helpers/Tools';
import { IAppState } from '../interfaces/IAppState';
import * as ssfActions from '../stores/actions/SsfActions';
import * as cookieHelper from '../helpers/CookiesHelper';
import EmployeePic from './EmployeePic';
// import ConsulcesiEye from '../imgs/consulcesi-eye.svg';
// import ConsulcesiEyeBlu from '../imgs/consulcesi-eye-blu.svg';
import ConsulcesiEye from '../imgs/consulcesi-eye-white.png';
import ConsulcesiEyeBlu from '../imgs/consulcesi-eye-color.png';
import { purifyLocation } from '../helpers/SuccessFactorsHelper';

export const BadgeHeaderMobile = (props: any) => {

    const appState: IAppState = useSelector(appState => appState) as IAppState;
    const employee: IEmployee = appState.ssfJobs[appState.selectedJob] as IEmployee;
    const dispatch = useDispatch();

    return appState.ssfIsLoaded ?
        <Flex column>
            <Flex className="badge-title-container" space="between" gap="gap.large">
                <Flex hAlign="center" vAlign="center" column >
                    <EmployeePic pic={employee?.pics[0]} />
                    <div className="badge-badge-user-id noselect">{employee.userId}</div>
                </Flex>
                <Flex hAlign='center' column  >
                    <div className="badge-person-name">{capitalizeWords(employee?.firstName)} {capitalizeWords(employee?.lastName)}</div>
                    <div>{capitalizeWords(employee?.roleName)} </div>
                    <span className="badge-header-company-name">{capitalizeWords(employee.companyName)}</span>
                    <Flex hAlign="center" vAlign="center" gap="gap.medium" className="badge-badge-user-location-mobile">
                        <div><img draggable="false" className="badge-badge-company-logo noselect" src={(appState.teamsContext.theme === "dark" || appState.teamsContext.theme === "contrast") ? ConsulcesiEye : ConsulcesiEyeBlu} alt="Company Logo" /></div>
                        {(employee.companyLocation !== null && employee.companyLocation !== undefined && employee.companyLocation !== "") ?
                            <div className="noselect">{purifyLocation(employee.companyLocation).toUpperCase()}</div>
                            : null}
                    </Flex>
                </Flex>                
                {/* {(appState.ssfJobs.length > 1) ?
                    <Flex gap="gap.small" hAlign="end" vAlign="center">
                        {appState.ssfJobs.map((emp: IEmployee, index: number) => {
                            let jobLabel = (emp.roleName === undefined || emp.roleName === '' || emp.roleName === null) ? `${capitalizeWords(emp.firstName)} ${capitalizeWords(emp.lastName)}` : '';
                            jobLabel += (emp.roleName !== undefined && emp.roleName !== '' && emp.roleName !== null && emp.roleName.length > 0) ? ` ${capitalizeWords(emp.roleName)}` : ``;

                            var empJobId: number = cookieHelper.getApplicationCookies.getSelectedJobCookie();
                            return (empJobId === index) ?
                                <Button disabled key={index} content={`${jobLabel}`} />
                                :
                                <Button key={index} onClick={() => dispatch(ssfActions.setSelectedJob(index))} content={`${jobLabel}`} primary disabled={appState.isRegistering} />
                        })
                        }
                    </Flex>
                    : null
                } */}
            </Flex>

            <Divider />
        </Flex>
        :
        <Loader label="Loading Success Factors data..." />
}

export default BadgeHeaderMobile;