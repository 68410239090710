import { Flex, Text, Divider, Loader, Avatar, AcceptIcon, Card, LocationIcon, Image } from "@fluentui/react-northstar";
import { FixedSizeList as List } from 'react-window';
import InfiniteLoader from "react-window-infinite-loader";
import AutoSizer from "react-virtualized-auto-sizer";
import { IUserStatusItem } from "../interfaces/IUserStatusItem";

const StaffList = (props: any) => {
    const { hasNextPage, items, theme, countryItems, workingModes } = props.tableProps;
    const itemCount: number = hasNextPage ? items.length + 1 : items.length;
    const isItemLoaded = (index: number) => {
        return !hasNextPage || index < items.length;
    }


    // Render an item or a loading indicator.
    const Item = (iprops: any) => {
        const { index, style } = iprops;
        let content: IUserStatusItem | null = null;
        let fullName: string = '';
        let uName: string = '';
        let message: string = '';
        let country: string = '';
        let workingMode: string = '';
        let picture: string = '';
        let managerFullName: string = '';

        if (!isItemLoaded(index)) {
            message = "Loading...";
        } else {
            const userItem: IUserStatusItem = items[index];
            content = userItem;
            fullName = `${userItem.lastName !== null ? userItem.lastName : ''} ${userItem.firstName !== null ? userItem.firstName : ''}`;
            uName = fullName.trim() !== '' ? fullName : (userItem.userName !== null && userItem.userName !== undefined && userItem.userName.length > 0 ? userItem.userName : userItem.idUserSF);
            country = countryItems.filter((c: any) => { return c.key === content?.country })[0].header;
            workingMode = content?.workingModeId !== null ? workingModes.filter((c: any) => { return c.id === content?.workingModeId })[0].name : 'Non disponibile';
            picture = content.picture;
            managerFullName = userItem.managerFullName !== null ? userItem.managerFullName : '';
        }

        return (
            <Flex style={style} id="StaffListMain" className={`staff-row ${theme === 'dark' ? "staff-row-dark" : "staff-row-default"}`} column key={index}>
                <Flex fill >
                    {content !== null ?
                        <Card
                            compact
                            fluid
                            horizontal
                            aria-roledescription="card with a preview image, text and action buttons"
                        >
                            <Card.Preview horizontal styles={{ padding: '3px' }}>
                                {picture !== '' ?
                                    <Avatar
                                        size="largest"
                                        square
                                        name={uName}
                                        image={{
                                            src: `data:image/png;base64, ${picture}`,
                                            alt: uName
                                        }}
                                        status={{
                                            color: 'green',
                                            icon: <AcceptIcon />,
                                            title: 'Available',
                                        }}
                                    />
                                    :
                                    <Avatar
                                        size="largest"
                                        square
                                        name={uName}
                                        status={{
                                            color: 'green',
                                            icon: <AcceptIcon />,
                                            title: 'Available',
                                        }}
                                    />
                                }

                                {/* <Image
                                    style={{
                                        height: '115px',
                                        width: '115px',
                                    }}
                                    src="https://fabricweb.azureedge.net/fabric-website/assets/images/wireframe/square-image.png"
                                /> */}
                            </Card.Preview>
                            <Card.Column styles={{ width: '85%' }} >
                                <Card.Header>
                                    <Flex column fill space="between" styles={{ padding: '5px' }}>
                                        <Text content={fullName.trim() !== '' ? fullName : uName} weight="bold" size="large" />
                                        <Text content={`Responsabile : ${managerFullName}`} />
                                    </Flex>

                                </Card.Header>
                                <Card.Body>
                                    <Flex fill space="between" styles={{ padding: '5px' }} >
                                        <Flex column>
                                            <Text content={`${content?.departmentName}`} size="small" />
                                            <Text content={`${content?.roleName}`} size="small" />
                                        </Flex>

                                        {/* <div className="report-table-cell" >{content.idUserSF}</div> */}

                                    </Flex>
                                </Card.Body>
                            </Card.Column>
                            <Card.Column styles={{ width: '25%', textAlign: 'left' }} >
                                <Card.Header>
                                    <Flex column hAlign="start" gap="gap.small" styles={{ padding: '5px' }} >
                                        <Flex gap="gap.small">
                                            <LocationIcon outline />
                                            <Text content={country} size="small" />
                                        </Flex>
                                        {/* <Text content="Modalità di lavoro:" size="small" /> */}
                                        <Text content={`Modalità di lavoro: ${workingMode}`} size="small" />
                                        {content?.worked &&
                                            <Text content="Turno completato" color="green" size="small" />
                                        }
                                        {(content?.working && content?.lunching === false && content?.breaking === false) &&
                                            <Text content="Turno in corso" color="brand" size="small" />
                                        }
                                        {(content?.working && content?.lunching) &&
                                            <Text content="Pausa pranzo in corso" color="pink" size="small" />
                                        }
                                        {(content?.working && content?.breaking) &&
                                            <Text content="Pausa in corso" color="pink" size="small" />
                                        }
                                        {(content?.worked === false && content?.working === false) &&
                                            <Text content="Turno non avviato" color="red" size="small" />
                                        }
                                    </Flex>
                                </Card.Header>
                            </Card.Column>
                        </Card>
                        // <Flex fill space="between"  >
                        //     <Flex gap="gap.small" >
                        //         {picture !== '' ?
                        //             <Avatar
                        //                 size="larger"
                        //                 square
                        //                 name={uName}
                        //                 image={{
                        //                     src: `data:image/png;base64, ${picture}`,
                        //                     alt: uName
                        //                 }}
                        //                 status={{
                        //                     color: 'green',
                        //                     icon: <AcceptIcon />,
                        //                     title: 'Available',
                        //                 }}
                        //             />
                        //             :
                        //             <Avatar
                        //                 size="larger"
                        //                 square
                        //                 name={uName}
                        //                 status={{
                        //                     color: 'green',
                        //                     icon: <AcceptIcon />,
                        //                     title: 'Available',
                        //                 }}
                        //             />
                        //         }
                        //         <Flex column space="between">
                        //             <Text content={fullName.trim() !== '' ? fullName : uName} weight="bold" />
                        //             <Flex column>
                        //                 <Text content={`${content?.departmentName}`} size="small" />
                        //                 <Text content={`${content?.roleName}`} size="small" />
                        //             </Flex>
                        //         </Flex>
                        //     </Flex>
                        //     {/* <div className="report-table-cell" >{content.idUserSF}</div> */}
                        //     <Flex column hAlign="end" >
                        //         <Text content={country} size="small" />
                        //         <Text content={workingMode} size="small" />
                        //     </Flex>
                        // </Flex>

                        :
                        <Flex hAlign="center">
                            <Loader label="caricamento in corso..." size="small" labelPosition="end" />
                        </Flex>
                    }

                </Flex >
                <Divider />
            </Flex>
        )
    }

    if (itemCount === 0) {
        return <Flex style={{ marginTop: "20px", marginLeft: "100%" }} fill hAlign="center"><Text size="larger" content="Nessun utente trovato" /></Flex>
    }

    return (
        <AutoSizer className="autosizer" style={{ display: "contents" }}>
            {({ height, width }) => (
                <InfiniteLoader
                    isItemLoaded={isItemLoaded}
                    itemCount={itemCount}
                    loadMoreItems={props.loadNextPage}
                >
                    {({ onItemsRendered, ref }) => (
                        <List
                            className="List"
                            height={height}
                            width={'55%'}
                            itemCount={itemCount}
                            itemSize={100}
                            onItemsRendered={onItemsRendered}
                            ref={ref}
                        // width='100%'
                        // height={850}
                        >
                            {Item}
                        </List>
                    )}
                </InfiniteLoader>
            )}
        </AutoSizer>
    );

}

export default StaffList;