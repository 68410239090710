/* IMPORT CSS / SASS */
import "../scss/TabsContainer.scss";

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Flex, Text, Loader } from "@fluentui/react-northstar";
import Assistances from "./Assistances";
import { IAppState } from "../interfaces/IAppState";
import { UserType } from "../constants/AppEnum";
import { ITab } from "../interfaces/ITab";
import * as cookieHelper from '../helpers/CookiesHelper';
import Overtimes from "./Overtimes";
import { IOrgChartUser } from "../interfaces/IOrgChartUser";
import { SiaStrings } from "../strings/strings";
import * as appActions from '../stores/actions/AppActions';
import * as microsoftTeams from "@microsoft/teams-js";
import DevTools from './DevTools';
import Calendar from "./Calendar";
//import Report from "./Report";
import Warning from "./Warning";
import { ISize } from '../interfaces/ISize';
import useWindowSize from '../hooks/useWindowSize';
import useLoadCommonData from "../hooks/useLoadCommonData";
import { LoadCommonDataStaus } from "../constants/AppEnum";
import { IObjectTabs } from '../interfaces/IObjectTabs';
import OvertimeViewer from "./OvertimeViewer";
import Staff from './Staff';
import { IEmployee } from "../interfaces/IEmployee";
import * as cookiesHelper from '../helpers/CookiesHelper';

const TabsContainer = (props: any) => {
    const appState: IAppState = useSelector((appState) => appState) as IAppState;
    const defaultTab: ITab = {
        index: 0,
        label: "",
        content: <div />
    };
    const [objectTabs, setObjectTabs] = useState<IObjectTabs>({
        tabs: [],
        selectedTab: defaultTab
    } as IObjectTabs);


    const [error, setError] = useState('');
    const [errorDetail, setErrorDetail] = useState('');
    const [orgChart, setOrgChart] = useState<IOrgChartUser | null>(null)
    const size: ISize = useWindowSize();
    const commonDataStatus: LoadCommonDataStaus = useLoadCommonData();


    useEffect(() => {
        if (commonDataStatus === LoadCommonDataStaus.Success) {
            if (appState.userType === UserType.Unknown) {
                setError('Impossibile recuperare la tipologia di utente');
                return;
            } else {
                const tmpOrgChart: IOrgChartUser = cookieHelper.getApplicationCookies.getOrgChartCookie();
                setOrgChart(tmpOrgChart);
            }
        } else if (commonDataStatus === LoadCommonDataStaus.Failed) {
            const contractNotFoundException: string = cookiesHelper.getApplicationCookies.getContractNotFoundCookie();
            let message: string = 'Si è verificato un errore imprevisto nel recupero dei dati';
            if (contractNotFoundException === 'true') {
                message = SiaStrings.COTRACT_NOT_FOUND_MESSAGE;
                setErrorDetail(SiaStrings.COTRACT_NOT_FOUND_DETAIL_MESSAGE);       
            }
            setError(message);
        }
    }, [commonDataStatus])

    useEffect(() => {
        if (commonDataStatus === LoadCommonDataStaus.Success && appState.userType !== UserType.Unknown && orgChart !== null) {
            microsoftTeams.getContext((context: microsoftTeams.Context) => {
                //subEntityId viene impostato con un deepLink nel tab Badge in un caso particolare
                const redirectToCalendar: boolean = context.subEntityId === 'Calendario';
                let tmpObjTabs: IObjectTabs = { ...objectTabs };

                //Se utente HR o Admin o responsabile (ha utenti sottoposti) aggiungo il tab per la gestione degli straordinari
                // if (appState.userType !== UserType.Unknown && (appState.userType === UserType.HR || appState.userType === UserType.Admin || orgChart.users.length > 0)) {
                //     if (tmpObjTabs.tabs.filter((tab) => { return tab.label === SiaStrings.OVERTIMES }).length === 0) {
                //         tmpObjTabs.tabs.push(
                //             {
                //                 index: tmpObjTabs.tabs.length,
                //                 label: SiaStrings.OVERTIMES,
                //                 content: <Overtimes />
                //             }
                //         );
                //     }
                // }

                //Se utente HR Svizzera visualizza il riepilogo del recupero degli straordinari
                if (appState.userType !== UserType.Unknown && (appState.userType === UserType.HR && (orgChart !== null && orgChart.companyCountry.toLocaleLowerCase() === 'che'))) {
                    if (tmpObjTabs.tabs.filter((tab) => { return tab.label === "Recupero straordinari" }).length === 0) {
                        const tmpOvertimes = {
                            index: tmpObjTabs.tabs.length,
                            label: "Recupero straordinari",
                            content: <OvertimeViewer />
                        };
                        tmpObjTabs.tabs.push(tmpOvertimes);
                        if (tmpObjTabs.tabs.length === 1) {
                            tmpObjTabs.selectedTab = tmpOvertimes;
                        }
                    }
                }

                //Cerco il contratto attuale (quello che ha data fine nell'anno 9999)
                const contracts: IEmployee[] = appState.ssfJobs.filter((a: IEmployee) => a.endDate?.getFullYear() === 9999);
                //Se non ci sono contratti validi non mostro niente
                if (contracts.length === 0) {
                    if (tmpObjTabs.tabs.length === 0) {
                        const infoTab = {
                            index: 1,
                            label: "",
                            content: <Warning label="Nessun contratto attivo trovato" height={size.height} />
                        };
                        tmpObjTabs.tabs.push(infoTab);
                        tmpObjTabs.selectedTab = infoTab;
                    }
                    setObjectTabs(tmpObjTabs);
                    return;
                }
                let currentContract: IEmployee = contracts[0];


                //Se l'utente deve compilare il calendario o è HR o Admin aggiungo il tab calendario e il tab rapportino solo per HR e Admin
                if (currentContract.mandatoryCalendar || (appState.userType !== UserType.Unknown && (appState.userType === UserType.HR || appState.userType === UserType.Admin))) {
                    //e non è già stato aggiunto ai tab da visualizzare
                    if (tmpObjTabs.tabs.filter((tab) => { return tab.label === SiaStrings.CALENDAR }).length === 0) {
                        const calendarTab = {
                            index: tmpObjTabs.tabs.length,
                            label: SiaStrings.CALENDAR,
                            content: <Calendar />
                        }
                        tmpObjTabs.tabs.push(calendarTab);
                        if (tmpObjTabs.tabs.length === 1) {
                            tmpObjTabs.selectedTab = calendarTab;
                        }

                    }
                    // Il rapportino verrà visualizzato solo con l'ultimo rilascio
                    // if (appState.userType === UserType.HR || appState.userType === UserType.Admin) {
                    //     if (tmpObjTabs.tabs.filter((tab) => { return tab.label === SiaStrings.RAPPORTINO }).length === 0) {
                    //         tmpObjTabs.tabs.push(
                    //             {
                    //                 index: tmpObjTabs.tabs.length,
                    //                 label: SiaStrings.RAPPORTINO,
                    //                 content: <Report />
                    //             }
                    //         );
                    //     }
                    // }
                }

                if (tmpObjTabs.tabs.filter((tab) => { return tab.label === SiaStrings.STAFF }).length === 0) {
                    const staffTab = {
                        index: tmpObjTabs.tabs.length,
                        label: SiaStrings.STAFF,
                        content: <Staff />
                    };
                    tmpObjTabs.tabs.push(staffTab);
                    if (tmpObjTabs.tabs.length === 1) {
                        tmpObjTabs.selectedTab = staffTab;
                    }
                }

                // if (tmpObjTabs.tabs.filter((tab) => { return tab.label === SiaStrings.DEVTOOLS }).length === 0) {
                //     const devTab = {
                //         index: tmpObjTabs.tabs.length,
                //         label: SiaStrings.DEVTOOLS,
                //         content: <DevTools />
                //     };
                //     tmpObjTabs.tabs.push(devTab);
                //     if (tmpObjTabs.tabs.length === 1) {
                //         tmpObjTabs.selectedTab = devTab;
                //     }
                // }

                if (tmpObjTabs.tabs.length === 0) {
                    const infoTab = {
                        index: 1,
                        label: "",
                        content: <Warning label="Nessuna funzionalità prevista" height={size.height} />
                    };
                    tmpObjTabs.tabs.push(infoTab);
                    tmpObjTabs.selectedTab = infoTab;
                }

                if (redirectToCalendar) {
                    var calendarTab = tmpObjTabs.tabs.filter((tab) => { return tab.label === SiaStrings.CALENDAR });
                    if (calendarTab.length > 0) {
                        tmpObjTabs.selectedTab = calendarTab[0];
                    }
                }

                setObjectTabs(tmpObjTabs);
            });
        }
    }, [commonDataStatus, orgChart]);

    if (appState.isMobile) {
        return <Warning label={'Funzionalità non supportata su dispositivi mobile'} height={size.height} />
    }

    return <Flex column fill styles={{ height: (size.height !== undefined && objectTabs.selectedTab.label !== 'Calendario') ? `${size.height}px` : '100%' }}>
        {commonDataStatus === LoadCommonDataStaus.Loading ?
            <Loader label={SiaStrings.LOADING} style={{ paddingTop: '20px', paddingBottom: '20px' }} />
            :
            <>
                {objectTabs.tabs.length > 0 &&
                    <ul className="inline">
                        {objectTabs.tabs.map((tab: any, index) => {
                            let style = index === objectTabs.selectedTab.index ? 'selected' : '';
                            return <li key={index} onClick={(el) => { setObjectTabs({ ...objectTabs, selectedTab: objectTabs.tabs[index] }); }}>
                                <div className={style}>
                                    <Text weight={index === objectTabs.selectedTab.index ? "bold" : "regular"}  >{tab.label}</Text>
                                </div>
                            </li>
                        })}
                    </ul>
                }
                {error !== '' ?
                    <Warning label={error} details={errorDetail} />
                    :
                    <Flex fill>
                        {objectTabs.selectedTab.content}
                    </Flex>
                }
            </>
        }
    </Flex>
}

export default TabsContainer;