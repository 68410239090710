import "../scss/OvertimeViewer.scss";

import { Flex, Text, Table, Divider, Loader } from '@fluentui/react-northstar';
import { IAppState } from "../interfaces/IAppState";
import { useState, useEffect } from 'react';
import CustomPeriodSelector from "./CustomPeriodSelector";
import UserSelector from "./UserSelector";
import { UserType } from "../constants/AppEnum";
import { useSelector } from "react-redux";
import { IOrgChartUser } from "../interfaces/IOrgChartUser";
import * as cookieHelper from '../helpers/CookiesHelper';
import { ISelectedUser } from "../interfaces/ISelectedUser";
import { IMonthlyAllowedPeriod } from "../interfaces/IMonthlyAllowedPeriod";
import { getOvertimeRecovery } from '../helpers/Fetch';
import { ISize } from "../interfaces/ISize";
import useWindowSize from '../hooks/useWindowSize';
import { SiaStrings } from "../strings/strings";
import { IUser } from "../interfaces/IUser";
import moment from 'moment';

const OvertimeViewer = () => {
    const appState: IAppState = useSelector((appState) => appState) as IAppState;
    const today: Date = new Date();
    const orgChart: IOrgChartUser = cookieHelper.getApplicationCookies.getOrgChartCookie();
    const [selectedUser, setSelectedUser] = useState<ISelectedUser>({ email: '', idUserSF: '', fullName: '', companyCountry: '', firstName: '', lastName:'' });
    const [selectedPeriod, setSelectedPeriod] = useState<IMonthlyAllowedPeriod | null>(null);
    const [loading, setLoading] = useState(true);
    const [rows, setRows] = useState<{ key: string, items: any[] }[]>([]);
    const size: ISize = useWindowSize();



    const tableHeader = {
        key: 'header',
        items: [
            {
                content: 'Utente',
                key: 'utente',
                className: 'table-header',
                // styles: { maxWidth: "6%" }
            },
            {
                content: 'Data straordinario',
                key: 'data_straordinario',
                className: 'table-header',
                // styles: { maxWidth: "6%" }
            },
            {
                content: 'Data recupero',
                key: 'data_recupero',
                className: 'table-header',
                // styles: { maxWidth: "6%" }
            },
            {
                content: 'Minuti da recuperare',
                key: 'minuti_darecuperare',
                className: 'table-header',
                // styles: { maxWidth: "6%" }
            },
            {
                content: 'Minuti recuperati',
                key: 'minuti_recuperati',
                className: 'table-header',
                // styles: { maxWidth: "6%" }
            },
        ]
    }

    const loadData = async (): Promise<any> => {
        const response = await getOvertimeRecovery();
        return response.payload.overtimesRecoveries;
    }

    useEffect(() => {
        loadData().then((response) => {
            buildTableRows(response);
        });
    }, []);

    useEffect(() => {
        const loadFilteredData = async (userId?: string, startDate?: Date, endDate?: Date): Promise<any> => {
            const response = await getOvertimeRecovery(userId, startDate, endDate);
            return response.payload.overtimesRecoveries;
        }

        let userId: string | undefined = undefined;
        let startDate: Date | undefined = undefined;
        let endDate: Date | undefined = undefined;

        if (selectedUser !== null && selectedUser.idUserSF !== '') {
            userId = selectedUser.idUserSF;
        }

        if (selectedPeriod !== null && selectedPeriod.month !== null && selectedPeriod.year !== null) {
            startDate = new Date(selectedPeriod.year, selectedPeriod.month - 1, 1);
            endDate = new Date(selectedPeriod.year, selectedPeriod.month, 0, 23, 59);
        }
        if (userId !== undefined || startDate !== undefined || endDate || undefined) {
            loadFilteredData(userId, startDate, endDate).then((response) => {
                buildTableRows(response);
            });
        }

    }, [selectedPeriod, selectedUser])

    const buildTableRows = async (data: any) => {
        let cUsers: IUser[] = await cookieHelper.setApplicationCookies.setCountryUsersCookie(orgChart.companyCountry);

        const tmpRows = data.map((item: any) => {
            const rowItems: any[] = [];
            //Colonna Utente
            const users: IUser[] = cUsers.filter(u => u.idUserSF === item.idUserSF);
            let userName: string = "";
            if (users.length > 0) {
                userName = users[0].fullName;
            }
            rowItems.push({
                key: `user_${item.id}`,
                content: <Text content={userName} />
            });

            //Colonna Data straordinario
            rowItems.push({
                key: `ds_${item.id}`,
                content: <Text content={moment(item.overtimeDay).format("DD/MM/YYYY")} />
            });

            //Colonna Data recupero
            rowItems.push({
                key: `dr_${item.id}`,
                content: <Text content={moment(item.maxRecoveryDay).format("DD/MM/YYYY")} />
            });

            //Colonna minuti straordinario
            rowItems.push({
                key: `straordinario_${item.id}`,
                content: <Text content={item.originalAmount} />
            });

            //Colonna minuti recuperati
            rowItems.push({
                key: `recupero_${item.id}`,
                content: <Text content={item.recoveredAmount} />
            });


            return {
                key: `row_${item.id}`,
                items: rowItems
            }
        });
        setRows(tmpRows);

        setLoading(false);

    }

    if (loading) {
        <Flex fill space="around" style={{ height: `${size.height}px` }}>
            <Loader label={SiaStrings.LOADING} style={{ paddingTop: '20px', paddingBottom: '20px' }} />
        </Flex>
    }

    return <Flex fill column className="overtimeviewer-container">
        <Text size="largest" content="Recupero straordinari" />
        <Flex style={{ width: "75%" }} vAlign="center">
            {(appState.userType === UserType.HR || appState.userType === UserType.Admin || ((orgChart !== undefined && orgChart !== null) && orgChart.users.length > 0)) &&
                <Flex style={{ paddingTop: "3px" }}>
                    <UserSelector onSelectedUserChange={setSelectedUser} disabled={loading} allUsers={true} />
                </Flex>
            }
            <CustomPeriodSelector showFirstEmprty={true} userType={appState.userType} onSelectedPeriodChange={setSelectedPeriod} disabled={loading} />
        </Flex>
        <Divider />
        <Flex>
            {loading ?
                <Flex fill space="around" style={{ height: `${size.height}px` }}>
                    <Loader label={SiaStrings.LOADING} style={{ paddingTop: '20px', paddingBottom: '20px' }} />
                </Flex>
                :
                <Table className="table"
                    header={tableHeader}
                    rows={rows}
                />
            }

        </Flex>
    </Flex>

}

export default OvertimeViewer;