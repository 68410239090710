import { ThemePrepared, teamsDarkTheme, teamsHighContrastTheme, teamsTheme } from '@fluentui/react-northstar';
import { UserType } from '../constants/AppEnum';
export const getTeamsTheme = (currentTheme: any): ThemePrepared<any> => {
    switch (currentTheme) {
        case 'dark':
            return teamsDarkTheme;
        case 'contrast':
            return teamsHighContrastTheme;
        default:
            return teamsTheme
    }
}

export const getRoleFromToken = (userToken: string): UserType => {
    let tokenInfo: string[] = userToken.split('.');
    let buff = Buffer.from(tokenInfo[1], 'base64');
    let token = buff.toString('ascii');
    const tokenObj: any = JSON.parse(token);        
    if (tokenObj.roles !== null) {
        switch (tokenObj.roles[0]) {
            case "HR":
                return UserType.HR
            case "ADMIN":
                return UserType.Admin
            default:
                return UserType.User
        }
    }
    return UserType.User;
}