/* IMPORT CSS / SASS */
import '../scss/App.scss';

/* IMPORT REACT & THIRD PARTY COMPONENTS */
import React, { useState, useEffect } from 'react';
import * as microsoftTeams from "@microsoft/teams-js";
import { Provider, ThemePrepared, teamsTheme } from '@fluentui/react-northstar';
import { useDispatch, useSelector } from 'react-redux';
import axios from "axios";
/* IMPORT CUSTOM COMPONENTS */
import { getTeamsTheme } from '../helpers/TeamsHelper';
import AppRoutes from './AppRoutes';
import * as teamsActions from '../stores/actions/TeamsActions';
import * as siaActions from '../stores/actions/SiaActions';
import * as CookiesHelper from '../helpers/CookiesHelper';
import { IAppState } from "../interfaces/IAppState";
import Notifications from './Notifications';

const App = () => {

  const [currentTheme, setCurrentTheme] = useState(teamsTheme);
  const appState: IAppState = useSelector((appState) => appState) as IAppState;
  const [appCanStart, setAppCanStart] = useState(false);
  const dispatch = useDispatch();



  useEffect(() => {
    //Gestione di tutte le chiamate axios:se non c'è o è scaduto ottengo lo user token e lo imposto nella richiesta
    axios.interceptors.request.use(
      async (config) => {
        var token = await CookiesHelper.setApplicationCookies.setUserToken(false);
        config.headers['Accept'] = 'application/json';
        if (!appState.debug) {
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
      },
      error => {
        console.log(`Errore in chiamata api ${error}`);
        Promise.reject(error)
      });
    //Gestione di tutte le response axios: se la risposta è 401 significa che lo user token non è più valido e devo quidni ottenerne uno nuovo
    axios.interceptors.response.use((response) => {
      return response
    },
      async (error) => {
        if (error.response?.data?.type === 7){
          //Type = 7 quando non viene trovato il contratto dell'utente su SSFF.
          CookiesHelper.setApplicationCookies.setContractNotFoundCookie();
        }
        const originalRequest = error.config;
        if (error.response.status === 401 && !originalRequest._retry) {
          console.log('Erroe 401, refresh token...');
          originalRequest._retry = true;
          await CookiesHelper.setApplicationCookies.setUserToken(true);
          return axios(originalRequest);
        }
        console.log(`Errore in response ${error}`);
        return Promise.reject(error);
      }
    );
    try {
      // Initialize the Microsoft TEAMS SDK
      microsoftTeams.initialize();

      // Get the Microsoft TEAMS context
      microsoftTeams.getContext((context: microsoftTeams.Context) => {
        if (Object.keys(context).length > 0 && context.userPrincipalName !== undefined
          && context.userPrincipalName !== null && context.userPrincipalName !== undefined
          && context.userPrincipalName !== "") {
          // Set the context in the store
          dispatch(teamsActions.setTeamsContext(context));
          //Indica se l'app è in esecuzione su in dispositivo mobile
          const isMobile = (context.hostClientType && context.hostClientType !== microsoftTeams.HostClientType.web && context.hostClientType !== microsoftTeams.HostClientType.desktop) ? true : false;
          dispatch(siaActions.setIsMobile(isMobile));
          // Set the TEAMS theme
          setCurrentTheme(getTeamsTheme(context.theme));
          //Set the TEAMS context status in the store
          dispatch(teamsActions.setTeamsContextIsInitialized(true));
        }
      });

      // Handle TEAMS theme changes
      microsoftTeams.registerOnThemeChangeHandler((theme) => {
        var newTeamsContext = { ...appState.teamsContext };
        newTeamsContext.theme = theme;
        dispatch(teamsActions.setTeamsContext(newTeamsContext));
        setCurrentTheme(getTeamsTheme(theme) as ThemePrepared);
      });
    } catch (error) {
      dispatch(teamsActions.setUnexpectedError(true));
    }
    //}, [appState.teamsContext, dispatch]);
  }, []);

  //Quando il contesto di Teams è inizializzato richiedo il token dell'utente e poi chiamo le API
  useEffect(() => {
    (async () => {
      try {
        if (appState.teamsContextIsInitialized) {
          /* SET COOKIES WITH COMMON SHARED VALUES */
          //imposto user token usato per tutte le chiamate axios successive
          await CookiesHelper.setApplicationCookies.setUserToken(false);
          microsoftTeams.appInitialization.notifySuccess();
        }
      } catch (error) {
        const errorMessage: string = (error as Error).message;
        microsoftTeams.appInitialization.notifyFailure({
          reason: microsoftTeams.appInitialization.FailedReason.Other,
          message: errorMessage
        });
      }

    })();
  }, [appState.teamsContextIsInitialized]);

  return <Provider theme={currentTheme}> {(appState.teamsContextIsInitialized)
    ?
    <React.Fragment>
      <Notifications />
      <AppRoutes />
    </React.Fragment>
    : null}
  </Provider>

}
export default App;