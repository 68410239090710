//Componenente che visualizza le attività nel mensile
import "../scss/MonthlyActivities.scss";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import moment from 'moment';
import { ITimesheetAction } from "../interfaces/ITimesheetAction";
import { IAppState } from "../interfaces/IAppState";
import { splitTimesheetActions } from '../helpers/SiaHelper';
import { Flex, Alert, Tooltip, Button, Dialog, MoreIcon, CloseIcon, InfoIcon, TrashCanIcon, Text, Divider } from "@fluentui/react-northstar";
import { IFestivity } from "../interfaces/IFestivity";
import { IActionType } from "../interfaces/IActionType";
import * as Constants from '../constants/AppConstants';
import { UserType } from "../constants/AppEnum";
import { Home20Regular, Home16Regular, Building16Regular, Building20Regular, ArrowCircleRight16Regular, ArrowCircleLeft16Regular, Info20Regular, Info16Regular } from '@fluentui/react-icons';
import MonthlyActivityStartEndTime from "./MonthlyActivityStartEndTime";
import { deleteTimeSheet } from "../helpers/Fetch";
import MonthlyActivitiesAddNew from "./MonthlyActivitiesAddNew";
import { map } from "lodash";

export interface IMonthlyActivitiesProps {
    activities: ITimesheetAction[];
    hoursPerDay: number;
    expectedWork: number;
    festivities: IFestivity[];
    date: Date;
    idTimbraturaAction: number;
    idPranzoAction: number;
    idPausaAction: number;
    actions: IActionType[]
    onActivitiesChange?: any;
    userActions: { key: string, header: string, disabled: boolean }[];
    idUserSF: string;
    //canEdit: boolean;
}

interface IDeleteAction {
    showDialog: boolean;
    details: any;
    idTimesheet: number;
    errorMessage: string;
    loading: boolean;
}

const MonthlyActivities = (props: IMonthlyActivitiesProps) => {
    const appState: IAppState = useSelector((appState) => appState) as IAppState;
    const [splittedActions, setSplittedActions] = useState<ITimesheetAction[]>([]);
    //Elenco delle attività da considerare per impostare il contenuto del box (<Alert/> principlae)
    const [controlActions, setControlActions] = useState<ITimesheetAction[]>([]);
    const [showActivitiesDialog, setShowActivitiesDialog] = useState(false);
    //const [idPranzoAction, setIdPranzoAction] = useState(0);
    const [deleteAction, setDeleteAction] = useState<IDeleteAction | null>(null);
    //Indica se l'ultima azione della giornata è l'azione di fine lavoro. Serve per applicare uno stile particolare per l'icona Smart/Ufficio
    const [lastActionIsEndWork, setLastActionIsEndWork] = useState(false);

    //Array che contiene gli id delle azioni di trasferta giornata intera
    const trasferteIds: number[] = props.actions.filter((action) => action.groupName === Constants.GROUP_NAME_TRASFERTA).map(i => i.id);

    const isFestivity: boolean = props.festivities.length === 0 ? false : props.festivities[0].dates.filter((f) => { return moment(f).format("DD/MM/YYYY") === moment(props.date).format("DD/MM/YYYY"); }).length > 0;
    const canEdit: boolean = appState.userType === UserType.HR || appState.userType === UserType.Admin
    //Numero di azioni di timbratura. Serve per modificare il layout del box principale (<Alert />) quando ci sono più azioni ingresso/uscita nella gioranta
    const workActionNumber: number = props.activities.filter(ac => ac.idActionType === props.idTimbraturaAction || trasferteIds.includes(ac.idActionType)).length;

    useEffect(() => {
        (async () => {
            if (props.activities.length > 0) {

                const notCalendarActions = props.actions.filter((action: IActionType) => {
                    //return action.groupName === Constants.GROUP_NAME_TIMBRATURA || action.groupName === Constants.GROUP_NAME_FERIE || action.groupName === Constants.GROUP_NAME_MALATTIA || action.groupName === Constants.GROUP_NAME_TRASFERTA || action.groupName === Constants.GROUP_NAME_PRANZO;
                    return action.groupName !== Constants.GROUP_NAME_CALENDARIO;
                });

                //Ricavo l'id dell'azione di pausa pranzo
                // const tmpIdPranzoAction = notCalendarActions.filter((action: IActionType) => {
                //     return action.groupName === Constants.GROUP_NAME_PRANZO;
                // })[0].id;
                // setIdPranzoAction(tmpIdPranzoAction);

                //Ricavo gli id delle azioni da utilizzare per impostare la visualizzazione nel box (<Alert/> principale)
                const controlActionIds = props.actions.filter((action: IActionType) => {
                    return action.groupName === Constants.GROUP_NAME_TIMBRATURA || action.groupName === Constants.GROUP_NAME_TRASFERTA;
                    //return (action.groupName !== Constants.GROUP_NAME_CALENDARIO && action.groupName !== Constants.GROUP_NAME_PAUSA && action.groupName !== Constants.GROUP_NAME_PRANZO);
                }).map(i => {
                    return i.id
                });

                if (notCalendarActions.length > 0) {
                    //Ottengo le attività con gruppo diversa da calendario
                    //Le attività del gruppo PRANZO saranno visualizzate solo nel pop up di gestione attività e non nel box principale
                    const activities = props.activities.filter((activity: ITimesheetAction) => {
                        const filtered = notCalendarActions.filter((action: IActionType) => {
                            return action.id === activity.idActionType;
                        });
                        return filtered.length > 0;
                    });
                    //activities = tutte le azioni da splittare e visualizzare nel dialog "Gestione attività"
                    //controlActionIds = id delle activities da visualizzare nel box                   
                    await elaborateTimeSheet(activities, controlActionIds);
                }
            } else {
                setSplittedActions([]);
            }
        })();
    }, []);


    const elaborateTimeSheet = async (items: ITimesheetAction[], controlActionIds: number[]): Promise<void> => {
        try {
            //Passo hoursPerDay e non expectedWork perchè expectedWork per alcuni giorni (es. domenica) è uguale a 0                        
            let getSplitted = await splitTimesheetActions(items, props.hoursPerDay);
            //Se c'è una sola azione ed è timbratura inserisco un'altra azione per simulare la fine lavoro con ora 00:00
            //Scommentare se si vuole visualizzare l'icona di uscita con 00:00, ma in questo caso ricordarsi di :
            // 1) Nel componente MonthlyActivityStartEndTime non aprire il dialog di modifica per questa azione
            // 2) Non visualizzare l'azione nel dettaglio delle attività della giornata
            // if (getSplitted.length === 1 && getSplitted[0].idActionType === props.idTimbraturaAction) {
            //     let fakeEndDate: Date = new Date();
            //     fakeEndDate.setHours(0);
            //     fakeEndDate.setMinutes(0);
            //     getSplitted.push({ ...getSplitted[0], startDate: null, endDate: fakeEndDate, header: "fake end" });
            // }                        
            setSplittedActions(getSplitted);
            const tmpControlActions = getSplitted.filter((sa: ITimesheetAction) => {
                return controlActionIds.indexOf(sa.idActionType as number) !== -1;
            });

            setControlActions(tmpControlActions);

            //const lastAction = getSplitted[getSplitted.length - 1];
            //const tmpLastActionIsEndWork: boolean = (lastAction.idActionType === props.idTimbraturaAction && lastAction.endDate !== null) ? true : false;
            //setLastActionIsEndWork(tmpLastActionIsEndWork);
            //Se esiste l'azione di fine lavoro, serve per il valore del margin dell'icona sesde/smart
            const endWorkExists: boolean = getSplitted.filter(a => a.idActionType === props.idTimbraturaAction && a.endDate !== null).length > 0 ? true : false;
            setLastActionIsEndWork(endWorkExists);

        } catch (error) {
            console.log(`errore in caricamento azioni mensili : ${error}`);
        }
    }

    //Aggiornamento dell'orario di ingresso o di uscita
    const updateCell = (timesheet: ITimesheetAction) => {
        const tmpActions = splittedActions.map((action) => {
            //Cerco le azioni modificate e le aggiorno
            if (action.id === timesheet.id) {
                if (action.startDate && (action.endDate === null || action.endDate === undefined)) { //endDate non è valorizzata per l'azione di inizio, quindi aggiorno la data di inizio
                    action.startDate = timesheet.startDate;
                } else if (action.endDate && (action.startDate === null || action.startDate === undefined)) { //startDate non è valorizzata per l'azione di fine, quindi aggiorno la data di fine
                    action.endDate = timesheet.endDate;
                }
            }
            return action;
        });
        setSplittedActions(tmpActions);
    }

    const deleteActivity = (action: ITimesheetAction) => {
        //Controllo da visualizzare nel contenuto del dialog per la cancellazione  
        const deleteDetail: any = <Flex column>
            {splittedActions.filter((ts) => {
                return ts.id === action.id
            }).map((action: ITimesheetAction, index: number) => {
                return <Flex gap="gap.medium" key={`action_list_${index}`}>
                    <span>{action.startDate !== null ? moment(action.startDate).format("HH:mm") : action.endDate !== null ? moment(action.endDate).format("HH:mm") : ''}</span>
                    <span>{action.header}</span>
                </Flex>
            })}
        </Flex>
        const tmpValue: IDeleteAction = {
            showDialog: true,
            idTimesheet: action.id ?? 0,
            details: deleteDetail,
            errorMessage: "",
            loading: false
        }
        setDeleteAction(tmpValue);
    }

    const deleteUserTimesheet = async (): Promise<void> => {
        const tmpDeleteAction: IDeleteAction | null = deleteAction !== null ? { ...deleteAction } : null;
        if (tmpDeleteAction && tmpDeleteAction !== null) {
            if (tmpDeleteAction.idTimesheet !== 0) {
                const response = await deleteTimeSheet(tmpDeleteAction.idTimesheet);
                if (response.status === 1) {
                    //Rimuovo le attività
                    let tmpSplittedAction: ITimesheetAction[] = [];
                    splittedActions.forEach((action: ITimesheetAction) => {
                        if (action.id !== tmpDeleteAction.idTimesheet) {
                            tmpSplittedAction.push(action);
                        }
                    });
                    setSplittedActions(tmpSplittedAction);
                    tmpDeleteAction.showDialog = false;
                } else {
                    tmpDeleteAction.errorMessage = response.message ?? "Errore imprevisto";
                }
            } else {
                tmpDeleteAction.errorMessage = "ID timesheet non valido";
            }
            setDeleteAction(tmpDeleteAction);
        }
    }

    //Quando viene chiuso il dialog di modifica dell'ora di ingresso/uscita faccio refresh dell'intera riga
    const refreshData = (day: Date) => {
        props.onActivitiesChange(day);
    }

    //Verifica se visualizzare l'azione della giornata senza orari.
    //Serve nei casi in cui c'è un unica azione, ma anche la pausa pranzo (es. Trasferta giornata completa)
    //Nel caso ritorna la posizione dell'azione nell'array
    const showOnlyMainAction = (splittedActions: ITimesheetAction[]): number => {

        let result: number = 0;
        for (let index = 0; index < splittedActions.length; index++) {
            const action = splittedActions[index];
            //Hanno richiesto che anche gli orari della trasferta siano modificabili come già succedeva per la timbratura
            if (action.idActionType === props.idTimbraturaAction || trasferteIds.includes(action.idActionType)) {
                return -1;
            }
            if (action.idActionType !== props.idPranzoAction) {
                result = index;
            }
        }
        return result;
    }


    const closeDialog = () => {
        setShowActivitiesDialog(false);
        //Aggiorno la riga della tabella
        props.onActivitiesChange(props.date);

    }

    //Per utenti NON Hr visualizzo festività se non ci sono attività nella giornata
    if (isFestivity && controlActions.length === 0 && appState.userType !== UserType.HR) {
        return <div className="monthlyActivity-container" key={`festivity_${props.date}`}>
            <Flex fill gap="gap.medium">
                <Alert className="action-details-main-box" content={<Text content="Festività" />} />
            </Flex>
        </div>
    }


    if (props.expectedWork === 0 && canEdit === false && props.activities.length === 0) {
        return <div key={`empty_${props.date}`} />
    }

    return <div className="monthlyActivity-container" key={`activities_${props.date}`}>
        <Flex fill vAlign="center" gap="gap.medium" space="between" >
            {/* Se non ci sono attività */}
            {(controlActions.length === 0) ?
                <>
                    <Alert className="action-details-main-box" content={
                        <Flex vAlign="center" space="between">
                            {/* Nessuna azione viene visualizato solo se per il giorno non c'è niente, neanche un assistenza */}
                            <Text style={{ width: "100%" }} content={isFestivity ? "Festività" : "Nessuna azione"} />
                            {canEdit &&
                                <Button className="action-detail-btn-gestione" text iconOnly icon={<MoreIcon />} title="Gestione attività" onClick={() => setShowActivitiesDialog(true)} />
                            }
                        </Flex>
                    }
                    />
                </>
                :

                <Alert className="action-details-main-box" content={
                    <Flex vAlign="center" className="action-detail-container1" fill>
                        <Flex fill gap="gap.smaller" vAlign="center" space="between" className={`${canEdit ? 'action-detail-container2' : 'action-detail-container3'}`}  >
                            {
                                // C'è un azione di timbratura o trasferta, nel box visualizzo solo quella
                                showOnlyMainAction(controlActions) === -1 ?
                                    <>
                                        {
                                            controlActions.map((action: ITimesheetAction, index: number, actions: ITimesheetAction[]) => {
                                                //Le azioni di pausa pranzo non vengono visualizzate in questa cella, ma in una cella dedicata della riga gestita da un altro componente
                                                if (action.idActionType !== props.idPranzoAction) {
                                                    if (action.startDate !== null && action.endDate === null) {
                                                        //Anche la trasferta deve essere modificabile
                                                        if (action.idActionType === props.idTimbraturaAction || trasferteIds.includes(action.idActionType)) {
                                                            //INIZIO LAVORO + icona smart working
                                                            return <React.Fragment key={`startwork_${action.id}`}>

                                                                < MonthlyActivityStartEndTime type="start" action={action} showTime={workActionNumber <= 2} idTimbraturaAction={props.idTimbraturaAction} trasferteIds={trasferteIds} onTimesheetUpdated={updateCell} onDialogClose={refreshData} />
                                                                {action.idActionType === props.idTimbraturaAction &&
                                                                    <>
                                                                        {(action.idWorkingMode !== null && (action.idWorkingMode === 2 || action.idWorkingMode === 1)) ?
                                                                            <Tooltip trigger={workActionNumber <= 2 ? <Home20Regular className={workActionNumber === 1 ? 'action-detail-workingmode-icon' : ''} style={{ right: `${lastActionIsEndWork ? '0' : '10px'}` }} /> : <Home16Regular />} content={action.idWorkingMode === 2 ? 'Smartworking' : 'Lavoro agile'} />
                                                                            :
                                                                            <Tooltip trigger={workActionNumber <= 2 ? <Building20Regular className={workActionNumber === 1 ? 'action-detail-workingmode-icon' : ''} style={{ right: `${lastActionIsEndWork ? '0' : '10px'}` }} /> : <Building16Regular />} content="In Sede" />
                                                                        }
                                                                    </>
                                                                }
                                                                {/* Nel caso di trasferta non visualizzo un icona, ma il nome del gruppo dell'azione (TRASFERTA), il titolo dell'azione è troppo lungo */}
                                                                {trasferteIds.includes(action.idActionType) &&
                                                                    <Flex vAlign="center" hAlign="center" gap="gap.small" fill id={`trasferta_${action.id}`}  >
                                                                        <Tooltip trigger={workActionNumber <= 2 ? <Info20Regular className={workActionNumber === 1 ? 'action-detail-workingmode-icon' : ''} style={{ right: `${lastActionIsEndWork ? '0' : '10px'}` }} /> : <Info16Regular />} content={`${props.actions.filter(a => a.id === action.idActionType)[0].title}`} />
                                                                    </Flex>
                                                                }
                                                            </React.Fragment>
                                                        }
                                                    } else if (action.startDate === null && action.endDate !== null && (action.idActionType === props.idTimbraturaAction || trasferteIds.includes(action.idActionType))) {
                                                        //FINE LAVORO                                
                                                        return <MonthlyActivityStartEndTime key={`end_${action.id}`} type="end" showTime={workActionNumber <= 2} action={action} idTimbraturaAction={props.idTimbraturaAction} trasferteIds={trasferteIds} onTimesheetUpdated={updateCell} onDialogClose={refreshData} />
                                                    }
                                                }
                                            })
                                        }
                                    </>
                                    :
                                    <>
                                        {/* <span>{`cl = ${controlActions.length} - id = ${controlActions[0].header}`}</span> */}
                                        {(controlActions.length === 1 && controlActions[0].idActionType !== props.idTimbraturaAction) ?
                                            // Caso in cui c'è un unica azione e non è timbratura 
                                            <Flex fill gap="gap.small" vAlign="center">
                                                <Text style={{ width: "100%" }} content={controlActions[0].header} />
                                            </Flex >
                                            :
                                            // Caso in cui ci sono più azioni. Visualizzo un icona generica di inizio/fine e l'ora 
                                            //Se per caso la data inizio e fine non sono valorizate visualizzo il nome dell'azione e basta (non dovrebbe mai succedere)
                                            <Flex vAlign="center" hAlign="center" gap="gap.small" space="between" fill >
                                                {
                                                    controlActions.map((action: ITimesheetAction, index: number, actions: ITimesheetAction[]) => {
                                                        //Le azioni di pausa pranzo non vengono visualizzate in questa cella, ma in una cella dedicata della riga gestita da un altro componente
                                                        if (action.idActionType !== props.idPranzoAction && action.idActionType !== props.idPausaAction) {
                                                            if (action.startDate === null && action.endDate === null) {
                                                                return <Text content={`${action.header}`} />
                                                            }

                                                            let isStart: boolean = false;
                                                            let icon: any;
                                                            if (action.startDate !== null) {
                                                                isStart = true;
                                                                icon = <ArrowCircleRight16Regular style={{ paddingTop: '7px' }} />;
                                                            } else {
                                                                icon = <ArrowCircleLeft16Regular style={{ paddingTop: '7px' }} />;
                                                            }
                                                            const data: string = action.startDate !== null ? moment(action.startDate).format("HH:mm") : moment(action.endDate).format("HH:mm");
                                                            return <Flex key={`otheractioncontainer_${index}`} gap="gap.medium" hAlign="center" vAlign="center" className="action-detail-container1">
                                                                {isStart ?
                                                                    <Tooltip content={action.header} trigger={<Flex key={`otheraction_${index}`} gap="gap.small" hAlign="center" vAlign="center">{icon} <Text content={data} /></Flex>} />
                                                                    :
                                                                    <Tooltip content={action.header} trigger={<Flex key={`otheraction_${index}`} gap="gap.small" hAlign="center" vAlign="center"><Text content={data} /> {icon}</Flex>} />
                                                                }
                                                            </Flex>
                                                        }
                                                    })
                                                }
                                                {/* {canEdit &&
                                                        <Button text iconOnly icon={<MoreIcon />} title="Gestione attività" onClick={() => setShowActivitiesDialog(true)} />
                                                    } */}
                                            </Flex>
                                        }
                                    </>
                            }
                        </Flex>
                        {canEdit &&
                            <Button className="action-detail-btn-gestione" text iconOnly icon={<MoreIcon />} title="Gestione attività" onClick={() => setShowActivitiesDialog(true)} />
                            // <Flex gap="gap.small">
                            //     <Button text iconOnly icon={<MoreIcon />} title="Gestione attività" onClick={() => setShowActivitiesDialog(true)} />
                            // </Flex>
                        }
                    </Flex>
                } />

                // <Alert style={{ width: "100%" }} content={
                //     <Flex vAlign="center" space="between">
                //         {(controlActions.length === 1 && controlActions[0].idActionType !== props.idTimbraturaAction) ?
                //             // Caso in cui c'è un unica azione e non è timbratura
                //             <Flex fill gap="gap.small" vAlign="center">
                //                 <Text style={{ width: "100%" }} content={controlActions[0].header} />
                //                 {props.canEdit &&
                //                     <Flex gap="gap.small">
                //                         <Button text iconOnly icon={<MoreIcon />} title="Gestione attività" onClick={() => setShowActivitiesDialog(true)} />
                //                     </Flex>
                //                 }
                //             </Flex >
                //             :
                //             <>
                //                 <Flex fill gap="gap.smaller" vAlign="center" style={{ paddingLeft: "5px", paddingRight: "5px" }} space="between" >
                //                     {
                //                         showOnlyMainAction(controlActions) !== -1 ?
                //                             <>
                //                                 <Flex fill gap="gap.small" vAlign="center">
                //                                     <Text style={{ width: "100%" }} content={controlActions[showOnlyMainAction(controlActions)].header} />
                //                                 </Flex >
                //                             </>
                //                             :
                //                             controlActions.map((action: ITimesheetAction) => {
                //                                 //Le azioni di pausa pranzo non vengono visualizzate in questa cella, ma in una cella dedicata della riga gestita da un altro componente
                //                                 if (action.idActionType !== idPranzoAction) {
                //                                     if (action.startDate !== null && action.endDate === null) {
                //                                         if (action.idActionType === props.idTimbraturaAction) {
                //                                             //INIZIO LAVORO + icona smart working
                //                                             return <Flex key={`start_${action.id}`} fill className="action-details">
                //                                                 <MonthlyActivityStartEndTime type="start" action={action} idTimbraturaAction={props.idTimbraturaAction} onTimesheetUpdated={updateCell} onDialogClose={refreshData} />
                //                                                 {(controlActions[0].idWorkingMode !== null && controlActions[0].idWorkingMode === 2) &&
                //                                                     <Flex fill hAlign="center" >
                //                                                         <Tooltip trigger={<Home20Regular />} content="Smartworking" />
                //                                                     </Flex>
                //                                                 }
                //                                             </Flex>
                //                                         } else {
                //                                             //Se l'azione non è TIMBRATURA visualizza anche il nome dell'assistenza
                //                                             return <Flex key={`assistance_${action.id}`} gap="gap.small" vAlign="center">
                //                                                 <MonthlyActivityStartEndTime type="start" action={action} idTimbraturaAction={props.idTimbraturaAction} onTimesheetUpdated={updateCell} onDialogClose={refreshData} />
                //                                                 {/* <Alert content={action.header !== null ? action.header.replace(SiaStrings.TS_START, "").trim() : ""} /> */}
                //                                                 <Text content={action.header !== null ? action.header.replace(SiaStrings.TS_START, "").trim() : ""} />
                //                                             </Flex>
                //                                         }
                //                                     } else if (action.startDate === null && action.endDate !== null) {
                //                                         //FINE LAVORO                                
                //                                         return <MonthlyActivityStartEndTime key={`end_${action.id}`} type="end" action={action} idTimbraturaAction={props.idTimbraturaAction} onTimesheetUpdated={updateCell} onDialogClose={refreshData} />
                //                                     }
                //                                 }
                //                             })
                //                     }
                //                 </Flex>
                //                 {props.canEdit &&
                //                     <Flex gap="gap.small">
                //                         <Button text iconOnly icon={<MoreIcon />} title="Gestione attività" onClick={() => setShowActivitiesDialog(true)} />
                //                     </Flex>
                //                 }
                //             </>
                //         }
                //     </Flex>
                // } />
            }

            {/* Dialog per la gestione delle attività */}
            <Dialog
                open={showActivitiesDialog}
                style={{ maxWidth: "500px" }}
                cancelButton={{ content: "Chiudi", disabled: false }}
                // confirmButton={{ content: "Salva", disabled: false }}
                closeOnOutsideClick={false}
                onCancel={() => {
                    setShowActivitiesDialog(false);
                    //Aggiorno la riga della tabella
                    props.onActivitiesChange(props.date);
                }}
                headerAction={{
                    icon: <CloseIcon />, title: 'Close', onClick: () => {
                        setShowActivitiesDialog(false);
                        //Aggiorno la riga della tabella
                        props.onActivitiesChange(props.date);

                    }
                }}
                header={`Gestione attività del ${moment(props.date).format("DD/MM/yyyy")}`}
                content={
                    <Flex column gap="gap.smaller" fill >
                        <>
                            <Divider />
                            {splittedActions.length === 0 ?
                                <>
                                    <Alert info icon={<InfoIcon />} content="Nessuna azione registrata" />
                                </>
                                :
                                <>
                                    {splittedActions.map((action: ITimesheetAction, index: number) => {
                                        return <Flex gap="gap.smaller" key={`actionDetail_${index}`} fill vAlign="center" space="between" >
                                            <Flex gap="gap.smaller">
                                                {(action.startDate && action.startDate !== null && action.endDate && action.endDate !== null) &&
                                                    <Text content={action.startDate && action.startDate !== null ? moment(action.startDate).format("HH:mm") : moment(action.endDate).format("HH:mm")} />
                                                }
                                                <Text content={action.header} />
                                            </Flex>
                                            <Button icon={<TrashCanIcon />} text iconOnly title="Elimina" onClick={() => { deleteActivity({ ...action }) }} />
                                        </Flex>
                                    })}
                                </>
                            }
                        </>
                        <Divider />
                        <MonthlyActivitiesAddNew idUserSF={props.idUserSF} userActions={props.userActions} allActionsType={props.actions} idTimbraturaAction={props.idTimbraturaAction} date={props.date} onCloseDialog={closeDialog} />
                        <Divider />
                    </Flex>
                }
            />
            {/* Dialog di conferma eliminazione attività */}
            <Dialog
                open={deleteAction !== null && deleteAction.showDialog === true}
                style={{ maxWidth: "450px" }}
                cancelButton="Annulla"
                confirmButton={{ content: "Conferma", disabled: deleteAction?.loading ?? true }}
                header="Elimina attività"
                closeOnOutsideClick={false}
                onCancel={() => {
                    if (deleteAction !== null) {
                        const tmpValue = { ...deleteAction };
                        tmpValue.showDialog = false;
                        tmpValue.loading = false;
                        setDeleteAction(tmpValue);
                    }
                }}
                onConfirm={async () => {
                    const tmpDeleteAction: IDeleteAction | null = deleteAction !== null ? { ...deleteAction } : null;
                    if (tmpDeleteAction !== null) {
                        tmpDeleteAction.loading = true;
                        setDeleteAction(tmpDeleteAction);
                    }
                    deleteUserTimesheet();
                }}
                content={
                    <Flex column gap="gap.medium" style={{ padding: '10px' }}>
                        <Flex gap="gap.small" vAlign="center" >
                            <Text important size="large" content="Attenzione :" />
                            <Text size="medium" content="Verrà eliminata l'intera attività" />
                        </Flex>
                        {deleteAction !== null &&
                            deleteAction.details
                        }
                        {(deleteAction !== null && deleteAction.errorMessage !== "") &&
                            <Alert danger content={deleteAction.errorMessage} />
                        }
                    </Flex>
                }

            />
        </Flex >
    </div >

}

export default MonthlyActivities;