/* IMPORT CSS / SASS */
import '../scss/Badge.scss';

import React, { useEffect, useState } from 'react';

/* IMPORT REACT & THIRD PARTY COMPONENTS */
import moment from 'moment';
import 'moment/locale/it';
import { Flex, Loader } from '@fluentui/react-northstar';
import { useSelector } from 'react-redux';
import { Home16Regular, DrinkCoffee16Regular, Food16Regular, Building16Regular } from '@fluentui/react-icons';

/* IMPORT CUSTOM COMPONENTS */
import { capitalizeWords } from '../helpers/Tools';
import { IAppState } from '../interfaces/IAppState';
import { splitTimesheetActions } from '../helpers/SiaHelper';
import { ITimesheetAction } from '../interfaces/ITimesheetAction';
import { IWorkingMode } from '../interfaces/IWorkingMode';
import { SiaStrings } from "../strings/strings";
import * as cookieHelper from '../helpers/CookiesHelper';
import { IActionType } from '../interfaces/IActionType';
import * as Constants from '../constants/AppConstants';

const BadgeActionsHistory = (props: any) => {


  const appState: IAppState = useSelector(appState => appState) as IAppState;
  //const workingModes: Array<IWorkingMode> = cookieHelper.getApplicationCookies.getWorkingModesCookie();
  const actionTypes: Array<IActionType> = cookieHelper.getApplicationCookies.getActionTypesCookie();
  const [splittedActions, setSplittedActions] = useState([] as Array<ITimesheetAction>);
  const [workingModes, setWorkingModes] = useState<IWorkingMode[]>([]);

  useEffect(() => {
    (async () => {
      const wmodes = await cookieHelper.setApplicationCookies.setWorkingModesCookie();
      setWorkingModes(wmodes);
      debugger;
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const expectedWork: number = new Date().getDay() === 6 ? appState.ssfJobs[appState.selectedJob].hoursPerSaturday.asHours() : appState.ssfJobs[appState.selectedJob].hoursPerDay.asHours();
      let getSplitted = await splitTimesheetActions(appState.todayStatus, expectedWork);
      setSplittedActions(getSplitted);
    })();
  }, [appState.todayStatus]);

  return appState.ssfIsLoaded && appState.ssfJobs.length > 0 && workingModes.length  > 0
    ?
    <Flex column className="badge-breadcrumb-container" padding="padding.medium">
      <Flex space="between" vAlign="center" className="badge-breadcrumb-title">
        <div className="badge-breadcrumb-day noselect">{moment(new Date()).locale('it').format("DD")}</div>
        <div className="badge-breadcrumb-day-name noselect">{capitalizeWords(moment(new Date()).format("dddd"))}</div>
      </Flex>
      {(!appState.todayStatusIsLoaded)
        ?
        <Loader size="smaller" />
        :
        (splittedActions.length > 0)
          ?
          (appState.todayStatus !== null && appState.todayStatus !== undefined && appState.todayStatus && appState.todayStatus !== undefined)
            ?
            splittedActions.map((item: ITimesheetAction, index: number) => {

              //let icon = workingModes.filter(wm => wm.id === item.idWorkingMode)?.[0].name.toLowerCase() === "in sede" ? <City16Regular aria-label="City16Regular" /> : <Home16Regular aria-label="Home16Regular" />;
              let icon = null;
              let actionType: IActionType = actionTypes.filter((value) => {
                return value.id === item.idActionType;
              })[0];
              switch (actionType.groupName) {
                case Constants.GROUP_NAME_TIMBRATURA:
                  icon = workingModes.filter(wm => wm.id === item.idWorkingMode)[0]?.name.toLowerCase() === "in sede" ? <Building16Regular aria-label="City16Regular" /> : <Home16Regular aria-label="Home16Regular" />;
                  break;
                case Constants.GROUP_NAME_PAUSA:
                  icon = <DrinkCoffee16Regular aria-label="drinkCoffee16Regular" />;
                  break;
                case Constants.GROUP_NAME_PRANZO:
                  icon = <Food16Regular aria-label="food16Regular" />
                  break;
                default:
                  break;
              }

              return <div key={index} className="badge-timesheet-row noselect">
                <span className="badge-history-workingmode">{icon}</span>
                <span className="badge-timesheet-row-time">
                  {(item.startDate !== null)
                    ? moment(item.startDate).format("HH:mm")
                    : (item.endDate !== null)
                      ? moment(item.endDate).format("HH:mm")
                      : ""}
                </span> {item.header}</div>
            })
            :
            null
          :
          <Flex column className="badge-history-no-actions">
            <div>{SiaStrings.NO_ACTIONS}</div>
            {/* {appState.userCanBadge ?
              <div className="badge-history-instructions">{SiaStrings.BADGE_DRAG_INSTRUCTION}</div>
              :
              null
            } */}
          </Flex>
      }
    </Flex>
    :
    null
}

export default BadgeActionsHistory;