import { ITimesheetAction } from "../interfaces/ITimesheetAction";
import { IEmployee } from '../interfaces/IEmployee';
import * as Constants from '../constants/AppConstants';
import * as CookiesHelper from '../helpers/CookiesHelper';
import { IActionType } from "../interfaces/IActionType";
import { SiaStrings } from "../strings/strings";
import { IMonthlyAllowedPeriod } from "../interfaces/IMonthlyAllowedPeriod";
import { ICustomSelectorItem } from "../interfaces/ICustomSelectorItem";
import { IOrgChartUser } from "../interfaces/IOrgChartUser";
import * as microsoftTeams from "@microsoft/teams-js";
import { IAppState } from "../interfaces/IAppState";
import moment from "moment";
import { IDateRange } from "../interfaces/IDateRange";
import { ICalendarResponse } from "../interfaces/ICalendarResponse";
import { IActionTime } from "../interfaces/IActionTime";
import { ISummaryBoxResponse } from "../interfaces/ISummaryBoxResponse";
import { LunchType } from '../constants/AppEnum';

export const splitTimesheetActions = async (actions: Array<ITimesheetAction>, expectedWork: number): Promise<Array<ITimesheetAction>> => {

    let response: Array<ITimesheetAction> = [] as Array<ITimesheetAction>;
    let acts: Array<IActionType> = await CookiesHelper.getApplicationCookies.getActionTypesCookie()
    //Array che contiene gli id delle azioni di trasferta giornata intera
    const trasferteIds: number[] = acts.filter((action) => action.groupName === Constants.GROUP_NAME_TRASFERTA).map(i => i.id);

    return new Promise((resolve) => {
        try {
            //Recupero id azione 'lavoro'
            const timbraturaAction: IActionType = acts.filter((action) => action.groupName === Constants.GROUP_NAME_TIMBRATURA)[0];
            actions.forEach((item: ITimesheetAction, index: number) => {
                let hDiff = 0;
                if (item.startDate !== null && item.endDate !== null) {
                    hDiff = moment(item.endDate).diff(moment(item.startDate), 'hours')
                }
                //Se l'azione non è timbratura e non è trasferta e dura più delle ore previste di lavoro non divido la riga, in questo modo ritorno un unico record per l'azione
                //serve per i casi di Malattia e Ferie che 'occupano' l'intera giornata e quindi non ho necessità di visualizzare 2 righe con data di inizio e fine
                const itemActions = acts.filter(i => i.id === item.idActionType);
                if (item.idActionType !== timbraturaAction.id && !trasferteIds.includes(item.idActionType) && hDiff > expectedWork) {
                    response.push({
                        id: item.id,
                        idActionType: item.idActionType,
                        header: `${itemActions.length > 0 ? itemActions[0].title : "[non identificato]"}`,
                        startDate: null,
                        endDate: null,
                        idWorkingMode: item.idWorkingMode
                    } as ITimesheetAction);
                } else {
                    if (item.startDate && item.startDate !== null) {
                        response.push({
                            id: item.id,
                            idActionType: item.idActionType,
                            header: `${SiaStrings.TS_START} ${itemActions.length > 0 ? itemActions[0].title : "[non identificato]"}`,
                            startDate: item.startDate,
                            endDate: null,
                            idWorkingMode: item.idWorkingMode
                        } as ITimesheetAction);
                    }
                    if (item.endDate && item.endDate !== null) {
                        response.push({
                            id: item.id,
                            idActionType: item.idActionType,
                            header: `${SiaStrings.TS_END} ${itemActions.length > 0 ? itemActions[0].title : "[non identificato]"}`,
                            startDate: null,
                            endDate: item.endDate,
                            idWorkingMode: item.idWorkingMode
                        } as ITimesheetAction);
                    }
                }
            });
            response.sort(function (a: ITimesheetAction, b: ITimesheetAction) {
                let atime: Date = a.startDate as Date ?? a.endDate as Date;
                let btime: Date = b.startDate as Date ?? b.endDate as Date;
                if (atime !== null && btime !== null) {
                    return atime.getTime() - btime.getTime();
                } else {
                    return 1;
                }

            });

            resolve(response);
        } catch (error) {
            console.log(`Errore in splitTimesheetActions : ${error}`);
        }
    });
}
export const suggestNextActions = async (todayStatus: Array<ITimesheetAction>, employee: IEmployee, appState: IAppState): Promise<Array<ITimesheetAction>> => {

    // IDENTIFICO LE AZIONI PRINCIPALI DI TIMBRATURA CHE SONO QUELLE CHE HANNO ATTRIBUTO isFromBadge=true
    var acts: Array<IActionType> = await CookiesHelper.getApplicationCookies.getActionTypesCookie()
    var mainActionIds = acts.filter(i => i.isFromBadge).map(i => i.id);
    const expectedWork: number = new Date().getDay() === 6 ? appState.ssfJobs[appState.selectedJob].hoursPerSaturday.asHours() : appState.ssfJobs[appState.selectedJob].hoursPerDay.asHours();
    var splittedActions: Array<ITimesheetAction> = await splitTimesheetActions(todayStatus.filter(i => mainActionIds?.includes(i.idActionType)), expectedWork);
    let suggestedActions: Array<ITimesheetAction> = [];
    return new Promise((resolve) => {
        if (
            splittedActions === null ||
            splittedActions === undefined ||
            !splittedActions ||
            splittedActions.length <= 0
        ) {
            //nessuna ultima azione: suggerisci inizio lavoro            
            suggestedActions.push({
                id: null,
                idActionType: appState.timbraturaAction?.id,
                startDate: null,
                endDate: null,
                idWorkingMode: appState.currentWorkingMode,
                comment: "",
                header: `${SiaStrings.TS_START} ${acts.filter(i => i.id === appState.timbraturaAction?.id)[0]?.title}`
            } as ITimesheetAction);
        }

        const lastAction = splittedActions.length > 0 ? splittedActions[splittedActions.length - 1] : null;
        let lunch = employee.lunch !== "" && employee.lunch !== null && employee.lunch !== undefined ? parseFloat(employee.lunch) : 0;

        if (lastAction !== null && lastAction?.idActionType) {
            //ultima azione: inizio lavoro
            if (lastAction.idActionType === appState.timbraturaAction?.id && lastAction?.startDate !== null) {
                //se hai il pranzo da contratto (e ancora non lo hai timbrato) suggerisci inizio pausa pranzo
                //appState.pranzoAction è undefined quando la pausa pranzo è automatica e quindi l'utente non deve timbrare pausa pranzo
                if (lunch > 0 && employee.decreaseLunch === LunchType.Manuale && !todayStatus.some(a => a.idActionType === appState.pranzoAction?.id)) {
                    //suggerisci inizio pausa pranzo
                    suggestedActions.push({
                        id: null,
                        idActionType: appState.pranzoAction?.id,
                        startDate: null,
                        endDate: null,
                        idWorkingMode: appState.currentWorkingMode,
                        comment: "",
                        header: `${SiaStrings.TS_START} ${acts.filter(i => i.id === appState.pranzoAction?.id)[0]?.title}`
                    } as ITimesheetAction);
                }

                //suggerisci fine lavoro
                suggestedActions.push({
                    id: lastAction.id, // riporto lo stesso id in modo che si vada ad eseguire l'update sullo stesso record
                    idActionType: appState.timbraturaAction?.id,
                    startDate: null,
                    endDate: null,
                    idWorkingMode: appState.currentWorkingMode,
                    comment: "",
                    header: `${SiaStrings.TS_END} ${acts.filter(i => i.id === appState.timbraturaAction?.id)[0]?.title}`
                } as ITimesheetAction);

                //suggerisci inizio pausetta
                suggestedActions.push({
                    id: null,
                    idActionType: appState.pausaAction?.id,
                    startDate: null,
                    endDate: null,
                    idWorkingMode: appState.currentWorkingMode,
                    comment: "",
                    header: `${SiaStrings.TS_START} ${acts.filter(i => i.id === appState.pausaAction?.id)[0]?.title}`
                } as ITimesheetAction);
            }
            // ultima azione: inizio pausa pranzo
            if (lastAction.idActionType === appState.pranzoAction?.id && lastAction?.startDate !== null) {
                //suggerisci fine pausa pranzo
                suggestedActions.push({
                    id: lastAction.id,
                    idActionType: appState.pranzoAction?.id,
                    startDate: null,
                    endDate: null,
                    idWorkingMode: appState.currentWorkingMode,
                    comment: "",
                    header: `${SiaStrings.TS_END} ${acts.filter(i => i.id === appState.pranzoAction?.id)[0]?.title}`
                } as ITimesheetAction);
            }

            // ultima azione: fine pausa pranzo
            if (lastAction.idActionType === appState.pranzoAction?.id && lastAction?.endDate !== null) {
                //suggerisci fine lavoro
                //Devo trovare l'azione Inizio lavoro, sul DB devo aggiornare quella impostando end date. Prendo sempre l'ultima perchè ci possono essere più azioni di timbratura
                const fitleredActions = splittedActions.filter(action => action.idActionType === appState.timbraturaAction?.id && action.endDate === null);
                const actionId = fitleredActions.length > 1 ? fitleredActions[fitleredActions.length - 1].id : fitleredActions[0].id;
                suggestedActions.push({
                    id: actionId,
                    idActionType: appState.timbraturaAction?.id,
                    startDate: null,
                    endDate: null,
                    idWorkingMode: appState.currentWorkingMode,
                    comment: "",
                    header: `${SiaStrings.TS_END} ${acts.filter(i => i.id === appState.timbraturaAction?.id)[0]?.title}`
                } as ITimesheetAction);

                //suggerisci inizio pausetta
                suggestedActions.push({
                    id: null,
                    idActionType: appState.pausaAction?.id,
                    startDate: null,
                    endDate: null,
                    idWorkingMode: appState.currentWorkingMode,
                    comment: "",
                    header: `${SiaStrings.TS_START} ${acts.filter(i => i.id === appState.pausaAction?.id)[0]?.title}`
                } as ITimesheetAction);
            }

            // ultima azione: fine lavoro
            if (lastAction.idActionType === appState.timbraturaAction?.id && lastAction?.endDate !== null) {
                //suggerisci inizio lavoro
                suggestedActions.push({
                    id: null,
                    idActionType: appState.timbraturaAction?.id,
                    startDate: null,
                    endDate: null,
                    idWorkingMode: appState.currentWorkingMode,
                    comment: "",
                    header: `${SiaStrings.TS_START} ${acts.filter(i => i.id === appState.timbraturaAction?.id)[0]?.title}`
                } as ITimesheetAction);
            }

            // ultima azione: inizio pausa
            if (lastAction.idActionType === appState.pausaAction?.id && lastAction?.startDate !== null) {
                //suggerisci fine pausa
                suggestedActions.push({
                    id: lastAction.id,
                    idActionType: appState.pausaAction?.id,
                    startDate: null,
                    endDate: null,
                    idWorkingMode: appState.currentWorkingMode,
                    comment: "",
                    header: `${SiaStrings.TS_END} ${acts.filter(i => i.id === appState.pausaAction?.id)[0]?.title}`
                } as ITimesheetAction);
            }

            // ultima azione: fine pausa
            if (lastAction.idActionType === appState.pausaAction?.id && lastAction?.endDate !== null) {
                //se hai il pranzo da contratto (e ancora non lo hai timbrato) suggerisci inizio pausa pranzo
                if (lunch > 0 && employee.decreaseLunch === LunchType.Manuale && !todayStatus.some(a => a.idActionType === appState.pranzoAction?.id)) {
                    //if (lunch > 0 && !todayStatus.some(a => a.idActionType === appState.pranzoAction?.id)) {
                    //suggerisci inizio pausa pranzo
                    suggestedActions.push({
                        id: null,
                        idActionType: appState.pranzoAction?.id,
                        startDate: null,
                        endDate: null,
                        idWorkingMode: appState.currentWorkingMode,
                        comment: "",
                        header: `${SiaStrings.TS_START} ${acts.filter(i => i.id === appState.pranzoAction?.id)[0]?.title}`
                    } as ITimesheetAction);
                }

                //Devo trovare l'azione Inizio lavoro, sul DB devo aggiornare quella impostando end date. Prendo sempre l'ultima perchè ci possono essere più azioni di timbratura
                const fitleredActions = splittedActions.filter(action => action.idActionType === appState.timbraturaAction?.id && action.endDate === null);
                const actionId = fitleredActions.length > 1 ? fitleredActions[fitleredActions.length - 1].id : fitleredActions[0].id;
                //suggerisci fine lavoro
                suggestedActions.push({
                    id: actionId,
                    idActionType: appState.timbraturaAction?.id,
                    startDate: null,
                    endDate: null,
                    idWorkingMode: appState.currentWorkingMode,
                    comment: "",
                    header: `${SiaStrings.TS_END} ${acts.filter(i => i.id === appState.timbraturaAction?.id)[0]?.title}`
                } as ITimesheetAction);

                //suggerisci inizio pausa
                suggestedActions.push({
                    id: null,
                    idActionType: appState.pausaAction?.id,
                    startDate: null,
                    endDate: null,
                    idWorkingMode: appState.currentWorkingMode,
                    comment: "",
                    header: `${SiaStrings.TS_START} ${acts.filter(i => i.id === appState.pausaAction?.id)[0]?.title}`
                } as ITimesheetAction);
            }
        }
        resolve(suggestedActions);
    });
};
export const getMonthlyAllowedPeriod = (): Array<IMonthlyAllowedPeriod> => {
    //periodo autorizzato = ultimi 12 mesi per tutti i dipendenti (ad eccezione di HR, TODO: inserire regole personalizzate)
    var allowedPeriods: Array<IMonthlyAllowedPeriod> = [] as Array<IMonthlyAllowedPeriod>;
    //var today = new Date();
    //var nextMonth = new Date(today.setMonth(today.getMonth() + 2));
    //allowedPeriods.push({ month: nextMonth.getMonth(), year: nextMonth.getFullYear() })
    var now = new Date();
    for (var i = 0; i < 12; i++) {
        let future = new Date(now.getFullYear(), now.getMonth() - i, 1);
        var month = future.getMonth() + 1;
        var year = future.getFullYear();
        allowedPeriods.push({ month: month, year: year })
    }
    //allowedPeriods.reverse();

    return allowedPeriods;
}
export const calculateSteps = (mins: number): Array<ICustomSelectorItem> => {

    //TODO: Verificare gli step straordinari sulla company, per il momento metto uno standard di 15 min
    let step: number = 15;
    let steps = [] as Array<ICustomSelectorItem>;
    mins = Math.ceil(mins);
    if (mins <= step) {
        if (mins > 0)
            steps.push({ key: mins, value: mins });
    }
    else {
        let numSteps: number = Math.floor(mins / step);
        //let remains : number = mins % step;
        //let totMins : number = 0;
        for (let i = 1; i <= numSteps; i++) {
            //steps.push({key:step*i, value:step*i});
            steps.push({ key: step * i, value: step });
            //totMins = step*i;
        }
        //PER AGGIUNGERE I MINUTI RESTANTI IN UN ULTIMO STEP, DECOMMENTARE
        //if(remains>0)
        //steps.push({key:totMins+remains, value:totMins+remains});
    }
    steps.push({ key: 0, value: 0 });
    steps.reverse();
    return steps;
}

export const findUserByIdRecursive = (idUserSF: string, orgChartPassed: IOrgChartUser): IOrgChartUser | any => {
    for (let index = 0; index < orgChartPassed.users.length; index++) {
        const element = orgChartPassed.users[index];
        if (element.idUserSF === idUserSF) {
            return element;
        } else {
            if (element.users) {
                const found: IOrgChartUser = findUserByIdRecursive(idUserSF, element);
                if (found) {
                    return found;
                }
            }
        }
    }
}

export const findUserByEmailRecursive = (email: string | null | undefined, orgChartPassed: IOrgChartUser): IOrgChartUser | any => {
    if (email === null || email === undefined)
        return null;
    for (let index = 0; index < orgChartPassed.users.length; index++) {
        const element = orgChartPassed.users[index];
        if (element.userName !== null && element.userName.toLocaleLowerCase() === email.toLocaleLowerCase()) {
            return element;
        } else {
            if (element.users) {
                const found: IOrgChartUser = findUserByEmailRecursive(email, element);
                if (found) {
                    return found;
                }
            }
        }
    }
}

//Recupera l'elenco di tutti gli utenti 'sottoposti' 
export const getAllUsers = (orgChart: IOrgChartUser): IOrgChartUser[] => {
    let result: IOrgChartUser[] = [];
    orgChart.users.forEach((user: IOrgChartUser) => {
        result.push(user);
        if (user.users.length > 0) {
            const usersToAdd = getAllUsers(user);
            result = result.concat(usersToAdd);
        }
    })
    return result;
}



export const getClientSideToken = async (): Promise<string> => {
    return new Promise((resolve, reject) => {
        microsoftTeams.authentication.getAuthToken({
            successCallback: (result) => {
                resolve(result);
            },
            failureCallback: function (error) {
                console.error("Error on getClientSideToken(): ", error);
                reject("");
            }
        });
    });
}

export const getLocaleDate = (date: Date): Date => {
    const _dateTimeFormat = new Intl.DateTimeFormat('it', { year: 'numeric', month: 'numeric', day: '2-digit', hour: "numeric", minute: "numeric" });
    return new Date(Date.parse(_dateTimeFormat.format(date)));
}

export const formatRowDate = (action: IActionType, startDate: Date | null, endDate: Date | null): IDateRange => {
    let result: IDateRange = {
        startDate: "",
        endDate: ""
    };

    if (action.daysAmount && action.daysAmount > 0 && action.hoursAmount && action.hoursAmount > 0) {
        // L'utente dovrà selezionare un range di giorni e di orari
        // DATA E ORA COMPLETA
        result.startDate = startDate != null ? moment(startDate).format("DD/MM/YYYY HH:mm") : "";
        result.endDate = endDate != null ? moment(endDate).format("DD/MM/YYYY HH:mm") : "";
    }
    else if (action.daysAmount && action.daysAmount === 1) {
        // L'utente deve selezionare un giorno singolo senza orario (giorata intera)
        // SOLO DATA
        result.startDate = startDate != null ? moment(startDate).format("DD/MM/YYYY") : "";
        result.endDate = endDate != null ? moment(endDate).format("DD/MM/YYYY") : "";
    }
    else if (action.daysAmount && action.daysAmount > 1 && !action.isLockedAmount) {
        // L'utente deve selezionare un range di giorni senza orario (giorata intera)
        // SOLO DATA
        result.startDate = startDate != null ? moment(startDate).format("DD/MM/YYYY") : "";
        result.endDate = endDate != null ? moment(endDate).format("DD/MM/YYYY") : "";
    }
    else if (action.daysAmount && action.daysAmount > 1 && action.isLockedAmount) {
        // L'utente deve selezionare un giorno singolo di partenza senza orario (giorata intera)
        // SOLO DATA
        result.startDate = startDate != null ? moment(startDate).format("DD/MM/YYYY") : "";
        result.endDate = endDate != null ? moment(endDate).format("DD/MM/YYYY") : "";
    }
    else if (!action.daysAmount || (action.daysAmount && action.daysAmount === 0)) {
        // Gestione delle ore
        if (action.hoursAmount && action.hoursAmount !== 0 && !action.isLockedAmount) {
            // L'utente deve selezionare un singolo giorno e gli orari di inizio e fine
            // DATA E ORARIO
            result.startDate = startDate != null ? moment(startDate).format("DD/MM/YYYY HH:mm") : "";
            result.endDate = endDate != null ? moment(endDate).format("DD/MM/YYYY HH:mm") : "";
        }
        else if (action.hoursAmount && action.hoursAmount !== 0 && action.isLockedAmount) {
            // L'utente deve selezionare un singolo giorno e l'orario di inizio
            // DATA E ORARIO
            result.startDate = startDate != null ? moment(startDate).format("DD/MM/YYYY HH:mm") : "";
            result.endDate = endDate != null ? moment(endDate).format("DD/MM/YYYY HH:mm") : "";
        }
    }
    return result;
}

export const mapCalendars = (calendarResponse: ICalendarResponse): ICalendarResponse => {
    for (let index = 0; index < calendarResponse.days.length; index++) {
        calendarResponse.days[index].day = calendarResponse.days[index].day ? new Date(calendarResponse.days[index].day) : new Date();
        for (let cIndex = 0; cIndex < calendarResponse.days[index].calendars.length; cIndex++) {
            calendarResponse.days[index].calendars[cIndex].startDate = calendarResponse.days[index].calendars[cIndex].startDate ? new Date(calendarResponse.days[index].calendars[cIndex].startDate) : new Date();
            calendarResponse.days[index].calendars[cIndex].endDate = calendarResponse.days[index].calendars[cIndex].endDate ? new Date(calendarResponse.days[index].calendars[cIndex].endDate) : new Date();
        }
    }

    return calendarResponse;
}

export const calcActivityDate = (date: Date, time: IActionTime): Date => {

    const newDate = new Date(date);
    if (time.hours !== undefined) {
        newDate.setHours(time.hours);
    }
    if (time.minutes !== undefined) {
        newDate.setMinutes(time.minutes);
    }
    newDate.setSeconds(0);
    return newDate;
}

export const stringIsNullOrEmpty = (value: string) => {
    return (!value || value.trim() === "");
}

export const isSameDate = (d1: Date, d2: Date) => {
    return d1.getDate() === d2.getDate() && d1.getMonth() === d2.getMonth() && d1.getFullYear() === d2.getFullYear();
}

export const buildSummaryBoxResponse = (obj: any): ISummaryBoxResponse | null => {

    try {
        const result: ISummaryBoxResponse = {
            summaryBox: {
                earnedHolidays: moment.duration(obj.summaryBox.earnedHolidays),
                leaveHours: moment.duration(obj.summaryBox.leaveHours),
                usedHolidays: moment.duration(obj.summaryBox.usedHolidays),
                remainingHolidays: moment.duration(obj.summaryBox.remainingHolidays),
                lackHours: moment.duration(obj.summaryBox.lackHours),
                workingMonthlyHours: obj.summaryBox.workingMonthlyHours,
                overtimeMonthlyToPayHours: obj.summaryBox.overtimeMonthlyToPayHours,
                timeSlotsAdditional: moment.duration(obj.summaryBox.timeSlotsAdditional),
                timeSlotsOvertime: moment.duration(obj.summaryBox.timeSlotsOvertime),
                lastHolidaysCalcDate: obj.summaryBox.lastHolidaysCalcDate,
                ticketRestaurantCount: obj.summaryBox.ticketRestaurantCount
            }
        };
        return result;
    } catch (error) {
        console.log(`Errore conversione in ISummaryBoxResponse : ${error}`);
        return null;
    }
}
