/* IMPORT CSS / SASS */
import "../scss/TabsContainer.scss";

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Flex, Text, Loader } from "@fluentui/react-northstar";
import Assistances from "./Assistances";
import { IAppState } from "../interfaces/IAppState";
import { UserType } from "../constants/AppEnum";
import { ITab } from "../interfaces/ITab";
import { SiaStrings } from "../strings/strings";
import Warning from "./Warning";
import { ISize } from '../interfaces/ISize';
import useWindowSize from '../hooks/useWindowSize';
import useLoadCommonData from "../hooks/useLoadCommonData";
import { LoadCommonDataStaus } from "../constants/AppEnum";
import { IObjectTabs } from '../interfaces/IObjectTabs';
import * as microsoftTeams from "@microsoft/teams-js";
import * as cookiesHelper from '../helpers/CookiesHelper';

import NewAssistence from './NewAssistence';

const TabAssistances = (props: any) => {
    const appState: IAppState = useSelector((appState) => appState) as IAppState;
    //const orgChart: IOrgChartUser = cookieHelper.getApplicationCookies.getOrgChartCookie();
    const ASSISTNZE_LABEL: string = 'Riepilogo';
    const defaultTab: ITab = {
        index: 0,
        label: "Nuova",
        content: <NewAssistence />
    };
    const assistances: ITab = {
        index: 1,
        label: ASSISTNZE_LABEL,
        content: <Assistances />
    }
    const [objectTabs, setObjectTabs] = useState<IObjectTabs>({
        tabs: [defaultTab, assistances],
        selectedTab: defaultTab
    } as IObjectTabs);
    const [error, setError] = useState('');
    const [errorDetail, setErrorDetail] = useState('');
    const size: ISize = useWindowSize();
    const commonDataStatus: LoadCommonDataStaus = useLoadCommonData();

    useEffect(() => {
        if (commonDataStatus === LoadCommonDataStaus.Success) {
            if (appState.userType === UserType.Unknown) {
                setError('Imposssibile recuperare la tipologia di utente');
                return;
            }
            microsoftTeams.getContext((context: microsoftTeams.Context) => {
                //subEntityId viene impostato con un deepLink dal bot
                const redirectToAssistances: boolean = context.subEntityId === 'VisualizzaAssistenza';
                let tmpObjTabs: IObjectTabs = { ...objectTabs };
                if (redirectToAssistances) {
                    var assistanceTab = tmpObjTabs.tabs.filter((tab) => { return tab.label === ASSISTNZE_LABEL });
                    if (assistanceTab.length > 0) {
                        tmpObjTabs.selectedTab = assistanceTab[0];
                        setObjectTabs(tmpObjTabs);
                    }
                }
            });
        } else if (commonDataStatus === LoadCommonDataStaus.Failed) {
            const contractNotFoundException: string = cookiesHelper.getApplicationCookies.getContractNotFoundCookie();
            let message: string = 'Si è verificato un errore imprevisto nel recupero dei dati';
            if (contractNotFoundException === 'true') {
                message = SiaStrings.COTRACT_NOT_FOUND_MESSAGE;
                setErrorDetail(SiaStrings.COTRACT_NOT_FOUND_DETAIL_MESSAGE);
            }
            setError(message);
        }
    }, [commonDataStatus])

    if (appState.isMobile) {
        return <Warning label={'Funzionalità non supportata su dispositivi mobile'} height={size.height} />
    }

    return <Flex column fill styles={{ height: (size.height !== undefined && objectTabs.selectedTab.label !== 'Calendario') ? `${size.height}px` : '100%' }}>
        {commonDataStatus === LoadCommonDataStaus.Loading ?
            <Loader label={SiaStrings.LOADING} style={{ paddingTop: '20px', paddingBottom: '20px' }} />
            :
            <>
                {(objectTabs.tabs.length > 0 && commonDataStatus === LoadCommonDataStaus.Success) &&
                    <ul className="inline">
                        {objectTabs.tabs.map((tab: any, index) => {
                            let style = index === objectTabs.selectedTab.index ? 'selected' : '';
                            return <li key={index} onClick={(el) => { setObjectTabs({ ...objectTabs, selectedTab: objectTabs.tabs[index] }); }}>
                                <div className={style}>
                                    <Text weight={index === objectTabs.selectedTab.index ? "bold" : "regular"}  >{tab.label}</Text>
                                </div>
                            </li>
                        })}
                    </ul>
                }
                {error !== '' ?
                    <Warning label={error} details={errorDetail}/>
                    :
                    <Flex fill>
                        {objectTabs.selectedTab.content}
                    </Flex>
                }
            </>
        }
    </Flex>
}

export default TabAssistances;