/* IMPORT CSS / SASS */
import '../scss/Badge.scss';

/* IMPORT REACT & THIRD PARTY COMPONENTS */
import React, { useState, useEffect } from 'react';
import { Button, Flex, Loader, Text } from '@fluentui/react-northstar';
import { useSelector } from 'react-redux';
import { Home20Regular, Building20Regular } from '@fluentui/react-icons';

/* IMPORT CUSTOM COMPONENTS */
import { IWorkingMode } from '../interfaces/IWorkingMode';
import * as cookieHelper from '../helpers/CookiesHelper';
import { IAppState } from '../interfaces/IAppState';
import { SiaStrings } from "../strings/strings";

export const BadgeWorkingModeSelector = (props: any) => {

    const appState: IAppState = useSelector(appState => appState) as IAppState;
    //const workingModes: Array<IWorkingMode> = cookieHelper.getApplicationCookies.getWorkingModesCookie();

    const [workingModes,setWorkingModes] = useState<IWorkingMode []>([]);


    useEffect(() => {
        (async () => {
            const wModes = await cookieHelper.setApplicationCookies.setWorkingModesCookie();
            setWorkingModes(wModes);
            debugger;
        })();
    }, []);

    //const dispatch = useDispatch();

    // const selectWorkingMode = (idWorkingMode: number) => {
    //     dispatch(siaActions.setCurrentWorkingMode(idWorkingMode));
    //     dispatch(appActions.setNotifications([{ type: Constants.notificationTypes.SUCCESS, message: SiaStrings.WORKINGMODE_SELECTED } as INotification]));
    // }

    return appState.todayStatusIsLoaded && workingModes.length > 0?
        <Flex column space="between" className="badge-workingmode-selector-container">

            <Flex>
                {appState.currentWorkingMode === null
                    ?
                    <div className="badge-working-mode-instructions">{SiaStrings.CHOOSE_WORKINGMODE}</div>
                    :
                    <div className="badge-working-mode-instructions">{SiaStrings.WORKINGMODE_SELECTED}</div>
                }
            </Flex>

            <Flex>
                {workingModes.filter((wm: IWorkingMode) => wm.active === true).map((item: IWorkingMode, index: number) => {
                    let icon = item.name.toLowerCase() === "in sede" ? <Building20Regular aria-label="AccessTime24Filled" /> : <Home20Regular aria-label="AccessTime24Filled" />;
                    if (appState.currentWorkingMode === null) {
                        return <Button key={index} icon={icon} text
                            content={item.name}
                        //La modalità di lavoro non è più selezionabile
                        //onClick={() => selectWorkingMode(item.id)}
                        />
                    }
                    else {
                        return appState.currentWorkingMode === item.id
                            ?
                            <Flex key={index} gap="gap.small">
                                {icon}
                                <Text content={item.name} />
                            </Flex>
                            //<Button key={index} icon={icon} text primary content={item.name} disabled={props.disabled}/>
                            :
                            <Button key={index} icon={icon}
                                text
                                content={item.name}
                                disabled={props.disabled}
                            //La modalità di lavoro non è più selezionabile
                            //onClick={() => selectWorkingMode(item.id)}
                            />
                    }
                    return null;
                })}
            </Flex>

        </Flex>
        :
        <Loader label="Loading SIA data..." />
}

export default BadgeWorkingModeSelector;