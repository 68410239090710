import * as Constants from '../../constants/AppConstants';

export const setTeamsContext = (context: object = {}) => {
    return { type: Constants.SET_TEAMS_CONTEXT, context };
}

export const setTeamsContextIsInitialized = (isInitialized: boolean = false) => {
    return { type: Constants.SET_TEAMS_INITIALIZED, isInitialized };
}

export const setUnexpectedError = (error: boolean) => {
    return { type: Constants.UNEXPECTED_ERROR, error };
}

