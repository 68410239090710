import "../scss/CalendarRow.scss";

import React, { useEffect, useState, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Flex, Divider, Alert, Text, Button, Card, ChevronEndIcon, TrashCanIcon, AddIcon, Dialog, Dropdown } from "@fluentui/react-northstar";
import moment from "moment";
import { ITimesheetAction, ITimesheetActionAdmin } from "../interfaces/ITimesheetAction";
import { IAppState } from "../interfaces/IAppState";
import { IActionType } from "../interfaces/IActionType";
import { deleteCalendar, postCalendar } from '../helpers/Fetch';
import * as appActions from '../stores/actions/AppActions';
import { SiaStrings } from "../strings/strings";
import { INotification } from "../interfaces/INotification";
import * as Constants from '../constants/AppConstants';
import { IActionTime } from "../interfaces/IActionTime";
import TimeSelector from "./TimeSelector";
import { calcActivityDate } from "../helpers/SiaHelper";
import * as cookieHelper from '../helpers/CookiesHelper';
import { UserType } from "../constants/AppEnum";


interface ICalendarRowProps {
    date: string;
    dayActions: ITimesheetAction[];
    actions: IActionType[];
    timbraturaAction: ITimesheetAction;
    idPranzoAction: number;
    userId: string;
    message: string;
}

const CalendarRow = (props: ICalendarRowProps) => {
    const appState: IAppState = useSelector((appState) => appState) as IAppState;
    const dispatch = useDispatch();
    const [theme, setTheme] = useState('');

    // const [rowActions, setRowActions] = useState<ITimesheetAction[]>([...props.dayActions.map((action) => {
    //     action.startDate?.setSeconds(0);
    //     action.endDate?.setSeconds(0);
    //     return action;
    // })
    // ]);
    const [rowActions, setRowActions] = useState<ITimesheetAction[]>([]);
    //Ora minima e massima assolute che è possibile impostare 
    const [actionTimes, setActionTimes] = useState<IActionTime[]>([]);
    //Ora minima dell'azione
    const [actionStartTime, setActionStartTime] = useState<IActionTime>({ hours: 0, minutes: 0 } as IActionTime);
    //Ora massima dell'azione
    const [actionEndTime, setActionEndTime] = useState<IActionTime>({ hours: 23, minutes: 59 } as IActionTime);
    const [saveDisabled, setSaveDisabled] = useState(true);
    const [newActivityProps, setNewActivityProps] = useState<ITimesheetActionAdmin>({
        id: null,
        userId: null,
        startDate: null,
        endDate: null,
        idActionType: -1
    } as ITimesheetActionAdmin);
    const [addActionDisabled, setAddActionDisables] = useState(false);
    const tmpEowAction = cookieHelper.getApplicationCookies.getTempEndOfWorkAction();

    const deleteCalendarAction = async (id: number): Promise<void> => {
        try {
            await deleteCalendar(id);
            //Rimuovo l'azione dalla riga
            let tmpActions = [...rowActions];
            const indexToDelete: number = tmpActions.findIndex(action => action.id === id);
            tmpActions.splice(indexToDelete, 1);
            setRowActions(tmpActions);
            dispatch(appActions.setNotifications([{ type: "success", message: SiaStrings.CALENDAR_REMOVED } as INotification]));
        } catch (error) {
            dispatch(appActions.setNotifications([{ type: "danger", message: SiaStrings.GENERIC_ERROR } as INotification]));
        }
    }

    const getNewCaledarActionTime = () => {
        let startActionTime: IActionTime = {
            hours: undefined,
            minutes: undefined
        };
        let endActionTime: IActionTime = {
            hours: undefined,
            minutes: undefined
        };

        let tmpRowActions: ITimesheetAction[] = [...rowActions];

        if (props.timbraturaAction === undefined || props.timbraturaAction.startDate === null) {
            console.log("Azione di inizio lavoro mancante");
            return;
        }
        const minStartTime: IActionTime = { hours: props.timbraturaAction.startDate.getHours(), minutes: props.timbraturaAction.startDate.getMinutes() } as IActionTime;
        let maxEndTime: IActionTime = { hours: 23, minutes: 59 } as IActionTime;
        const now = new Date();

        //Se la data non è 'oggi' esco perchè le azioni possono essere inserite solo per il giorno corrente
        if (moment(now).format("DD/MM/yyyy") !== moment(props.date).format("DD/MM/yyyy")) {
            return;
        }

        //Se l'azione di timbratura ha una endDate la splitto e aggiungo in ultima posizione un'azione fake con la startDate uguale alla endDate che consente di effettuare il calcolo corretto
        if (props.timbraturaAction.endDate !== null) {

            tmpRowActions.push({
                id: props.timbraturaAction.id,
                idActionType: props.timbraturaAction.idActionType,
                startDate: props.timbraturaAction.endDate,
                endDate: props.timbraturaAction.endDate
            } as ITimesheetAction);
            //e come massima ora di fine attività imposto l'ora di fine lavoro
            maxEndTime = { hours: props.timbraturaAction.endDate.getHours(), minutes: props.timbraturaAction.endDate.getMinutes() } as IActionTime;
        } else {
            //tmpEowAction è != null quando l'utente ha timbrato l'uscita, ma non ha completato tutte le azioni di calendario e quindi viene memorizzata la data di fine
            //e poi viene rimandato sulla pagina del calendario per inserire le azioni mancanti.
            // In questo caso come massima data di fine e come ora di fine di default metto l'ora memorizzata 
            if (tmpEowAction != null && tmpEowAction.endDate !== null && moment(tmpEowAction.endDate).format("DD/MM/yyyy") === moment(props.date).format("DD/MM/yyyy")) {
                const tmpDate = new Date(tmpEowAction.endDate);
                if (moment(tmpDate).format("DD/MM/yyyy") === moment(now).format("DD/MM/yyyy"))
                    maxEndTime = { hours: tmpDate.getHours(), minutes: tmpDate.getMinutes() } as IActionTime;
                endActionTime.hours = tmpDate.getHours();
                endActionTime.minutes = tmpDate.getMinutes();
            } else if (moment(props.date).format("DD/MM/yyyy") === moment(now).format("DD/MM/yyyy")) {
                //Se non c'è l'uscita e sto modificando il calendario della data odierna come massima data imposto adesso
                maxEndTime = { hours: now.getHours(), minutes: now.getMinutes() } as IActionTime;
            }
        }
        //Minima data di inizio e massima data di fine da passare al componente TimeSelector
        //setActionTimes([minStartTime, maxEndTime]);

        //La prima data da confrontare è sempre l'ora di inizio lavoro
        let prevEndTime: Date = props.timbraturaAction.startDate as Date;

        //C'è solo un'azione registrata per il giorno
        if (tmpRowActions.length === 1) {
            startActionTime.hours = tmpRowActions[0].startDate?.getHours();
            startActionTime.minutes = tmpRowActions[0].startDate?.getMinutes();
            //Se stiamo modificando il giorno corrente l'ora di fine massima è adesso
            if (endActionTime.hours === undefined && moment(props.date).format("DD/MM/yyyy") === moment(now).format("DD/MM/yyyy")) {
                endActionTime.hours = now.getHours();
                endActionTime.minutes = now.getMinutes();
            }
        } else {
            //parto dalla posizione 1 perchè in posizione 0 c'è l'azione di inizio lavoro che non ha nessun'altra azione prima
            for (let index = 1; index < tmpRowActions.length; index++) {
                const element = tmpRowActions[index];


                //Se l'ora di inizio dell'azione precedente è uguale all'ora di inizio dell'azione attuale allore le due azioni sono contigue e quindi non c'è un intervallo in cui 
                //può essere aggiunta l'azione di calendario, se invece sono diverse calcolo ora di inizio e fine dell'azione
                if (moment(element.startDate).format("HH:mm") !== moment(prevEndTime).format("HH:mm")) {
                    startActionTime.hours = prevEndTime.getHours();
                    startActionTime.minutes = prevEndTime.getMinutes()
                    //L'ora di fine massima è l'ora di inizio dell'azione corrente
                    endActionTime.hours = element.startDate?.getHours();
                    endActionTime.minutes = element.startDate?.getMinutes();
                    //Ho trovato il primo intervallo disponibile : Se l'azione non è timbratura esco, se invece è timbratura non esco perchè l'intervallo fra finetimbratura e inizio timbratura
                    //Non è da considerare
                    if (element.idActionType !== props.timbraturaAction.idActionType) {
                        break;
                    } else {
                        startActionTime.hours = undefined;
                        startActionTime.minutes = undefined;
                        endActionTime.hours = undefined;
                        endActionTime.minutes = undefined;
                        prevEndTime = element.startDate as Date;
                        //Se l'azione è inizio timbratura (caso di più timbrature ingresso/uscita nella giornata) imposto il prossimo intervallo con inizio  l'ora di inizio timbratura
                        //e fine timbratura adesso. Se dopo l'azione di inizio timbratura ci sono altre azioni l'intervallo viene calcolato all'elemento successivo
                        //if (element.endDate === null) {
                        // if (index === tmpRowActions.length - 1) {
                        //     startActionTime.hours = element.startDate?.getHours();
                        //     startActionTime.minutes = element.startDate?.getMinutes();
                        //     endActionTime.hours = now.getHours();
                        //     endActionTime.minutes = now.getHours();
                        //     //Per la prossima azione (se c'è) la data di fine precedente è la data inizio della timbratura perchè l'inizio timbratura non ha una data di fine
                        //     prevEndTime = element.startDate as Date;
                        // }
                    }
                    //break;
                }
                if (element.endDate !== null) {
                    prevEndTime = element.endDate;
                }
            }
        }

        //Se non c'è la startAction e non c'è la fine timbratura allora imposto come startActionTime la fine dell'ultima azione disponibile
        //es. Start ore 9 -> azione calendario 9 - 13 -> pausa pranzo 13 -14 -> non c'è la fine timbratura => In questo caso posso inserire azioni dalle 14 in poi
        if (startActionTime.hours === undefined && props.timbraturaAction.endDate === null) {
            startActionTime.hours = prevEndTime.getHours();
            startActionTime.minutes = prevEndTime.getMinutes();
        }

        //Se  c'è la data di inizio significa che c'è un intervallo disponibile per inserire una nuova azione, altrimenti no
        if (startActionTime.hours !== undefined) {
            //SE endTime è undefined E NON c'è l'azione di fine timbratura la end date è adesso se è il gionro corrente
            if (endActionTime.hours === undefined && props.timbraturaAction.endDate === null) {
                //e stiamo modificando il giorno corrente l'ora di fine massima è adesso
                if (moment(props.date).format("dd/MM/yyyy") === moment(new Date()).format("dd/MM/yyyy")) {
                    endActionTime.hours = now.getHours();
                    endActionTime.minutes = now.getMinutes();
                }
            }

            //Data di inizio e fine da passare al componente TimeSelector
            setActionStartTime(startActionTime);
            setActionEndTime(endActionTime);
            //setActionTimes([startActionTime, endActionTime]);
            //Minima data di inizio e massima data di fine da passare al componente TimeSelector
            if (startActionTime.hours !== undefined) {
                minStartTime.hours = startActionTime.hours;
                minStartTime.minutes = startActionTime.minutes;
            }

            if (endActionTime.hours !== undefined) {
                maxEndTime.hours = endActionTime.hours;
                maxEndTime.minutes = endActionTime.minutes;
            }

            setActionTimes([minStartTime, maxEndTime]);

            //Valorizzo per la prima volta le date dell'attività da salvare
            //Reset newActivityProps
            let tmpNewActivity: ITimesheetActionAdmin = {
                id: null,
                userId: null,
                startDate: null,
                endDate: null,
                idActionType: -1
            } as ITimesheetActionAdmin;
            tmpNewActivity.startDate = calcActivityDate(new Date(props.date), startActionTime);
            tmpNewActivity.endDate = calcActivityDate(new Date(props.date), endActionTime.hours !== undefined ? endActionTime : calcTempEndDate(startActionTime));
            setNewActivityProps(tmpNewActivity);
            setSaveDisabled(true);
            setAddActionDisables(false);
        } else {
            console.log("nessun intervallo disponibile");
            setAddActionDisables(true);
        }

    }

    const activityCanAdded = (startTimeDate: Date, endTimeDate: Date): boolean => {
        //Se c'è una sola azione ed è quella di timbratura controllo che la nuova azione non sia precedente
        if (rowActions.length === 1 && rowActions[0].idActionType === props.timbraturaAction.idActionType) {
            if (startTimeDate < (rowActions[0].startDate as Date) || endTimeDate < (rowActions[0].startDate as Date)) {
                return false;
            }
        }
        //Verifico se l'itervallo si sovrappone ad altre azioni di calendario o pausa pranzo
        var filtered = rowActions.filter((rAction) => {
            return (
                rAction.idActionType !== props.timbraturaAction.idActionType &&
                (
                    !((rAction.endDate as Date) <= startTimeDate || (rAction.startDate as Date) >= endTimeDate)
                )
            );
        });

        //L'azione non deve essere prima della data di inizio (timbratura)
        const afterStart: boolean = (startTimeDate >= (props.timbraturaAction.startDate as Date)) && (endTimeDate >= (props.timbraturaAction.startDate as Date));
        let afterEnd: boolean = false;
        if (props.timbraturaAction.endDate !== undefined && props.timbraturaAction.endDate !== null) {
            //L'azione non deve essere dopo la fine giornata
            afterEnd = (startTimeDate > (props.timbraturaAction.endDate as Date)) || (endTimeDate > (props.timbraturaAction.endDate as Date))
        }

        return (filtered.length === 0 && afterStart && !afterEnd);
    }

    //Aggiungo un ora alla data di inizio, serve per impostare un ora di fine temporanea nel caso in cui l'azione che si sta inserendo non ha un'azione successiva e quindi una data di fine precisa
    const calcTempEndDate = (startActionTime?: IActionTime): IActionTime => {
        let result: IActionTime = {
            hours: 23,
            minutes: 59
        } as IActionTime;
        try {
            const sTime: IActionTime = startActionTime !== undefined ? startActionTime : actionTimes[0];
            if (sTime.hours !== undefined) {
                if (sTime.hours !== 23) {
                    result.hours = sTime.hours + 1;
                    result.minutes = sTime.minutes;
                } else {
                    result.hours = 0;
                    result.minutes = sTime.minutes;
                }
            }
        } catch (error) {
            console.log(`calcTempEndDate error : ${error}`);
        }
        return result;
    }

    const onStartActivityChange = (time: IActionTime) => {
        const startDate: Date = calcActivityDate(new Date(props.date), time);
        let tmpNewActivity: ITimesheetActionAdmin = { ...newActivityProps };
        tmpNewActivity.startDate = startDate;
        setNewActivityProps(tmpNewActivity);
        if (activityCanAdded(startDate, newActivityProps.endDate as Date)) {
            if (saveDisabled) {
                //Se anche l'azione è selezionata e la data di fine è valorizzata e la data di inizio è precedente a quella di fine abilito il pulsante salva
                setSaveDisabled(!(tmpNewActivity.idActionType > 0 && tmpNewActivity.endDate !== null && startDate < tmpNewActivity.endDate));
            }
        } else {
            setSaveDisabled(true);
        }
    }

    const onEndActivityChange = (time: IActionTime) => {
        const endDate: Date = calcActivityDate(new Date(props.date), time);
        let tmpNewActivity: ITimesheetActionAdmin = { ...newActivityProps };
        tmpNewActivity.endDate = endDate;
        setNewActivityProps(tmpNewActivity);
        if (activityCanAdded(newActivityProps.startDate as Date, endDate)) {
            if (saveDisabled) {
                //Se anche l'azione è selezionata e la data di inizio è valorizzata abilito il pulsante salva
                const disabled: boolean = !(tmpNewActivity.idActionType > 0 && tmpNewActivity.startDate !== null && endDate > tmpNewActivity.startDate)               
                setSaveDisabled(disabled);
            }
        } else {
            setSaveDisabled(true);
        }
    }

    const onSaveNewActivity = async () => {
        let tmpNewActivity: ITimesheetActionAdmin = { ...newActivityProps };
        tmpNewActivity.userId = props.userId;
        //IdWorkingMode la recupero dall'azione di timbratura, non può essere diversa
        tmpNewActivity.idWorkingMode = props.timbraturaAction.idWorkingMode;
        if (tmpNewActivity.userId !== null && tmpNewActivity.startDate !== null && tmpNewActivity.endDate !== null && tmpNewActivity.idActionType > 0) {
            try {
                const admin: boolean = appState.userType === UserType.HR || appState.userType === UserType.Admin
                const response = await postCalendar(tmpNewActivity, admin);

                if (response !== null && response.status === 1) {
                    let newDayActions: ITimesheetAction[] = [...rowActions];
                    newDayActions.push(response.payload as ITimesheetAction);
                    //Riordino le azioni
                    newDayActions.sort((a: ITimesheetAction, b: ITimesheetAction) => {
                        //Imposto a 0 i secondi per non considerarli nell'ordinamento
                        let tmpAStartDate: Date = new Date(a.startDate as Date);
                        tmpAStartDate.setSeconds(0);
                        let tmpBStartDate: Date = new Date(b.startDate as Date);
                        tmpBStartDate.setSeconds(0);
                        let result = (tmpAStartDate.getTime() - tmpBStartDate.getTime());
                        //Se le date sono uguali ordino per id
                        if (result === 0) {
                            result = (a.id ?? 1) - (b.id ?? 0);
                        }
                        return result;
                    });
                    setRowActions(newDayActions);
                    dispatch(appActions.setNotifications([{ type: "success", message: SiaStrings.CALENDAR_ADDED } as INotification]));
                } else {
                    dispatch(appActions.setNotifications([{ type: "danger", message: SiaStrings.GENERIC_ERROR } as INotification]));
                }
            } catch (error) {
                dispatch(appActions.setNotifications([{ type: "danger", message: SiaStrings.GENERIC_ERROR } as INotification]));
            } finally {
                debugger;
                //Reset newActivityProps
                setNewActivityProps({
                    id: null,
                    userId: null,
                    startDate: null,
                    endDate: null,
                    idActionType: -1
                } as ITimesheetActionAdmin);
                setSaveDisabled(true);
            }
        }
    }

    const splitTimbraturaActions = (): ITimesheetAction[] => {
        let result: ITimesheetAction[] = [];
        let addedActions: ITimesheetAction[] = [];
        const tmpDayActions = props.dayActions.map((action) => {
            action.startDate?.setSeconds(0);
            action.endDate?.setSeconds(0);
            return action;
        });
        if (props.timbraturaAction !== undefined && props.timbraturaAction !== null) {
            tmpDayActions.forEach(action => {
                if (action.idActionType === props.timbraturaAction.idActionType) {
                    if (action.endDate !== undefined && action.endDate !== null) {

                        addedActions.push({
                            id: action.id,
                            header: action.header,
                            comment: action.comment,
                            startDate: action.endDate,//action.startDate,
                            endDate: action.endDate,
                            idActionType: action.idActionType,
                            idWorkingMode: action.idWorkingMode
                        });
                        action.endDate = null;
                    }
                }
            });
        }
        result = tmpDayActions.concat(addedActions).sort((a: ITimesheetAction, b: ITimesheetAction) => {
            let atime: Date = a.startDate as Date ?? a.endDate as Date;
            let btime: Date = b.startDate as Date ?? b.endDate as Date;
            if (atime !== null && btime !== null) {
                return atime.getTime() - btime.getTime();
            } else {
                return 1;
            }
        });

        return result;
    }

    const canAddActivity = (): boolean => {
        //Il piulsante per aggiungere un attività è visibile se:
        // - siamo nel giorno corrente
        // - L'ultima azione della giornata non deve essre la timbratura di uscita 
        if (moment(props.date).format("DD/MM/yyyy") === moment(new Date()).format("DD/MM/yyyy")) {
            const lastAction = rowActions[rowActions.length - 1];
            if (lastAction.idActionType === props.timbraturaAction.idActionType && lastAction.endDate !== null) {
                return false;
            }
            return true;
        }
        return false;
    }

    useEffect(() => {
        (async () => {
            const splitted = splitTimbraturaActions();
            setRowActions(splitted);
        })();
    }, []);

    useEffect(() => {
        //Rieseguo la funzione per impostare il primo interallo disponibile
        if (rowActions.length > 0) {
            getNewCaledarActionTime();
        }
    }, [rowActions])

    useEffect(() => {
        const theme = appState.teamsContext.theme !== undefined ? appState.teamsContext.theme : '';
        setTheme(theme);
    }, [appState.teamsContext])


    return (
        <Flex fill column className="calendar-row-container">
            <Flex >
                <Flex key="data" gap="gap.smaller" hAlign="start" className="data-column">

                    <Alert info content={
                        <Flex column >
                            <Text content={moment(new Date(props.date)).format("dddd").substring(0, 1).toUpperCase()} />
                            <Text weight="bold" content={moment(new Date(props.date)).format("DD")} />
                        </Flex>
                    } styles={{ width: '50px' }} />{/* <Alert info content={moment(new Date(props.date)).format("dddd").substring(0, 1).toUpperCase()} styles={{ width: '40px' }} /> */}
                    {/* <Text content={moment(new Date(props.date)).format("DD")} /> */}
                </Flex>
                {props.message === '' ?
                    <Flex className="detail-column" gap="gap.small">
                        {rowActions.length === 0 ?
                            <Card aria-roledescription="card start" styles={{ width: '100%' }} >
                                <Card.Header fitted>
                                    <Flex gap="gap.small" vAlign="center">
                                        <Flex column>
                                            <Text content="Nessuna azione" weight="bold" />
                                        </Flex>
                                    </Flex>
                                </Card.Header>
                            </Card>
                            :
                            <>
                                {props.timbraturaAction !== undefined ?
                                    <Flex vAlign="center" gap="gap.small" wrap >
                                        {/* timbratura di inizio lavoro */}
                                        {/* 
                                        <Flex key="ingresso" hAlign="center" className={`action-container ${theme === 'dark' ? 'action-container-dark' : 'action-container-default'} `} styles={{ width: '120px' }}>
                                            <Flex gap="gap.small" vAlign="center">
                                                <ChevronEndIcon circular bordered size="small" />
                                                <Flex column>
                                                    <Text content="Ingresso" weight="bold" />
                                                    <Text content={moment(props.timbraturaAction.startDate).format("HH:mm")} size="small" />
                                                </Flex>
                                            </Flex>
                                        </Flex>
                                         */}
                                        {/* azioni (pausa pranzo + azioni calendario)*/}
                                        {rowActions.map((item, index) => {
                                            //Escludo l'azione di timbratura perchè non la gestisco nel ciclo
                                            if (item.idActionType !== props.timbraturaAction.idActionType || 1 === 1) {
                                                //**************************************************** */
                                                if (item.idActionType === props.timbraturaAction.idActionType && item.endDate === null) {
                                                    return <Flex key={`ingresso_${index}`} hAlign="center" className={`action-container ${theme === 'dark' ? 'action-container-dark' : 'action-container-default'} `} styles={{ width: '120px' }}>
                                                        <Flex gap="gap.small" vAlign="center">
                                                            <ChevronEndIcon circular bordered size="small" />
                                                            <Flex column>
                                                                <Text content="Ingresso" weight="bold" />
                                                                <Text content={moment(item.startDate).format("HH:mm")} size="small" />
                                                            </Flex>
                                                        </Flex>
                                                    </Flex>
                                                }
                                                //**************************************************** */

                                                //***************************************************** */
                                                if (item.idActionType === props.timbraturaAction.idActionType && item.endDate !== null) {
                                                    return <Flex key={`uscita_${index}`} hAlign="center" className={`action-container ${theme === 'dark' ? 'action-container-dark' : 'action-container-default'} `} styles={{ width: '120px' }}>
                                                        <Flex gap="gap.small" vAlign="center" hAlign="center">
                                                            <Flex column>
                                                                <Text content="Uscita" weight="bold" />
                                                                <Text content={moment(item.endDate).format("HH:mm")} size="small" />
                                                            </Flex>
                                                            <ChevronEndIcon circular bordered size="small" />
                                                        </Flex>
                                                    </Flex>
                                                }
                                                //**************************************************** */


                                                let actionTitle: string = '';
                                                const arrACtionTitle = props.actions.filter((action) => {
                                                    return action.id === item.idActionType;
                                                });
                                                if (arrACtionTitle.length > 0) {
                                                    actionTitle = arrACtionTitle[0].title;
                                                }
                                                return (
                                                    <Flex key={index} className={`action-container ${theme === 'dark' ? 'action-container-dark' : 'action-container-default'} `} styles={{ width: '250px' }}>
                                                        <Flex gap="gap.smaller" vAlign="center">
                                                            <Flex space="between" gap="gap.large" vAlign="center" >
                                                                <Text content={moment(item.startDate).format("HH:mm")} size="small" />
                                                                <Text content={actionTitle} size="small" />
                                                                <Text content={item.endDate ? moment(item.endDate).format("HH:mm") : 'Fine'} size="small" />
                                                            </Flex>
                                                            {/* L'azione di pausa pranzo e le azioni dei giorni passati non possono ssere eliminate */}
                                                            {(item.idActionType !== props.idPranzoAction && moment(item.startDate).format("DD/MM/yyyy") === moment(new Date()).format("DD/MM/yyyy")) &&
                                                                <Dialog
                                                                    style={{ maxWidth: "400px" }}
                                                                    cancelButton="Annulla"
                                                                    confirmButton="Conferma"
                                                                    content={
                                                                        <Text content={"Sei sicuro di voler procedere?"} />
                                                                    }
                                                                    onConfirm={async () => {
                                                                        if (item.id !== null) {
                                                                            deleteCalendarAction(item.id);
                                                                        }
                                                                    }}
                                                                    header={`Elimina attività calendario`}
                                                                    trigger={<Button icon={<TrashCanIcon />} iconOnly title="Elimina" size="small" text />}
                                                                />
                                                            }
                                                        </Flex>
                                                    </Flex>
                                                )
                                            }
                                        })
                                        }
                                        {/* Eventuale timbratura di uscita */}
                                        {/*                                         
                                        {props.timbraturaAction.endDate !== null ?
                                            <Flex key="uscita" hAlign="center" className={`action-container ${theme === 'dark' ? 'action-container-dark' : 'action-container-default'} `} styles={{ width: '120px' }}>
                                                <Flex gap="gap.small" vAlign="center" hAlign="center">
                                                    <Flex column>
                                                        <Text content="Uscita" weight="bold" />
                                                        <Text content={moment(props.timbraturaAction.endDate).format("HH:mm")} size="small" />
                                                    </Flex>
                                                    <ChevronEndIcon circular bordered size="small" />
                                                </Flex>
                                            </Flex>
                                            :
                                            null
                                        }
                                         */}
                                        {/* Pulsante nuova azione */}
                                        {(props.timbraturaAction !== null && props.timbraturaAction.startDate !== null) &&
                                            <Dialog
                                                style={{ maxWidth: "400px" }}
                                                cancelButton="Annulla"
                                                confirmButton={{ content: "Conferma", disabled: saveDisabled }}
                                                onCancel={() => {
                                                    //Reset newActivityProps
                                                    // setNewActivityProps({
                                                    //     userId: null,
                                                    //     startDate: null,
                                                    //     endDate: null,
                                                    //     idActionType: -1
                                                    // } as ITimesheetActionAdmin);
                                                    setSaveDisabled(true);
                                                }}
                                                onOpen={() => {
                                                    //if (actionTimes.length === 0) {
                                                    getNewCaledarActionTime();
                                                    //}
                                                }}
                                                content={
                                                    <Flex column gap="gap.medium" style={{ padding: '5px', width: '100%' }}>
                                                        <Dropdown
                                                            className="calendar-dropdown"
                                                            fluid
                                                            placeholder="Seleziona l'azione di calendario"
                                                            items={props.actions.filter((item) => {
                                                                return item.groupName === Constants.GROUP_NAME_CALENDARIO;
                                                            }).map((action) => {
                                                                return {
                                                                    key: action.id.toString(),
                                                                    header: action.title
                                                                };
                                                            })}
                                                            onChange={(ev: any, data: any) => {
                                                                const activityId: number = data.value ? data.value.key as number : -1;
                                                                let tmpNewActivity: ITimesheetActionAdmin = { ...newActivityProps };
                                                                tmpNewActivity.idActionType = activityId;
                                                                setNewActivityProps(tmpNewActivity);
                                                                if (saveDisabled) {
                                                                    //Se anche le date di start e end sono valorizzate abiltio il pulsante salva
                                                                    //setSaveDisabled(!(tmpNewActivity.startDate !== null && tmpNewActivity.endDate !== null));
                                                                    setSaveDisabled(!activityCanAdded(tmpNewActivity.startDate as Date, tmpNewActivity.endDate as Date));
                                                                }
                                                            }}
                                                        />
                                                        {actionTimes.length > 0 &&
                                                            <Flex column gap="gap.medium">
                                                                <Flex gap="gap.small" vAlign="center" >
                                                                    <Text content="inizio" style={{ minWidth: '55px' }} />
                                                                    {/* <TimeSelector time={actionTimes[0]} minTime={actionTimes[0]} maxTime={actionTimes[1]} onTimeModified={(newTime: IActionTime) => { */}
                                                                    <TimeSelector time={actionStartTime} minTime={actionTimes[0]} maxTime={actionTimes[1]} onTimeModified={(newTime: IActionTime) => {
                                                                        onStartActivityChange(newTime);
                                                                    }} />
                                                                </Flex>
                                                                <Flex gap="gap.small" vAlign="center" >
                                                                    <Text content="fine" style={{ minWidth: '55px' }} />
                                                                    {/* <TimeSelector time={actionTimes[1].hours !== undefined ? actionTimes[1] : calcTempEndDate()} minTime={actionTimes[0]} maxTime={actionTimes[1]} onTimeModified={(newTime: IActionTime) => { */}
                                                                    <TimeSelector time={actionEndTime.hours !== undefined ? actionEndTime : calcTempEndDate(actionStartTime)} minTime={actionTimes[0]} maxTime={actionTimes[1]} onTimeModified={(newTime: IActionTime) => {
                                                                        onEndActivityChange(newTime);
                                                                    }} />
                                                                </Flex>
                                                            </Flex>
                                                        }
                                                    </Flex>
                                                }
                                                onConfirm={async () => {
                                                    await onSaveNewActivity();
                                                }}
                                                header={`Nuova attività calendario`}
                                                trigger={canAddActivity() ? <Button primary iconOnly icon={<AddIcon />} disabled={addActionDisabled} /> : <></>}
                                            />
                                        }

                                    </Flex>
                                    :
                                    <Card aria-roledescription="card start" styles={{ width: '100%' }} >
                                        <Card.Header fitted>
                                            <Flex gap="gap.small" vAlign="center">
                                                <Flex column>
                                                    <Text content="Azione di inizio lavoro assente" weight="bold" />
                                                </Flex>
                                            </Flex>
                                        </Card.Header>
                                    </Card>
                                }
                            </>
                        }
                    </Flex>
                    :
                    <Card aria-roledescription="card start" styles={{ width: '95%' }} >
                        <Card.Header fitted>
                            <Flex gap="gap.small" vAlign="center">
                                <Flex column>
                                    <Text content={props.message} weight="bold" />
                                </Flex>
                            </Flex>
                        </Card.Header>
                    </Card>
                }
            </Flex>
            <Divider />
        </Flex >
    )
}

export default CalendarRow