import React, { useState, useEffect } from "react";

import { Flex, Text, Tooltip, Dialog, Button, SaveIcon, Alert, CloseIcon, CalendarIcon, Datepicker, DayOfWeek } from "@fluentui/react-northstar";
import { Money24Regular, ShiftsActivity20Regular, InkStroke20Regular } from '@fluentui/react-icons';

import OvertimeSelector from "./OvertimeSelector";
import { updateOvertime, addOvertimeRecovery } from '../helpers/Fetch';
import { ISaveResult } from "../interfaces/ISaveResult";
import moment from "moment";
import { ITimesheetAction } from "../interfaces/ITimesheetAction";
import { IOvertime } from "../interfaces/IOvertime";
import { IOvertimeRecovery } from "../interfaces/IOvertimeRecovery";

export interface IMonthlyDayOvertimeProps {
    overtimeInformation: IOvertime;
    userCountry: string;
    onOvertimeChange: any;
    timesheets: ITimesheetAction[];
    idTimbraturaAction: number;
    idUserSF: string;
}

enum OvertimeValueType {
    Paid = "Paid",
    Compensated = "compensated",
    Elastic = "Elastic"

}

const MonthlyDayOvertime = (props: IMonthlyDayOvertimeProps) => {

    const { overtimeInformation, userCountry, timesheets, idTimbraturaAction, idUserSF } = props;
    const [overtimeInfo, setOvertimeInfo] = useState<IOvertime>({
        //day: overtimeInformation.day,
        //overtime: {
        id: overtimeInformation.id,
        compensatedMinutes: overtimeInformation.compensatedMinutes,
        elasticClauseMinutes: overtimeInformation.elasticClauseMinutes,
        minutesOvertime: overtimeInformation.minutesOvertime,
        paidMinutes: overtimeInformation.paidMinutes,
        recovery: overtimeInformation.recovery
        //}
    });
    const [remainingMinutes, setRemainingMinutes] = useState(overtimeInformation.minutesOvertime);
    const [saveDisabled, setSaveDisabled] = useState(true);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogMaxDateOpen, setDialogMaxDateOpen] = useState(false);
    const [saveMessage, setSaveMessage] = useState<ISaveResult>({ message: "", error: false });
    const [maxCompensationDate, setMaxCompensationDate] = useState(new Date());
    const [idTimesheet, setIdTimesheet] = useState(0);
    const [day, setDay] = useState(new Date());

    useEffect(() => {
        //Ottengo id dell'azione di timbratura
        if (timesheets && timesheets.length > 0) {
            const items = timesheets.filter(t => t.idActionType === idTimbraturaAction);
            if (items.length > 0) {
                setIdTimesheet(items[0].id ?? 0);
                let tmpDay = new Date(items[0].startDate !== null ? items[0].startDate.toString() : new Date().toString());
                tmpDay.setHours(0);
                tmpDay.setMinutes(0);
                tmpDay.setSeconds(0);
                setDay(tmpDay);
            } else {
                //throw new Error('Nessuna azione di timbratura trovata');
                console.log('Nessuna azione di timbratura trovata');
            }
        }
        //Se già esiste il recovery utilizzo la data
        if (overtimeInformation.recovery !== null) {
            setMaxCompensationDate(new Date(overtimeInformation.recovery.maxRecoveryDay));
        }
    }, []);

    useEffect(() => {
        const remainingMinutesValue: number = overtimeInfo.minutesOvertime - (overtimeInfo.compensatedMinutes ?? 0) - (overtimeInfo.paidMinutes ?? 0) - (overtimeInfo.elasticClauseMinutes ?? 0);
        setRemainingMinutes(remainingMinutesValue);
        debugger;

    }, [overtimeInfo]);



    const onOvertimeValueChange = (id: number, newValue: number, type: OvertimeValueType) => {
        const ovToUpdate = { ...overtimeInfo };
        switch (type) {
            case OvertimeValueType.Compensated:
                ovToUpdate.compensatedMinutes = newValue;
                break;
            case OvertimeValueType.Paid:
                ovToUpdate.paidMinutes = newValue;
                break;
            case OvertimeValueType.Elastic:
                ovToUpdate.elasticClauseMinutes = newValue;
                break;
            default:
                break;
        }
        setOvertimeInfo(ovToUpdate);
        const canSave: boolean = canSaveOvertime(ovToUpdate);
        setSaveDisabled(!canSave);
    }

    const onPaidMinutesChange = (id: number, newValue: number) => {
        onOvertimeValueChange(id, newValue, OvertimeValueType.Paid);
    }

    const onCompensatedMinutesChange = (id: number, newValue: number) => {
        onOvertimeValueChange(id, newValue, OvertimeValueType.Compensated);
    }

    const onElasticMinutesChange = (id: number, newValue: number) => {
        onOvertimeValueChange(id, newValue, OvertimeValueType.Elastic);
    }

    const canSaveOvertime = (ovToUpdate: IOvertime): boolean => {
        if (
            (ovToUpdate.compensatedMinutes !== null && ovToUpdate.compensatedMinutes < 30 && ovToUpdate.compensatedMinutes !== 0) ||
            (ovToUpdate.elasticClauseMinutes !== null && ovToUpdate.elasticClauseMinutes < 30 && ovToUpdate.elasticClauseMinutes !== 0) ||
            (ovToUpdate.paidMinutes !== null && ovToUpdate.paidMinutes < 30 && ovToUpdate.paidMinutes !== 0)
        ) {
            return false;
        }
        return true;
    }

    const setRecoveryDate = async (setNullDate: boolean): Promise<boolean> => {
        if (idTimesheet === 0) {
            setSaveMessage({
                message: "Nella data selezionata non è presente un'azione di timbratura",
                error: true
            });
            return false;
        }

        let data: IOvertimeRecovery = {
            //idTimesheet: idTimesheet,
            overtimeDay: moment(day).format(),
            idUserSF: idUserSF
        };

        if (!setNullDate) {
            data.maxDateRecovery = moment(maxCompensationDate).format();
        }
        const addResponse = await addOvertimeRecovery(data);

        // const addResponse = await addOvertimeRecovery({
        //     idTimesheet: idTimesheet,
        //     overtimeDay: moment(day).format(),
        //     maxDateRecovery: setNullDate ? null : moment(maxCompensationDate).format(),
        //     idUserSF: idUserSF
        // });

        if (addResponse.status !== 1) {
            setSaveMessage({
                message: "Si è verificato un errore durante l'inserimento",
                error: true
            });
            return false;
        } else {
            setSaveMessage({
                message: "Operazione completata con successo",
                error: false
            });
        }

        return true;
    }

    return <div>
        {overtimeInfo !== null &&
            <Flex gap="gap.smaller" hAlign="end" vAlign="center" >
                <Tooltip content={`${remainingMinutes > 0 ? 'Minuti di straordinario non assegnati' : 'carenze'}`} trigger={
                    <Text size="medium" style={{ paddingTop: '8px', marginRight: "4px !important" }} content={`${remainingMinutes} min.`} color={`${remainingMinutes > 0 ? 'green' : 'red'}`} />
                } />
                {/* Il controllo è disabilitato se sono già stati recuperati dei minuti */}
                <Flex gap="gap.smaller">
                    <OvertimeSelector disabled={overtimeInfo.recovery?.recoveredAmount !== undefined && overtimeInfo.recovery?.recoveredAmount > 0} icon={<Tooltip content="Minuti di compensazione" trigger={<ShiftsActivity20Regular />} />} id={overtimeInfo.id} minutesOvertime={overtimeInfo.minutesOvertime} remainingMinutes={remainingMinutes} value={overtimeInfo.compensatedMinutes ?? 0} step={1} onValueChange={onCompensatedMinutesChange} />
                </Flex>
                <Flex gap="gap.smaller">
                    <OvertimeSelector icon={<Tooltip content="Minuti da pagare" trigger={<Money24Regular style={{ paddingRight: "15px" }} />} />} id={overtimeInfo.id} minutesOvertime={overtimeInfo.minutesOvertime} remainingMinutes={remainingMinutes} value={overtimeInfo.paidMinutes ?? 0} step={1} onValueChange={onPaidMinutesChange} />
                </Flex>
                {(userCountry.toLowerCase() === 'all' || userCountry.toLowerCase() === 'ita') &&
                    <Flex gap="gap.smaller">
                        <OvertimeSelector icon={<Tooltip content="Minuti clausola elastica" trigger={<InkStroke20Regular />} />} id={overtimeInfo.id} minutesOvertime={overtimeInfo.minutesOvertime} remainingMinutes={remainingMinutes} value={overtimeInfo.elasticClauseMinutes ?? 0} step={1} onValueChange={onElasticMinutesChange} />
                    </Flex>
                }
                {/* Pulsante/Dialog Salva */}
                <Dialog
                    style={{ maxWidth: "400px" }}
                    cancelButton="Chiudi"
                    confirmButton={{ content: `Conferma`, disabled: saveMessage.message !== '' }}
                    open={dialogOpen}
                    headerAction={{ icon: <CloseIcon />, title: 'Close', onClick: () => setDialogOpen(false) }}
                    onOpen={() => {
                        setDialogOpen(true);
                    }}
                    onCancel={() => {
                        setDialogOpen(false);
                        //reset saveMessage
                        setSaveMessage({
                            message: '',
                            error: false
                        });

                        if (saveMessage.message === 'Operazione completata con successo') {
                            //aggiorno la riga solo se ho modificato i valori
                            debugger;
                            props.onOvertimeChange(day);
                        }
                    }}
                    content={
                        <Flex column gap="gap.medium">
                            {!saveDisabled ?// canSaveOvertime() ?
                                <Text content={"Salvare i dati inseriti?"} />
                                :
                                <Text content={"Non è possibile inserire valori inferiori a 30 minuti"} />
                            }
                            {saveMessage.message &&
                                <Alert danger={saveMessage.error} success={!saveMessage.error} content={saveMessage.message} />
                            }
                        </Flex>
                    }
                    onConfirm={async () => {
                        const updateResponse = await updateOvertime(overtimeInfo);
                        if (updateResponse.status !== 1) {
                            setSaveMessage({
                                message: "Si è verificato un errore durante l'aggiornamento",
                                error: true
                            });
                        } else {
                            //Se i minuti di compensazione vengono re-impostati a 0 devo annullare la data massima di recupero straordinario 
                            if (overtimeInformation.compensatedMinutes > 0 && overtimeInfo.compensatedMinutes === 0) {
                                const response: boolean = await setRecoveryDate(true);
                                if (!response) {
                                    return;
                                }
                            }
                            setSaveMessage({
                                message: "Operazione completata con successo",
                                error: false
                            });
                        }
                    }}
                    header={`Conferma`}
                    trigger={<Button icon={<SaveIcon />} disabled={saveDisabled} title="Salva" size="medium" iconOnly primary />}
                />
                {/* Pulsante/Dialog per assegnazione max data uso compensazione */}
                {userCountry.toLowerCase() === 'che' &&
                    <Dialog
                        style={{ maxWidth: "400px" }}
                        cancelButton="Chiudi"
                        confirmButton={{ content: `Conferma`, disabled: saveMessage.message !== '' }}
                        open={dialogMaxDateOpen}
                        headerAction={{
                            icon: <CloseIcon />, title: 'Close', onClick: () => {
                                setDialogMaxDateOpen(false);
                                if (saveMessage.message === 'Operazione completata con successo') {
                                    //reset saveMessage
                                    setSaveMessage({
                                        message: '',
                                        error: false
                                    });
                                    //aggiorno la riga solo se ho modificato i valori
                                    props.onOvertimeChange(day);
                                }
                            }
                        }}
                        onOpen={() => {
                            setDialogMaxDateOpen(true);
                        }}
                        onCancel={() => {
                            setDialogMaxDateOpen(false);
                            //reset saveMessage
                            setSaveMessage({
                                message: '',
                                error: false
                            });
                            if (saveMessage.message === 'Operazione completata con successo') {
                                //aggiorno la riga solo se ho modificato i valori
                                props.onOvertimeChange(day);
                            }
                        }}
                        content={
                            <Flex column gap="gap.medium">
                                <Datepicker
                                    onDateChange={(event, control) => {
                                        if (control) {
                                            let eDate: Date = new Date(control.value);
                                            eDate.setHours(23);
                                            eDate.setMinutes(59);
                                            setMaxCompensationDate(eDate);
                                        }
                                    }}
                                    selectedDate={maxCompensationDate}
                                    minDate={new Date()}
                                    formatMonthDayYear={(date: Date, strings): string => {
                                        return moment(date).format("DD/MM/YYYY");
                                    }}
                                    firstDayOfWeek={DayOfWeek.Monday}
                                    days={['Domenica', 'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato']}
                                    shortDays={['D', 'L', 'M', 'M', 'G', 'V', 'S']}
                                    months={['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre']}
                                    nextMonthAriaLabel="Mese successivo"
                                    prevMonthAriaLabel="Mese precedente"
                                />
                                {saveMessage.message &&
                                    <Alert danger={saveMessage.error} success={!saveMessage.error} content={saveMessage.message} />
                                }
                            </Flex>
                        }
                        onConfirm={async () => {
                            await setRecoveryDate(false);
                        }}
                        header={`Data recupero`}
                        trigger={<Button icon={<CalendarIcon />} disabled={overtimeInformation.compensatedMinutes <= 0} title="Imposta data recupero" size="medium" iconOnly primary />}
                    />
                }
            </Flex>
        }
    </div>
}

export default MonthlyDayOvertime;