
import * as Constants from '../../constants/AppConstants';
import { IEmployee } from '../../interfaces/IEmployee';

export const setSsfJobs = (jobs:Array<IEmployee> = []) => {    
    return { type: Constants.SET_SSF_JOBS, jobs };
}

export const setSelectedJob = (jobIndex:number = 0) => {
    return { type: Constants.SET_SELECTED_JOB, jobIndex };
}

export const setSsfIsLoaded = (isLoaded:boolean) => {
    return { type: Constants.SET_SSF_ISLOADED, isLoaded };
}

export const setIdUserSf = (idUserSF:string) => {
    return { type: Constants.SET_ID_USER_SF, idUserSF };
}