import "../scss/TimeSelector.scss";

import React, { useEffect, useState } from "react";
import { Flex, Input, Text, ExclamationTriangleIcon } from '@fluentui/react-northstar';
import { IActionTime } from "../interfaces/IActionTime";
import { calcActivityDate } from '../helpers/SiaHelper';
import moment from "moment";

const TimeSelector = (props: any) => {
    const [time, setTime] = useState<IActionTime | undefined>(props.time != null ? props.time : undefined);
    // const [minTime, setMinTime] = useState<IActionTime | undefined>(props.minTime != null ? props.minTime : undefined);
    // const [maxTime, setMaxTime] = useState<IActionTime | undefined>(props.maxTime != null ? props.maxTime : undefined);
    const [minTime, setMinTime] = useState<IActionTime>({ hours: 0, minutes: 0 } as IActionTime);
    const [maxTime, setMaxTime] = useState<IActionTime>({ hours: 23, minutes: 59 } as IActionTime);
    const [maxTimeDate, setMaxTimeDate] = useState<Date | undefined>(undefined);
    const [minTimeDate, setMinTimeDate] = useState<Date | undefined>(undefined);
    const [error, setError] = useState<boolean | undefined>(undefined);

    useEffect(() => {
        if (props.minTime !== undefined && props.minTime.hours !== undefined && props.minTime.minutes !== undefined) {
            setMinTime(props.minTime);
        }
        if (props.maxTime !== undefined && props.maxTime.hours !== undefined && props.maxTime.minutes !== undefined) {
            setMaxTime(props.maxTime);
        }
    }, [])

    useEffect(() => {

        if (time !== undefined && minTimeDate !== undefined && maxTimeDate !== undefined) {
            let timeDate: Date = calcActivityDate(new Date(), time);
            if (moment(timeDate).isBefore(minTimeDate) || moment(timeDate).isAfter(maxTimeDate)) {
                setError(true);
            } else {
                if (error) {
                    setError(false);
                }
            }
            props.onTimeModified(time);
        }
    }, [time])

    useEffect(() => {
        if (minTime.hours !== undefined && minTime.minutes !== undefined) {
            let minDate: Date = calcActivityDate(new Date(), minTime);
            setMinTimeDate(minDate);
        }

    }, [minTime])

    useEffect(() => {
        if (maxTime.hours !== undefined && maxTime.minutes !== undefined) {
            let maxDate: Date = calcActivityDate(new Date(), maxTime);
            setMaxTimeDate(maxDate);
        }
    }, [maxTime])


    return (
        <Flex gap="gap.smaller" hAlign="center" vAlign="center" className={`time-selector-main ${props.showLabel ? '' : 'time-selector-compact'}`}   >
            <div className="text-input" >
                <Flex column gap="gap.smaller" hAlign="center" >
                    {props.showLabel &&
                        <Text content="Ore" />
                    }
                    <Input maxLength={2}
                        fluid
                        placeholder="00"
                        defaultValue="0"
                        min={minTime.hours}
                        max={maxTime.hours}
                        type="number"
                        value={time !== undefined ? time.hours : undefined}
                        onChange={(ev, data) => {
                            if (data?.value as string === '' || (parseInt(data?.value as string) <= 23 && (data?.value as string).length <= 2)) {
                                const tmpTime: IActionTime = {
                                    hours: parseInt(data?.value as string),
                                    minutes: time?.minutes
                                }
                                setTime(tmpTime);
                            }
                        }}
                    />
                </Flex>
            </div>
            <span className={`${props.showLabel ? 'text-divider' : ''}`}>:</span>
            <div className="text-input" >
                <Flex column gap="gap.smaller" hAlign="center" >
                    {props.showLabel &&
                        <Text content="Minuti" />
                    }
                    <Input maxLength={2}
                        fluid
                        placeholder="00"
                        type="number"
                        defaultValue="0"
                        min={time?.hours === minTime.hours ? minTime.minutes : 0}
                        max={time?.hours === maxTime.hours ? maxTime.minutes : 59}
                        value={time !== undefined ? time.minutes : undefined}
                        onChange={(ev, data) => {
                            if (data?.value as string === '' || (parseInt(data?.value as string) <= 59 && (data?.value as string).length <= 2)) {
                                // const nValue: number = parseInt(data?.value as string);
                                // if (nValue < (data?.min as number) || nValue > (data?.max as number)) {
                                //     setError(true);
                                //     return;
                                // }
                                // if (error) {
                                //     setError(undefined);
                                // }

                                const tmpTime: IActionTime = {
                                    hours: time?.hours,
                                    minutes: parseInt(data?.value as string)
                                }
                                setTime(tmpTime);
                                //props.onTimeModified(tmpTime);
                            }
                        }}
                    />
                </Flex>
            </div>
            {error &&
                <div className="text-input" >
                    {/* <Alert danger content="" icon={<ExclamationTriangleIcon />}/> */}
                    <ExclamationTriangleIcon className="time-selector-error" />
                </div>
            }
        </Flex >
    );
}

export default TimeSelector;