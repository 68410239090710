//Componenente che visualizza la sezione per l'inserimento di una nuova attività

import { useEffect, useState } from "react";

import { Flex, Alert, Button, Text, Label, Dropdown, Tooltip, Loader, FormTextArea } from "@fluentui/react-northstar";
import { IActionType } from "../interfaces/IActionType";
import { ITimesheetActionAdmin } from "../interfaces/ITimesheetAction";
import TimeSelector from "../components/TimeSelector";
import { IActionTime } from "../interfaces/IActionTime";
import { calcActivityDate, formatRowDate } from '../helpers/SiaHelper';
import { IDateRange } from "../interfaces/IDateRange";
import { postTimesheet } from "../helpers/Fetch";
import { ISaveResult } from "../interfaces/ISaveResult";
import { SiaStrings } from "../strings/strings";
import * as cookieHelper from '../helpers/CookiesHelper';
import * as Constants from '../constants/AppConstants';
import { IWorkingMode } from "../interfaces/IWorkingMode";

interface IMonthlyActivitiesAddNewProps {
    userActions: { key: string, header: string, disabled: boolean }[];
    allActionsType: IActionType[];
    date: Date;
    idUserSF: string;
    idTimbraturaAction: number;
    onCloseDialog: any;
}

const MonthlyActivitiesAddNew = (props: IMonthlyActivitiesAddNewProps) => {
    const [newActivityProps, setNewActivityProps] = useState<ITimesheetActionAdmin>({} as ITimesheetActionAdmin);
    const [showTimes, setShowTimes] = useState(false);
    const [saving, setSaving] = useState(false);
    const [workingModesItems, setWorkingModesItems] = useState<{ key: string, header: string }[]>([]);
    const [saveResult, setSaveResult] = useState<ISaveResult>({
        message: "",
        error: false
    });

    useEffect(() => {
        (async () => {
            let sDate: Date = new Date(props.date);
            sDate.setHours(0);
            sDate.setMinutes(0);
            sDate.setSeconds(0);
            let eDate = new Date(props.date);
            eDate.setHours(23);
            eDate.setMinutes(59);
            eDate.setSeconds(59);
            let newAction = { ...newActivityProps };
            newAction.id = null;
            newAction.startDate = sDate;
            newAction.endDate = eDate;
            setNewActivityProps(newAction);
            const workingModes: IWorkingMode[] = await cookieHelper.setApplicationCookies.setWorkingModesCookie(); //cookieHelper.getApplicationCookies.getWorkingModesCookie();
            const tmpWorkingModeItems: { key: string, header: string }[] = workingModes.map((wm: IWorkingMode) => {
                return {
                    key: wm.id.toString(),
                    header: wm.name
                };
            });
            setWorkingModesItems(tmpWorkingModeItems);
        })();

    }, [])

    const onNewActivityStartDateChange = (time: IActionTime) => {
        let tmpNewActivity: ITimesheetActionAdmin = { ...newActivityProps };
        let startDate: Date = calcActivityDate(props.date, time);
        tmpNewActivity.startDate = startDate;
        setNewActivityProps(tmpNewActivity);
    }

    const onNewActivityEndDateChange = (time: IActionTime) => {
        let tmpNewActivity: ITimesheetActionAdmin = { ...newActivityProps };
        let endDate: Date = calcActivityDate(props.date, time);
        tmpNewActivity.endDate = endDate;
        setNewActivityProps(tmpNewActivity);
    }

    const saveDisabled = (): boolean => {

        if (newActivityProps.idActionType === props.idTimbraturaAction) {
            return (!newActivityProps.idWorkingMode || !newActivityProps.idActionType)
        } else {
            return !newActivityProps.idActionType;
        }
    }


    return workingModesItems.length > 0 ?
        <Flex column gap="gap.small" fill>
            <Text weight="bold" content="Inserisci attività" />
            {(newActivityProps.idActionType === props.idTimbraturaAction) &&
                <Flex gap="gap.small" fill >
                    <Dropdown
                        autoSize
                        items={workingModesItems}
                        placeholder="Seleziona la modalità di lavoro"
                        onChange={(event: any, data: any) => {
                            const workMode: number = data.value ? parseInt(data.value.key) : 0;
                            if (workMode === 0) {
                                return;
                            }
                            let tmpNewActivity: ITimesheetActionAdmin = { ...newActivityProps };
                            tmpNewActivity.idWorkingMode = workMode;
                            setNewActivityProps(tmpNewActivity);
                        }}
                        error={newActivityProps.idWorkingMode ? false : true}
                    />
                    {!newActivityProps.idWorkingMode &&
                        <Text content="*" style={{ color: "red" }} />
                    }
                </Flex>
            }
            <Flex gap="gap.small" fill >
                <Dropdown
                    autoSize
                    items={props.userActions.filter(a => a.disabled === false)}
                    placeholder="Seleziona l'attività"
                    onChange={(event: any, data: any) => {
                        // debugger;
                        const actionId: string = data.value ? data.value.key : '';
                        if (actionId === '') {
                            return;
                        }
                        const selAction: IActionType = props.allActionsType.filter((action: IActionType) => { return action.id === parseInt(actionId); })[0];
                        let tmpNewActivity: ITimesheetActionAdmin = { ...newActivityProps };
                        tmpNewActivity.idActionType = selAction.id;
                        tmpNewActivity.userId = props.idUserSF;
                        setNewActivityProps(tmpNewActivity);
                        const dRange: IDateRange = formatRowDate(selAction, new Date(), new Date());
                        setShowTimes(dRange.startDate.length > 10 || selAction.groupName === Constants.GROUP_NAME_TRASFERTA);
                    }}
                    error={newActivityProps.idActionType ? false : true}
                />
                {!newActivityProps.idActionType &&
                    <Text content="*" style={{ color: "red" }} />
                }
            </Flex>
            {newActivityProps.idActionType &&
                <FormTextArea fluid label="Commento"
                    id="commentId"
                    onChange={(event, control) => {
                        debugger;
                        if (control !== undefined && control.value !== undefined) {
                            let tmpNewActivity: ITimesheetActionAdmin = { ...newActivityProps };
                            tmpNewActivity.comment = control.value !== '' ? control.value : '';
                            setNewActivityProps(tmpNewActivity);
                        }
                    }}
                />
            }
            {showTimes &&
                <Flex gap="gap.small" hAlign="end" key={newActivityProps.idActionType} >
                    <Flex vAlign="center" gap="gap.smaller" >
                        <Text content="Inizio" />
                        <TimeSelector time={{
                            hours: 0,
                            minutes: 0
                        } as IActionTime}
                            onTimeModified={onNewActivityStartDateChange}
                            showLabel={false} />
                    </Flex>
                    <Flex vAlign="center" gap="gap.smaller">
                        <Text content="Fine" />
                        <TimeSelector time={{
                            hours: 0,
                            minutes: 0
                        } as IActionTime}
                            onTimeModified={onNewActivityEndDateChange}
                            showLabel={false} />
                    </Flex>
                </Flex>
            }
            <Flex column gap="gap.small" hAlign="end" fill >
                <Tooltip trigger={<Button content="Salva" primary loading={saving} disabled={saveDisabled()} size="small" onClick={async () => {
                    setSaving(true);
                    try {
                        const response = await postTimesheet(newActivityProps, true);
                        if (response.status === 1) {
                            setSaveResult({ message: SiaStrings.TIMESHEET_ADDED, error: false });
                            props.onCloseDialog();
                        } else {
                            setSaveResult({ message: SiaStrings.GENERIC_ERROR, error: true })
                        }
                    } catch (error) {
                        setSaveResult({ message: SiaStrings.GENERIC_ERROR, error: true })
                    } finally {
                        setSaving(false);
                    }
                }} />} content="Salva la nuova attività" />
                {saveResult.message !== "" &&
                    <Alert style={{ width: "100%" }} content={saveResult.message} success={!saveResult.error} danger={saveResult.error} />
                }
            </Flex>
        </Flex>
        :
        <Loader label="Loading SIA data..." />

}

export default MonthlyActivitiesAddNew;