import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { IAppState } from "../interfaces/IAppState";
import { IEmployee } from "../interfaces/IEmployee";
import { ICustomSelectorItem } from '../interfaces/ICustomSelectorItem';
import { suggestNextActions } from "../helpers/SiaHelper";
import { SiaStrings } from "../strings/strings";
import * as siaActions from '../stores/actions/SiaActions'
import { ITimesheetAction } from "../interfaces/ITimesheetAction";
import { Home20Regular, DrinkCoffee20Regular, Food20Regular, Building20Regular } from '@fluentui/react-icons';
import { INotification } from '../interfaces/INotification';
import * as appActions from "../stores/actions/AppActions";
import * as Constants from '../constants/AppConstants';
import { Flex, Button } from "@fluentui/react-northstar";
import Clock from "./Clock";

export interface IBadgeActionSelectorMobileProps {
    actionClick: any;
    saving: boolean;
}

const BadgeActionSelectorMobile = (props: IBadgeActionSelectorMobileProps) => {

    const dispatch = useDispatch();
    const appState: IAppState = useSelector((appState) => appState) as IAppState;
    const employee: IEmployee = appState.ssfJobs[appState.selectedJob] as IEmployee;
    const [suggestedActions, setSuggestedActions,] = useState([] as Array<ITimesheetAction>);
    const [customSelectorItems, setCustomSelectorItems] = useState([{ key: 0, value: SiaStrings.NO_SUGGESTIONS }] as Array<ICustomSelectorItem>);
    const [itemKey, setItemKey] = useState(-1);

    const mapActionsToCustomSelectItems = (suggActions: Array<ITimesheetAction>): Array<ICustomSelectorItem> => {
        let custoSelectorItems: Array<ICustomSelectorItem> = [{ key: 0, value: SiaStrings.NO_SUGGESTIONS }] as Array<ICustomSelectorItem>;
        if (suggActions && suggActions.length > 0) {
            custoSelectorItems = [] as Array<ICustomSelectorItem>;
            suggActions.forEach((item: ITimesheetAction, index: number) => {
                custoSelectorItems.push({ key: index, value: item.header, idActionType: item.idActionType } as ICustomSelectorItem);
                //custoSelectorItems.push({ key: item.idActionType, value: item.header } as ICustomSelectorItem);
            })
        }
        return custoSelectorItems;
    }

    useEffect(() => {
        (async () => {
            if (appState.todayStatus && appState.timbraturaAction !== null && appState.pranzoAction !== null && appState.pausaAction !== null) {
                //let suggActions = await suggestNextActions(appState.todayStatus, employee, appState);
                let suggActions: ITimesheetAction[] = [];
                try {
                    suggActions = await suggestNextActions(appState.todayStatus, employee, appState);
                    //Nelle azioni suggerite ci sarà sempre una sola azione di fine, quindi la metto sempre in ultima posizione per fare in modo che il pulsante di fine sia sempre iin fondo
                    const endIndex = suggActions.findIndex(action => action.header?.toLowerCase().indexOf("fine") !== -1);
                    if (endIndex !== -1) {
                        const endAction = suggActions.splice(endIndex, 1);
                        suggActions.push(endAction[0]);
                    }
                } catch (error) {
                    console.log(`Errore nel calcole delle azioni da suggerire nel badge`);
                }
                setSuggestedActions(suggActions);
                dispatch(siaActions.setActionToRegister(suggActions[0]));
                let customItems: Array<ICustomSelectorItem> = mapActionsToCustomSelectItems(suggActions);
                setCustomSelectorItems(customItems);
            }
        })();
    }, [appState.todayStatus, dispatch, employee, appState.pausaAction, appState.pranzoAction, appState.timbraturaAction]);

    return suggestedActions != null && appState.todayStatusIsLoaded ? (
        <Flex column gap="gap.medium" hAlign="center" >
            <Clock />


            {customSelectorItems.map(item => {
                let buttonIcon = null;
                const filteredAction = suggestedActions.filter(a => a.idActionType === item.idActionType);
                if (filteredAction.length > 0) {
                    switch (filteredAction[0].idActionType) {
                        case 2:
                            buttonIcon = filteredAction[0].idWorkingMode === 3 ? <Building20Regular /> : filteredAction[0].idWorkingMode !== null ? <Home20Regular /> : null;
                            break;
                        case 1:
                            buttonIcon = <Food20Regular />;
                            break;
                        case 48:
                            buttonIcon = <DrinkCoffee20Regular />;
                            break;
                        default:
                            break;
                    }
                }
                return <Button key={`action_${item.key}`}
                    icon={buttonIcon}
                    content={item.value}
                    primary
                    size="medium"
                    styles={{ width: '100vw', padding: '20px 0px' }}
                    disabled={props.saving}
                    onClick={() => {
                        debugger;
                        setItemKey(item.key as number);
                        if (appState.currentWorkingMode === null && appState.userCanBadge) {
                            dispatch(appActions.setNotifications([{ type: Constants.notificationTypes.DANGER, message: SiaStrings.CHOOSE_WORKINGMODE } as INotification]));
                        } else {
                            props.actionClick(suggestedActions[item.key as number]);
                        }
                    }}
                    loading={props.saving && item.key === itemKey}
                />
            })}
        </Flex>
        // <div className="badge-drop-down-actions">
        // 	<CustomSlideSelector data={customSelectorItems} onChange={handleDropwDownChange} defaultSelected={0} />
        // </div>
    ) : null;
};

export default BadgeActionSelectorMobile;