import { BrowserRouter as Router, Route } from "react-router-dom";
import Assistances from "./Assistances";
import Badge from './Badge';
import Monthly from './Monthly';
import Staff from './Staff';
import TabsContainer from "./TabsContainer";
import TabAssistances from './TabAssistances';
import NewMonthly from './NewMonthly';
import BadgeMobile from './BadgeMobile';
import NewAssistence from './NewAssistence';

const AppRoutes = (props:any) => {
    return <Router>
         <Route exact path="/" render={(props) => <Badge  />} />         
         <Route exact path="/badge" render={(props) => <Badge  />} />         
         <Route exact path="/mobile" render={(props) => <BadgeMobile  />} />         
         <Route exact path="/monthly" render={(props) => <NewMonthly />} />
         {/* <Route exact path="/assistances" render={(props) => <Assistances />} /> */}
         <Route exact path="/staff" render={(props) => <Staff />} />
         <Route exact path="/altro" render={(props) => <TabsContainer />} />
         <Route exact path="/assistances" render={(props) => <TabAssistances />} />
         <Route exact path="/NewAssistence" render={(props) => <NewAssistence />} />
    </Router>
}
export default AppRoutes;