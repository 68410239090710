import AppState from '../states/AppState';
import * as Constants from '../../constants/AppConstants';
import { selectCurrentEmpJob } from '../../helpers/SuccessFactorsHelper';
import { IActionType } from '../../interfaces/IActionType';


const SiaReducer = (state = AppState, action: any) => {
    switch (action.type) {
        case Constants.SET_TEAMS_INITIALIZED:
            return { ...state, teamsContextIsInitialized: action.isInitialized }
        case Constants.SET_TEAMS_CONTEXT:
            return { ...state, teamsContext: action.context }
        case Constants.SET_SSF_ISLOADED:
            return { ...state, ssfIsLoaded: action.isLoaded }
        case Constants.SET_SSF_JOBS:
            {
                return { ...state, ssfJobs: action.jobs }
            }
        case Constants.SET_SELECTED_JOB:
            return { ...state, selectedJob: selectCurrentEmpJob(action.jobIndex) }
        case Constants.SET_ID_USER_SF:
            return { ...state, idUserSF: action.idUserSF }
        case Constants.SET_TODAY_STATUS_ISLOADED:
            return { ...state, todayStatusIsLoaded: action.isLoaded }
        case Constants.SET_CURRENT_WORKINGMODE:
            return { ...state, currentWorkingMode: action.idWorkingMode }
        case Constants.SET_IS_REGISTERING:
            return { ...state, isRegistering: action.isRegistering }
        case Constants.SET_NOTIFICATIONS:
            return { ...state, notifications: action.notifications }
        case Constants.ADD_TIMESHEET:
            let newStatus = [...state.todayStatus];
            newStatus.push(action.timesheetAction);
            return { ...state, todayStatus: newStatus };
        case Constants.SET_ACTION_TO_REGISTER:
            return { ...state, actionToRegister: action.timesheetAction };
        case Constants.SET_TODAY_STATUS:
            return { ...state, todayStatus: action.todayStatus }
        case Constants.SET_CLOCKING_DATE:
            return { ...state, clockingDate: action.clockingDate }
        case Constants.SET_USER_CAN_BADGE:
            return { ...state, userCanBadge: action.canBadge }
        case Constants.SET_ACTIONS_FROM_BADGE:
            {
                const timbratura: IActionType = action.actions.filter((value: IActionType, index: number, array: IActionType[]) => {
                    return (value.groupName === Constants.GROUP_NAME_TIMBRATURA);
                })[0];
                const pranzo: IActionType = action.actions.filter((value: IActionType, index: number, array: IActionType[]) => {
                    return (value.groupName === Constants.GROUP_NAME_PRANZO);
                })[0];
                const pausa: IActionType = action.actions.filter((value: IActionType, index: number, array: IActionType[]) => {
                    return (value.groupName === Constants.GROUP_NAME_PAUSA);
                })[0];
                return {
                    ...state,
                    timbraturaAction: timbratura,
                    pausaAction: pausa,
                    pranzoAction: pranzo
                };
            }
        case Constants.SET_USER_TYPE:
            return { ...state, userType: action.userType }
        case Constants.SET_IS_MOBILE:
            return {...state, isMobile : action.isMobile }
        default:
            return state;
    }
}
export default SiaReducer;