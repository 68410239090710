export const SiaStrings = {
    LOADING: "Caricamento in corso...",
    LOADING_SSF: "Caricamento dati...",
    LOADING_SIA: "Caricamento dati...",
    LOADING_ASSISTANCES: "Caricamento assistenze...",
    WELCOME: "Benvenuto",
    REGISTER: "Registra",
    GENERIC_ERROR: 'Si è verificato un errore',
    NO_CONTRACTS: "Non ci sono contratti per questo utente.",
    CHOOSE_A_CONTRACT: "Selezionare un contratto tra quelli proposti.",
    BADGE_ACTION_DESCRIPTION_START: "Rilascia per registrare",
    BADGE_ACTION_DESCRIPTION_END: "Rilascia per registrare",
    BADGE_DRAG_INSTRUCTION: "Trascina il badge per timbrare",
    NO_ACTIONS: "Non sono presenti timbrature",
    TS_START: 'Inizio',
    TS_END: 'Fine',
    PERIOD_SELECTOR: "Seleziona il periodo",
    CHOOSE_WORKINGMODE: 'Scegli la modalità lavorativa',
    WORKINGMODE_SELECTED: 'Modalità lavorativa selezionata',
    NO_SUGGESTIONS: 'Nessuna azione suggerita',
    YOUR_TEAM: 'Il tuo Team',
    ASSISTANCES: 'Assistenze di ',
    ASSISTANCES_TO_APPROVE: 'Assistenze da approvare',
    ASSISTANCES_HISTORY: 'Storico assistenze',
    SELECT_USER: 'Selezione utente',
    MONTHLY: 'Mensile',
    MONTHLY_SUMMARY_OF: 'Riepilogo mensile di',
    CHECKING_ANOMALIES: 'Verifico anomalie...',
    LOADING_HOLIDAYS: 'Caricamento ferie...',
    HOLIDAYS: 'Ferie',
    HOLIDAYS_REMAINING: 'Ferie residue',
    HOLIDAYS_EARNED: 'Ferie maturate',
    HOLIDAYS_USED: 'Ferie godute',
    HOLIDAYS_USED_DETAIL: 'Dettaglio godute',
    FESTIVITY: 'Festività',
    ANOMALIES_OF_THE_DAY_TITLE: 'Anomalie giornata',
    ASSISTANCES_OF_THE_DAY_TITLE: 'Assistenze',
    ASSISTANCE_REMOVED: 'Assistenza rimossa',
    ASSISTANCE_APPROVED: 'Assistenza approvata',
    ASSISTANCE_REJECTED: 'Assistenza rifiutata',
    ASSISTANCE_SAVED : 'Assistenza inserita con successo',
    REGISTERED_ACTIONS: 'Azioni registrate',
    TIMESHEET_REMOVED: 'Timesheet eliminato',
    TIMESHEET_UPDATED: 'Timesheet aggiornato con successo',
    TIMESHEET_ADDED: 'Timesheet aggiunto con successo',
    TIMESHEET_NO_ACTION : "Selezionare un'attività",
    COTRACT_NOT_FOUND_MESSAGE : "Utente non presente su Success Factors",
    COTRACT_NOT_FOUND_DETAIL_MESSAGE : "Contattare il personale hr della propria sede per maggiori informazioni",
    H_EXPECTED: 'previste',
    REJECT: 'Rifiuta',
    APPROVE: 'Approva',
    APPROVED: 'Approvato',
    REMOVE: 'Rimuovi',
    OVERTIME: 'Straordinario',
    OVERTIMES: 'Straordinari',
    OVERTIMES_UPDATED : 'Straordinari modificati con successo',
    CALENDAR : 'Calendario',
    CALENDAR_REMOVED : 'Azione di calendario rimossa con successo',
    CALENDAR_ADDED : 'Azione di calendario aggiunta con successo',
    RAPPORTINO : 'Rapportino',
    DEVTOOLS : 'Dev Tools',
    STAFF : 'Staff',
    H_ACTUALWORK: 'di lavoro',
    ON: 'su',
    HOUR: 'ora',
    HOURS: 'ore',
    H: 'h',
    MIN: 'min',
    RECOVER: 'Recupero',
    TO_PAY: 'Da pagare',
    ELASTIC_CLAUSE: 'Clausola Elastica',
    MINUTES: 'Minuti',
}