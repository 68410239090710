import "../scss/Calendar.scss";

import React, { useEffect, useState, Fragment } from "react";
import { useSelector } from "react-redux";
import { Flex, Divider, Alert, Loader, InfoIcon } from "@fluentui/react-northstar";
import UserSelector from "./UserSelector";
import CustomPeriodSelector from "./CustomPeriodSelector";
import { IAppState } from "../interfaces/IAppState";
import { IMonthlyAllowedPeriod } from "../interfaces/IMonthlyAllowedPeriod";
import Warning from "./Warning";
import { IOrgChartUser } from "../interfaces/IOrgChartUser";
import * as cookieHelper from '../helpers/CookiesHelper';
import { UserType } from "../constants/AppEnum";
import { getCalendar, getSiaUserTimesheet, getSiaActionTypes, getOrgChart } from '../helpers/Fetch';
import { IUserTimesheetResponse } from "../interfaces/IUserTimesheetResponse";
import { ITimesheet } from "../interfaces/ITimesheet";
import { IActionType } from "../interfaces/IActionType";
import * as Constants from '../constants/AppConstants';
import { ITimesheetAction } from "../interfaces/ITimesheetAction";
import { ICalendar, ICalendarResponse } from "../interfaces/ICalendarResponse";
import { ICalendarAction } from "../interfaces/ICalendarAction";
import CalendarRow from '../components/CalendarRow';
import moment from "moment";
import { findUserByEmailRecursive } from "../helpers/SiaHelper";
import { IFestivity } from "../interfaces/IFestivity";
import useLoadCommonData from "../hooks/useLoadCommonData";
import { LoadCommonDataStaus } from "../constants/AppEnum";

const Calendar = (props: any) => {
    const appState: IAppState = useSelector((appState) => appState) as IAppState;
    const today: Date = new Date();
    const [selectedPeriod, setSelectedPeriod] = useState({ month: today.getMonth() + 1, year: today.getFullYear() } as IMonthlyAllowedPeriod);
    const [selectedUser, setSelectedUser] = useState({ email: '', idUserSF: '', fullName: '' } as { email: string, idUserSF: string, fullName: string });
    const [orgChart, setOrgChart] = useState<IOrgChartUser>({} as IOrgChartUser);
    const [tableRows, setTableRows] = useState<any[]>([]);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const commonDataStatus: LoadCommonDataStaus = useLoadCommonData();

    const getTableRows = (timesheets: ITimesheet[], calendarTimesheets: ICalendar[], actions: IActionType[], festivities: IFestivity[], theme: string) => {
        let idPranzoAction: number = 0;
        let idTimbraturaAction: number = 0;

        var pranzoActions = actions.filter((a) => { return a.groupName === Constants.GROUP_NAME_PRANZO });
        if (pranzoActions.length > 0) {
            idPranzoAction = pranzoActions[0].id;
        }

        var timbratureActions = actions.filter((a) => { return a.groupName === Constants.GROUP_NAME_TIMBRATURA });
        if (timbratureActions.length > 0) {
            idTimbraturaAction = timbratureActions[0].id;
        }

        let tableRowComponents: any[] = [];
        for (let index = 0; index < timesheets.length; index++) {
            //rowItems conterrà tutte le azioni da visualizzare per il giorno
            let rowItems: any[] = [];
            //rowMessage è valorizzato se:
            // - la gionata non prevede ore di lavoro
            // - è una festività
            // - è un'azione che copre tutta la giornata (es. Ferie, Malattia, Infortunio, ecc..)
            let rowMessage: string = '';
            const timesheet: ITimesheet = timesheets[index];
            //Recupero l'azione Timbratura del giorno (se c'è)
            const timbraturaActions = timesheet.timesheet.filter((t) => {
                return t.idActionType === idTimbraturaAction;
            });
            if (timesheet.expectedWork.hours() > 0) {
                //Recupero l'azione Pausa Pranzo del giorno
                const pranzoActions = timesheet.timesheet.filter((t) => {
                    return t.idActionType === idPranzoAction;
                });
                //Recupero le azioni di calendario del giorno
                const calendarActions = calendarTimesheets.filter((ct) => {
                    return ct.day.toString() === timesheet.day.toString();
                })[0].calendars;
                //Unisco tutte le azioni del giorno
                rowItems = timbraturaActions.concat(pranzoActions).concat(calendarActions);
                //Ordino le azioni per ora
                rowItems.sort((a: ITimesheetAction | ICalendarAction, b: ITimesheetAction | ICalendarAction) => {
                    //Imposto a 0 i secondi per non considerarli nell'ordinamento
                    let tmpAStartDate: Date = new Date(a.startDate as Date);
                    tmpAStartDate.setSeconds(0);
                    let tmpBStartDate: Date = new Date(b.startDate as Date);
                    tmpBStartDate.setSeconds(0);
                    let result = (tmpAStartDate.getTime() - tmpBStartDate.getTime());
                    //Se le date sono uguali ordino per id
                    if (result === 0) {
                        result = (a.id ?? 1) - (b.id ?? 0);
                    }
                    return result;
                    //return (a.startDate?.getTime() ?? new Date().getTime()) - (b.startDate?.getTime() ?? new Date().getTime());
                });

                //Se c'è un'attività che copre tutta la giornata lavorativa (diversa da timbratura) imposto solo quella come attività sulla riga ignorando le altre
                //es. Trasferta gioranta completa: per quel giorno esiste anche la pausa pranzo, ma non ha senso visualizzarla nel calendario, per quel giorno non si devono aggiungere altre attività 
                const allDayActions = timesheet.timesheet.filter((t) => {
                    var hDiff = moment(t.endDate).diff(moment(t.startDate), 'hours')
                    return hDiff >= timesheet.expectedWork.hours() && t.idActionType !== idTimbraturaAction;
                });
                if (allDayActions.length > 0) {
                    //Cerco la descrizione dell'attività che copre tutta la giornata
                    var actionType = actions.filter((action) => { return action.id === allDayActions[0].idActionType });
                    rowMessage = actionType.length > 0 ? actionType[0].title : '';
                }
            } else {
                //Giorno che non prevede ore lavorative (es. Sabato o domenica)
                rowMessage = "Non previsto"
            }
            //Verifico se il giorno è tra le festività
            if (festivities.length > 0) {
                if (festivities[0].dates.filter((f) => { return moment(f).format("DD/MM/YYYY") === moment(timesheet.day).format("DD/MM/YYYY"); }).length > 0) {
                    rowMessage = "Festività";
                }
            }

            tableRowComponents.push(<CalendarRow key={timesheet.day.toString()} message={rowMessage} userId={selectedUser.idUserSF} date={timesheet.day.toString()} dayActions={rowItems} timbraturaAction={timbraturaActions[0]} actions={actions} idPranzoAction={idPranzoAction} />)
        }
        setTableRows(tableRowComponents);
    }

    useEffect(() => {
        (async () => {
            //AL primo caricamento imposto come utente selezionato l'utente loggato
            const org = await cookieHelper.setApplicationCookies.setOrgChartCookie(appState.debug ? appState.debugUser : appState.teamsContext.userPrincipalName?.toLowerCase());
            setOrgChart(org);
            const user = { email: org.userName, idUserSF: org.idUserSF, fullName: org.fullName } as { email: string, idUserSF: string, fullName: string };
            setSelectedUser(user);
        })();
    }, []);

    useEffect(() => {
        if (commonDataStatus === LoadCommonDataStaus.Success) {
            (async () => {
                //Quando dalla drop down di selezione utente viene cancellato l'utente selezionato
                if (selectedUser === null) {
                    //Imposto come selected user l'utente loggato
                    const user = { email: orgChart.userName, idUserSF: orgChart.idUserSF, fullName: orgChart.fullName } as { email: string, idUserSF: string, fullName: string };
                    setSelectedUser(user);
                } else if (selectedUser.idUserSF !== undefined && selectedUser.idUserSF !== '') {
                    try {
                        setLoading(true);
                        //Recupero orgChart dell'utente selezionato 
                        const startDate: Date = new Date(selectedPeriod.year, selectedPeriod.month - 1, 1);
                        const endDate: Date = new Date(selectedPeriod.year, selectedPeriod.month, 0, 23, 59);
                        var userOrgChart: IOrgChartUser = await getOrgChart(selectedUser.email, startDate, endDate) as IOrgChartUser;
                        const selectedUserOrgChart: IOrgChartUser = findUserByEmailRecursive(selectedUser.email, userOrgChart);

                        let initTasks: Promise<any>[] = [];

                        initTasks.push(getSiaUserTimesheet(selectedUser.idUserSF, startDate, endDate));
                        initTasks.push(getCalendar(selectedUser.idUserSF, startDate, endDate));
                        initTasks.push(cookieHelper.setApplicationCookies.setFestivitiesCookie(selectedUserOrgChart.placesOfWork, selectedPeriod.year));

                        const initTasksResults = await Promise.all(initTasks);
                        const timesheetResponse = initTasksResults[0];
                        const calendarRows: ICalendarResponse = initTasksResults[1];
                        const festivities = initTasksResults[2];

                        //userActions conterrà tutte le azioni di tipo tibratura e CALENDARIO dell'utente
                        let userActions: any[] = [];
                        let actionTasks: Promise<any>[] = [];
                        const isAdminOrHR: boolean = appState.userType === UserType.HR || appState.userType === UserType.Admin;
                        debugger;
                        if (isAdminOrHR && selectedUser.idUserSF !== orgChart.idUserSF) {
                            console.log("recupero azioni utente drop down");
                            //Set Azioni dell'utente selezionato dalla dropDown nel caso di utente diverso da quello loggato                         
                            actionTasks.push(getSiaActionTypes(selectedUser.idUserSF));
                            actionTasks.push(getSiaActionTypes(selectedUser.idUserSF, true));
                        } else {
                            console.log("recupero azioni utente cookie");
                            //Set azioni utente loggato
                            actionTasks.push(cookieHelper.setApplicationCookies.setActionTypesCookie());
                            actionTasks.push(cookieHelper.setApplicationCookies.setActionTypesCookie(true));
                        }

                        const actionTasksResult = await Promise.all(actionTasks);
                        const actionsResponse = actionTasksResult[0];
                        const calendarActionResponse = actionTasksResult[1];

                        //Escludo le azioni che devono essere visibili solo sul mensile    
                        debugger;
                        let actions: IActionType[] = actionsResponse.actions ? [...actionsResponse.actions] : [...actionsResponse];
                        actions = actions.filter(action => action.showOnMonthlyPageOnly === false);

                        //Se le azioni sono recuperate dai cookie la risposta è già l'array di actions, se sono recuperate con 'getSiaActionTypes' devo leggere la proprietà actions
                        userActions = [...actions].concat(calendarActionResponse.actions ? calendarActionResponse.actions : calendarActionResponse);
                        getTableRows(timesheetResponse.days, calendarRows.days, userActions, festivities, "");
                    } catch (error) {
                        setError('Si è verificato un errore imprevisto nel caricamento del calednario');
                    } finally {
                        setLoading(false);
                    }
                }
            })();
        }
    }, [selectedUser, selectedPeriod, commonDataStatus]);

    return (
        commonDataStatus === LoadCommonDataStaus.Loading ?
            <Loader style={{ paddingTop: '20px', paddingBottom: '20px' }} />
            :
            <Fragment>
                {error !== '' &&
                    <Warning label={error}></Warning>
                }
                {error === '' && selectedUser !== null && selectedUser.idUserSF !== '' &&
                    <Flex column fill className="calendar-container">
                        <div className="monthly-titles noselect">Calendario di <b>{selectedUser.fullName}</b></div>
                        <Flex fill vAlign="center" wrap>
                            {(appState.userType === UserType.HR || appState.userType === UserType.Admin) &&
                                <div style={{ width: '272px' }}>
                                    <UserSelector onSelectedUserChange={setSelectedUser} allUsers={true} />
                                </div>
                            }
                            <CustomPeriodSelector userType={appState.userType} onSelectedPeriodChange={setSelectedPeriod} />
                        </Flex>
                        {loading ?
                            <Loader size="small" style={{ paddingTop: '20px' }} styles={{ textAlign: "center" }} />
                            :
                            <Flex column fill>
                                {/* Headers */}
                                <Flex fill>
                                    <div style={{ width: '6%' }} >Data</div>
                                    <div style={{ width: '94%' }} >Dettagli</div>
                                </Flex>
                                <Divider />
                                {tableRows.length > 0 ?
                                    <>
                                        {tableRows.map((row) => {
                                            return row;
                                        })}
                                    </>
                                    :
                                    <Alert info icon={<InfoIcon />} content="Non sono state trovate azioni di calendario" />
                                }
                            </Flex>
                        }
                    </Flex >
                }
            </Fragment>
    )
}

export default Calendar;