import * as Constants from '../../constants/AppConstants';
import { UserType } from '../../constants/AppEnum';
import { INotification } from '../../interfaces/INotification';

export const setTipDrag = (isOn: boolean) => {
    return { type: Constants.SET_TIP_DRAG, isOn };
}

export const setNotifications = (notifications: Array<INotification>) => {
    return { type: Constants.SET_NOTIFICATIONS, notifications };
}

export const setUserType = (userType: UserType) => {
    return { type: Constants.SET_USER_TYPE, userType };
}