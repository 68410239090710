

/* IMPORT CSS / SASS */
import "../scss/App.scss";

/* IMPORT REACT & THIRD PARTY COMPONENTS */
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Alert } from '@fluentui/react-northstar';

/* IMPORT CUSTOM COMPONENTS */
import * as appActions from "../stores/actions/AppActions";
import { IAppState } from "../interfaces/IAppState";
import { INotification } from "../interfaces/INotification";
import * as Constants from '../constants/AppConstants';

const Notifications = () => {

    const appState: IAppState = useSelector((appState) => appState) as IAppState;
	const dispatch = useDispatch();
   

    useEffect(()=>{

        if(appState.notifications.length>0)
        {
            const timeout = appState.notifications.find(i => (i.type === Constants.notificationTypes.DANGER || i.type === Constants.notificationTypes.WARNING)) ? 6000 : 3000;
            setTimeout(()=>{
                dispatch(appActions.setNotifications([]))
            }, timeout);
        }

    }, [appState.notifications, dispatch]);

    return appState.notifications.length>0 
    ? 
    <div className="app-notifications-container">
        {appState.notifications.map((n:INotification,i:number) => {

                if(n.type===Constants.notificationTypes.DANGER)
                {
                    return <Alert key={i} className="app-notification" danger content={n.message} />
                }
                if(n.type===Constants.notificationTypes.INFO)
                {
                    return <Alert key={i} className="app-notification" info content={n.message} />
                }
                if(n.type===Constants.notificationTypes.WARNING)
                {
                    return <Alert key={i} className="app-notification" warning content={n.message} />
                }
                if(n.type===Constants.notificationTypes.SUCCESS)
                {
                    return <Alert key={i} className="app-notification" success content={n.message} />
                }
                return null;
        })}
   </div>
   : null
}
export default Notifications;