/* IMPORT REACT & THIRD PARTY COMPONENTS */
import "../scss/Monthly.scss";

import React from "react";
import { Flex, Popup } from "@fluentui/react-northstar";
import moment from 'moment';

/* IMPORT CUSTOM COMPONENTS */
import { IHoliday } from "../interfaces/IHoliday";
import { IUsedHoliday } from "../interfaces/IUsedHoliday";
import { SiaStrings } from "../strings/strings";
import { WeatherSunny16Regular } from '@fluentui/react-icons';

const MonthlyUserHolidays = (props:any) => {

    const holidays: IHoliday = props.holidays as IHoliday;
    // const godute:number =  (holidays.used.length<=0) ? 0 : holidays.used.reduce((a, b) => a + b.amount, 0);

    return <Flex className="monthly-holidays noselect">
                <WeatherSunny16Regular style={{marginTop:'2px', marginRight:'5px'}} /> 
                {SiaStrings.HOLIDAYS_REMAINING}: 
                <Popup
                    align="end"
                    content={
                    <Flex column className="monthly-used-holiday-details">
                        <div>{SiaStrings.HOLIDAYS_EARNED}: {holidays.earned.asDays().toFixed(2)} <span className="monthly-holiday-gg">{holidays.earned.asDays() <= 1 ? "g" : "gg"}</span></div>
                        {/* <div>{SiaStrings.HOLIDAYS_EARNED}: {holidays.earned} <span className="monthly-holiday-gg">h</span></div> */}
                        <div>{SiaStrings.HOLIDAYS_USED}: {holidays.used.asDays().toFixed(2)} <span className="monthly-holiday-gg">{holidays.used.asDays() <= 1 ? "g" : "gg"}</span></div>
                        {/* <div>{SiaStrings.HOLIDAYS_USED}: {holidays.used} <span className="monthly-holiday-gg">h</span></div> */}                        
                    </Flex>
                    } 
                    pointing
                    position="below"
                    trigger={
                    <Flex>
                        <span className="monthly-num-holiday">{holidays.remaining.asDays().toFixed(2)}</span> 
                    </Flex>
                    }
                />
                <span className="monthly-holiday-gg">{ holidays.remaining.asDays() <= 1 ? "g" : "gg"}</span>
           </Flex>
}
export default MonthlyUserHolidays;