import { useEffect, useState } from "react";
import { Flex, Alert, Dialog, CloseIcon, Loader, EditIcon } from "@fluentui/react-northstar";
import TimeSelector from "./TimeSelector";
import { IActionTime } from "../interfaces/IActionTime";
import { ISaveResult } from "../interfaces/ISaveResult";
import { SiaStrings } from "../strings/strings";
import { ITimesheetAction } from "../interfaces/ITimesheetAction";
import { updateTimeSheet } from "../helpers/Fetch";
import moment from 'moment';

interface IMonthlyBreakProps {
    editable: boolean;
    action: ITimesheetAction;
    type: string;
    idBreakAction: number;
    onTimesheetUpdated: any;
    onDialogClose: any;
    breakType: string;
    showEditIcon: boolean;
}

const MonthlyBreakStartEndTime = (props: IMonthlyBreakProps) => {
    const [modifiedAction, setModifiedAction] = useState<ITimesheetAction>(props.action);
    const [saving, setSaving] = useState(false);
    const [saveMessage, setSaveMessage] = useState<ISaveResult>({ message: "", error: false });
    const [showDialog, setShowDialog] = useState(false);
    const [endDate, setEndDate] = useState(new Date());
    const [error, setError] = useState("");

    // const hh: number = (props.action.startDate !== null) ? parseInt(moment(props.action.startDate).format("HH"))
    //     : (props.action.endDate !== null) ? parseInt(moment(props.action.endDate).format("HH")) : 0;
    // const mm: number = (props.action.startDate !== null) ? parseInt(moment(props.action.startDate).format("mm"))
    //     : (props.action.endDate !== null) ? parseInt(moment(props.action.endDate).format("mm")) : 0;
    // const [actionTime, setActionTime] = useState<IActionTime>({
    //     hours: hh,
    //     minutes: mm
    // })
    const [actionTime, setActionTime] = useState<IActionTime>({
        hours: 0,
        minutes: 0
    })

    const [boundaryTime, setBoundaryTime] = useState<IActionTime>({
        hours: 0,
        minutes: 0
    })


    useEffect(() => {
        if ((props.action.startDate === null || props.action.startDate === undefined) && (props.action.endDate === null || props.action.endDate === undefined)) {
            setError("Inizio e fine mancanti");
            return;
        }
        setEndDate(calcEndDate());

    }, [])

    useEffect(() => {
        if (endDate !== undefined && endDate !== null) {
            const hh: number = (props.type === "start") ? parseInt(moment(props.action.startDate).format("HH"))
                : (props.action.endDate !== null) ? parseInt(moment(props.action.endDate).format("HH")) : 0;
            const mm: number = (props.type === "start") ? parseInt(moment(props.action.startDate).format("mm"))
                : (props.action.endDate !== null) ? parseInt(moment(props.action.endDate).format("mm")) : 0;
            setActionTime({
                hours: hh,
                minutes: mm
            });
        }
    }, [endDate])

    useEffect(() => {
        if (actionTime.hours !== 0 && actionTime.minutes !== 0) {
            setBoundaryTime(calcBoundaryTime());
        }
    }, [actionTime])

    const calcBoundaryTime = (): IActionTime => {
        let result: IActionTime = { hours: 0, minutes: 0 };
        if (props.type === "start") {
            //La data di inizio non può essere uguale o maggiore della data di fine
            result.hours = parseInt(moment(endDate).format("HH"));
            result.minutes = parseInt(moment(endDate).format("mm")) - 1;
        } else {
            //La data di fine non può essere minore o uguale alla data di inizio
            result.hours = parseInt(moment(props.action.startDate).format("HH"));
            result.minutes = parseInt(moment(props.action.startDate).format("mm")) + 1;
        }
        return result;
    }


    const updateAction = (newTime: IActionTime): void => {
        if (newTime.hours !== undefined && newTime.minutes !== undefined) {
            let tmpAction: ITimesheetAction = { ...modifiedAction };
            const newDate: Date = props.type === "start" ? new Date(props.action.startDate as Date) : endDate;
            newDate.setHours(newTime.hours);
            newDate.setMinutes(newTime.minutes);
            if (props.type === "start") {
                tmpAction.startDate = newDate;
            } else {
                tmpAction.endDate = newDate;
            }
            setModifiedAction(tmpAction);
            setActionTime(newTime);
        }
    }

    const updateUserTimeSheet = async (item: ITimesheetAction): Promise<void> => {
        setSaving(true);
        try {
            const response = await updateTimeSheet(item);
            if (response.status === 1) {
                setSaveMessage({ message: SiaStrings.TIMESHEET_UPDATED, error: false });
                props.onTimesheetUpdated(response.payload);
            } else {
                setSaveMessage({ message: SiaStrings.GENERIC_ERROR, error: true })
            }

        } catch (error) {
            setSaveMessage({ message: SiaStrings.GENERIC_ERROR, error: true })
        } finally {
            setSaving(false);
        }

    }

    const calcEndDate = (): Date => {
        let endDate: Date = new Date();
        if (props.action.endDate !== undefined && props.action.endDate !== null) {
            endDate = props.action.endDate
        } else {
            endDate = new Date(props.action.startDate as Date);
            endDate.setHours(0);
            endDate.setMinutes(0);
            endDate.setSeconds(0);
            endDate.setMilliseconds(0);
        }
        return endDate;
    }

    const createChildControls = () => {
        return <Flex gap="gap.small">
            {props.type === "start" ?
                <>
                    {(props.breakType === "pausa" && (props.editable && props.showEditIcon)) ?
                        <Flex gap="gap.small">
                            <span>{moment(props.action.startDate).format("HH:mm")}</span>
                            <EditIcon size="small" />
                        </Flex>
                        :
                        <span>{moment(props.action.startDate).format("HH:mm")}</span>
                    }
                    {/* <span>{moment(props.action.startDate).format("HH:mm")}</span> */}
                </>
                :
                <>
                    {(props.breakType === "pausa" && (props.editable && props.showEditIcon)) ?
                        <Flex gap="gap.small">
                            <span>{moment(endDate).format("HH:mm")}</span>
                            <EditIcon size="small" />
                        </Flex>
                        :
                        <span>{moment(endDate).format("HH:mm")}</span>
                    }
                </>
           
            }
        </Flex>
    }


    return <Flex gap="gap.medium">
        <Flex gap="gap.small">
            {props.editable ?
                <>
                    <Dialog
                        style={{ maxWidth: "400px" }}
                        cancelButton={{ content: "Chiudi", disabled: saving }}
                        confirmButton={{ content: "Salva", disabled: saving }}
                        closeOnOutsideClick={false}
                        open={showDialog}
                        headerAction={{
                            icon: <CloseIcon />, title: 'Close', onClick: () => {
                                setShowDialog(false);
                                props.onDialogClose(props.action.startDate);
                            }
                        }}
                        content={
                            <>
                                {saving ?
                                    <Loader size="small" label="Aggiornamento in corso..." style={{ paddingTop: '20px', paddingBottom: '20px' }} />
                                    :
                                    <Flex gap="gap.small" column>
                                        <TimeSelector time={actionTime} onTimeModified={updateAction} showLabel={true} minTime={props.type === "end" ? boundaryTime : undefined} maxTime={props.type === "start" ? boundaryTime : undefined} />
                                        {saveMessage.message !== "" &&
                                            <Alert content={saveMessage.message} success={!saveMessage.error} danger={saveMessage.error} />
                                        }
                                    </Flex>
                                }
                            </>
                        }
                        onConfirm={async () => {
                            if (saveMessage.message !== "") {
                                setSaveMessage({ message: "", error: false });
                            }
                            await updateUserTimeSheet(modifiedAction);
                        }}
                        onCancel={() => {
                            setShowDialog(false);
                            props.onDialogClose(props.action.startDate);
                        }}
                        onOpen={() => {
                            setSaveMessage({ message: "", error: false });
                            setShowDialog(true);
                        }}
                        header={`Modifica ${props.type === "start" ? "Inzio" : "Fine"} ${props.breakType}`}
                        trigger={
                            <Flex gap="gap.small" style={{ cursor: "pointer" }} >
                                {createChildControls()}
                            </Flex>
                        }
                    />
                </>
                :
                <>
                    {createChildControls()}
                </>
            }
        </Flex>
    </Flex>

}

export default MonthlyBreakStartEndTime;