/* IMPORT CSS / SASS */
import './scss/Index.scss';

/* IMPORT REACT & THIRD PARTY COMPONENTS */
import ReactDOM from 'react-dom';
import { createStore, applyMiddleware } from 'redux';
import AppReducer from './stores/reducers/AppReducer';
import { Provider as ReduxProvider } from 'react-redux';
import thunk from 'redux-thunk';

/* IMPORT CUSTOM COMPONENTS */
import App from './components/App';

/* CREATE APP STORE */
const appStore = createStore(AppReducer, applyMiddleware(thunk));

/* RENDER THE APP WITH THE STORE */
ReactDOM.render(<ReduxProvider store={appStore}><App /></ReduxProvider>, document.getElementById('root'));