/* IMPORT CSS / SASS */
import "../scss/Staff.scss";

/* IMPORT REACT & THIRD PARTY COMPONENTS */
import React, { useEffect, useState } from "react";
import { Loader, Flex, Text } from "@fluentui/react-northstar";

/* IMPORT CUSTOM COMPONENTS */
import { SiaStrings } from "../strings/strings";
import { getUserPicture, getFilterValues, getUserStatus } from '../helpers/Fetch';
import * as cookieHelper from '../helpers/CookiesHelper';
import { IUser } from "../interfaces/IUser";
import { IDropdownItem } from "../interfaces/IDropdownItem";
import { IStaffFilter } from "../interfaces/IStaffFilter";
import StaffFilters, { IStaffFilterProps } from "./StaffFilters";
import * as promiseHelper from 'bluebird';
import { FilterType } from "../constants/AppEnum";
import { IUsersStatusResponse } from '../interfaces/IUsersStatusResponse';
import { IUserStatusItem } from '../interfaces/IUserStatusItem';
import StaffList from './StaffList';
import { useSelector } from "react-redux";
import { IAppState } from "../interfaces/IAppState";
import { ISize } from '../interfaces/ISize';
import useWindowSize from '../hooks/useWindowSize';
import Warning from "./Warning";


const Staff = (props: any) => {
    const appState: IAppState = useSelector((appState) => appState) as IAppState;
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [allUsers, setAllUsers] = useState<IUserStatusItem[]>([]);
    const [listItems, setListItems] = useState<any[]>([]);
    const [filterProps, setFitlerProps] = useState<IStaffFilterProps>({
        userItems: [],
        areaItems: [],
        roleItems: [],
        locationItems: [],
        managerItems: [],
        onFilterChanged: undefined,
        onDownloadClick: undefined
    } as IStaffFilterProps);

    const [staffFilter, setStaffFilter] = useState<IStaffFilter | null>(null);

    const [listProps, setListProps] = useState<any>({
        hasNextPage: true,
        isNextPageLoading: false,
        items: [],
        theme: 'default',
        countryItems: [],
        workingModes: []
    });

    const [nextToken, setNextToken] = useState('');
    const usersNumber: number = 10;
    const size: ISize = useWindowSize();


    const getUsersDropdownItems = (users: IUser[], keyPropName: string, valuePropName: string): IDropdownItem[] => {
        let result: IDropdownItem[] = [];
        try {
            new Map(users.map(user => [user[keyPropName as (keyof IUser)], user[valuePropName as (keyof IUser)]])).forEach((ivalue, ikey) => {
                if (ivalue && ivalue !== '' && ikey && ikey !== '') {
                    result.push({ key: ikey, header: ivalue } as IDropdownItem);
                }
            });
        } catch (error: any) {
            console.error(`getDropdownItems error : ${error.message}`);
            result.push({ key: 'error', header: 'Errore in caricamento dati' } as IDropdownItem);
        }
        return result;
    }

    const getDropdownItems = async (filter: FilterType): Promise<IDropdownItem[]> => {
        let result: IDropdownItem[] = [];
        try {
            const response = await getFilterValues(filter);
            switch (filter) {
                case FilterType.Departments: {
                    const departments = response.payload;
                    departments.forEach((element: any) => {
                        result.push({ key: element.departmentExternalCode, header: element.departmentName } as IDropdownItem);
                    });
                    break;
                }
                case FilterType.Roles: {
                    const roles = response.payload;
                    roles.forEach((element: any) => {
                        result.push({ key: element.roleExternalCode, header: element.roleName } as IDropdownItem);
                    });
                    break;
                }
                case FilterType.Managers: {
                    const managers = response.payload;
                    managers.forEach((element: any) => {
                        result.push({ key: element.idUser, header: `${element.firstName} ${element.lastName}` } as IDropdownItem);
                    });
                    break;
                }
                case FilterType.Countries: {
                    const countries = response.payload;
                    countries.forEach((element: any) => {
                        result.push({ key: element.countryCode, header: element.countryName } as IDropdownItem);
                    });
                    break;
                }
                default:
                    break;
            }
        } catch (error: any) {
            console.error(`getDropdownItems error : ${error.message}`);
            result.push({ key: 'error', header: 'Errore in caricamento dati' } as IDropdownItem);
        }
        return result.sort((a: any, b: any) => {
            if (a.header !== null && b.header !== null) {
                return a.header.localeCompare(b.header);
            } else {
                return 1;
            }

        });
    }

    //Uso una function e non un arrow function perchè altrimenti lo stato staffFilter non viene valorizzato
    const filterChanged = function (filterName: string, filterValue: any) {
        let filter: any;
        if (staffFilter !== null) {
            filter = { ...staffFilter }
        } else {
            filter = {
                userId: '',
                country: '',
                companyCode: '',
                areaCode: '',
                roleCode: '',
                managerId: '',
                state: false
            } as IStaffFilter;
        }
        filter[filterName] = filterValue;
        setNextToken('');
        setStaffFilter(filter);
    }

    const downloadResults = async (): Promise<void> => {
        try {
            debugger;
            let filter: any;
            if (staffFilter !== null) {
                filter = { ...staffFilter }
            } else {
                filter = {
                    userId: '',
                    country: '',
                    companyCode: '',
                    areaCode: '',
                    roleCode: '',
                    managerId: ''
                } as IStaffFilter;
            }
            //imposto 1000 per recuperare tutti gli utenti
            const userResponse = await getUserStatus(filter.userId, filter.roleCode, filter.areaCode, filter.country, filter.managerId, false, 1000, '');
            const usersStatus: IUsersStatusResponse = userResponse.payload;
            let csv: string = "ID Success Factors;Cognome;Nome;Email;Area;Ruolo;Nazione;Stato;Modalita di lavoro;\r\n";

            usersStatus.users.forEach(user => {
                csv += `${user.idUserSF};`;
                csv += `${user.lastName};`;
                csv += `${user.firstName};`;
                csv += `${user.userName};`;
                csv += `${user.departmentName};`;
                csv += `${user.roleName};`;
                csv += `${user.country};`;
                let state: string = '';
                if (user.worked) {
                    state = "Turno completato";
                }
                if (user.working) {
                    state = "Turno in corso";
                }
                if (user.lunching) {
                    state = "Pausa pranzo in corso";
                }
                if (!user.worked && !user.working) {
                    state = "Turno non avviato";
                }
                if (user.breaking) {
                    state = "Pausa in corso";
                }
                csv += `${state};`;
                const workingMode: string = user.workingModeId !== null ? listProps.workingModes.filter((c: any) => { return c.id === user.workingModeId })[0].name : '';
                csv += `${workingMode};`;
                csv += "\r\n";

            });

            var aLink = document.createElement('a');
            aLink.download = "Staff.csv";
            aLink.href = 'data:text/csv;charset=UTF-8,' + encodeURIComponent(csv);
            var event = new MouseEvent('click');
            aLink.dispatchEvent(event);
        } catch (error) {

        }

    }


    const loadData = async (newQuery: boolean, date: Date): Promise<void> => {
        const tmpListProps = { ...listProps };
        let userResponse;
        const token: string = nextToken !== undefined && nextToken !== '' ? nextToken : '';
        if (staffFilter === null) {
            userResponse = await getUserStatus('', '', '', '', '', false, usersNumber, token);
        } else {
            userResponse = await getUserStatus(staffFilter.userId, staffFilter.roleCode, staffFilter.areaCode, staffFilter.country, staffFilter.managerId, staffFilter.state, usersNumber, token);
        }

        if (userResponse.status !== 1) {
            console.log(`Errore in loadData : ${userResponse.message}`);
            setError('Errore in caricamento utenti');
            setLoading(false);
            return;
        }
        //const userResponse = await getUserStatus( '', '', '', '', '', '', usersNumber, nextToken !== undefined && nextToken !== '' ? nextToken : '');
        //const userResponse = await getUserStatus(staffFilter.userId, staffFilter.roleCode, staffFilter.areaCode, staffFilter.country, staffFilter.managerId, usersNumber, nextToken !== undefined && nextToken !== '' ? nextToken : '');
        const usersStatus: IUsersStatusResponse = userResponse.payload;

        setNextToken(usersStatus.nextToken);
        let lastDate: Date = cookieHelper.getApplicationCookies.getStaffQueryDataCookie();
        if (date < lastDate && !newQuery) {
            console.log(`annullata ${JSON.stringify(staffFilter)}`);
            return;
        }
        cookieHelper.setApplicationCookies.setStaffQueryDataCookie(date);
        const tmpLoadedRows = newQuery ? usersStatus.users : [...tmpListProps.items].concat(usersStatus.users);
        const pictureResponses = await promiseHelper.Promise.map(tmpLoadedRows, async (user: IUserStatusItem) => {
            if (user && user.userName !== null && user.userName !== undefined && user.userName !== '') {
                const response = await getUserPicture(user.userName);
                if (response.status === 1) {
                    return response.payload;
                } else {
                    return [{
                        "idUserSF": user.idUserSF,
                        "pic": ""
                    }];
                }
            } else {
                return [{
                    "idUserSF": user.idUserSF,
                    "pic": ""
                }];
            }
        }, { concurrency: usersNumber });

        tmpLoadedRows.forEach(element => {
            const pictureIndex = pictureResponses.findIndex(pr => pr[0] !== undefined && (pr[0].idUserSF === (element.idUserSF ?? '')));
            if (pictureIndex !== -1) {
                element.picture = pictureResponses[pictureIndex][0].pic;
            } else {
                element.picture = '';
            }
        });

        tmpListProps.hasNextPage = usersStatus.nextToken !== '';
        tmpListProps.isNextPageLoading = false;
        tmpListProps.items = tmpLoadedRows;
        tmpListProps.countryItems = filterProps.locationItems;
        console.log('setListProps');
        setListProps(tmpListProps);
    }

    const loadNextPage = async (startIndex: number, stopIndex: number): Promise<void> => {
        console.log('loadNextPAge')
        await loadData(false, new Date());
    };

    useEffect(() => {
        (async () => {
            setLoading(true);
            // const userResponse = await getUserStatus('', '', '', '', '', '', usersNumber);
            // const usersStatus: IUsersStatusResponse = userResponse.payload;
            const cUsers: IUser[] = await cookieHelper.setApplicationCookies.setCountryUsersCookie('');
            cUsers.sort((a: any, b: any) => a.fullName.localeCompare(b.fullName));

            let tmpUsers: IDropdownItem[] = [...getUsersDropdownItems(cUsers, 'idUserSF', 'fullName')];
            let tmpAreas: IDropdownItem[] = await getDropdownItems(FilterType.Departments);
            let tmpRoles: IDropdownItem[] = await getDropdownItems(FilterType.Roles);
            let tmpManagers: IDropdownItem[] = await getDropdownItems(FilterType.Managers);
            let tmpLocations: IDropdownItem[] = await getDropdownItems(FilterType.Countries);

            const filter: IStaffFilterProps = {
                userItems: tmpUsers,
                roleItems: tmpRoles,
                areaItems: tmpAreas,
                managerItems: tmpManagers,
                locationItems: tmpLocations,
                onFilterChanged: filterChanged,
                onDownloadClick: downloadResults
            };
            setFitlerProps(filter);
            // setAllUsers(usersStatus.users);
            // setNextToken(usersStatus.nextToken);
            const wModes = await cookieHelper.setApplicationCookies.setWorkingModesCookie();
            setListProps(
                {
                    hasNextPage: true,
                    isNextPageLoading: false,
                    items: [],
                    theme: 'default',
                    countryItems: [],
                    workingModes: wModes
                })
            setLoading(false);
        })();
    }, []);

    useEffect(() => {
        //Filtro gli utenti 
        (async () => {
            if (staffFilter !== null) {
                await loadData(true, new Date());
            }
        })();
    }, [staffFilter])

    useEffect(() => {
        const theme = appState.teamsContext.theme !== undefined ? appState.teamsContext.theme : '';
        const tmpListProps = { ...listProps };
        tmpListProps.theme = theme;
        setListProps(tmpListProps);
    }, [appState.teamsContext])

    return (
        <Flex className="staff-main-container" padding="padding.medium" column fill styles={{ height: size.height !== undefined ? `${size.height}px` : '100%' }} >
            <StaffFilters {...filterProps} onFilterChanged={filterChanged} onDownloadClick={downloadResults} />
            {error ?
                <Warning label="Si è verificato un errore imprevisto" />
                :
                <>
                    {loading ?
                        <Loader label={SiaStrings.LOADING_SSF} style={{ paddingTop: '20px', paddingBottom: '20px' }} />
                        :
                        <>
                            {/* {listProps.items.length > 0 ? */}
                                <Flex column id="staffUserContainer" hAlign="center" fill className="staff-users-container">

                                    <StaffList
                                        tableProps={listProps}
                                        loadNextPage={loadNextPage}
                                    />
                                </Flex>
                                {/* :
                                <Flex style={{marginTop:"20px"}} fill hAlign="center"><Text size="larger" content="Nessun utente trovato" /></Flex>
                            } */}
                        </>
                    }

                </>
            }
        </Flex >
    )
}
export default Staff;