import { Flex, Text, Datepicker, Divider, Loader, Button, Dialog, CloseIcon, DayOfWeek, Alert, Label } from '@fluentui/react-northstar';
import { buildSummaryBoxResponse } from "../helpers/SiaHelper";
import { useState, useEffect } from 'react';
import { getSummaryBox, getHolidays } from '../helpers/Fetch';
import moment from 'moment';
import { ChartPerson20Regular } from '@fluentui/react-icons';
import { ISummaryBoxResponse } from '../interfaces/ISummaryBoxResponse';
import { IMonthlyAllowedPeriod } from "../interfaces/IMonthlyAllowedPeriod";

export interface IMonthlyUserReportProps {
    userId?: string;
    userCountry: string;
    userBirthDate: string;
    period?: IMonthlyAllowedPeriod;
}

const MonthlyUserReport = (props: IMonthlyUserReportProps) => {
    const [open, setOpen] = useState(false);
    const [report, setReport] = useState<ISummaryBoxResponse>({
        summaryBox: {
            earnedHolidays: moment.duration(0, 'day'),
            leaveHours: moment.duration(0, 'hours'),
            usedHolidays: moment.duration(0, 'day'),
            remainingHolidays: moment.duration(0, 'day'),
            lackHours: moment.duration(0, 'hours'),
            workingMonthlyHours: "",
            overtimeMonthlyToPayHours: "",
            timeSlotsAdditional: moment.duration(0, 'hours'),
            timeSlotsOvertime: moment.duration(0, 'hours'),
            lastHolidaysCalcDate: "",
            ticketRestaurantCount: 0
        }
    });
    const [error, setError] = useState(false);
    const [simulatedHolidays, setSimulatedHolidays] = useState(0);
    const [simulationDate, setSimulationDate] = useState(moment(new Date()).format("DD/MM/YYYY"));
    const [holidayDate, setHolidayDate] = useState("");
    const [simulatedHolidaysInfo, setSimulatedHolidaysInfo] = useState<{ earned: number, used: number, remaining: number }>({
        earned: 0,
        used: 0,
        remaining: 0
    });


    const COEFFICIENTE_X: number = 2.08333333;
    const COEFFICIENTE_Y: number = 1.66666667;


    const loadData = async () => {
        let startDate: Date | undefined = undefined;
        let endDate: Date | undefined = undefined;
        if (props.period !== undefined && props.period !== null) {
            startDate = new Date(props.period.year, props.period.month - 1, 1);
            endDate = new Date(props.period.year, props.period.month, 0, 23, 59);

            const response = await getSummaryBox(props.userId, startDate, endDate);
            if (response.status === 1) {
                const summaryBox: ISummaryBoxResponse = response.payload;
                //Se siamo nel mese corrente o nel futuro utilizzo come HolidayDate quella che ricevo dalle API               
                const currentDate = new Date(new Date().getFullYear(), new Date().getMonth(), 1);

                if ((props.period.month === currentDate.getMonth() + 1 && props.period.year === currentDate.getFullYear()) || (startDate > currentDate)) {
                    //setHolidayDate(moment(new Date()).subtract(1, 'day').format("DD/MM/YYYY"));
                    setHolidayDate(response.payload.summaryBox.lastHolidaysCalcDate);
                } else {
                    setHolidayDate(moment(endDate).format("DD/MM/YYYY"));
                }

                setReport(response.payload);

            } else {
                setError(true);
            }
        }
    }

    const getUserAge = (): number => {
        return moment(new Date()).diff(moment(props.userBirthDate), 'years');
    }

    const calcHolidays = async (date: Date): Promise<void> => {
        let coefficiente: number = 0;
        if (props.userCountry.toLowerCase() === 'alb') {
            coefficiente = COEFFICIENTE_Y;
        } else {
            const userAge: number = getUserAge();
            coefficiente = (userAge < 20 || userAge > 50) ? COEFFICIENTE_X : COEFFICIENTE_Y;
        }
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        date.setMilliseconds(0);

        const today: Date = new Date();
        today.setHours(0);
        today.setMinutes(0);
        today.setSeconds(0);
        today.setMilliseconds(0);
        const earnedHolidays = report !== null ? report.summaryBox.earnedHolidays.asDays() : 0;
        let daysPeriod: number = 0;
        let calculatedHolidays: number = 0;
        if (date.getMonth() === today.getMonth()) {
            daysPeriod = moment(date).add(1, 'day').diff(moment(today), 'days');
            calculatedHolidays = daysPeriod * (coefficiente / moment(today).daysInMonth());
        } else {
            // Ottenere il mese e l'anno di ogni data
            let meseInizio = today.getMonth();
            let annoInizio = today.getFullYear();
            let meseFine = date.getMonth();
            let annoFine = date.getFullYear();
            // Calcolare la differenza di mesi tra le due date
            const iterationCount = ((annoFine - annoInizio) * 12 + (meseFine - meseInizio)) + 1;
            let firstDayOfMonth = moment(new Date(today.getFullYear(), today.getMonth(), 1, 0, 0, 0, 0));
            let lastDayOfMonth = moment(new Date(today.getFullYear(), today.getMonth(), moment(today).daysInMonth(), 0, 0, 0, 0));
            for (let index = 0; index < iterationCount; index++) {
                const leftOperator = (lastDayOfMonth < moment(date)) ? moment(firstDayOfMonth).add(1, 'month') : moment(date).add(1, 'day');
                const rightOperator = lastDayOfMonth.get('month') === today.getMonth() ? moment(today) : firstDayOfMonth;
                daysPeriod = leftOperator.diff(rightOperator, 'days', true);
                console.log(firstDayOfMonth.daysInMonth());
                calculatedHolidays += daysPeriod * (coefficiente / firstDayOfMonth.daysInMonth());
                firstDayOfMonth.add(1, 'month');
                lastDayOfMonth.add(1, 'month');
            }
        }

        const simulatedHolidays = await getHolidays(props.userId as string, new Date(), date, true);
        const holidaysInfo = {
            earned: earnedHolidays + calculatedHolidays,
            used: simulatedHolidays.used.asDays(),
            remaining: (earnedHolidays + calculatedHolidays) - simulatedHolidays.used.asDays()
        };
        setSimulatedHolidaysInfo(holidaysInfo);
        //setSimulatedHolidays(earnedHolidays + calculatedHolidays);
    }

    const formatValue = (value: moment.Duration): string => {
        let result = "";
        if (Math.abs(value.days()) > 0) {
            let hours = (Math.abs(value.days()) * 24) + value.hours();
            result = `${value.days() < 0 ? '- ' : ''}${hours}:${Math.abs(value.minutes()).toString().padEnd(2, '0')} ore`;
        } else {
            result = `${value.hours() < 0 ? '- ' : ''}${Math.abs(value.hours())}:${Math.abs(value.minutes()).toString().padEnd(2, '0')} ore`;

        }
        return result;
    }

    return <Flex>
        <Dialog
            open={open}
            closeOnOutsideClick={false}
            style={{ width: "500px" }}
            header={`Riepilogo mensile`}
            headerAction={{
                icon: <CloseIcon />, title: 'Close', onClick: () => {
                    setOpen(false);
                    setSimulatedHolidaysInfo({
                        earned: 0,
                        used: 0,
                        remaining: 0
                    });
                    //setSimulatedHolidays(0);
                }
            }}
            cancelButton="Chiudi"
            content={
                <Flex fill padding="padding.medium" hAlign="center">
                    {error === true ?
                        <Alert warning content="Si è verificato un errore imprevisto" />
                        :
                        <>
                            {report === null ?
                                <Loader label="Caricamento in corso..." />
                                :
                                <Flex column fill gap="gap.medium">
                                    {/* Le info relative alle ferie sono visibili solo agli utenti svizzeri o albanesi */}
                                    {(props.userCountry.toLowerCase() === 'che' || props.userCountry.toLowerCase() === 'alb') &&
                                        <>
                                            <Flex space="between">
                                                <Text content={`Ferie maturate al ${holidayDate}`} />
                                                <Text content={`${report.summaryBox.earnedHolidays.asDays().toFixed(2)} ${report.summaryBox.earnedHolidays.asDays() > 1 ? 'gg' : 'g'}`} />
                                            </Flex>
                                            <Flex space="between">
                                                <Text content={`Ferie godute al ${holidayDate}`} />
                                                <Text color={report.summaryBox.usedHolidays.asDays() < 0 ? 'red' : ''} content={`${report.summaryBox.usedHolidays.asDays().toFixed(2)} ${report.summaryBox.usedHolidays.asDays() > 1 ? 'gg' : 'g'}`} />
                                            </Flex>
                                            <Flex space="between">
                                                <Text content={`Ferie residue al ${holidayDate}`} />
                                                <Text color={report.summaryBox.remainingHolidays.asDays() < 0 ? 'red' : ''} content={`${report.summaryBox.remainingHolidays.asDays().toFixed(2)} ${(report.summaryBox.remainingHolidays.asDays() > 1 || report.summaryBox.remainingHolidays.asDays() < -1) ? 'gg' : 'g'}`} />
                                            </Flex>
                                        </>
                                    }
                                    <Flex space="between">
                                        <Text content="Permessi" />
                                        <Text color={report.summaryBox.leaveHours.asHours() < 0 ? 'red' : ''} content={formatValue(report.summaryBox.leaveHours)} />
                                    </Flex>
                                    <Flex space="between">
                                        <Text content="Difetto" />
                                        {/* <Text color={report.summaryBox.lackHours.asHours() < 0 ? 'red' : ''} content={`${report.summaryBox.lackHours.asHours().toFixed(2)} ore`} /> */}
                                        <Text color={report.summaryBox.lackHours.asHours() < 0 ? 'red' : ''} content={formatValue(report.summaryBox.lackHours)} />
                                    </Flex>
                                    <Flex space="between">
                                        <Text content="Totale" />
                                        <Text content={`${report.summaryBox.workingMonthlyHours}`} />
                                    </Flex>
                                    <Flex space="between">
                                        <Text content="Totale straordinari retribuiti nel mese" />
                                        <Text content={`${report.summaryBox.overtimeMonthlyToPayHours}`} />
                                    </Flex>
                                    {props.userCountry.toLowerCase() === 'ita' &&
                                        <Flex space="between">
                                            <Text content="Totale buoni pasto" />
                                            <Text content={`${report.summaryBox.ticketRestaurantCount}`} />
                                        </Flex>
                                    }
                                    {props.userCountry.toLowerCase() === 'che' &&
                                        <>
                                            <Flex space="between">
                                                <Text content="Ore supplementari" />
                                                <Text content={`${report.summaryBox.timeSlotsAdditional.hours().toString().padStart(2, "0")}:${report.summaryBox.timeSlotsAdditional.minutes().toString().padStart(2, "0")}`} />
                                            </Flex>
                                            <Flex space="between">
                                                <Text content="Ore straordinari" />
                                                <Text content={`${report.summaryBox.timeSlotsOvertime.hours().toString().padStart(2, "0")}:${report.summaryBox.timeSlotsOvertime.minutes().toString().padStart(2, "0")}`} />
                                            </Flex>
                                        </>
                                    }
                                    <Divider />
                                    {/* Simulazione ferie visibile solo per Svizzera e Albania*/}
                                    {props.userCountry.toLowerCase() !== 'ita' &&
                                        <Flex column fill gap="gap.small">
                                            <Text content="Simulazione ferie" weight='bold' />
                                            <Flex column gap="gap.small">
                                                <Text content={"Seleziona una data per calcolare i giorni di ferie"} />
                                                <Datepicker
                                                    onDateChange={(event, control) => {
                                                        if (control) {
                                                            let eDate: Date = new Date(control.value);
                                                            eDate.setHours(23);
                                                            eDate.setMinutes(59);
                                                            setSimulationDate(moment(eDate).format("DD/MM/YYYY"));
                                                            calcHolidays(eDate);
                                                        }
                                                    }}
                                                    formatMonthDayYear={(date: Date, strings): string => {
                                                        return moment(date).format("DD/MM/YYYY");
                                                    }}
                                                    minDate={new Date()}
                                                    firstDayOfWeek={DayOfWeek.Monday}
                                                    days={['Domenica', 'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato']}
                                                    shortDays={['D', 'L', 'M', 'M', 'G', 'V', 'S']}
                                                    months={['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre']}
                                                    nextMonthAriaLabel="Mese successivo"
                                                    prevMonthAriaLabel="Mese precedente"
                                                />
                                            </Flex>
                                            {/* {simulatedHolidays !== 0 &&
                                                <Flex fill space="between">
                                                    <Text content={`Giorni di ferie maturati al ${simulationDate}`} />
                                                    <Text weight='bold' content={simulatedHolidays.toFixed(2)} />
                                                </Flex>
                                            } */}
                                            {simulatedHolidaysInfo.earned !== 0 &&
                                                <Flex gap="gap.small" column>
                                                    <Flex fill space="between">
                                                        <Text content={`Giorni di ferie maturati al ${simulationDate}`} />
                                                        <Text weight='bold' content={simulatedHolidaysInfo.earned.toFixed(2)} />
                                                    </Flex>
                                                    <Flex fill space="between">
                                                        <Text content={`Giorni di ferie goduti al ${simulationDate}`} />
                                                        <Text weight='bold' content={simulatedHolidaysInfo.used.toFixed(2)} />
                                                    </Flex>
                                                    <Flex fill space="between">
                                                        <Text content={`Giorni di ferie residui al ${simulationDate}`} />
                                                        <Text weight='bold' content={simulatedHolidaysInfo.remaining.toFixed(2)} />
                                                    </Flex>
                                                </Flex>
                                            }
                                            <Divider />
                                        </Flex>
                                    }
                                </Flex>
                            }
                        </>
                    }
                </Flex>
            }
            onCancel={() => {
                setOpen(false);
                //setSimulatedHolidays(0);
                setSimulatedHolidaysInfo({
                    earned: 0,
                    used: 0,
                    remaining: 0
                });
            }}
            trigger={<Button icon={<ChartPerson20Regular />} title="Report utente" disabled={!props.period} iconOnly onClick={async () => {
                setOpen(true);
                await loadData();
            }} />}
        />

    </Flex>
}

export default MonthlyUserReport;