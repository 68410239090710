import { Flex, Input } from '@fluentui/react-northstar';
import { useState, useEffect, useCallback } from 'react';


export interface OvertimeSelectorProps {
    id: number;
    minutesOvertime: number;
    remainingMinutes: number;
    step: number;
    value: number;
    onValueChange: any;
    icon: any;
    disabled?: boolean;
}

const OvertimeSelector = (props: OvertimeSelectorProps) => {
    const [value, setValue] = useState(props.value)
    const [warning, setWarning] = useState<boolean | undefined>(undefined);

    useEffect(() => {
        setValue(props.value);
    });

    return <Flex gap="gap.medium" vAlign="center">
        <Input type="number"
            icon={props.icon}
            iconPosition="start"
            step={props.step}
            placeholder="00"
            error={warning}
            defaultValue={props.value.toString()}
            disabled={props.minutesOvertime < 30 || props.disabled}
            min="0"
            max={props.minutesOvertime}
            value={value}
            onChange={(ev, data) => {
                let newValue: number = data?.value as string !== '' ? parseInt(data?.value as string) : 0;
                //Sto aumentando i minuti
                if (newValue > props.value) {
                    const difference: number = newValue - props.value;
                    if (newValue <= props.minutesOvertime && (difference <= props.remainingMinutes)) {
                        if (warning) {
                            setWarning(undefined);
                        }
                        props.onValueChange(props.id, newValue);
                    } else {
                        setWarning(true);
                    }
                } else {
                    if (warning) {
                        setWarning(undefined);
                    }
                    props.onValueChange(props.id, newValue);
                }


                // if (data?.value as string === '') {
                //     setValue(0);
                // } else if (parseInt(data?.value as string) <= props.minutesOvertime && (data?.value as string).length <= 2 ) {
                //     console.log(`imposto valore ${parseInt(data?.value as string)}`);
                //     setValue(parseInt(data?.value as string));
                // }
            }} />
    </Flex>

}

export default OvertimeSelector;