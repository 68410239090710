/* IMPORT CSS / SASS */
import "../scss/Assistances.scss";

/* IMPORT REACT & THIRD PARTY COMPONENTS */
import React, { useEffect, useState,  useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Table, NotesIcon, Button, Flex, Loader, Dialog, AcceptIcon, CloseIcon, Alert, InfoIcon, TrashCanIcon, ShiftActivityIcon, Text, Divider, Dropdown } from '@fluentui/react-northstar'
import moment from 'moment';
import { IAppState } from "../interfaces/IAppState";
import { IAssistance } from "../interfaces/IAssistance";
import { getAssistances, getAssistancesToApprove, revocaAssistance } from '../helpers/Fetch';
import { IUserAssistanceResponse } from "../interfaces/IUserAssistanceResponse";
import * as cookieHelper from '../helpers/CookiesHelper';
import { IOrgChartUser } from "../interfaces/IOrgChartUser";
import { IMonthlyAllowedPeriod } from "../interfaces/IMonthlyAllowedPeriod";
import { SiaStrings } from "../strings/strings";
import { gridCellWithFocusableElementBehavior, gridNestedBehavior } from '@fluentui/accessibility'
import CustomPeriodSelector from "./CustomPeriodSelector";
import { IActionType } from "../interfaces/IActionType";
import { IAssistanceStatus } from "../interfaces/IAssistanceStatus";
import { formatRowDate } from '../helpers/SiaHelper';
import Warning from "./Warning";
import { IDateRange } from "../interfaces/IDateRange";
import { IFetchResponse } from "../interfaces/IFetchResponse";
import { INotification } from "../interfaces/INotification";
import { updateAssistance, deleteAssistance } from '../helpers/Fetch';
import * as appActions from '../stores/actions/AppActions';
import { IUser } from '../interfaces/IUser';
import { AssistanceAction, UserType } from "../constants/AppEnum";
import UserSelector from "./UserSelector";
import { ISelectedUser } from "../interfaces/ISelectedUser";
// import { ISize } from '../interfaces/ISize';
// import  useWindowSize  from '../helpers/useWindowSize';

const Assistances = (props: any) => {

    const appState: IAppState = useSelector((appState) => appState) as IAppState;
    const [assistancesLoaded, setAssistancesLoaded] = useState(false);
    const [assistancesToApproveLoaded, setAssistancesToApproveLoaded] = useState(false);
    //const [selectedUser, setSelectedUser] = useState({ email: '', idUserSF: '', fullName: '' } as { email: string, idUserSF: string, fullName: string });
    const [selectedUser, setSelectedUser] = useState<ISelectedUser>({ email: '', idUserSF: '', fullName: '', companyCountry: '', lastName: '', firstName: '' });

    const today: Date = new Date();
    const [selectedPeriod, setSelectedPeriod] = useState({ month: today.getMonth() + 1, year: today.getFullYear() } as IMonthlyAllowedPeriod);
    const [tableRows, setTableRows] = useState<any[]>([]);
    const [tableRowsToApprove, setTableRowsToApprove] = useState<any[]>([]);
    const [tableRowsHistory, setTableRowsHistory] = useState<any[]>([]);
    const [actionTypes, setActionTypes] = useState<IActionType[]>([]);
    const [orgChart, setOrgChart] = useState<IOrgChartUser>({} as IOrgChartUser);
    const [assStatuses, setAssStatuses] = useState<IAssistanceStatus[]>([]);
    const [countryUsers, setCountryUsers] = useState<IUser[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const refSelectedMotivation = useRef('');
    // const size: ISize = useWindowSize();
    //    const [assistanceUser, setAssistanceUser] = useState('');

    const dispatch = useDispatch();

    const header = {
        key: 'header',
        items: [
            {
                content: 'Descrizione',
                key: 'Descrizione',
            },
            {
                content: 'Inizio',
                key: 'Inizio',
                styles: { maxWidth: '145px' }
            },
            {
                content: 'Fine',
                key: 'Fine',
                styles: { maxWidth: '145px' }
            },
            {
                content: 'Data Richiesta',
                key: 'Richiesta',
                styles: { maxWidth: '145px' }
            },
            {
                content: 'Commento',
                key: 'Commento',
            },
            {
                content: 'Stato',
                key: 'Stato',
                styles: { maxWidth: '130px' }
            },
            {
                key: 'Actions',
                styles: { maxWidth: '90px' }
            }
        ],
    }

    const headerToApprove = {
        key: 'headerToApprove',
        items: [
            {
                content: 'Utente',
                key: 'Utente',
            },
            {
                content: 'Descrizione',
                key: 'Descrizione',
            },
            {
                content: 'Inizio',
                key: 'Inizio',
                styles: { maxWidth: '145px' }
            },
            {
                content: 'Fine',
                key: 'Fine',
                styles: { maxWidth: '145px' }
            },
            {
                content: 'Data Richiesta',
                key: 'Richiesta',
                styles: { maxWidth: '145px' }
            },
            {
                content: 'Commento',
                key: 'Commento',
            },
            {
                content: 'Stato',
                key: 'Stato',
                styles: { maxWidth: '130px' }
            },
            {
                key: 'Actions',
                styles: { maxWidth: '90px' }
            }
        ],
    }

    const commentText = (comment: string) => {
        return {
            content:
                <Flex gap="gap.smaller" vAlign="center" >
                    <div style={{ overflowWrap: "anywhere" }}>{comment.length > 55 ? comment.substring(0, 55) + " ..." : comment}</div>
                    {comment.length > 55 &&
                        <Dialog
                            cancelButton="Chiudi"
                            content={<div style={{ overflowWrap: "break-word" }}>{comment}</div>}
                            header="Commento"
                            trigger={<Button tabIndex={-1} text icon={<NotesIcon />} iconOnly title="Mostra commento" />}
                        />
                    }
                </Flex>,
            accessibility: gridCellWithFocusableElementBehavior
        };
    }

    const motivationsItems = [
        'Esigenza operativa',
        'Malattia',
        'Impegno personale',
        'Riprogrammazione',
        'Altro',
    ];

    
    const onAssistnceUpdate = async (assistanceId: number, type: AssistanceAction) => {
        setIsLoading(true);

        try {
            let status: string = "";
            switch (type) {
                case AssistanceAction.Approve:
                    status = 'approvata'
                    break;
                case AssistanceAction.Delete:
                    status = 'cancellata'
                    break;
                case AssistanceAction.Reject:
                    status = 'negata';
                    break;
                default:
                    break;
            }
            const updateStatus: IAssistanceStatus[] = assStatuses.filter((ass) => { return ass.name.toLocaleLowerCase() === status });
            if (updateStatus.length === 0) {
                throw new Error("Status  non trovato");
            }
            let response: IFetchResponse;
            if (type === AssistanceAction.Delete) {
                response = await deleteAssistance(assistanceId) as IFetchResponse;
            } else {
                response = await updateAssistance(assistanceId, updateStatus[0].id) as IFetchResponse;
            }

            //if (response.status === updateStatus[0].id) {
            if (response.status === 1) {
                dispatch(appActions.setNotifications([{ type: "success", message: `Assistenza ${status}` } as INotification]));
            }
            else {
                dispatch(appActions.setNotifications([{ type: "danger", message: SiaStrings.GENERIC_ERROR } as INotification]));
            }
        } catch (error) {
            console.log(`onAssistnceUpdate error : ${error}`);
            dispatch(appActions.setNotifications([{ type: "danger", message: (error as Error).message } as INotification]));
        } finally {
            await loadAssistances();
            setIsLoading(false);
        }
    }

    const getApproveActions = (assistance: IAssistance, approveActionsRequired: boolean) => {
        //In Attesa
        if (assistance.status === 4) {
            return {
                content: approveActionsRequired ?
                    <Flex gap="gap.smaller" vAlign="center" >
                        <Dialog
                            style={{ maxWidth: "300px" }}
                            cancelButton="Annulla"
                            confirmButton="Conferma"
                            content={""}
                            onConfirm={async () => {
                                onAssistnceUpdate(assistance.id, AssistanceAction.Approve);
                            }}
                            header="Approvare assistenza?"
                            trigger={<Button icon={<AcceptIcon />} iconOnly title="Approva assistenza" size="medium" text />}
                        />
                        <Dialog
                            style={{ maxWidth: "300px" }}
                            cancelButton="Annulla"
                            confirmButton="Conferma"
                            content={""}
                            onConfirm={async () => onAssistnceUpdate(assistance.id, AssistanceAction.Reject)}
                            header="Rifiutare assistenza?"
                            trigger={<Button icon={<CloseIcon />} iconOnly title="Rifiuta Assistenza" size="medium" text />}
                        />
                    </Flex>
                    :
                    <Dialog
                        style={{ maxWidth: "300px" }}
                        cancelButton="Annulla"
                        confirmButton="Conferma"
                        content={""}
                        onConfirm={async () => onAssistnceUpdate(assistance.id, AssistanceAction.Delete)}
                        header="Eliminare assistenza?"
                        trigger={<Button icon={<TrashCanIcon />} iconOnly title="Elimina assistenza" size="medium" text />}
                    />
                ,
                accessibility: gridCellWithFocusableElementBehavior
            };
        } else if (!approveActionsRequired && assStatuses.filter(as => as.id === assistance.status)[0].name !== "In attesa" && (assistance.idActionType == 93 || assistance.idActionType == 131) && moment(assistance.startDate) > moment(new Date())) {
            return {
                content: <Dialog
                    style={{ maxWidth: "300px" }}
                    cancelButton="Annulla"
                    confirmButton="Conferma Revoca"
                    content={<Dropdown
                        fluid
                        clearable
                        placeholder="Motivazione"
                        items={motivationsItems}
                        onChange={(event: any, data: any) => {
                            refSelectedMotivation.current = data.value;

                        }}
                    />}
                    onConfirm={async () => {
                        if (refSelectedMotivation.current === "") {
                            dispatch(appActions.setNotifications([{ type: "danger", message: "Campo Motivazione obbligatorio" } as INotification]));
                            return;
                        }
                        const response = await revocaAssistance(assistance.id, refSelectedMotivation.current);
                        if (response.status === 1) {
                            dispatch(appActions.setNotifications([{ type: "success", message: SiaStrings.ASSISTANCE_REMOVED } as INotification]));
                        }
                        else {
                            dispatch(appActions.setNotifications([{ type: "danger", message: SiaStrings.GENERIC_ERROR } as INotification]));
                        }
                        //Refresh
                        await loadAssistances();

                    }}
                    header="Eliminare assistenza?"
                    trigger={<Button content="Revoca" title="Revocare assistenza" size="medium" styles={{ backgroundColor: '#ff00009e', borderColor: 'red' }} />}
                />,
                accessibility: gridCellWithFocusableElementBehavior
            };
        }


    }

    const getTableRows = (assistances: IAssistance[], approveActionsRequired: boolean): any[] => {
        const rows: any[] = assistances.map((assistance, index) => {
            const actions: IActionType[] = actionTypes.filter((value) => {
                return value.id === assistance.idActionType
            });
            const description: string = actions.length > 0 ? actions[0].title : "non identificata";
            const statuses: IAssistanceStatus[] = assStatuses.filter((value) => {
                return value.id === assistance.status;
            });
            const status: string = statuses.length > 0 ? statuses[0].name : "non identificato";

            //Valori di Default
            let dataInizio: string = moment(assistance.startDate).format("DD/MM/YYYY");
            let dataFine: string = moment(assistance.endDate).format("DD/MM/YYYY")

            let dates: IDateRange = {
                startDate: dataInizio,
                endDate: dataFine
            };

            if (actions.length > 0) {
                dates = formatRowDate(actions[0], assistance.startDate, assistance.endDate);
            } else {
                // //Se inizio e fine sono nello stesso giorno visualizzo anche le ore
                if (dataInizio === dataFine) {
                    dataInizio = moment(assistance.startDate).format("DD/MM/YYYY HH:mm");
                    dataFine = moment(assistance.endDate).format("DD/MM/YYYY HH:mm")
                    dates.startDate = dataInizio;
                    dates.endDate = dataFine;
                }
            }

            if (dates.startDate.startsWith("01/01/0001")) {
                dates.startDate = "-"
            }
            if (dates.endDate.startsWith("01/01/0001")) {
                dates.endDate = "-"
            }


            let rowItems: any[] = [
                {
                    key: `${index}-1`,
                    content: description
                },
                {
                    key: `${index}-2`,
                    content: dates.startDate,
                    styles: { maxWidth: '145px' }
                },
                {
                    key: `${index}-3`,
                    content: dates.endDate,
                    styles: { maxWidth: '145px' }
                },
                {
                    key: `${index}-4`,
                    content: moment(assistance.createdOn).format("DD/MM/YYYY"),
                    styles: { maxWidth: '145px' }
                },
                {
                    key: `${index}-5`,
                    content: commentText(assistance.comment ? assistance.comment : '')
                },
                {
                    key: `${index}-7`,
                    content: <Alert success={status === 'Approvata'} warning={status === 'In attesa'} danger={status === 'Negata'} icon={status === 'Approvata' ? <AcceptIcon /> : status === 'Negata' ? <CloseIcon /> : status === 'In attesa' ? <ShiftActivityIcon /> : <TrashCanIcon />} content={status} />,
                    //<Flex vAlign="center" gap="gap.smaller"><span>{status}</span> <div>{status === 'Approvata' ? <AcceptIcon /> : status === 'Negata' ? <CloseIcon /> : status === 'In attesa' ? <ShiftActivityIcon /> : <TrashCanIcon />}</div></Flex>,
                    styles: { maxWidth: '130px' }
                },
                {
                    key: `${index}-8`,
                    content: status === 'In attesa' || status === 'Approvata' ? getApproveActions(assistance, approveActionsRequired) : "",
                    styles: { maxWidth: '90px' }
                }
            ];

            if (approveActionsRequired) {

                let fullName: string = '';
                if (assistance.fullName && assistance.fullName !== null && assistance.fullName !== '') {
                    fullName = assistance.fullName;
                }

                if (fullName === '') {
                    const user = countryUsers.filter((user) => {
                        return user.idUserSF === assistance.IdUserSf
                    });
                    fullName = user.length > 0 ? user[0].fullName : 'utente non trovato';
                }
                // const user = countryUsers.filter((user) => {
                //     return user.idUserSF === assistance.IdUserSf
                // });
                // rowItems.unshift({
                //     key: `${index}-9`,
                //     content: user.length > 0 ? user[0].fullName : 'utente non trovato'
                // });
                rowItems.unshift({
                    key: `${index}-9`,
                    content: fullName
                });
            }

            const row = {
                key: assistance.id,
                items: rowItems
            };

            return row;
        });
        return rows;
    }

    const loadAssistances = async () => {
        if (assistancesLoaded) {
            setAssistancesLoaded(false);
        }

        try {
            let currentUser = { email: orgChart.userName, idUserSF: orgChart.idUserSF, fullName: orgChart.fullName } as { email: string, idUserSF: string, fullName: string };
            //const user = { email: orgChart.userName, idUserSF: orgChart.idUserSF, fullName: orgChart.fullName } as { email: string, idUserSF: string, fullName: string };

            const ass: IUserAssistanceResponse = await getAssistances(currentUser.idUserSF, new Date(selectedPeriod.year, selectedPeriod.month - 1, 1), new Date(selectedPeriod.year, selectedPeriod.month, 0, 23, 59));
            ass.assistances.sort((a: IAssistance, b: IAssistance) => {
                //return (a.startDate !== null && b.startDate !== null) ? (a.startDate < b.startDate ? -1 : 1) : 1;
                return (a.IdUserSf !== null && b.IdUserSf !== null) ? (a.IdUserSf < b.IdUserSf ? -1 : 1) : 1;
            });

            const rows: any[] = getTableRows(ass.assistances, false);
            setTableRows(rows);
            //Caricamenteo assistenza da approvare (nel caso di responsabile/HR/Sostituto)           
            await loadAssistancesToApprove();
            //Sse c'è un utente selezionato nella drop down di selezione utente (e quindi in caso di responsabile/HR/Sostituto )ricarico le assistenze dell'utente.  
            if (selectedUser && selectedUser.idUserSF !== '') {
                loadSelectedUserAssistences(selectedUser);
            }
        } catch (err) {
            console.log(`Errore in caricamento assistenze : ${err}`);
            setError('Errore in caricamento assistenze');
        } finally {
            setAssistancesLoaded(true);
        }
    };

    const loadAssistancesToApprove = async (): Promise<void> => {
        if (assistancesToApproveLoaded) {
            setAssistancesToApproveLoaded(false);
        }

        //const assToApprove: IUserAssistanceResponse = await getAssistancesToApprove(new Date(selectedPeriod.year, selectedPeriod.month - 1, 1), new Date(selectedPeriod.year, selectedPeriod.month, 0, 23, 59));
        //Modifica per visualizzare le assistenze in un range di -/+ 6 mesi e non solo quelle del periodo selezionato
        debugger;
        const selectedMonth = selectedPeriod.month;
        const assToApprove: IUserAssistanceResponse = await getAssistancesToApprove(new Date(selectedPeriod.year, selectedMonth - 7, 1), new Date(selectedPeriod.year, selectedMonth + 7, 0, 23, 59));
        assToApprove.assistances.sort((a: IAssistance, b: IAssistance) => {
            return (a.startDate !== null && b.startDate !== null) ? (a.startDate < b.startDate ? -1 : 1) : 1;
        });
        const rowsToApprove: any[] = getTableRows(assToApprove.assistances, true);
        setTableRowsToApprove(rowsToApprove);
        setAssistancesToApproveLoaded(true);
    }

    const loadSelectedUserAssistences = async (assistanceUser: ISelectedUser): Promise<void> => {
        if (assistanceUser === null) {
            setTableRowsToApprove([]);
            setTableRowsHistory([]);
            return;
        }

        setAssistancesToApproveLoaded(false);
        try {
            const inattesaStatus: IAssistanceStatus[] = assStatuses.filter((ass) => { return ass.name.toLocaleLowerCase() === 'in attesa' });
            if (inattesaStatus.length === 0) {
                throw new Error("Status 'In attesa' non trovato");
            }
            //const ass: IUserAssistanceResponse = await getAssistances(assistanceUser.idUserSF, new Date(selectedPeriod.year, selectedPeriod.month - 1, 1), new Date(selectedPeriod.year, selectedPeriod.month, 0, 23, 59));
            //Modifica per visualizzare le assistenze in un range di -/+ 6 mesi e non solo quelle del periodo selezionato
            debugger;
            const ass: IUserAssistanceResponse = await getAssistances(assistanceUser.idUserSF, new Date(selectedPeriod.year, selectedPeriod.month - 7, 1), new Date(selectedPeriod.year, selectedPeriod.month + 7, 0, 23, 59));
            const filteredAss = ass.assistances.filter((ass) => {
                return ass.status === inattesaStatus[0].id;
            }).sort((a: IAssistance, b: IAssistance) => {
                return (a.IdUserSf !== null && b.IdUserSf !== null) ? (a.IdUserSf < b.IdUserSf ? -1 : 1) : 1;
            });

            const rows: any[] = getTableRows(filteredAss, true);
            setTableRowsToApprove(rows);
            //Storico assistenze    
            const historyAss = ass.assistances.filter((ass) => {
                return ass.status !== inattesaStatus[0].id;
            }).sort((a: IAssistance, b: IAssistance) => {
                return a.createdOn < b.createdOn ? 1 : -1;
            });
            const historyRows: any[] = getTableRows(historyAss, true);
            setTableRowsHistory(historyRows);
        } catch (err) {
            console.log(`Errore in caricamento assistenze utente : ${assistanceUser.idUserSF}`);
            setError('Errore in caricamento assistenze');
        } finally {
            setAssistancesToApproveLoaded(true);
        }
    }

    useEffect(() => {
        (async () => {
            const email: string = appState.debug ? appState.debugUser : appState.teamsContext.userPrincipalName?.toLowerCase() as string;
            const tmpOrgChart = await cookieHelper.setApplicationCookies.setOrgChartCookie(email);
            setOrgChart(tmpOrgChart);
            setActionTypes(await cookieHelper.setApplicationCookies.setActionTypesCookie());
            setAssStatuses(await cookieHelper.setApplicationCookies.setAssistanceStatusesCookie());
            const users: IUser[] = await cookieHelper.setApplicationCookies.setCountryUsersCookie(tmpOrgChart.companyCountry)
            setCountryUsers(users);
        })();
    }, []);

    useEffect(() => {
        console.log(`SelectedPeriod = mese : ${selectedPeriod.month} anno : ${selectedPeriod.year}`);
        const load = async () => {
            if (orgChart.userName !== undefined && assStatuses.length > 0 && countryUsers.length > 0 && actionTypes.length > 0) {
                await loadAssistances();
            }
        };

        if (error !== '') {
            //reset error
            setError('');
        }

        load();
        //**********  Scommentare se la pagina deve essere visualizzata come tab indipendente e non sotto il tab 'Altro' */
        // if (appState.userType === null || appState.userType === undefined || appState.userType === UserType.Unknown) {
        //     console.log('carico userType');
        //     const userType: UserType = cookieHelper.getApplicationCookies.getUserTypeCookie();
        //     dispatch(appActions.setUserType(userType));
        // }

    }, [selectedPeriod, orgChart.userName, assStatuses, actionTypes, countryUsers]);

    useEffect(() => {
        if (selectedUser && selectedUser.idUserSF !== '') {
            loadSelectedUserAssistences(selectedUser);
        } else {
            //setTableRowsToApprove([]);
            loadAssistancesToApprove();
            setTableRowsHistory([]);
        }
    }, [selectedUser])

    return (
        <Flex className="assistances-main-container"  >
            {/* {error !== '' &&
                <Warning label={error}></Warning>
            } */}
            {orgChart.idUserSF !== '' &&
                <Flex column className="assistances-container" gap="gap.medium">
                    <div className="assistances-titles noselect">{SiaStrings.ASSISTANCES} <b>{orgChart.fullName}</b></div>
                    <div className="assistances-titles noselect">{SiaStrings.PERIOD_SELECTOR} </div>
                    <Flex column className="assistances-period-selector">
                        <CustomPeriodSelector userType={appState.userType} onSelectedPeriodChange={setSelectedPeriod} />
                    </Flex>
                    {error === '' ?
                        <>
                            <Dialog
                                open={isLoading}
                                styles={{ maxWidth: "300px", textAlign: "center" }}
                                content={<Loader size="small" style={{ paddingTop: '20px' }} label={SiaStrings.LOADING} />}
                                header=""
                            />
                            {assistancesLoaded ?
                                <Flex column gap="gap.medium" >
                                    {tableRows.length > 0 ?
                                        <Table
                                            className="assistances-table"
                                            header={header}
                                            rows={tableRows}
                                            aria-label="Assistenze"
                                            accessibility={gridNestedBehavior}
                                        />
                                        :
                                        <Alert info icon={<InfoIcon />} content="Non ci sono assistenze per il mese selezionato" />
                                    }
                                </Flex>
                                :
                                <Loader size="small" style={{ paddingTop: '20px' }} styles={{ textAlign: "center" }} />
                            }
                            {((orgChart.idUserSF !== appState.idUserSF) || (orgChart && orgChart.users.length > 0) || appState.userType === UserType.HR || tableRowsToApprove.length > 0) &&
                                <Flex column gap="gap.medium">
                                    <Divider />
                                    <Flex column gap="gap.medium">
                                        <div className="assistances-titles noselect">Seleziona l'utente per le Assistenze da approvare e per lo Storico assistenze </div>
                                        <div style={{ width: '272px' }}>
                                            <UserSelector onSelectedUserChange={setSelectedUser} allUsers={false} />
                                        </div>

                                    </Flex>
                                    <Divider />
                                    <div className="assistances-titles noselect">{SiaStrings.ASSISTANCES_TO_APPROVE}</div>

                                    {!assistancesToApproveLoaded &&
                                        <Loader size="small" style={{ paddingTop: '20px' }} styles={{ textAlign: "center" }} />
                                    }
                                    {tableRowsToApprove.length > 0 && assistancesToApproveLoaded ?
                                        <Table
                                            className="assistances-table"
                                            variables={{
                                                cellContentOverflow: 'none',
                                            }}
                                            header={headerToApprove}
                                            rows={tableRowsToApprove}
                                            aria-label="Nested navigation"
                                            accessibility={gridNestedBehavior}
                                        />
                                        :
                                        assistancesToApproveLoaded ? <Alert info icon={<InfoIcon />} content="Non ci sono assistenze da approvare" /> : null
                                    }
                                    <Flex column gap="gap.medium">
                                        <div className="assistances-titles noselect">{SiaStrings.ASSISTANCES_HISTORY}</div>
                                        {!assistancesToApproveLoaded &&
                                            <Loader size="small" style={{ paddingTop: '20px' }} styles={{ textAlign: "center" }} />
                                        }
                                        {tableRowsHistory.length > 0 && assistancesToApproveLoaded ?
                                            <Table
                                                className="assistances-table"
                                                variables={{
                                                    cellContentOverflow: 'none',
                                                }}
                                                header={headerToApprove}
                                                rows={tableRowsHistory}
                                                aria-label="Nested navigation"
                                                accessibility={gridNestedBehavior}
                                            />
                                            :
                                            assistancesToApproveLoaded ? <Alert info icon={<InfoIcon />} content={selectedUser !== null && selectedUser.idUserSF !== '' ? "Non ci sono assistenze negli ulitmi 6 mesi per l'utente selezionato" : "Seleziona l'utente dal filtro"} /> : null
                                        }
                                    </Flex>
                                </Flex>
                            }
                        </>
                        :
                        <Warning label={error}></Warning>
                    }
                </Flex>
            }
            {/*         
        <Flex fill space="around" >
            <Loader size="small" label={SiaStrings.LOADING_ASSISTANCES} style={{ paddingTop: '20px' }} styles={{ textAlign: "center" }} />
        </Flex> */}
        </Flex>
    );
}

export default Assistances