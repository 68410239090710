/* IMPORT CSS / SASS */
import '../scss/Badge.scss';

/* IMPORT REACT & THIRD PARTY COMPONENTS */
import React from 'react';
import { Image, Flex, Loader } from '@fluentui/react-northstar';
import { useSelector, useDispatch } from 'react-redux';

/* IMPORT CUSTOM COMPONENTS */
import { IEmployee } from '../interfaces/IEmployee';
import { capitalizeWords } from '../helpers/Tools';
import { IAppState } from '../interfaces/IAppState';
import * as ssfActions from '../stores/actions/SsfActions';
import * as cookieHelper from '../helpers/CookiesHelper';
import LogoConsulcesi from '../imgs/logo_consulcesi-group.png';
import LogoConsulcesiForDark from '../imgs/Group-negativo.svg';


export const BadgeHeader = (props: any) => {

    const appState: IAppState = useSelector(appState => appState) as IAppState;
    const employee: IEmployee = appState.ssfJobs[appState.selectedJob] as IEmployee;
    const dispatch = useDispatch();

    return appState.ssfIsLoaded ?
        <Flex space="between" className="badge-title-container" >
            <Flex column>
                <div className="badge-person-name">{capitalizeWords(employee?.firstName)} {capitalizeWords(employee?.lastName)}</div>
                <div>{capitalizeWords(employee?.roleName)} </div>
                <span className="badge-header-company-name">{capitalizeWords(employee.companyName)}</span>
            </Flex>

            {(appState.teamsContext.theme === "dark" || appState.teamsContext.theme === "contrast") ? <Image src={LogoConsulcesiForDark} className="logo" /> : <Image src={LogoConsulcesi} className="logo" />}


            {/* <Image src={LogoConsulcesiForDark} className="logo"  /> */}
            {/* {(appState.ssfJobs.length > 1) ?
                <Flex gap="gap.small" hAlign="end" vAlign="center">
                    {appState.ssfJobs.map((emp: IEmployee, index: number) => {
                        let jobLabel = (emp.roleName === undefined || emp.roleName === '' || emp.roleName === null) ? `${capitalizeWords(emp.firstName)} ${capitalizeWords(emp.lastName)}` : '';
                        jobLabel += (emp.roleName !== undefined && emp.roleName !== '' && emp.roleName !== null && emp.roleName.length > 0) ? ` ${capitalizeWords(emp.roleName)}` : ``;

                        var empJobId: number = cookieHelper.getApplicationCookies.getSelectedJobCookie();
                        return (empJobId === index) ?
                            <Button disabled key={index} content={`${jobLabel}`} />
                            :
                            <Button key={index} onClick={() => dispatch(ssfActions.setSelectedJob(index))} content={`${jobLabel}`} primary disabled={appState.isRegistering} />
                    })
                    }
                </Flex>
                : null
            } */}
        </Flex>
        :
        <Loader label="Loading Success Factors data..." />
}

export default BadgeHeader;