/* IMPORT CSS / SASS */
import '../scss/Badge.scss';

/* IMPORT REACT & THIRD PARTY COMPONENTS */
import { useSelector } from 'react-redux';
import { Flex } from '@fluentui/react-northstar';
import { useDispatch } from "react-redux";

/* IMPORT CUSTOM COMPONENTS */
import { purifyLocation } from '../helpers/SuccessFactorsHelper';
import { IEmployee } from '../interfaces/IEmployee';
import { IAppState } from '../interfaces/IAppState';
import { INotification } from '../interfaces/INotification';
import { capitalizeWords } from '../helpers/Tools';
import * as Constants from '../constants/AppConstants';
import EmployeePic from './EmployeePic';
import Draggable from 'react-draggable';
// import ConsulcesiEye from '../imgs/consulcesi-eye.svg';
// import ConsulcesiEyeBlu from '../imgs/consulcesi-eye-blu.svg';
import ConsulcesiEye from '../imgs/consulcesi-eye-white.png';
import ConsulcesiEyeBlu from '../imgs/consulcesi-eye-color.png';
import * as appActions from "../stores/actions/AppActions";
import { SiaStrings } from "../strings/strings";

const BadgeCard = (props: any) => {

  const appState: IAppState = useSelector(appState => appState) as IAppState;
  const employee: IEmployee = appState.ssfJobs[appState.selectedJob] as IEmployee;
  const badgeOffset: number = 100;
  const dispatch = useDispatch();
  const checkWorkingMode = () => {
    if (appState.actionToRegister === undefined) {
      dispatch(appActions.setNotifications([{ type: Constants.notificationTypes.DANGER, message: 'Nessauna azione consentita' } as INotification]));
    } else if (appState.currentWorkingMode === null && appState.userCanBadge) {
      dispatch(appActions.setNotifications([{ type: Constants.notificationTypes.DANGER, message: SiaStrings.CHOOSE_WORKINGMODE } as INotification]));
    }
  }

  return appState.ssfJobs.length > 0
    ?
    <Draggable
      // disabled={appState.isRegistering || !appState.todayStatusIsLoaded || appState.currentWorkingMode === null || appState.userCanBadge === false}
      disabled={true}
      axis="x"
      handle=".drag-me"
      defaultPosition={{ x: 0, y: 0 }}
      position={props.badgePosition}
      grid={[1, 1]}
      scale={1}
      bounds={{ left: -badgeOffset, top: 0, right: badgeOffset, bottom: 0 }}
      //onStart={handleStart}
      onDrag={(event, data) => props.handleBadgeDrag(event, data)}
      onStop={(event, data) => props.handleBadgeStop(event, data)}
      onMouseDown={checkWorkingMode}
    >
      <div id="drag-me" data-offset="{'top': 50, 'left': 0}" className={((appState.teamsContext.theme === "dark" || appState.teamsContext.theme === "contrast") ? "badge-badge-background-dark" : "badge-badge-background") + " drag-me badge-badge"}>
        <div id="badge-badge-side-left" className="badge-badge-side badge-badge-side-left"></div>
        <div id="badge-badge-content" className="badge-badge-content">

          <Flex vAlign="start" space="between" style={{ width: '100%' }}>
            <EmployeePic pic={employee?.pics[0]} />
            <div className="badge-badge-user-id noselect">{employee.userId}</div>
          </Flex>

          <Flex space="between" vAlign="end" hAlign="end" style={{ width: '100%' }} >
            <div>
              <div className="badge-badge-user-name noselect">{capitalizeWords(employee?.firstName)} {capitalizeWords(employee?.lastName)}</div>
              <div className="badge-badge-user-description noselect">{capitalizeWords(employee.roleName)}</div>
            </div>
            <div className="badge-badge-user-location">
              <div><img draggable="false" className="badge-badge-company-logo noselect" src={(appState.teamsContext.theme === "dark" || appState.teamsContext.theme === "contrast") ? ConsulcesiEye : ConsulcesiEyeBlu} alt="Company Logo" /></div>
              {(employee.companyLocation !== null && employee.companyLocation !== undefined && employee.companyLocation !== "") ?
                <div className="noselect">{purifyLocation(employee.companyLocation).toUpperCase()}</div>
                : null}
            </div>
          </Flex>

        </div>
        <div id="badge-badge-side-right" className="badge-badge-side badge-badge-side-right"></div>
      </div>
    </Draggable>
    :
    null
}

export default BadgeCard;