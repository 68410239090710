import { ICustomSelectorItem } from '../interfaces/ICustomSelectorItem';
import { IMonthlyAllowedPeriod } from '../interfaces/IMonthlyAllowedPeriod';
import { SiaStrings } from '../strings/strings';

import moment from 'moment';
moment.locale('it');

export const capitalizeWords = (str: string): string => {
    if (str === null || str === undefined || str === "") return '';
    var splitStr = str.toLowerCase().split(' ');
    for (var i = 0; i < splitStr.length; i++) {
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(' ');
}

export const convertAllowedPeriodToCustomSelectorItems = (allowedPeriod: Array<IMonthlyAllowedPeriod>): Array<ICustomSelectorItem> => {
    let period: Array<ICustomSelectorItem> = [] as Array<ICustomSelectorItem>;
    allowedPeriod.forEach((p: IMonthlyAllowedPeriod, i: number) => {
        period.push({ key: p, value: capitalizeWords(moment(new Date(p.year, p.month - 1, 1)).format("MMMM YYYY")) } as ICustomSelectorItem);
    })
    return period;
}

export const minutesToHours = (mins: number): string => {
    var m = mins % 60;
    var h = (mins - m) / 60;
    if (h <= 0)
        return (m < 10 ? "0" : "") + m.toString() + " " + SiaStrings.MINUTES;
    else
        return h.toString() + ":" + (m < 10 ? "0" : "") + m.toString() + " " + SiaStrings.HOURS;
}

export const capitalize = (value: string): string => {
    return value ? value[0].toUpperCase() + value.slice(1) : "";
}