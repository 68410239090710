
import "../scss/CalendarDate.scss";
import { Flex, ErrorIcon, AcceptIcon, Tooltip, OcrOnIcon, Text } from '@fluentui/react-northstar';
import { Checkmark16Regular } from '@fluentui/react-icons';
import { capitalize } from '../helpers/Tools';
import moment from 'moment';
import { IAnomaly } from "../interfaces/IAnomaly";
import React, { useEffect, useState } from "react";
import { IAnomalyType } from "../interfaces/IAnomalyType";

export interface ICalendarDateProps {
    date: Date;
    anomalies: IAnomaly[];
    anomalyTypes: IAnomalyType[];
    ticketRestaurant: boolean;
}
const CalendarDate = (props: ICalendarDateProps) => {
    const [tooltipMessage, setTooltipMessage] = useState<any[]>([]);

    useEffect(() => {
        let messages: any[] = [];
        props.anomalies.forEach((anomaly: IAnomaly) => {
            const anomalyTypesFiltered = props.anomalyTypes.filter(at => at.id === anomaly.idAnomalyType);
            if (anomalyTypesFiltered.length > 0) {
                messages.push(<Text key={anomalyTypesFiltered[0].id} content={anomalyTypesFiltered[0].name} />);
            }
        });
        setTooltipMessage(messages);
    }, [])

    return <Flex gap="gap.medium" vAlign="center" wrap style={{ width: "max-content" }} >
        {props.ticketRestaurant &&
            <Tooltip trigger={<OcrOnIcon size="smaller" />} content="Ticket Restaurant" />
        }
        {props.anomalies.length > 0 ? <Tooltip trigger={<div style={{ width: "16px", marginRight: "3px" }}><ErrorIcon size="smaller" /></div>} content={<Flex column>{tooltipMessage}</Flex>} /> : <div style={{ width: "16px", marginRight: "3px" }}><Checkmark16Regular style={{ color: "green" }} /></div>}
        <span style={{ width: "16px", marginRight: "5px" }}>{capitalize(moment(props.date).format("dd"))}</span>
        <span style={{ width: "16px", marginRight: "3px" }}><b>{moment(props.date).format("DD")}</b></span>
    </Flex>
}

export default CalendarDate;