/* IMPORT CSS / SASS */
import "../scss/BadgeMobile.scss";

/* IMPORT REACT & THIRD PARTY COMPONENTS */
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Loader, Flex, Divider, Dialog, Text, Alert, InfoIcon } from "@fluentui/react-northstar";
import * as microsoftTeams from "@microsoft/teams-js";

/* IMPORT CUSTOM COMPONENTS */
import * as siaActions from "../stores/actions/SiaActions";
import { IAppState } from "../interfaces/IAppState";
import { IFetchResponse } from "../interfaces/IFetchResponse";
import { ITimesheetAction } from "../interfaces/ITimesheetAction";
import { SiaStrings } from "../strings/strings";
import BadgeHeaderMobile from "./BadgeHeaderMobile";
import { getSiaToday, postTimesheet } from "../helpers/Fetch";
import BadgeActionsHistoryMobile from "./BadgeActionsHistoryMobile";
import BadgeCard from "./BadgeCard";
import BadgeActionIndicator from "./BadgeActionIndicator";
import BadgeActionSelector from "./BadgeActionSelector";
import BadgeActionSelectorMobile from './BadgeActionSelectorMobile';
import BadgeWorkingModeSelector from './BadgeWorkingModeSelector';
import Warning from "./Warning";
import * as cookieHelper from '../helpers/CookiesHelper';
import * as Constants from '../constants/AppConstants';
import * as appActions from '../stores/actions/AppActions';
import { INotification } from "../interfaces/INotification";
import moment from 'moment';
import { ISize } from "../interfaces/ISize";
import { IEmployee } from '../interfaces/IEmployee';
import useWindowSize from "../hooks/useWindowSize";
import useLoadCommonData from "../hooks/useLoadCommonData";
import { LoadCommonDataStaus } from "../constants/AppEnum";


const BadgeMobile = (props: any) => {

    const badgeOffset: number = 100;
    const appState: IAppState = useSelector((appState) => appState) as IAppState;
    const employee: IEmployee = appState.ssfJobs[appState.selectedJob] as IEmployee;
    const [canSelectWorkingMode, setCanSelectWorkingMode] = useState(false);
    const dispatch = useDispatch();
    const [badgePosition, setBadgePosition] = useState({ x: 0, y: 0 });
    const badgeRightDescription: HTMLElement = document.getElementById("badge-action-description-left") as HTMLElement;
    const badgeLeftDescription: HTMLElement = document.getElementById("badge-action-description-right") as HTMLElement;
    const badgeRightSide: HTMLElement = document.getElementById("badge-badge-side-right") as HTMLElement;
    const badgeLeftSide: HTMLElement = document.getElementById("badge-badge-side-left") as HTMLElement;
    const badgeContent: HTMLElement = document.getElementById("badge-badge-content") as HTMLElement;
    const currentWorkingMode = cookieHelper.getApplicationCookies.getCurrentWorkingModeCookie() ?? appState.currentWorkingMode;
    const today = new Date();
    const [showCalendarWarning, setShowCalendarWarning] = useState(false);
    const tmpEowAction = cookieHelper.getApplicationCookies.getTempEndOfWorkAction();
    const [showConfirmTempEowAction, setShowConfirmTempEowAction] = useState(false);
    const [error, setError] = useState('');
    const size: ISize = useWindowSize();
    const commonDataStatus: LoadCommonDataStaus = useLoadCommonData();
    const [pageLoaded, setPageLoaded] = useState(false);
    const [saving, setSaving] = useState(false);

    const saveTimesheet = async (action: ITimesheetAction): Promise<void> => {
        dispatch(siaActions.setIsRegistering(true));
        const registered: IFetchResponse | null = await postTimesheet(action);
        if (registered !== null && registered.status === 1) {
            //dispatch(siaActions.addTimesheet(registered.payload));
            let todayStatus = await getSiaToday(appState.ssfJobs[appState.selectedJob].userId, new Date(today.getFullYear(), today.getMonth(), today.getDate(), 3));
            dispatch(siaActions.setTodayStatus(todayStatus));
            
            //****** Non serve più perchè la modalità lavorativa non è più selezionabile dall'utente ******
            //Se è la timbratura di uscita lavoro resetto il working mode
            //in modo che venga richiesto alla prossima timbratura di ingresso lavoro
            // if (action.idActionType === appState.timbraturaAction?.id && action.endDate !== null) {
            //     dispatch(siaActions.setCurrentWorkingMode(null));
            //     cookieHelper.setApplicationCookies.setCurrentWorkingModeCookie(null);
            //     setCanSelectWorkingMode(true);
            // }
            // //Se invece è la timbratura di ingresso memorizzo il workingmode
            // else if (action.idActionType === appState.timbraturaAction?.id && action.startDate !== null) {
            //     cookieHelper.setApplicationCookies.setCurrentWorkingModeCookie(appState.currentWorkingMode);
            //     setCanSelectWorkingMode(false);
            // }
        } else {
            if (registered.payload.response?.data?.details === "Calendar exception.") {
                //Gestione del caso in cui l'utente non ha compilato le azioni di calendario
                setShowCalendarWarning(true);
                cookieHelper.setApplicationCookies.setTempEndOfWorkAction(action);
            } else {
                dispatch(appActions.setNotifications([{ type: Constants.notificationTypes.DANGER, message: "Si è verificato un errore durante la timbratura" } as INotification]));
            }
        }
    }

    const handleActionButtonClick = async (actionToRegister: ITimesheetAction) => {
        debugger;
        try {
            setSaving(true);
            let updateTimeAction: ITimesheetAction;
            // aggiungo la end date o la start date a seconda che sia un inizio o una fine azione
            if (actionToRegister.id !== null)
                updateTimeAction = { ...actionToRegister, endDate: new Date(), idWorkingMode: currentWorkingMode } as ITimesheetAction;
            else
                updateTimeAction = { ...actionToRegister, startDate: new Date(), idWorkingMode: currentWorkingMode } as ITimesheetAction;
            await saveTimesheet(updateTimeAction);
        } finally {
            setSaving(false);
        }
    }

    useEffect(() => {
        (async () => {
            if (commonDataStatus === LoadCommonDataStaus.Success) {
                //Carico per la prima volta i timesheets dell'utente
                if (appState.ssfJobs && appState.ssfJobs.length > 0) {
                    try {
                        let todayStatus = await getSiaToday(appState.ssfJobs[appState.selectedJob].userId, new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0));

                        dispatch(siaActions.setTodayStatus(todayStatus));
                        dispatch(siaActions.setTodayStatusIsLoaded(true));
                        //Se c'è un'azione di uscita in sospeso per la giornata corrente
                        if (tmpEowAction !== null) {
                            if (moment(tmpEowAction.endDate).format("DD/MM/yyyy") === moment(new Date()).format("DD/MM/yyyy")) {
                                setShowConfirmTempEowAction(true)
                                dispatch(siaActions.setUserCanBadge(false));
                            }
                        }
                        //**** LA modalità di lavoro è selzionata in automatico in base all'ip dell'utente */
                        //come workingmode imposto quello dell'azione di inizio lavoro non ancora completata. (una volta timbrato iniziato giornata non posso più cambiare working mode) 
                        // const todayStatusStartWork = todayStatus.find(item => {
                        //     return item.idActionType === appState.timbraturaAction?.id && item.endDate === null;
                        // });
                        // dispatch(siaActions.setCurrentWorkingMode(todayStatusStartWork ? todayStatusStartWork.idWorkingMode : null));
                        // if (todayStatusStartWork) {
                        //     setCanSelectWorkingMode(false);
                        // } else {
                        //     setCanSelectWorkingMode(true);
                        // }
                        setPageLoaded(true);
                    } catch (error: any) {
                        setError(error.message);
                    }
                } else {
                    setError("Non sono stati trovati contratti per l'utente corrente")
                }
            }
        })();

    }, [commonDataStatus])


    return (
        pageLoaded === false ?
            <Flex styles={{ height: size.height !== undefined ? `${size.height}px` : '100%' }} hAlign="center" vAlign="center">
                <Loader label={SiaStrings.LOADING} size="large" />
            </Flex>
            :
            <div id="div1">
                {error !== '' ?
                    <Warning label={error} height={size.height} />
                    :
                    <div id="div2">
                        <Flex column className="badge-main-container-mobile" styles={{ height: size.height !== undefined ? `${size.height}px` : '100%' }} hAlign="center" >
                            <BadgeHeaderMobile />
                            <Flex column hAlign="start" vAlign="start">
                                {appState.todayStatusIsLoaded
                                    ?
                                    <Flex column hAlign="center">
                                        {appState.userCanBadge && appState.actionToRegister !== undefined ?
                                            <BadgeWorkingModeSelector disabled={!canSelectWorkingMode} />
                                            :
                                            null
                                        }
                                        {/*
                                         <div onTouchStart={() => handleBadgeDrag} ></div>
                                        <Flex
                                            className="badge-badge-container"
                                            gap="gap.small"
                                            hAlign="center"
                                            vAlign="center">
                                             <BadgeActionIndicator position="left" /> 
                                            <BadgeCard
                                                badgePosition={badgePosition}
                                                handleBadgeDrag={handleBadgeDrag}
                                                handleBadgeStop={handleBadgeStop}></BadgeCard>
                                             <BadgeActionIndicator position="right" /> 
                                        </Flex> 
                                        */}
                                        {appState.userCanBadge ?
                                            <BadgeActionSelectorMobile actionClick={handleActionButtonClick} saving={saving}/>
                                            :
                                            <Alert info icon={<InfoIcon />} content="Non sono previste azioni di timbratura" />
                                        }
                                        {/* Dialog per inserire eventuali azioni di calendario mancanti  */}
                                        <Dialog
                                            style={{ maxWidth: "500px" }}
                                            open={showCalendarWarning}
                                            confirmButton="Vai al calendario"
                                            content={
                                                <Text content={"Attenzione : non hai insertio tutte le azioni nel calendario. L'uscita potrà essere registrata solo quando hai inserito tutte le attività della giornata"} />
                                            }
                                            onConfirm={async () => {
                                                var encodedContext = encodeURI('{"subEntityId": "Calendario"}');
                                                var calendarTabUrl = `https://teams.microsoft.com/l/entity/${process.env.REACT_APP_SIA_APPID}/${process.env.REACT_APP_SIA_ALTRO_TAB_ID}?&context=${encodedContext}`;
                                                microsoftTeams.executeDeepLink(calendarTabUrl);
                                            }}
                                            header={`Attività calendario mancanti`}
                                        />
                                        {/* Dialog per inserire azione di uscita non confermata per mancanza azioni di calendario  */}
                                        <Dialog
                                            style={{ maxWidth: "500px" }}
                                            open={showConfirmTempEowAction}
                                            cancelButton="Annulla"
                                            confirmButton="Conferma"
                                            content={
                                                <Flex column gap="gap.small">
                                                    <Text content={"Hai un'azione di fine lavoro in sospeso :"} />
                                                    <Text content={`Dettaglio : ${moment(tmpEowAction?.endDate).format("DD/MM/yyyy HH:mm")}`} />
                                                    <Text content={"Vuoi confermarla?"} />
                                                </Flex>
                                            }
                                            onCancel={(() => { setShowConfirmTempEowAction(false) })}
                                            onConfirm={async () => {
                                                try {
                                                    if (tmpEowAction !== null) {
                                                        await saveTimesheet(tmpEowAction);
                                                        //cancello cookie
                                                        cookieHelper.setApplicationCookies.setTempEndOfWorkAction();
                                                        //Riabilito il badge
                                                        dispatch(siaActions.setUserCanBadge(true));
                                                    }
                                                } catch (error) {
                                                    dispatch(appActions.setNotifications([{ type: Constants.notificationTypes.DANGER, message: "Si è verificato un errore durante la timbratura" } as INotification]));
                                                }
                                            }}

                                            header={`Conferma uscita`}
                                        />
                                    </Flex>
                                    :
                                    <Flex column hAlign="center" vAlign="center" style={{ width: '100%', minHeight: '200px', paddingTop: '20px' }}><Loader label={SiaStrings.LOADING_SIA} size="small" /></Flex>
                                }

                            </Flex>
                            <BadgeActionsHistoryMobile />

                        </Flex>
                    </div>
                }
            </div>
    );

};
export default BadgeMobile;
