import "../scss/UserSelector.scss";

import React, { useEffect, useState } from "react";
import { Dropdown, Flex } from '@fluentui/react-northstar';
import * as cookieHelper from '../helpers/CookiesHelper';
import { useSelector } from "react-redux";
import { IAppState } from "../interfaces/IAppState";
import { UserType } from "../constants/AppEnum";
import { IOrgChartUser } from "../interfaces/IOrgChartUser";
import { IUser } from "../interfaces/IUser";
import { ISelectedUser } from "../interfaces/ISelectedUser";
import { getAllUsers } from "../helpers/SiaHelper";

const UserSelector = (props: any) => {
    //Contiene TUTTI gli utenti da visualizzare nella drop down. Viene valorizzato solo la prima volta. Viene utilizzato quando il filtro sulla company � vuoto
    const [allDropDownUsers, setAllDropDownUsers] = useState<{ key: string, header: string, company: string }[]>([]);
    //Contiene gli utenti filtrati in base alla company
    const [dropDownUsers, setDropDownUsers] = useState<{ key: string, header: string, company: string }[]>([]);
    const [dropDownCompany, setDropDownCompany] = useState<{ key: string, header: string }[]>([]);
    const appState: IAppState = useSelector((appState) => appState) as IAppState;
    const [error, setError] = useState('');
    const [allUsers, setAllUsers] = useState<IUser[]>([]);
    const startDate: Date | undefined = props.period !== undefined ? new Date(props.period.year, props.period.month - 1, 1) : undefined;
    const endDate: Date | undefined = props.period !== undefined ? new Date(props.period.year, props.period.month, 0, 23, 59) : undefined;
    const [selectedCompany, setSelectedCompany] = useState('');

    const setUsers = async (country: string): Promise<void> => {
        debugger;
        let cUsers: IUser[] = await cookieHelper.setApplicationCookies.setCountryUsersCookie(country, startDate, endDate);
        cUsers = cUsers.filter(u => u.userName !== null && u.fullName !== null);
        setAllUsers(cUsers);        
        setDropDownSources(cUsers);
    }

    //Imposto gli elementi delle dropdown
    const setDropDownSources = (users: IUser[]) => {
        let companies: { key: string, header: string }[] = [];
        const ddUsers = users.map((user) => {
            if (props.loadCompany !== null && props.loadCompany === true) {
                if (companies.findIndex(c => c.key === user.companyCode) === -1 && user.companyCode !== null && user.companyCode !== '') {
                    companies.push({
                        key: user.companyCode,
                        header: user.companyDescription !== null ? user.companyDescription : user.companyCode
                    });
                }
            }
                //Inverto nome e cognome
                //const splittedName = user.fullName.split(' ');
                //if (splittedName.length >= 2) {
                //    const fName: string = splittedName.shift() as string;
                //    splittedName.push(fName);
                //}
                //const name = splittedName.length >= 2 ? splittedName.join(' ') : user.fullName;
                
                const name = user.lastName + ' ' + user.firstName;
                return {
                key: user.idUserSF,
                header: name,
                company: user.companyCode
            };
        }).sort((a: any, b: any) => a.header.localeCompare(b.header))
        setAllDropDownUsers(ddUsers);
        setDropDownUsers(ddUsers);

        if (companies.length > 0) {
            setDropDownCompany(companies);
        }
    }

    useEffect(() => {
        (async () => {
            try {
                if (appState.userType !== UserType.Unknown) {
                    //const orgChart: IOrgChartUser = cookieHelper.getApplicationCookies.getOrgChartCookie();
                    debugger;
                    const currentUserEmail: string = appState.debug ? appState.debugUser : appState.teamsContext.userPrincipalName?.toLowerCase() ?? 'unknown';
                    const orgChart: IOrgChartUser = await cookieHelper.setApplicationCookies.setOrgChartCookie(currentUserEmail, startDate, endDate)
                    if (appState.userType === UserType.HR) {
                        //Carico gli utenti della sede di appartenenza dell'utente HR         
                        await setUsers(orgChart.companyCountry);
                    }
                    else if (appState.userType === UserType.Admin) {
                        //Carico tutti gli utenti
                        await setUsers('');
                    }
                    else if (appState.userType === UserType.User && orgChart.users.length > 0) {
                        let users: any[] = [];
                        //Se props.allUsers recupero gli utenti da tutta la gerarchia, altrimenti solo gli utenti sottoposti di primo livello
                        let usersToFilter: IOrgChartUser[] = props.allUsers ? getAllUsers(orgChart) : [...orgChart.users];

                        //Rimuovo i duplicati -> necessario perchè la getAllUsers può ritornare duplicati (es. per Borin)
                        usersToFilter.forEach((u, index) => {
                            const isDuplicate = users.filter(utf => utf.idUserSF === u.idUserSF).length > 0 ? true : false;
                            if (!isDuplicate) {
                                users.push({
                                    idUserSF: u.idUserSF,
                                    userName: u.userName,
                                    fullName: u.fullName,
                                    firstName: u.firstName,
                                    lastName: u.lastName,
                                    dateOfBirth: u.dateOfBirth,
                                    companyCountry: u.companyCountry,
                                    companyCode: u.companyCode,
                                    placesOfWork: u.placesOfWork,
                                    companyDescription: u.companyDescription
                                });
                            }

                        });
                        
                        setAllUsers(users);
                        setDropDownSources(users);
                    }
                }
            } catch (error) {
                setError('Impossibile caricare gli utenti');
            }
        })();
    }, [appState.userType, props.period])


    useEffect(() => {
        if (selectedCompany !== '') {
            //Filtro utenti per company
            const comapnyUsers = dropDownUsers.filter(u => u.company === selectedCompany);
            setDropDownUsers(comapnyUsers);

        } else {
            if (allDropDownUsers.length > 0) {              
                //Quando la company � vuota ricarico tutti gli utenti
                setDropDownUsers(allDropDownUsers);
            }
        }
    }, [selectedCompany])

    return <div className="user-selector-main-xx">
        {error !== '' ?
            <div>{error}</div>
            :
            <Flex className="user-selector-container" gap="gap.small" padding="padding.medium" wrap >
                <div className="selector-dd">
                    <Dropdown
                        items={dropDownUsers}
                        fluid
                        disabled={(props.disabled !== null && props.disabled !== undefined) ? props.disabled : false}
                        placeholder="Cerca o seleziona un utente"
                        search
                        clearable={(props.disabled !== null && props.disabled !== undefined) ? !props.disabled : true}
                        // defaultValue={months.filter((element) => { return element.key === new Date().getMonth() + 1; })[0]}
                        onChange={(event: any, data: any) => {
                            const userId: string = data.value ? data.value.key : '';
                            //cerco l'utente
                            const user = allUsers.filter((us) => {
                                return us.idUserSF === userId;
                            });
                            if (user.length > 0) {
                                const selectedUSer: ISelectedUser = {
                                    idUserSF: user[0].idUserSF,
                                    email: user[0].userName,
                                    fullName: user[0].fullName,
                                    dateOfBirth: user[0].dateOfBirth,
                                    companyCountry: user[0].companyCountry,
                                    lastName: user[0].lastName,
                                    firstName: user[0].firstName
                                };
                                props.onSelectedUserChange(selectedUSer);
                            } else {
                                props.onSelectedUserChange(null);
                            }

                        }}
                    />
                </div>
                {props.loadCompany !== null && props.loadCompany === true &&
                    <div className="selector-dd">
                        <Dropdown
                            items={dropDownCompany}
                            fluid
                            disabled={(props.disabled !== null && props.disabled !== undefined) ? props.disabled : false}
                            placeholder="Cerca o seleziona una società"
                            search
                            clearable={(props.disabled !== null && props.disabled !== undefined) ? !props.disabled : true}
                            onChange={(event: any, data: any) => {
                                const companyCode: string = data.value ? data.value.key : '';
                                setSelectedCompany(companyCode);                                
                            }}
                        />
                    </div>
                }

            </Flex>
        }
    </div>

}

export default UserSelector;