import * as Constants from '../../constants/AppConstants';
import { IActionType } from '../../interfaces/IActionType';
import { ITimesheetAction } from '../../interfaces/ITimesheetAction';

export const setTodayStatusIsLoaded = (isLoaded: boolean) => {
    return { type: Constants.SET_TODAY_STATUS_ISLOADED, isLoaded };
}

export const setTodayStatus = (todayStatus: Array<ITimesheetAction>) => {
    return { type: Constants.SET_TODAY_STATUS, todayStatus };
}

export const setIsRegistering = (isRegistering: boolean) => {
    return { type: Constants.SET_IS_REGISTERING, isRegistering };
}

export const setClockingDate = (clockingDate: Date | null) => {
    return { type: Constants.SET_CLOCKING_DATE, clockingDate };
}

export const addTimesheet = (timesheetAction: ITimesheetAction) => {
    return { type: Constants.ADD_TIMESHEET, timesheetAction }
}

export const setActionToRegister = (timesheetAction: ITimesheetAction) => {
    return { type: Constants.SET_ACTION_TO_REGISTER, timesheetAction }
}

export const setCurrentWorkingMode = (idWorkingMode: number | null) => {
    return { type: Constants.SET_CURRENT_WORKINGMODE, idWorkingMode }
}

export const setUserCanBadge = (canBadge: boolean) => {
    return { type: Constants.SET_USER_CAN_BADGE, canBadge }
}

export const setActionsFromBadge = (actions: IActionType[]) => {
    return { type: Constants.SET_ACTIONS_FROM_BADGE, actions }
}

export const setIsMobile = (isMobile: boolean) => {
    return { type: Constants.SET_IS_MOBILE, isMobile }
}
