import "../scss/NewAssistence.scss";
import React, { useEffect, useState } from "react";
import { IAssistanceActionType, IAssistanceActionTypeContent, IAssistanceGroup } from "../interfaces/INewAssistenceResponse";
import { Flex, Alert, Form, FormField, InfoIcon, ErrorIcon, FormButton, FormTextArea, FormDatepicker, Dropdown, FormDropdown, DayOfWeek, FormInput, Dialog, RadioGroup, Loader } from "@fluentui/react-northstar";
import { IAssistanceDateControls, IAssistanceDateRange, IAssistanceDates, IControlProps, IOverlappedItemsDialog, IReplaceOption, OverlappedItemsType } from "../interfaces/INewAssistance";
import { stringIsNullOrEmpty } from '../helpers/SiaHelper';
import { ControlType } from "../constants/AppEnum";
import moment from "moment";

const NewAssistanceForm = (props: any) => {
    //const content: IAssistanceActionTypeContent[] = props.action.content;
    const dateControls: IAssistanceDateControls = props.dateControls;
    const INPUT_DATETIME_CONTROL = "INPUT_DATETIME_CONTROL";
    const DATETIME_CONTROL = "DATETIME_CONTROL";
    const [controlProps, setControlProps] = useState<IControlProps | null>(null);
    const [assistanceDates, setAssistanceDates] = useState<IAssistanceDates>({ startDate: undefined, endDate: undefined });
    //Eventuale messaggio di errore che visualizza il campo con id uguale a controlId
    const [fieldErrorInfo, setFieldErrorInfo] = useState<{ message: string, controlId: string }>({ message: "", controlId: "" });
    const [requestData, setRequestData] = useState<any>(null);

    const [content, setContent] = useState<IAssistanceActionTypeContent[]>(props.action.content);

    const calcAllowedDate = (timeline: string, controlType: string, minDate?: string): IAssistanceDateRange => {
        const lowerTimeLine = timeline.toLocaleLowerCase();
        const todayDate: Date = new Date();
        const today: string | Date = controlType === INPUT_DATETIME_CONTROL ? `${todayDate.toISOString().split("T")[0]}T23:59` : todayDate;
        const tomorrowDate: Date = new Date(todayDate.setDate(todayDate.getDate() + 1));
        const tomorrow: string | Date = controlType === INPUT_DATETIME_CONTROL ? `${tomorrowDate.toISOString().split("T")[0]}T00:00` : tomorrowDate;
        const tmpMinDate: string | Date | null = minDate ? (controlType === INPUT_DATETIME_CONTROL ? `${minDate.split("T")[0]}T00:00` : new Date(minDate)) : null;
        if (stringIsNullOrEmpty(lowerTimeLine) || "ptf" === lowerTimeLine || "pf" === lowerTimeLine) {
            return {
                minDate: tmpMinDate ?? undefined,
                maxDate: undefined
            };
        }
        if ("p" === lowerTimeLine) {
            return {
                minDate: tmpMinDate ?? undefined,
                maxDate: today
            };
        }
        if ("t" === lowerTimeLine) {
            return {
                minDate: tmpMinDate ?? today,
                maxDate: today
            };
        }
        if ("f" === lowerTimeLine) {
            return {
                minDate: tomorrow,
                maxDate: undefined
            };
        }
        if ("pt" === lowerTimeLine || "tp" === lowerTimeLine) {
            return {
                minDate: tmpMinDate ?? undefined,
                maxDate: today
            };
        }
        if ("tf" === lowerTimeLine || "ft" === lowerTimeLine) {
            return {
                minDate: today,
                maxDate: undefined
            };
        }

        return {
            minDate: tmpMinDate ?? undefined,
            maxDate: undefined
        };
    }

    //Calcola i valori di default del controllo datePicker
    const calcSelectedDate = (controlId: string): Date | undefined => {
        //const result = controlId === dateControls.startDateControlId ? (assistanceDates.startDate ? assistanceDates.startDate as Date : undefined) : (assistanceDates.endDate ? assistanceDates.endDate as Date : undefined);
        let result = controlId === dateControls.startDateControlId ? (assistanceDates.startDate ? new Date(assistanceDates.startDate) : undefined) : (assistanceDates.endDate ? new Date(assistanceDates.endDate) : undefined);
        if (result === undefined) {
            result = new Date();
            let tmpAssDates: IAssistanceDates = { ...assistanceDates };
            if (controlId === dateControls.startDateControlId) {
                if (tmpAssDates.startDate === undefined) {
                    result.setHours(0);
                    result.setMinutes(0);
                    result.setSeconds(0);
                    tmpAssDates.startDate = result;
                }
            } else {
                if (tmpAssDates.endDate === undefined) {
                    result.setHours(23);
                    result.setMinutes(59);
                    result.setSeconds(0);
                    tmpAssDates.endDate = result;
                }
            }
            setAssistanceDates(tmpAssDates);

        }
        return result;

    }

    //Calcola i valori di default del controllo dateTimePicker
    const calcSelectedDateTime = (controlId: string): string | undefined => {
        let result = controlId === dateControls.startDateControlId ? (assistanceDates.startDate ? moment(assistanceDates.startDate).format("YYYY-MM-DDTHH:mm") : undefined) : (assistanceDates.endDate ? moment(assistanceDates.endDate).format("YYYY-MM-DDTHH:mm") : undefined)
        if (result === undefined) {
            let dateToSet: Date = new Date();
            dateToSet.setSeconds(0);
            result = moment(dateToSet).format("YYYY-MM-DDTHH:mm");
            let tmpAssDates: IAssistanceDates = { ...assistanceDates };
            if (controlId === dateControls.startDateControlId) {
                if (tmpAssDates.startDate === undefined) {
                    tmpAssDates.startDate = result;
                }

                //Se non è valorizzata o se deve essere calcolata (es. trasferta mezza giornata) calcolo la data fine
                if (!tmpAssDates.endDate || !dateControls.endDateControlId) {
                    const actionInfo: IAssistanceActionType = props.action;
                    //La data di fine è calcolata come startDate + (ore da contratto / 2)
                    if (actionInfo.isLockedAmount && actionInfo.hourAmount === 4) {
                        const newEndDate = moment(dateToSet).add((props.hoursPerDayAsMinutes / 2), 'minutes').format("YYYY-MM-DDTHH:mm");
                        tmpAssDates.endDate = newEndDate;
                    } else if (actionInfo.isLockedAmount && actionInfo.hourAmount === 8) {
                        //La data di fine è calcolata come startDate + ore da contratto
                        const newEndDate = moment(dateToSet).add((props.hoursPerDayAsMinutes), 'minutes').format("YYYY-MM-DDTHH:mm");
                        tmpAssDates.endDate = newEndDate;
                    } else {
                        tmpAssDates.endDate = result;
                    }
                } else {
                    //al cambio di starDate imposto sempre la data di fine uguale
                    tmpAssDates.endDate = result;
                }



            } else {
                if (tmpAssDates.endDate === undefined) {
                    tmpAssDates.endDate = result;
                }
            }
            setAssistanceDates(tmpAssDates);
        }
        return result;
    }

    const verifyRequiredFields = (): boolean => {
        if (props.action) {
            const tmpRequestData = { ...requestData };
            for (let index = 0; index < props.action.content.length; index++) {
                const element: IAssistanceActionTypeContent = props.action.content[index];
                if (element.type === ControlType.Select && element.required) {
                    if (tmpRequestData[element.id] === undefined) {
                        setFieldErrorInfo({
                            message: "Campo obbligatorio",
                            controlId: `${element.id}Id`
                        })
                        return false;
                    }
                }
            }
        }
        return true;
    }

    useEffect(() => {
        //Faccio reset per forzare il ricalcolo delle date di default dei componenti date e dateTime picker
        setAssistanceDates(({ startDate: undefined, endDate: undefined }));
        setContent(props.action.content);

    }, [props.action.content])

    useEffect(() => {
        if (assistanceDates.startDate && assistanceDates.endDate) {
            if (moment(assistanceDates.startDate) > moment(assistanceDates.endDate)) {
                setFieldErrorInfo({
                    message: "La data di inizio non può essere successiva alla data di fine",
                    controlId: dateControls.startDateControlId
                });
            } else {
                if (fieldErrorInfo.message !== "") {
                    setFieldErrorInfo({
                        message: "",
                        controlId: ""
                    });
                }
            }
        }
    }, [assistanceDates])

    useEffect(() => {
        if (controlProps) {
            let tmpRequest: any = { ...requestData } || {};
            //rimuovo il suffisso Id
            const propertyName: string = controlProps.controlId.substring(0, controlProps.controlId.length - 2);
            tmpRequest[propertyName] = controlProps.value;
            setRequestData(tmpRequest);
        }
    }, [controlProps])

    return <Form className="assistences-form"
        onSubmit={() => {
            if (verifyRequiredFields()) {
                const tmpRequestData = { ...requestData };
                tmpRequestData.startDate = assistanceDates.startDate;
                tmpRequestData.endDate = assistanceDates.endDate;
                props.formSubmitted(tmpRequestData);
                setRequestData(tmpRequestData);
            }
        }}
    >
        <Flex className="assistances-flex" column gap="gap.small">
            {content.map((element: IAssistanceActionTypeContent) => {
                const controlId: string = `${element.id}Id`;
                switch (element.type) {
                    case ControlType.DateTimePicker:
                        {
                            const dates: IAssistanceDateRange = calcAllowedDate(props.action.timeline.toLocaleLowerCase(), INPUT_DATETIME_CONTROL, element.minDate);
                            return <FormField key={element.label}>
                                <FormInput fluid name={element.label} id={controlId} type="datetime-local" required={element.required} placeholder={element.placeholder}
                                    label={element.label}
                                    errorMessage={fieldErrorInfo.controlId === controlId ? fieldErrorInfo.message : ""}
                                    onChange={(event, control) => {
                                        let tmpAssDates: IAssistanceDates = { ...assistanceDates };
                                        if (control && control.value && control.value !== '') {
                                            if (controlId === dateControls.startDateControlId) {
                                                tmpAssDates.startDate = control.value;
                                                //Se il campo è required e se non è valorizzata o se deve essere calcolata (es. trasferta mezza giornata) calcolo la data fine
                                                if (element.required) {
                                                    if (!tmpAssDates.endDate || !dateControls.endDateControlId) {
                                                        const actionInfo: IAssistanceActionType = props.action;
                                                        //La data di fine è calcolata come startDate + (ore da contratto / 2)
                                                        if (actionInfo.isLockedAmount && actionInfo.hourAmount === 4) {
                                                            const newEndDate = moment(control.value).add((props.hoursPerDayAsMinutes / 2), 'minutes').format("YYYY-MM-DDTHH:mm");
                                                            tmpAssDates.endDate = newEndDate;
                                                        } else if (actionInfo.isLockedAmount && actionInfo.hourAmount === 8) {
                                                            //La data di fine è calcolata come startDate + ore da contratto
                                                            const newEndDate = moment(control.value).add((props.hoursPerDayAsMinutes), 'minutes').format("YYYY-MM-DDTHH:mm");
                                                            tmpAssDates.endDate = newEndDate;
                                                        } else {
                                                            tmpAssDates.endDate = control.value;
                                                        }
                                                    } else {
                                                        //al cambio di starDate imposto sempre la data di fine uguale
                                                        tmpAssDates.endDate = control.value;
                                                    }
                                                }
                                            } else {
                                                //tmpAssDates.endDate = new Date(control.value);
                                                tmpAssDates.endDate = control.value;
                                            }
                                            setAssistanceDates(tmpAssDates);
                                        } else {
                                            //Reset del valore
                                            if (controlId === dateControls.startDateControlId) {
                                                tmpAssDates.startDate = undefined;
                                            } else {
                                                tmpAssDates.endDate = undefined;
                                            }
                                            setAssistanceDates(tmpAssDates);
                                        }
                                    }}
                                    value={element.required ? calcSelectedDateTime(controlId) : undefined}
                                    min={dates.minDate as string}
                                    max={dates.maxDate as string}
                                />
                            </FormField>
                        }
                    case ControlType.DatePicker:
                        {
                            const dates: IAssistanceDateRange = calcAllowedDate(props.action.timeline.toLocaleLowerCase(), DATETIME_CONTROL, element.minDate);
                            return <FormField key={element.label}>
                                <FormDatepicker
                                    // key={element.label}
                                    label={`${element.label}${element.required ? '*' : ''}`}
                                    id={controlId}
                                    required={element.required}
                                    aria-required={element.required}
                                    errorMessage={fieldErrorInfo.controlId === controlId ? fieldErrorInfo.message : ""}
                                    inputPlaceholder={element.placeholder}
                                    onDateChange={(event, control) => {
                                        if (control) {
                                            let tmpAssDates: IAssistanceDates = { ...assistanceDates };
                                            //La endDate va sempre impostata alle 23:59
                                            let eDate: Date = new Date(control.value);
                                            eDate.setHours(23);
                                            eDate.setMinutes(59);
                                            if (controlId === dateControls.startDateControlId) {
                                                tmpAssDates.startDate = control.value;
                                                //Se non è valorizzata o se deve essere uguale alla data di inizio perchè l'assistenza ha un solo campo (es. economia domestica) imposto di default la data di fine uguale alla data di inizio
                                                if (!tmpAssDates.endDate || !dateControls.endDateControlId) {

                                                    tmpAssDates.endDate = eDate;
                                                }
                                            } else {
                                                tmpAssDates.endDate = eDate;
                                            }
                                            setAssistanceDates(tmpAssDates);
                                        }
                                    }}
                                    selectedDate={element.required ? calcSelectedDate(controlId) : undefined}
                                    minDate={dates.minDate as Date}
                                    maxDate={dates.maxDate as Date}
                                    formatMonthDayYear={(date: Date, strings): string => {
                                        return moment(date).format("DD/MM/YYYY");
                                    }}
                                    firstDayOfWeek={DayOfWeek.Monday}
                                    days={['Domenica', 'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato']}
                                    shortDays={['D', 'L', 'M', 'M', 'G', 'V', 'S']}
                                    months={['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre']}
                                    nextMonthAriaLabel="Mese successivo"
                                    prevMonthAriaLabel="Mese precedente"
                                />
                            </FormField>
                        }
                    case ControlType.TextField: {
                        return <FormField key={element.label} >
                            <FormTextArea fluid label={`${element.label}${element.required ? '*' : ''}`}
                                id={controlId}
                                required={element.required}
                                errorMessage={fieldErrorInfo.controlId === controlId ? fieldErrorInfo.message : ""}
                                onChange={(event, control) => {

                                    if (control && control.value) {
                                        setControlProps({
                                            controlId: controlId,
                                            value: control.value,
                                            type: ControlType.TextField
                                        });
                                    }
                                }}
                            />
                        </FormField>
                    }
                    case ControlType.Select: {
                        let options: any[] = [];
                        if (element.options) {
                            options = element.options.map((option) => {
                                return {
                                    header: option.name,
                                    key: option.value
                                }
                            })
                        }
                        return <FormField key={element.label} >
                            <FormDropdown
                                fluid
                                id={controlId}
                                label={{
                                    content: `${element.label} ${element.required ? '*' : ''}`
                                }}
                                items={options}
                                placeholder={element.placeholder}
                                errorMessage={fieldErrorInfo.controlId === controlId ? fieldErrorInfo.message : ""}
                                onChange={(ev: any, data: any) => {
                                    //controlId === "commentId" controllo particolare per il campo commento per le assistenze di timbratura:
                                    //la key contiene sempre un numero, ma in qusto caso mi serve la descrizione
                                    setControlProps({
                                        controlId: controlId,
                                        value: controlId === "commentId" ? data.value.header : data.value.key,
                                        type: ControlType.Select
                                    });
                                }}
                            />

                        </FormField>
                    }
                    default:
                        break;
                }
            })}
        </Flex>
        <Flex className="assistances-flex" hAlign="end">
            <FormButton primary content="SALVA" />
        </Flex>

    </Form>
}

export default NewAssistanceForm;