import React, { useEffect, useState } from "react";
import { IActionType } from "../interfaces/IActionType";
import { Tooltip, Flex, Text } from '@fluentui/react-northstar';
import { TextEffects20Regular } from '@fluentui/react-icons';
import { IAssistance } from "../interfaces/IAssistance";
import moment from 'moment';

export interface IMonthlyAssistances {
    date: Date;
    actions: IActionType[];
    assistances: IAssistance[];
}

const MonthlyAssistances = (props: IMonthlyAssistances) => {
    const [tooltipMessage, setTooltipMessage] = useState("");
    const [assistancesOfTheDay, setAssistancesOfTheDay] = useState<IAssistance[]>([]);

    useEffect(() => {
        let ass: IAssistance[] = [] as Array<IAssistance>;
        props.assistances.forEach((a: IAssistance, idx: number) => {
            if (a.startDate !== null && a.endDate !== null) {
                if (moment(props.date).format("YYYYMMDD") >= moment(a.startDate).format("YYYYMMDD") && moment(props.date).format("YYYYMMDD") <= moment(a.endDate).format("YYYYMMDD"))
                    ass.push(a)
            }
            else if (a.startDate === null && a.endDate !== null) {
                if (moment(props.date).format("YYYYMMDD") === moment(a.endDate).format("YYYYMMDD"))
                    ass.push(a)
            }
            else if (a.startDate !== null && a.endDate === null) {
                if (moment(props.date).format("YYYYMMDD") === moment(a.startDate).format("YYYYMMDD"))
                    ass.push(a)
            }
        });
        setAssistancesOfTheDay(ass);
    }, [])

    useEffect(() => {
        if (assistancesOfTheDay.length > 0) {
            //Trovo la descrizione delle assistenze
            let message: string = "";
            assistancesOfTheDay.forEach((as: IAssistance) => {
                const description = props.actions.filter(element => { return element.id === as.idActionType });
                message += description.length > 0 ? `${description[0].title} \r\n` : `assistenza sconosciuta [${as.idActionType}] \r\n`;
            })
            setTooltipMessage(message);
        }
    }, [assistancesOfTheDay])


    // return assistancesOfTheDay.length > 0 ?<Flex fill hAlign="center" vAlign="center"> <Tooltip trigger={<TextEffects20Regular color="red" style={{ color: "red" }} />} content={tooltipMessage} /></Flex> : <div></div>
    return assistancesOfTheDay.length > 0 ?
        <Flex fill hAlign="center" vAlign="center">
            <Tooltip trigger={<Text content="A" error size="medium" important  />} content={tooltipMessage} />
        </Flex>
        : <div></div>

}

export default MonthlyAssistances;