import { ITimesheet } from '../interfaces/ITimesheet';
import { ITimesheetAction } from '../interfaces/ITimesheetAction';
import * as cookieHelper from '../helpers/CookiesHelper';
import moment from 'moment';

// Metodo che data in risposta un EmpJob selezionato
export const selectCurrentEmpJob = (jobIndex: number = 0) => {
    cookieHelper.setApplicationCookies.setSelectedJobCookie(jobIndex);
    return jobIndex;
}
//Pulisce le date che vengono fornite come /Date(281923200000)/
export const purifyDate = (dateString: string): Date | null => {
    if (dateString === null)
        return null;
    let timeStamp = parseInt(dateString.replace("/Date(", "").replace(")/", ""));
    var newDate = new Date(timeStamp);
    //console.log(newDate)
    return (newDate).getTime() > 0 ? newDate : null;
}
//Converte le stringhe Yes/No in bool
export const purifyBool = (str: string | null): boolean | null => {
    switch (str) {
        case 'Yes':
            return true;
        case 'No':
            return false;
        default:
            return null;
    }
}
export const purifyLocation = (str: string | null): string => {

    return str === null ? '' : str.replace(/ *\([^)]*\) */g, "");
}

export const mapSsfTimesheetsToTimesheets = (ssfTimeshets: Array<any>): Array<ITimesheet> => {
    let result: ITimesheet[] = [];
    ssfTimeshets.forEach(item => {
        result.push(mapTimesheet(item));
    });
    return result;
}

export const mapTimesheet = (day: any): ITimesheet => {
    if (day === undefined || day === null)
        return {} as ITimesheet;
    let ts: ITimesheet = {
        day: new Date(day.day),
        actualLunch: moment.duration(day.actualLunch),
        actualWork: moment.duration(day.actualWork),
        expectedLunch: moment.duration(day.expectedLunch),
        expectedWork: moment.duration(day.expectedWork),
        justifications: moment.duration(day.justifications),
        overtime: day.overtime,
        ticketRestaurant: day.ticketRestaurant,
        timesheet: []
    };

    for (let index = 0; index < day.timesheet.length; index++) {
        const element = day.timesheet[index];
        let tsa: ITimesheetAction = {
            id: element.id,
            idActionType: element.idActionType,
            idWorkingMode: element.idWorkingMode,
            comment: element.comment,
            startDate: element.startDate ? new Date(element.startDate) : null,
            endDate: element.endDate ? new Date(element.endDate) : null,
            header: element.headers
        };
        ts.timesheet.push(tsa);
    }
    return ts;
}