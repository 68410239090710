import React from 'react';
import { useEffect, useState } from "react";
import { Flex, Popup, Text } from "@fluentui/react-northstar";
import { ITimesheetAction } from "../interfaces/ITimesheetAction";
import MonthlyBreakStartEndTime from "./MonthlyBreakStartEndTime";
import { DrinkCoffee20Regular, Food20Regular } from '@fluentui/react-icons';

interface IMonthlyBreakProps {
    editable: boolean;
    activities: ITimesheetAction[];
    idBreakAction: number;
    onActiviyChange: any;
    breakType: string;
}

const MonthlyBreak = (props: IMonthlyBreakProps) => {
    const [breakActivity, setBreakActivity] = useState<ITimesheetAction | null>(null);
    const [breakActivities, setBreakActivities] = useState<ITimesheetAction[]>([]);

    useEffect(() => {
        const tmpBreakActivities = props.activities.filter((action) => {
            return action.idActionType === props.idBreakAction;
        });
        setBreakActivities(tmpBreakActivities);
        if (tmpBreakActivities.length > 0) {
            setBreakActivity(tmpBreakActivities[0]);
        }
    }, [])

    //Aggiorno gli orari visualizzati con i nuovi valori
    const updateBreakActivity = (activity: ITimesheetAction) => {
        if (activity !== null) {
            setBreakActivity((prevBreakActivity) => {
                return prevBreakActivity !== null ? { ...prevBreakActivity, startDate: activity.startDate, endDate: activity.endDate } : null;
            })
        }
    }

    //Quando viene chiuso il dialog faccio refresh dell'intera riga
    const refreshData = (day: Date) => {
        props.onActiviyChange(day);
    }

    return <Flex hAlign="center" gap="gap.small" key={`breakAction_${props.idBreakAction}`} >
        {breakActivities !== null && breakActivities.length > 0 &&
            <>
                {breakActivities.map(ba => {
                    return props.breakType === "pausa" ?
                        <React.Fragment key={`MonthlyBreak_${ba.id}`}>
                            {breakActivities.length === 1 ?
                                <Flex gap="gap.smaller" key={`pausa_content_${ba.id}`}>
                                    <DrinkCoffee20Regular />
                                    <MonthlyBreakStartEndTime type="start" editable={props.editable} showEditIcon={false} action={ba} idBreakAction={props.idBreakAction} breakType={props.breakType} onTimesheetUpdated={updateBreakActivity} onDialogClose={refreshData} />
                                    <MonthlyBreakStartEndTime type="end" editable={props.editable} showEditIcon={false} action={ba} idBreakAction={props.idBreakAction} breakType={props.breakType} onTimesheetUpdated={updateBreakActivity} onDialogClose={refreshData} />
                                </Flex>
                                :
                                <Popup key={`popup_${ba.id}`} trigger={<DrinkCoffee20Regular key={`break_${ba.id}`} style={{ cursor: "pointer" }} />} content={
                                    <Flex column gap="gap.small" key={`break_content_${ba.id}`}>
                                        <Flex gap="gap.small" space="between" hAlign="center">
                                            <Text content="Inizio :" />
                                            <MonthlyBreakStartEndTime type="start" editable={props.editable} showEditIcon={true} action={ba} idBreakAction={props.idBreakAction} breakType={props.breakType} onTimesheetUpdated={updateBreakActivity} onDialogClose={refreshData} />

                                        </Flex>
                                        <Flex gap="gap.small" space="between" hAlign="center">
                                            <Text content="Fine :" />
                                            <MonthlyBreakStartEndTime type="end" editable={props.editable} showEditIcon={true} action={ba} idBreakAction={props.idBreakAction} breakType={props.breakType} onTimesheetUpdated={updateBreakActivity} onDialogClose={refreshData} />

                                        </Flex>
                                    </Flex>
                                } />
                            }
                        </React.Fragment>
                        :
                        <Flex gap="gap.smaller" key={`pranzo_content_${ba.id}`}>
                            <Food20Regular style={{ marginRight: "0px" }} />
                            <MonthlyBreakStartEndTime type="start" editable={props.editable} showEditIcon={false} action={ba} idBreakAction={props.idBreakAction} breakType={props.breakType} onTimesheetUpdated={updateBreakActivity} onDialogClose={refreshData} />
                            <MonthlyBreakStartEndTime type="end" editable={props.editable} showEditIcon={false} action={ba} idBreakAction={props.idBreakAction} breakType={props.breakType} onTimesheetUpdated={updateBreakActivity} onDialogClose={refreshData} />
                        </Flex>
                })}
            </>
        }

        {/* {breakActivity !== null &&
            <Flex gap="gap.smaller">
                {props.breakType === "pausa" ? <DrinkCoffee20Regular /> : <Food20Regular />}
                <MonthlyBreakStartEndTime type="start" editable={props.editable} action={breakActivity} idBreakAction={props.idBreakAction} breakType={props.breakType} onTimesheetUpdated={updateBreakActivity} onDialogClose={refreshData} />
                <MonthlyBreakStartEndTime type="end" editable={props.editable} action={breakActivity} idBreakAction={props.idBreakAction} breakType={props.breakType} onTimesheetUpdated={updateBreakActivity} onDialogClose={refreshData} />
            </Flex>
        } */}
    </Flex>
}

export default MonthlyBreak;