import "../scss/NewAssistence.scss";
import React, { useEffect, useState } from "react";
import { IAssistanceActionType, IAssistanceActionTypeContent, IAssistanceGroup } from "../interfaces/INewAssistenceResponse";
import { Flex, Alert, ErrorIcon, Dropdown, FormDropdown, DayOfWeek, Dialog, RadioGroup, Loader } from "@fluentui/react-northstar";
import { ControlType } from "../constants/AppEnum";
import * as cookieHelper from '../helpers/CookiesHelper';
import moment from "moment";
import * as appActions from '../stores/actions/AppActions';
import { SiaStrings } from "../strings/strings";
import { ISelectedUser } from "../interfaces/ISelectedUser";
import { verifyAssistance, addAssistance } from '../helpers/Fetch';
import { IAppState } from "../interfaces/IAppState";
import { useSelector, useDispatch } from "react-redux";
import { IActionType } from "../interfaces/IActionType";
import { INotification } from "../interfaces/INotification";
import NewAssistanceForm from "./NewAssistanceForm";
import { IAssistanceDateControls, IOverlappedItemsDialog, IReplaceOption, OverlappedItemsType } from "../interfaces/INewAssistance";

const NewAssistenceTabContent = ({ props, selUser, refreshData, hoursPerDayAsMinutes }: any) => {
    const { group } = props;
    const actions: IAssistanceActionType[] = (group as IAssistanceGroup).actionTypes;
    const appState: IAppState = useSelector((appState) => appState) as IAppState;
    //const INPUT_DATETIME_CONTROL = "INPUT_DATETIME_CONTROL";
    //const DATETIME_CONTROL = "DATETIME_CONTROL";
    const ACTION_GROUP_PRANZO = "PRANZO";
    const [selectedAssistance, setSelectedAssistance] = useState<IAssistanceActionType | null>(null);
    const [dateControls, setDateControls] = useState<IAssistanceDateControls>({ startDateControlId: "", endDateControlId: "" });
    //const [assistanceDates, setAssistanceDates] = useState<IAssistanceDates>({ startDate: undefined, endDate: undefined });
    //const [controlProps, setControlProps] = useState<IControlProps | null>(null);
    //const [messageError, setMessageError] = useState("");
    //const [noAssistances, setNoAssistances] = useState(false);
    const [overlappedItemsDialog, setOverlappedItemsDialog] = useState<IOverlappedItemsDialog | null>(null);
    const [replaceItemsDialog, setReplaceItemsDialog] = useState<IReplaceOption[]>([]);
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [showSaveInPrgressDialog, setShowSaveInPrgressDialog] = useState(false);
    const [loading, setLoading] = useState(false);
    const [saveError, setSaveError] = useState("");
    const [dialogTitle, setDialogTitle] = useState("Assistenze - Conferma");
    const [selectedRadioItem, setSelectedRadioItem] = useState<number | undefined>(undefined);
    //const [requiredFieldMessage, setRequiredFieldMessage] = useState("");
    //const [tabControls, setTabControls] = useState<any[]>([]);
    const [requestData, setRequestData] = useState<any>({
        idActionType: 0,
        startDate: "",
        endDate: "",
        referenceNumber: "",
        //idUserSF: (selUser as ISelectedUser).idUserSF
        //l'idUser è valorizzato solo quando l'utente selezionato nella drop down è diverso dall'utente loggato (caso di HR che inserisce assistenze per conto di altri utenti)
        idUserSF: (selUser && (selUser as ISelectedUser).idUserSF !== appState.idUserSF) ? (selUser as ISelectedUser).idUserSF : null
    });
    const dispatch = useDispatch();

    const createHeader = (): any => {
        if (actions.length === 1) {
            return <Flex column gap="gap.small">
                <Flex gap="gap.small">
                    <div className="assistances-titles noselect">Assistenza : {actions[0].label}</div>

                </Flex>
            </Flex>
        } else {
            const ddItems: any[] = actions.map((action: IAssistanceActionType) => {
                return { key: action.id, header: action.label };
            });
            return <Flex column gap="gap.small">
                <Flex gap="gap.small">
                    <div className="assistances-titles noselect">Assistenza</div>
                    <Dropdown
                        items={ddItems}
                        placeholder="Seleziona l'assistenza"
                        onChange={(event: any, data: any) => {
                            const assistenceId: number = data.value ? data.value.key as number : 0;
                            const selAss: IAssistanceActionType = actions.filter((ac: IAssistanceActionType) => {
                                return ac.id === assistenceId;
                            })[0];
                            setSelectedAssistance(selAss);
                        }}
                    />
                </Flex>
            </Flex>
        }
    }

    const timelineType = (isLockedAmount?: boolean, dayAmount?: number | null, hoursAmount?: number | null): string => {
        let timex: string = "";
        if (dayAmount && dayAmount > 0 && hoursAmount && hoursAmount > 0) {
            // L'utente sul BOT dovrà selezionare un range di giorni e di orari
            return "datetimerange";
        }
        else if (dayAmount && dayAmount === 1) {
            // L'utente sul BOT deve selezionare un giorno singolo senza orario (giorata intera)
            // Quindi in questo caso ci sarà una data con due orari
            return "daterange";
        }
        else if (dayAmount && dayAmount > 1 && !isLockedAmount) {
            // L'utente sul BOT deve selezionare un range di giorni senza orario (giorata intera)
            // Quindi in questo caso ci sarà una data con due orari
            return "daterange";
        }
        else if (dayAmount && dayAmount > 1 && isLockedAmount) {
            // L'utente sul BOT deve selezionare un giorno singolo di partenza senza orario (giorata intera)
            // Quindi in questo caso ci sarà una data con due orari
            return "daterange";
        }
        else if (!dayAmount || (dayAmount && dayAmount === 0)) {
            // Gestione delle ore
            if (hoursAmount && hoursAmount !== 0 && !isLockedAmount) {
                // L'utente sul BOT deve selezionare un singolo giorno e gli orari di inizio e fine
                return "datetimerange";
            }
            else if (hoursAmount && hoursAmount !== 0 && isLockedAmount) {
                // L'utente sul BOT deve selezionare un singolo giorno e l'orario di inizio
                // Quindi in questo caso ci sarà una data con due orari
                return "datetimerange";
            }
        }

        return timex;
    }

    const onFormSubmitted = (data: any) => {
        const tmpRequestData = { ...requestData };

        Object.keys(data).forEach((prop: any) => {
            //Se la proprietà è una data la devo formattare nel modo corretto
            let value = data[prop];
            if (value !== undefined && (value instanceof Date || (prop === "startDate" || prop === "endDate"))) {
                value = moment(value).format();
            }
            tmpRequestData[prop] = value;
        });
        debugger;
        if (selectedAssistance?.dayAmount === 1 && selectedAssistance.isLockedAmount === true) {
            if (tmpRequestData.endDate === undefined || tmpRequestData.endDate === null) {
                tmpRequestData.endDate = moment(tmpRequestData.startDate).set({ hour: 23, minute: 59, second: 59, millisecond: 999 }).format();
            }
        }
        setRequestData(tmpRequestData);
        setShowConfirmDialog(true);
    }

    useEffect(() => {
        if (actions.length === 1) {
            setSelectedAssistance(actions[0]);
            // if (userType === UserType.HR && !actions[0].hr) {
            //     setNoAssistances(true);
            // } else {
            //     setSelectedAssistance(actions[0]);
            // }
        }
    }, [])

    useEffect(() => {
        if (selectedAssistance) {
            setRequestData((request: any) => ({ ...request, idActionType: selectedAssistance.id }));
            //Trovo quali sono i controlli di data inizio e fine
            let tmpDateControls: IAssistanceDateControls = { ...dateControls };
            let startDateFind: boolean = false;
            selectedAssistance.content.forEach((element: IAssistanceActionTypeContent) => {
                const controlId: string = `${element.id}Id`;
                switch (element.type) {
                    case ControlType.DateTimePicker:
                    case ControlType.DatePicker: {
                        if (!startDateFind) {
                            startDateFind = true;
                            tmpDateControls.startDateControlId = controlId;
                        } else {
                            tmpDateControls.endDateControlId = controlId;
                        }
                        break;
                    }
                    default:
                        break;
                }
                setDateControls(tmpDateControls);
            });
        }
    }, [selectedAssistance])

    const saveConfirmed = async () => {
        console.log("saveConfirmed");
        if (saveError !== "") {
            console.log("saveConfirmed 1");
            reset();
        } else if (replaceItemsDialog.length === 0 && (!overlappedItemsDialog || overlappedItemsDialog?.messages.length === 0)) {
            //Parte la procedura di salvataggio
            console.log("saveConfirmed 2");
            await saveConfirm();
        } else if (replaceItemsDialog.length > 0) {
            //Ho visualizzato gli elementi che si sovrappongono e che posso selezionare => salvo l'elemento selezionato     
            console.log("saveConfirmed 3");
            setLoading(true);
            await saveAssistance({ ...requestData });
        } else if (overlappedItemsDialog && overlappedItemsDialog?.messages.length > 0) {
            //Ho visualizzato gli elementi che si sovrappongono, ma il salvataggio non può essere fatto
            console.log("saveConfirmed 4");
            reset();
        }
    }

    useEffect(() => {
        console.log(`loading = ${loading}`);
    },[loading])

    useEffect(() => {
        console.log(`useEffect showSaveInPrgressDialog : ${showSaveInPrgressDialog}`);
        if (showSaveInPrgressDialog === true) {
            saveConfirmed();
        }
    }, [showSaveInPrgressDialog])

    useEffect(() => {
        console.log(`useEffect showConfirmDialog : ${showConfirmDialog} - loading : ${loading}`);
        if (showConfirmDialog === true) {
            //quando rendo visibile il dialog dopo la conferma di salvataggio inizio ad impostare loading = true così sono sicuro che i pulsanti OK/annulla di quest'utlimo siano inizialemente disabilitati. 
            // Impostando loading=true all'apertura del secondo dialog sembra che il cambio stato avvenga "in ritardo" in alcuni casi e questo causa il problema che l'utente può cliccare due volte 
            //su OK (perchè abilitato) e quindi inserire una doppia assitenza
            if (!loading) {                
                setLoading(true);
            }
        }
    }, [showConfirmDialog])

    const buildMessage = (timex: string, sd: Date, ed: Date): string => {
        const mSD = moment(sd);
        const mED = moment(ed);
        let message: string = "";
        switch (timex) {
            case "date": {
                message += mSD.isValid() ? `il giorno ${mSD.format('DD/MM/YYYY')}` : 'Data inizio non valida';
                break;
            }
            case "time":
                {
                    message += mSD.isValid() ? `alle ore ${mSD.format('HH:mm')}` : 'Data inizio non valida';
                    break;
                }
            case "datetime":
                {
                    message += mSD.isValid() ? `il giorno ${mSD.format('DD/MM/YYYY')} alle ore ${mSD.format('HH:mm')}` : 'Data inizio non valida';
                    break;
                }
            case "daterange":
                {
                    message += mSD.isValid() ? `dal giorno ${mSD.format('DD/MM/YYYY')} ` : 'Data inizio non valida';
                    message += mED.isValid() ? ` al giorno ${mED.format('DD/MM/YYYY')}` : 'al giorno --';
                    break;
                }

            case "timerange":
                {
                    message += mSD.isValid() ? `dalle ore ${mSD.format('HH:mm')} ` : 'Data inizio non valida';
                    message += mED.isValid() ? `alle ore ${mED.format('HH:mm')}` : 'alle ore --';
                    break;
                }
            case "datetimerange":
                if (!mSD.isValid()) {
                    return "Data inizio non valida";
                }

                var isSingleDay = mED.isValid() ? mSD.format('DD/MM/YYYY') === mED.format('DD/MM/YYYY') : false;
                if (isSingleDay) {
                    message += `il giorno ${moment(sd).format('DD/MM/YYYY')} dalle ore ${moment(sd).format('HH:mm')} alle ore `;
                    message += mED.isValid() ? `${mED.format('HH:mm')}` : '--';
                }
                else {
                    message += `dal giorno ${mSD.format('DD/MM/YYYY')} alle ore ${mSD.format('HH:mm')} al giorno `;
                    message += mED.isValid() ? `${mED.format('DD/MM/YYYY')} alle ore ${mED.format('HH:mm')}` : '-- alle ore --'
                }
                break;
        }
        return message;
    }

    const buildOverlappedTimesheetDialog = async (response: any, itemsType: OverlappedItemsType) => {
        let tmpOverlappedItemsDialog: IOverlappedItemsDialog = { title: "", messages: [] };
        let defaultTile: string;
        setDialogTitle("Assistenze - Salvataggio annullato");
        switch (itemsType) {
            case OverlappedItemsType.Timesheet: {
                defaultTile = "Azioni già presenti a sistema :";
                break;
            }
            case OverlappedItemsType.Assistances: {
                defaultTile = "Assistenze già presenti a sistema :";
                break;
            }
            default: {
                defaultTile = "Azioni e assistenze già presenti a sistema : ";
                break;
            }
        }
        tmpOverlappedItemsDialog.title = (response.message && response.message !== "") ? response.message : defaultTile;
        tmpOverlappedItemsDialog.messages = [];
        const actions: Array<IActionType> = await cookieHelper.getApplicationCookies.getActionTypesCookie();

        if (itemsType === OverlappedItemsType.Timesheet || itemsType === OverlappedItemsType.All) {
            for (let index = 0; index < response.timesheets.length; index++) {
                const timesheet = response.timesheets[index];
                const action: IActionType = actions.filter((a) => { return a.id === timesheet.idActionType })[0];
                const timex: string = timelineType(action.isLockedAmount, action.daysAmount, action.hoursAmount);
                let message: string = `Azione di ${action.title} `;
                message += buildMessage(timex, moment(timesheet.startDate).toDate(), moment(timesheet.endDate).toDate());
                tmpOverlappedItemsDialog.messages.push(message);
            }
        }

        if (itemsType === OverlappedItemsType.Assistances || itemsType === OverlappedItemsType.All) {
            for (let index = 0; index < response.assistances.length; index++) {
                const assistance = response.assistances[index];

                const action: IActionType = actions.filter((a) => { return a.id === assistance.idActionType })[0];
                const timex: string = timelineType(action.isLockedAmount, action.daysAmount, action.hoursAmount);
                let message: string = `Assistenza di ${action.title} `;
                message += buildMessage(timex, moment(assistance.startDate).toDate(), moment(assistance.endDate).toDate());
                tmpOverlappedItemsDialog.messages.push(message);
            }
        }
        setOverlappedItemsDialog(tmpOverlappedItemsDialog);
    }

    const buildReplaceItemsDialog = async (items: any[]) => {
        setDialogTitle("Assistenze - Sovrascrivi azione ");
        let replaceItems: IReplaceOption[] = [];
        const actions: Array<IActionType> = await cookieHelper.getApplicationCookies.getActionTypesCookie();
        for (let index = 0; index < items.length; index++) {
            const timesheet = items[index];
            const action: IActionType = actions.filter((a) => { return a.id === timesheet.idActionType })[0];
            const timex: string = timelineType(action.isLockedAmount, action.daysAmount, action.hoursAmount);
            let label: string = buildMessage(timex, moment(timesheet.startDate).toDate(), moment(timesheet.endDate).toDate());
            const newItem: IReplaceOption = {
                key: timesheet.id,
                label: `${selectedAssistance?.label} ${label}`,
                name: "ReplaceOptions",
                value: timesheet.id
            }
            replaceItems.push(newItem);
        }

        // replaceItems.push({
        //     key: "new_item_id",
        //     label: `Nuova assistenza`,
        //     name: "ReplaceOptions",
        //     value: "newItem"
        // });

        setReplaceItemsDialog(replaceItems);
    }

    const handleVerifyResponse = async (response: any) => {
        try {
            if (selectedAssistance?.isFromBadge) {
                if (response.assistances && response.assistances.length > 0) {
                    await buildOverlappedTimesheetDialog(response, OverlappedItemsType.Assistances);
                    setLoading(false);
                    return;
                }
                //Mostro card con tutte le azioni di tipologia diversa, che si sovrappongono
                const differentActions: any[] = response.timesheets.filter((t: any) => {
                    //const result: boolean = moment(t.startDate) <= moment((assistanceDates.endDate as Date)) && moment((assistanceDates.startDate as Date)) <= moment(t.endDate) && t.idActionType !== selectedAssistance.id

                    const reqEndDate: Date = requestData.endDate ? moment(requestData.endDate).toDate() : moment(t.startDate).set({ hour: 23, minute: 59, second: 59, millisecond: 999 }).toDate();
                    const reqStartDate: Date = requestData.startDate ? moment(requestData.startDate).toDate() : moment(t.startDate).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate();
                    let result: boolean = false;
                    if (t.endDate !== null) {
                        result = moment(t.startDate) <= moment(reqEndDate) && moment(reqStartDate) <= moment(t.endDate) && t.idActionType !== selectedAssistance.id;
                    } else {
                        result = moment(t.startDate) <= moment(reqEndDate) && t.idActionType !== selectedAssistance.id;
                    }
                    return result;
                });

                if (differentActions.length > 0) {
                    response.timesheets = [...differentActions];
                    await buildOverlappedTimesheetDialog(response, OverlappedItemsType.Timesheet);
                    setLoading(false);
                    return;
                }

                // Se l'assistenza è 'Timbratura' o 'Pausa Pranzo' o 'Pausetta', prendere i timesheet per il giorno selezionato.
                // Prendo l'id e la working mode relativi al timesheet
                const actions: any[] = response.timesheets.filter((t: any) => {
                    const tSD_Date = moment(t.startDate).hours(0).minutes(0).format("DD/MM/YYYY");
                    //const assSD_Date = moment((assistanceDates.startDate as Date)).hours(0).minutes(0).format("DD/MM/YYYY");

                    const reqStartDate: Date = requestData.startDate ? moment(requestData.startDate).toDate() : moment(t.startDate).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate();

                    const assSD_Date = moment(reqStartDate).hours(0).minutes(0).format("DD/MM/YYYY");
                    return tSD_Date === assSD_Date && t.idActionType === selectedAssistance.id
                });

                if (actions.length > 0 && ACTION_GROUP_PRANZO !== group.label) {
                    // Se abbiamo almeno un action di 'Timbratura' o 'Pausetta' chiedo all'utente
                    // se vuole sostituire l'action oppure se vuole crearne una nuova                           
                    await buildReplaceItemsDialog(actions);
                    setLoading(false);
                }

                if (actions.length > 0 && ACTION_GROUP_PRANZO === group.label) {
                    // Se abbiamo almeno un action di 'Pausa Pranzo' salvo l'id e la working mode
                    let tmpRequest: any = { ...requestData }
                    tmpRequest.idTimesheet = actions[0].id;
                    tmpRequest.idWorkingMode = actions[0].idWorkingMode;
                    saveAssistance(tmpRequest);
                }
            } else {
                // Mostro card di tutte le assistenze e/o azioni che si sovrappongono a quella che l'utente sta cercando di creare.
                await buildOverlappedTimesheetDialog(response, OverlappedItemsType.All);
                setLoading(false);
            }
        } catch (error) {
            console.log(`Errore in handleVerifyResponse : ${error}`);
        } 
    }

    const saveAssistance = async (saveData: any) => {       
        console.log("saveAssistance - chiamo addAssistance");
        const saveResponse = await addAssistance(saveData);
        if (saveResponse.status === 1) {
            console.log("addAssistance OK - chiamo reset");
            dispatch(appActions.setNotifications([{ type: "success", message: `${SiaStrings.ASSISTANCE_SAVED}` } as INotification]));
            reset();
            //Forzo la selzione dell'utente nel componente padre per fare un refresh dell'intero contenuto del TAB            
            refreshData();
        } else {
            setSaveError(saveResponse.message ?? "Si è verificato un errore nel salvataggio dell'assistenza");
            setLoading(false);
            return;
        }
    }

    const saveConfirm = async () => {
        console.log("saveConfirm");
        try {
            setReplaceItemsDialog([]);
            setOverlappedItemsDialog(null);            
            setLoading(true);
            setSaveError("");
            //const isValid: boolean | undefined = validateInput();
            if (requestData) {
                console.log("chiamo verifyAssistance");
                const verifyResponse = await verifyAssistance(requestData);
                if (verifyResponse.status === 1) {
                    console.log("verifyResponse.status === 1");
                    if (verifyResponse.payload.error) {
                        if (verifyResponse.payload.message !== null && verifyResponse.payload.message !== "") {
                            setSaveError(verifyResponse.payload.message as string);
                            setLoading(false);
                            return;
                        }
                        console.log("chiamo handleVerifyResponse");
                        await handleVerifyResponse(verifyResponse.payload);
                    } else {
                        //salvo
                        console.log("saveConfirm - chiamo saveAssistance");
                        await saveAssistance(requestData);
                    }
                } else {
                    setSaveError("Si è verificato un errore nella verifica dell'assistenza");
                    setLoading(false);
                }
            }
        } catch (error) {
            console.log(`errore in saveAssistance : ${error}`);
            setSaveError("Si è verificato un errore durante l'inserimento dell'assistenza");
            setLoading(false);
        }
    }

    const reset = () => {
        console.log("reset");
        //setShowConfirmDialog(false);
        setShowSaveInPrgressDialog(false);        
        setSaveError("");
        setDialogTitle("Assistenze - Conferma");
        //setRequiredFieldMessage("");
        setReplaceItemsDialog([]);
        setOverlappedItemsDialog(null);
        setSelectedRadioItem(undefined);
        setLoading(false);
    }


    // if (noAssistances) {
    //     return <Flex column gap="gap.large">
    //         <Alert info icon={<InfoIcon />} content="Non è possibile inserire assistenze per questa tipologia " />
    //     </Flex>
    // }

    return (
        <Flex column gap="gap.small">
            {createHeader()}
            {
                (selectedAssistance && dateControls.startDateControlId !== "") &&
                <NewAssistanceForm action={selectedAssistance} dateControls={dateControls} formSubmitted={onFormSubmitted} hoursPerDayAsMinutes={hoursPerDayAsMinutes} />
            }
            {/* Dialog per la conferma dell'operazione di salvataggio */}
            <Dialog
                open={showConfirmDialog}
                closeOnOutsideClick={false}
                style={{ maxWidth: "500px" }}
                confirmButton={{ content: "OK" }}
                cancelButton={{ content: "Annulla" }}
                header={dialogTitle}
                onConfirm={() => {
                    setShowConfirmDialog(false);
                    setShowSaveInPrgressDialog(true);
                }}
                onCancel={() => { setShowConfirmDialog(false); }}
                content={<Flex column gap="gap.medium">
                    <span>Procedere con l'inserimento della nuova assistenza?</span>
                </Flex>
                }
            />
            <Dialog
                open={showSaveInPrgressDialog}
                closeOnOutsideClick={false}
                style={{ maxWidth: "500px" }}
                confirmButton={{ content: "OK", disabled: loading || (replaceItemsDialog && replaceItemsDialog.length > 0 && selectedRadioItem === undefined) }}
                cancelButton={{ content: "Annulla", disabled: loading }}
                //cancelButton="Annulla"
                //confirmButton="OK"
                content={
                    <Flex column gap="gap.medium">
                        {saveError !== "" &&
                            <Alert danger icon={<ErrorIcon />} content={saveError} />
                        }
                        {/* 
                        {                            
                            (replaceItemsDialog.length === 0 && (!overlappedItemsDialog || overlappedItemsDialog?.messages.length === 0) && saveError === "") &&
                            <span>Procedere con l'inserimento della nuova assistenza?</span>
                        }
                         */}
                        {loading &&
                            <Loader size="small" style={{ paddingTop: '20px' }} styles={{ textAlign: "center" }} label="Salvataggio in corso..." />
                        }
                        {(overlappedItemsDialog && overlappedItemsDialog.messages.length > 0) &&
                            <Flex column gap="gap.medium">
                                <span>{overlappedItemsDialog.title}</span>
                                <Flex column gap="gap.small">
                                    {overlappedItemsDialog.messages.map((message: string) => {
                                        return <span>{message}</span>
                                    })}
                                </Flex>
                            </Flex>
                        }
                        {(replaceItemsDialog && replaceItemsDialog.length > 0) &&
                            <Flex column gap="gap.medium">
                                <span>Selezionare l'azione che si vuole sostituire</span>
                                <RadioGroup
                                    vertical
                                    items={replaceItemsDialog}
                                    checkedValue={selectedRadioItem}
                                    onCheckedValueChange={(e, props) => {
                                        if (props && props.value) {
                                            let tmpRequest: any = { ...requestData };
                                            tmpRequest.idTimesheet = props.value;
                                            setRequestData({ ...tmpRequest });
                                            setSelectedRadioItem(tmpRequest.idTimesheet)
                                        }
                                    }}
                                />
                            </Flex>
                        }
                    </Flex>
                }
                onConfirm={async () => {
                    await saveConfirmed();
                }}
                onCancel={() => {
                    reset();
                }}
                header={"Assistenza confermata"}
            />
        </Flex>

    );
}

export default NewAssistenceTabContent;