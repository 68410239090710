import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { IAppState } from "../interfaces/IAppState";
import * as CookiesHelper from '../helpers/CookiesHelper';
import { IOrgChartUser } from "../interfaces/IOrgChartUser";
import { IActionType } from "../interfaces/IActionType";
import * as Constants from '../constants/AppConstants';
import * as siaActions from '../stores/actions/SiaActions';
import * as ssfActions from '../stores/actions/SsfActions';
import * as appActions from '../stores/actions/AppActions';
import { LoadCommonDataStaus, UserType } from "../constants/AppEnum";
import { IWorkingMode } from "../interfaces/IWorkingMode";

const useLoadCommonData = () => {
    const appState: IAppState = useSelector((appState) => appState) as IAppState;
    const dispatch = useDispatch();
    const [status, setStatus] = useState<LoadCommonDataStaus>(LoadCommonDataStaus.Loading);


    useEffect(() => {
        (async () => {
            try {
                /* SET COOKIES WITH COMMON SHARED VALUES */
                let email: string = appState.debug ? appState.debugUser : appState.teamsContext.userPrincipalName?.toLowerCase() as string;
                let initTasks: Promise<any>[] = [];
                const currentUser: string = appState.debug ? appState.debugUser : appState.teamsContext.userPrincipalName?.toLowerCase() ?? 'unknown';
                //initTasks.push(CookiesHelper.setApplicationCookies.setActionTypesCookie());
                initTasks.push(CookiesHelper.setApplicationCookies.setOrgChartCookie(currentUser));
                initTasks.push(CookiesHelper.setApplicationCookies.setWorkingModesCookie());
                initTasks.push(CookiesHelper.setApplicationCookies.setUserContractsCookie(email));

                let userOrgchart: IOrgChartUser = {} as IOrgChartUser;
                const initTasksResults = await Promise.all(initTasks);
              
                userOrgchart = initTasksResults[0];
                dispatch(ssfActions.setIdUserSf(userOrgchart.idUserSF));

                const workingModes : IWorkingMode[] = initTasksResults[1];
                const currWorkingMode: IWorkingMode[] = workingModes.filter(wm => wm.active === true);
                dispatch(siaActions.setCurrentWorkingMode(currWorkingMode[0].id));

                const userContracts = initTasksResults[2];
                dispatch(ssfActions.setSsfJobs(userContracts));
                dispatch(ssfActions.setSsfIsLoaded(true));

                if (userContracts.mandatoryTimesheet === false && userContracts.oneShotTimesheet === false) {
                    dispatch(siaActions.setUserCanBadge(false));
                }

                initTasks = [];
                // FESTIVITIES
                initTasks.push(CookiesHelper.setApplicationCookies.setFestivitiesCookie(userOrgchart.placesOfWork));
                // ANOMALY TYPES
                initTasks.push(CookiesHelper.setApplicationCookies.setAnomalyTypesCookie());
                // ASSISTANCE STATUSES
                initTasks.push(CookiesHelper.setApplicationCookies.setAssistanceStatusesCookie());
                //USER TYPE
                initTasks.push(CookiesHelper.setApplicationCookies.setUserTypeCookie(appState.debug));

                const promiseResult = await Promise.all(initTasks);
                const userType: UserType = promiseResult[3] !== null ? promiseResult[3] : UserType.Unknown;
                dispatch(appActions.setUserType(userType));
                let actionTypes: IActionType[] = await CookiesHelper.setApplicationCookies.setActionTypesCookie();
                if (!actionTypes || actionTypes.length === 0) {
                    //Non ci sono actions quiandi l'utente non può fare niente
                    dispatch(siaActions.setUserCanBadge(false));
                } else {
                    //Imposto le azioni del badge
                    const actionsFromBadge: IActionType[] = actionTypes.filter((value: IActionType, index: number, array: IActionType[]) => {
                        return (value.groupName === Constants.GROUP_NAME_TIMBRATURA || value.groupName === Constants.GROUP_NAME_PRANZO || value.groupName === Constants.GROUP_NAME_PAUSA);
                    });
                    dispatch(siaActions.setActionsFromBadge(actionsFromBadge));
                }


                setStatus(LoadCommonDataStaus.Success);
            } catch (error) {
                setStatus(LoadCommonDataStaus.Failed);
            }
        })();

    }, []);

    return status;
}

export default useLoadCommonData;