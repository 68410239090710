/* TEAMS CONSTANTS */
export const SET_TEAMS_INITIALIZED = 'SET_TEAMS_INITIALIZED';
export const SET_TEAMS_CONTEXT = 'SET_TEAMS_CONTEXT';

/* SUCCESS FACTORS CONSTANTS */
export const SET_SSF_ISLOADED = 'SET_SSF_ISLOADED';
export const SET_SSF_JOBS = 'SET_SSF_JOBS';
export const SET_SELECTED_JOB = 'SET_SELECTED_JOB';
export const SET_ID_USER_SF = 'SET_ID_USER_SF';

/* SIA CONSTANTS */
export const SET_TODAY_STATUS_ISLOADED = 'SET_TODAY_STATUS_ISLOADED';
export const SET_TODAY_STATUS = 'SET_TODAY_STATUS';
export const SET_IS_REGISTERING = 'SET_IS_REGISTERING';
export const ADD_TIMESHEET = 'ADD_TIMESHEET';
export const SET_CLOCKING_DATE = 'SET_CLOCKING_DATE';
export const SET_ACTION_TO_REGISTER = 'SET_ACTION_TO_REGISTER';
export const SET_CURRENT_WORKINGMODE = 'SET_CURRENT_WORKINGMODE';
export const SET_USER_CAN_BADGE = 'SET_USER_CAN_BADGE';
export const SET_ACTIONS_FROM_BADGE = 'SET_ACTIONS_FROM_BADGE';
export const SET_IS_MOBILE = 'SET_IS_MOBILE';
// export const TS_LAVORO = 1;
// export const TS_PRANZO = 66;
// export const TS_PAUSA = 43;
export const GROUP_NAME_TIMBRATURA = 'TIMBRATURA';
export const GROUP_NAME_PAUSA = 'PAUSETTA';
export const GROUP_NAME_PRANZO = 'PRANZO';
export const GROUP_NAME_CALENDARIO = 'CALENDARIO';
export const GROUP_NAME_FERIE = 'FERIE';
export const GROUP_NAME_MALATTIA = 'MALATTIA';
export const GROUP_NAME_TRASFERTA = 'TRASFERTA';
export const GROUP_NAME_PERMESSO = 'PERMESSO';
export const GROUP_NAME_ALTRO = 'ALTRO';
export const GROUP_NAME_INFORTUNIO = 'INFORTUNIO';
/* APP CONSTANTS */
export const SET_TIP_DRAG = 'SET_TIP_DRAG';
export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS';
export const SET_USER_TYPE = 'SET_USER_TYPE';
export const SET_MONTHLY_ROWS = 'SET_MONTHLY_ROWS';

/* APP COOKIE NAMES */
export const ACTION_TYPES_COOKIE = 'ACTION_TYPES';
export const ACTION_TYPES_MONTHLY_PAGE_COOKIE = 'ACTION_TYPES_MONTHLY_PAGE';
export const ORG_CHART_COOKIE = 'ORG_CHART';
export const WORKING_MODES_COOKIE = 'WORKING_MODES';
export const CURRENT_WORKING_MODE_COOKIE = 'CURRENT_WORKING_MODE';
export const EMPJOB_SELECTED_COOKIE = 'EMPJOB_SELECTED';
export const FESTIVITIES_COOKIE = 'FESTIVITIES';
export const ANOMALY_TYPES_COOKIE = 'ANOMALY_TYPES';
export const ASSISTANCE_STATUSES_COOKIE = 'ASSISTANCE_STATUSES';
export const COUNTRY_USERS_COOKIE = 'COUNTRY_USERS';
export const USER_TYPE_COOKIE = 'USER_TYPE';
export const USER_TOKEN_COOKIE = 'USER_TOKEN';
export const USER_CONTRACTS_COOKIE = 'USER_CONTRACTS';
export const USER_ACTIONS_COOKIE = 'USER_ACTIONS';
export const EOW_ACTION_COOKIE = "EOW_ACTION";
export const CLEAR_SESSION_COOKIE = 'CLEAR_SESSION';
export const CONTRACT_NOT_FOUND_COOKIE = 'CONTRACT_NOT_FOUND_COOKIE';
export const UNEXPECTED_ERROR = 'UNEXPECTED_ERROR';


export const notificationTypes = {
	WARNING: "warning",
	INFO: "info",
	DANGER: "danger",
	SUCCESS: "success",
}