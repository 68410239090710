/* IMPORT CSS / SASS */
import "../scss/Badge.scss";

/* IMPORT REACT & THIRD PARTY COMPONENTS */
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Loader, Flex, Divider, Dialog, Text, Alert, InfoIcon } from "@fluentui/react-northstar";
import * as microsoftTeams from "@microsoft/teams-js";

/* IMPORT CUSTOM COMPONENTS */
import * as siaActions from "../stores/actions/SiaActions";
import { IAppState } from "../interfaces/IAppState";
import { IFetchResponse } from "../interfaces/IFetchResponse";
import { ITimesheetAction } from "../interfaces/ITimesheetAction";
import { SiaStrings } from "../strings/strings";
import BadgeHeader from "./BadgeHeader";
import { getDaily, postTimesheet } from "../helpers/Fetch";
import BadgeActionsHistory from "./BadgeActionsHistory";
import BadgeCard from "./BadgeCard";
import BadgeActionSelectorMobile from './BadgeActionSelectorMobile';
import BadgeWorkingModeSelector from './BadgeWorkingModeSelector';
import Warning from "./Warning";
import * as cookieHelper from '../helpers/CookiesHelper';
import * as Constants from '../constants/AppConstants';
import * as appActions from '../stores/actions/AppActions';
import { INotification } from "../interfaces/INotification";
import moment from 'moment';
import { ISize } from "../interfaces/ISize";
import useWindowSize from "../hooks/useWindowSize";
import useLoadCommonData from "../hooks/useLoadCommonData";
import { LoadCommonDataStaus } from "../constants/AppEnum";
import BadgeMobile from "./BadgeMobile";
import Texture2 from '../imgs/texture_2.png';

const Badge = (props: any) => {

	const badgeOffset: number = 100;
	const appState: IAppState = useSelector((appState) => appState) as IAppState;
	const [canSelectWorkingMode, setCanSelectWorkingMode] = useState(false);
	const dispatch = useDispatch();
	const [badgePosition, setBadgePosition] = useState({ x: 0, y: 0 });
	// const badgeRightDescription: HTMLElement = document.getElementById("badge-action-description-left") as HTMLElement;
	// const badgeLeftDescription: HTMLElement = document.getElementById("badge-action-description-right") as HTMLElement;
	const badgeRightSide: HTMLElement = document.getElementById("badge-badge-side-right") as HTMLElement;
	const badgeLeftSide: HTMLElement = document.getElementById("badge-badge-side-left") as HTMLElement;
	const badgeContent: HTMLElement = document.getElementById("badge-badge-content") as HTMLElement;
	const currentWorkingMode = cookieHelper.getApplicationCookies.getCurrentWorkingModeCookie() ?? appState.currentWorkingMode;
	const [showCalendarWarning, setShowCalendarWarning] = useState(false);
	const tmpEowAction = cookieHelper.getApplicationCookies.getTempEndOfWorkAction();
	const [showConfirmTempEowAction, setShowConfirmTempEowAction] = useState(false);
	const [error, setError] = useState('');
	const [errorDetail, setErrorDetail] = useState('');
	const size: ISize = useWindowSize();
	const commonDataStatus: LoadCommonDataStaus = useLoadCommonData();
	const [pageLoaded, setPageLoaded] = useState(false);
	const [saving, setSaving] = useState(false);

	const saveTimesheet = async (action: ITimesheetAction): Promise<void> => {
		dispatch(siaActions.setIsRegistering(true));
		const registered: IFetchResponse | null = await postTimesheet(action);
		if (registered !== null && registered.status === 1) {
			//dispatch(siaActions.addTimesheet(registered.payload));
			//let todayStatus = await getSiaToday(appState.ssfJobs[appState.selectedJob].userId, new Date(today.getFullYear(), today.getMonth(), today.getDate(), 3));
			let todayStatusResponse = await getDaily();
			if (todayStatusResponse.status !== 0) {
				let todayStatus: ITimesheetAction[] = todayStatusResponse.payload.actions.map((action: any) => {
					return {
						id: action.id,
						idActionType: action.idActionType,
						idWorkingMode: action.idWorkingMode,
						startDate: new Date(action.startDate),
						endDate: action.endDate !== null ? new Date(action.endDate) : null,
						comment: action.comment,
						header: action.header
					};
				});
				dispatch(siaActions.setTodayStatus(todayStatus));
			}

			//****** Non serve più perchè la modalità lavorativa non è più selezionabile dall'utente ******
			//Se è la timbratura di uscita lavoro resetto il working mode
			//in modo che venga richiesto alla prossima timbratura di ingresso lavoro
			// if (action.idActionType === appState.timbraturaAction?.id && action.endDate !== null) {
			// 	dispatch(siaActions.setCurrentWorkingMode(null));
			// 	cookieHelper.setApplicationCookies.setCurrentWorkingModeCookie(null);
			// 	setCanSelectWorkingMode(true);
			// }
			//Se invece è la timbratura di ingresso memorizzo il workingmode
			// else if (action.idActionType === appState.timbraturaAction?.id && action.startDate !== null) {
			// 	cookieHelper.setApplicationCookies.setCurrentWorkingModeCookie(appState.currentWorkingMode);
			// 	setCanSelectWorkingMode(false);
			// }
		} else {
			if (registered.payload.response?.data?.details === "Calendar exception.") {
				//Gestione del caso in cui l'utente non ha compilato le azioni di calendario
				setShowCalendarWarning(true);
				cookieHelper.setApplicationCookies.setTempEndOfWorkAction(action);
			} else {
				dispatch(appActions.setNotifications([{ type: Constants.notificationTypes.DANGER, message: "Si è verificato un errore durante la timbratura" } as INotification]));
			}
		}
		//resetBadge();
	}

	const handleBadgeStop = (event: any, data: any) => {
		if (data.x <= -badgeOffset || data.x >= badgeOffset) {
			//var registered: IFetchResponse | null = null;
			setBadgePosition({ x: data.x, y: data.y });
			badgeContent.style.opacity = "0.5";
			dispatch(siaActions.setIsRegistering(true));
			(async () => {
				await saveTimesheet(appState.actionToRegister);
			})();
		}
	};

	const resetBadge = () => {
		badgeLeftSide?.classList.remove("badge-badge-side-active");
		badgeRightSide?.classList.remove("badge-badge-side-active");
		// badgeRightDescription.style.opacity = "0";
		// badgeLeftDescription.style.opacity = "0";
		badgeContent.style.opacity = "1";
		setBadgePosition({ x: 0, y: 0 });
		dispatch(siaActions.setIsRegistering(false));
		dispatch(siaActions.setTodayStatusIsLoaded(true));
	};

	const handleBadgeDrag = (event: any, data: any) => {
		let updateTimeAction: ITimesheetAction;
		// aggiungo la end date o la start date a seconda che sia un inizio o una fine azione
		if (appState.actionToRegister.id !== null)
			updateTimeAction = { ...appState.actionToRegister, endDate: new Date(), idWorkingMode: currentWorkingMode } as ITimesheetAction;
		else
			updateTimeAction = { ...appState.actionToRegister, startDate: new Date(), idWorkingMode: currentWorkingMode } as ITimesheetAction;
		dispatch(siaActions.setActionToRegister(updateTimeAction));

		// if (data.x > badgeOffset / 4) {
		// 	badgeRightDescription.style.opacity = (data.x / badgeOffset).toString();
		// 	badgeLeftDescription.style.opacity = "0";
		// }

		// if (data.x < (badgeOffset / 4) * -1) {
		// 	badgeRightDescription.style.opacity = "0";
		// 	badgeLeftDescription.style.opacity = ((data.x * -1) / badgeOffset).toString();
		// }

		if (data.x >= badgeOffset) {
			badgeRightSide?.classList.add("badge-badge-side-active");
			badgeLeftSide?.classList.remove("badge-badge-side-active");
		} else {
			badgeRightSide?.classList.remove("badge-badge-side-active");
		}

		if (data.x <= -badgeOffset) {
			badgeRightSide?.classList.remove("badge-badge-side-active");
			badgeLeftSide?.classList.add("badge-badge-side-active");
		} else {
			badgeLeftSide?.classList.remove("badge-badge-side-active");
		}

	};

	const handleActionButtonClick = async (actionToRegister: ITimesheetAction) => {
		try {
			setSaving(true);
			let updateTimeAction: ITimesheetAction;
			// aggiungo la end date o la start date a seconda che sia un inizio o una fine azione
			if (actionToRegister.id !== null)
				updateTimeAction = { ...actionToRegister, endDate: new Date(), idWorkingMode: currentWorkingMode } as ITimesheetAction;
			else
				updateTimeAction = { ...actionToRegister, startDate: new Date(), idWorkingMode: currentWorkingMode } as ITimesheetAction;
			await saveTimesheet(updateTimeAction);
		} finally {
			setSaving(false);
		}
	}

	useEffect(() => {
		(async () => {
			if (commonDataStatus === LoadCommonDataStaus.Success) {
				//Carico per la prima volta i timesheets dell'utente
				if (appState.ssfJobs && appState.ssfJobs.length > 0) {
					try {
						// let todayStatus = await getSiaToday(appState.ssfJobs[appState.selectedJob].userId, new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0));
						// dispatch(siaActions.setTodayStatus(todayStatus));

						let todayStatusResponse = await getDaily();
						if (todayStatusResponse.status === 0) {
							throw new Error("Si è verificato un errore nel recupero delle azioni giornaliere dell'utente");
						}
						let todayStatus: ITimesheetAction[] = todayStatusResponse.payload.actions.map((action: any) => {
							return {
								id: action.id,
								idActionType: action.idActionType,
								idWorkingMode: action.idWorkingMode,
								startDate: new Date(action.startDate),
								endDate: action.endDate !== null ? new Date(action.endDate) : null,
								comment: action.comment,
								header: action.header
							};
						});

						dispatch(siaActions.setTodayStatus(todayStatus));
						dispatch(siaActions.setTodayStatusIsLoaded(true));
						//Se c'è un'azione di uscita in sospeso per la giornata corrente
						if (tmpEowAction !== null) {
							if (moment(tmpEowAction.endDate).format("DD/MM/yyyy") === moment(new Date()).format("DD/MM/yyyy")) {
								setShowConfirmTempEowAction(true)
								dispatch(siaActions.setUserCanBadge(false));
							}
						}

						//**** LA modalità di lavoro è selzionata in automatico in base all'ip dell'utente */
						//come workingmode imposto quello dell'azione di inizio lavoro non ancora completata. (una volta timbrato iniziato giornata non posso più cambiare working mode) 
						// const todayStatusStartWork = todayStatus.find(item => {
						// 	return item.idActionType === appState.timbraturaAction?.id && item.endDate === null;
						// });
						// dispatch(siaActions.setCurrentWorkingMode(todayStatusStartWork ? todayStatusStartWork.idWorkingMode : null));
						// if (todayStatusStartWork) {
						// 	setCanSelectWorkingMode(false);
						// } else {
						// 	setCanSelectWorkingMode(true);
						// }
						setPageLoaded(true);
					} catch (error: any) {
						setError(error.message);
					}
				} else {
					setError("Non sono stati trovati contratti per l'utente corrente")
				}
			}
			else if (commonDataStatus === LoadCommonDataStaus.Failed) {
				const contractNotFoundException: string = cookieHelper.getApplicationCookies.getContractNotFoundCookie();
				let message: string = 'Si è verificato un errore imprevisto nel recupero dei dati';
				if (contractNotFoundException === 'true') {
					message = SiaStrings.COTRACT_NOT_FOUND_MESSAGE;
					setErrorDetail(SiaStrings.COTRACT_NOT_FOUND_DETAIL_MESSAGE);
				}
				setError(message);
			}
		})();

	}, [commonDataStatus])

	if (error !== '') {
		return <Warning label={error} details={errorDetail} height={size.height} />;
	}

	return (
		pageLoaded === false ?
			<Flex styles={{ height: size.height !== undefined ? `${size.height}px` : '100%' }} hAlign="center" vAlign="center">
				<Loader label={SiaStrings.LOADING} size="large" />
			</Flex>
			:
			<>
				{/* <div>{appState.teamsContext.hostClientType}</div> */}
				{(appState.teamsContext.hostClientType !== undefined && appState.teamsContext.hostClientType !== microsoftTeams.HostClientType.web && appState.teamsContext.hostClientType !== microsoftTeams.HostClientType.desktop) ?
					<BadgeMobile />
					:
					<Flex column className="badge-main-container" styles={{ height: size.height !== undefined ? `${size.height}px` : '100%' }}>
						<BadgeHeader />
						<Divider />
						<Flex hAlign="start" vAlign="start" className="badge-container">
							<BadgeActionsHistory />
							{appState.todayStatusIsLoaded
								?
								<Flex column hAlign="center">
									{appState.userCanBadge && appState.actionToRegister !== undefined ?
										<BadgeWorkingModeSelector disabled={!canSelectWorkingMode} />
										:
										null
									}
									<Flex
										className="badge-badge-container"
										gap="gap.small"
										hAlign="center"
										vAlign="center">
										{/* <BadgeActionIndicator position="left" /> */}
										<BadgeCard
											badgePosition={badgePosition}
											handleBadgeDrag={handleBadgeDrag}
											handleBadgeStop={handleBadgeStop}></BadgeCard>
										{/* <BadgeActionIndicator position="right" /> */}
									</Flex>
									{appState.userCanBadge ?
										<>
											{/* <BadgeActionSelector /> */}
											<BadgeActionSelectorMobile actionClick={handleActionButtonClick} saving={saving} />
										</>
										:
										<Alert info icon={<InfoIcon />} content="Non sono previste azioni di timbratura" />
									}
									{/* Dialog per inserire eventuali azioni di calendario mancanti  */}
									<Dialog
										style={{ maxWidth: "500px" }}
										open={showCalendarWarning}
										confirmButton="Vai al calendario"
										content={
											<Text content={"Attenzione : non hai insertio tutte le azioni nel calendario. L'uscita potrà essere registrata solo quando hai inserito tutte le attività della giornata"} />
										}
										onConfirm={async () => {
											var encodedContext = encodeURI('{"subEntityId": "Calendario"}');
											var calendarTabUrl = `https://teams.microsoft.com/l/entity/${process.env.REACT_APP_SIA_APPID}/${process.env.REACT_APP_SIA_ALTRO_TAB_ID}?&context=${encodedContext}`;
											microsoftTeams.executeDeepLink(calendarTabUrl);
										}}
										header={`Attività calendario mancanti`}
									/>
									{/* Dialog per inserire azione di uscita non confermata per mancanza azioni di calendario  */}
									<Dialog
										style={{ maxWidth: "500px" }}
										open={showConfirmTempEowAction}
										cancelButton="Annulla"
										confirmButton="Conferma"
										content={
											<Flex column gap="gap.small">
												<Text content={"Hai un'azione di fine lavoro in sospeso :"} />
												<Text content={`Dettaglio : ${moment(tmpEowAction?.endDate).format("DD/MM/yyyy HH:mm")}`} />
												<Text content={"Vuoi confermarla?"} />
											</Flex>
										}
										onCancel={(() => {
											//cancello cookie
											cookieHelper.setApplicationCookies.setTempEndOfWorkAction();
											setShowConfirmTempEowAction(false);
											//Riabilito il badge
											dispatch(siaActions.setUserCanBadge(true));
										}
										)}
										onConfirm={async () => {
											try {
												if (tmpEowAction !== null) {
													console.log("salvo timesheet...");
													await saveTimesheet(tmpEowAction);
													console.log("timesheet salvato...");
													debugger;
													//cancello cookie
													cookieHelper.setApplicationCookies.setTempEndOfWorkAction();
													//Riabilito il badge
													dispatch(siaActions.setUserCanBadge(true));
													setShowConfirmTempEowAction(false);
												}
											} catch (error: any) {
												debugger;
												console.log(`Errore in onConfirm : ${error.message} --- ${error}`);
												dispatch(appActions.setNotifications([{ type: Constants.notificationTypes.DANGER, message: "Si è verificato un errore durante la timbratura" } as INotification]));
											}
										}}

										header={`Conferma uscita`}
									/>
								</Flex>
								:
								<Flex column hAlign="center" vAlign="center" style={{ width: '100%', minHeight: '200px', paddingTop: '20px' }}><Loader label={SiaStrings.LOADING_SIA} size="small" /></Flex>
							}

							{/* Qui va l'immagine di sfondo */}

							{/* <svg
									style={{ width: '350px', height: '350px' }}
										viewBox="0 0 200 200"
										xmlns="http://www.w3.org/2000/svg"
									>
										
									</svg> */}
							<Flex fill className="flexImage" hAlign="end" >
								<div style={{ width: '16%', height: '100%', backgroundImage: `url(${Texture2})` }} className="badgeTexture">
								</div>
							</Flex>
						</Flex>
					</Flex>
				}
			</>


	);

};
export default Badge;
