/* IMPORT CSS / SASS */
import "../scss/NewMonthly.scss";

/* IMPORT REACT & THIRD PARTY COMPONENTS */
import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from 'moment';

/* IMPORT CUSTOM COMPONENTS */
import { IAppState } from "../interfaces/IAppState";
import { IEmployee } from "../interfaces/IEmployee";
import { IUserTimesheetResponse } from "../interfaces/IUserTimesheetResponse";
import { IOrgChartUser } from "../interfaces/IOrgChartUser";
import { IMonthlyAllowedPeriod } from "../interfaces/IMonthlyAllowedPeriod";
import { IAnomalyResponse } from "../interfaces/IAnomalyResponse";
import { IHoliday } from "../interfaces/IHoliday";
import { IAssistance } from "../interfaces/IAssistance";
import { getUserContracts, getSiaUserTimesheet, getAnomalies, getHolidays, getAssistances, getOrgChart, getSiaActionTypes } from '../helpers/Fetch';
import * as cookieHelper from '../helpers/CookiesHelper';
import { SiaStrings } from "../strings/strings";
import CustomPeriodSelector from "./CustomPeriodSelector";
import Warning from "./Warning";
import { Table, Flex, Loader, Text, Divider, DownloadIcon, Button, ArrowRightIcon, ArrowLeftIcon, Tooltip } from '@fluentui/react-northstar';
import UserSelector from "./UserSelector";
import { UserType } from "../constants/AppEnum";
import { ISelectedUser } from "../interfaces/ISelectedUser";
import { findUserByEmailRecursive, getAllUsers } from "../helpers/SiaHelper";
import { IFestivity } from "../interfaces/IFestivity";
import useLoadCommonData from "../hooks/useLoadCommonData";
import { LoadCommonDataStaus } from "../constants/AppEnum";
import useWindowSize from '../hooks/useWindowSize';
import { ISize } from "../interfaces/ISize";
import { ITimesheet } from "../interfaces/ITimesheet";
import CalendarDate from "./CalendarDate";
import MonthlyActivities from "./MonthlyActivities";
import { IActionType } from "../interfaces/IActionType";
import * as Constants from '../constants/AppConstants';
import { IAnomalyType } from "../interfaces/IAnomalyType";
import MonthlyAssistances from "./MonthlyAssistances";
import { IAssistanceStatus } from "../interfaces/IAssistanceStatus";
import { IAnomaly } from "../interfaces/IAnomaly";
import { IUserAssistanceResponse } from "../interfaces/IUserAssistanceResponse";
import MonthlyBreak from "./MonthlyBreak";
import { Clock20Regular, Clock16Regular } from '@fluentui/react-icons';
import MonthlyDayOvertime from "./MonthlyDayOvertime";
import MonthlyUserHolidays from './MonthlyUserHolidays';
import MonthlyUserReport from './MonthlyUserReport';
import { ITimesheetAction } from "../interfaces/ITimesheetAction";
import * as appActions from '../stores/actions/AppActions';
import { INotification } from "../interfaces/INotification";
import { IWorkingMode } from "../interfaces/IWorkingMode";



interface IMonthlyRowInfo {
    items: any[];
    csvRow: string;
}

const NewMonthly = (props: any) => {
    const appState: IAppState = useSelector((appState) => appState) as IAppState;
    const dispatch = useDispatch();
    const today: Date = new Date();
    const [timesheet, setTimesheet] = useState({} as IUserTimesheetResponse);
    const [anomalies, setAnomalies] = useState({} as IAnomalyResponse);
    const [anomalyTypes, setAnomalyTypes] = useState<IAnomalyType[]>([]);
    const [holidays, setHolidays] = useState<IHoliday | null>(null);
    const [festivities, setFestivities] = useState<IFestivity[]>([]);
    const [assistances, setAssistances] = useState([] as Array<IAssistance>);
    const [selectedUser, setSelectedUser] = useState<ISelectedUser>({ email: '', idUserSF: '', fullName: '', companyCountry: '', firstName: '', lastName: '', dateOfBirth: '' });
    const [selectedPeriod, setSelectedPeriod] = useState({ month: today.getMonth() + 1, year: today.getFullYear() } as IMonthlyAllowedPeriod);
    const [error, setError] = useState('');
    const [errorDetail, setErrorDetail] = useState('');
    const [userContracts, setUserContracts] = useState<IEmployee[]>([]);
    const commonDataStatus: LoadCommonDataStaus = useLoadCommonData();
    const [loading, setLoading] = useState(true);
    const [userDataReady, setUserDataReady] = useState(false);
    const [orgChart, setOrgChart] = useState<IOrgChartUser>();
    const [monthlyRows, setMonthlyRows] = useState<{ key: string, items: any[] }[]>([]);
    const [idTimbraturaAction, setIdTimbraturaAction] = useState(0);
    const [idPausaAction, setIdPausaAction] = useState(0);
    const [idPranzoAction, setIdPranzoAction] = useState(0);
    const [otherActionIds, setOtherActionIds] = useState<number[]>([]);
    const [userActions, setUserActions] = useState<{ key: string, header: string, disabled: boolean }[]>([]);
    const [csvRows, setCsvRows] = useState<string[]>([]);
    const [userCanEdit, setUserCanEdit] = useState(false);
    const [borderColor, setBorderColor] = useState(`${appState.teamsContext.theme === "default" ? 'rgb(237, 235, 233)' : '#edebe926'}`);
    const [tableHeaderSticky, setTableHeaderSticky] = useState({
        key: 'header',
        items: [
            <div key="Giorno" className="newmonthly-table-header stickyCol" style={{ width: "9%" }}>GIORNO</div>
            ,
            <div key="Assistenze" className="newmonthly-table-header stickyCol" style={{ width: "1.5%" }}></div>
            ,
            <div key="Attivita" className="newmonthly-table-header stickyCol" style={{ width: "22%" }}>ATTIVITA'</div>
            ,
            <div key="Pause" className="newmonthly-table-header stickyCol" style={{ width: "8%" }}>PAUSE</div>
            ,
            <div key="Pranzo" className="newmonthly-table-header stickyCol" style={{ width: "8%" }}>PRANZO</div>
            ,
            <div key="Assenze/Permessi" className="newmonthly-table-header stickyCol" style={{ width: "24%" }} >ASSENZE/PERMESSI</div>
            ,
            <div key="Note" className="newmonthly-table-header stickyCol" style={{ width: "27%" }}>NOTE</div>
        ]
    });
    const [workingModes, setWorkingModes] = useState<IWorkingMode[]>([]);

    // Scommentare se si vuole utilizzare la propriet� nativa header dell'oggetto <Table>
    // const [tableHeader, setTableHeader] = useState({
    //     key: 'header',
    //     items: [
    //         {
    //             content: 'GIORNO',
    //             key: 'Giorno',
    //             className: 'newmonthly-table-header',
    //             // styles: { maxWidth: "110px" }
    //             styles: { maxWidth: "6%" }
    //         },
    //         {
    //             content: "",
    //             key: 'Assistenze',
    //             className: 'newmonthly-table-header',
    //             // styles: { maxWidth: "25px" }
    //             styles: { maxWidth: "1.5%" }
    //         },
    //         {
    //             content: "ATTIVITA'",
    //             key: 'Attivit�',
    //             className: 'newmonthly-table-header',
    //             // styles: { minWidth: "350px" }
    //             styles: { maxWidth: "20%" }
    //         },
    //         {
    //             content: 'PAUSE',
    //             key: 'Pause',
    //             className: 'newmonthly-table-header',
    //             styles: { maxWidth: "6%" }
    //             // styles: { maxWidth: "180px" }
    //         },
    //         {
    //             content: 'PRANZO',
    //             key: 'Pranzo',
    //             className: 'newmonthly-table-header',
    //             styles: { maxWidth: "6%" }
    //             // styles: { maxWidth: "180px" }
    //         },
    //         {
    //             content: 'ASSENZE/PERMESSI',
    //             key: 'Assenze/Permessi',
    //             className: 'newmonthly-table-header',
    //             styles: { maxWidth: "22%" }
    //         },
    //         {
    //             content: 'NOTE',
    //             key: 'Note',
    //             className: 'newmonthly-table-header',
    //             styles: { maxWidth: "16%" }
    //         }
    //     ]
    // })
    const size: ISize = useWindowSize();
    const LOAD_ERRROR_MESSAGE: string = "Errore imprevisto nel caricamento del rapportino mensile";

    const selectedUserChanged = (user: ISelectedUser): void => {
        if (user === null) {
            if (orgChart && orgChart !== null) {
                setSelectedUser({ email: orgChart.userName, idUserSF: orgChart.idUserSF, fullName: orgChart.fullName, companyCountry: orgChart.companyCountry, lastName: orgChart.lastName, firstName: orgChart.firstName, dateOfBirth: orgChart.dateOfBirth });
            }
        } else {
            setSelectedUser(user);
        }
    }

    // useEffect(() => {
    //     //Imposto tipologia utente
    //     var ut = process.env.REACT_APP_SIA_DEBUG_USER_TYPE as string;
    //     const userType: UserType = appState.debug ? (UserType as any)[`${ut as string}`] : cookieHelper.getApplicationCookies.getUserTypeCookie();
    //     //const userType: UserType = cookieHelper.getApplicationCookies.getUserTypeCookie();
    //     dispatch(appActions.setUserType(userType));
    // }, [])

    useEffect(() => {
        //console.log(`useEffect commonDataStatus ${commonDataStatus} - ${moment(new Date()).format("HH:mm:ss")}`);
        const loadUserChart = async (): Promise<void> => {
            try {
                //Carico l'orgChart dell'utente
                debugger;
                const startDate: Date = new Date(selectedPeriod.year, selectedPeriod.month - 1, 1);
                const endDate: Date = new Date(selectedPeriod.year, selectedPeriod.month, 0, 23, 59);
                const tmpOrgChart = await cookieHelper.setApplicationCookies.setOrgChartCookie(appState.debug ? appState.debugUser : appState.teamsContext.userPrincipalName?.toLowerCase() ?? 'unknown', startDate, endDate);
                setOrgChart(tmpOrgChart);
                //imposto l'utente selezionato
                setSelectedUser({ email: tmpOrgChart.userName, idUserSF: tmpOrgChart.idUserSF, fullName: tmpOrgChart.fullName, companyCountry: tmpOrgChart.companyCountry, lastName: tmpOrgChart.lastName, firstName: tmpOrgChart.firstName, dateOfBirth: tmpOrgChart.dateOfBirth });
                setAnomalyTypes(cookieHelper.getApplicationCookies.getAnomalyTypesCookie());
                const wModes = await cookieHelper.setApplicationCookies.setWorkingModesCookie();
                setWorkingModes(wModes);
            } catch (error) {
                debugger;
                setError(LOAD_ERRROR_MESSAGE);
            }
        }
        if (commonDataStatus === LoadCommonDataStaus.Success) {
            //console.log("LoadCommonDataStaus.Success");
            loadUserChart();
        } else if (commonDataStatus === LoadCommonDataStaus.Failed) {
            const contractNotFoundException: string = cookieHelper.getApplicationCookies.getContractNotFoundCookie();
            let message: string = 'Si � verificato un errore imprevisto nel recupero dei dati';
            if (contractNotFoundException === 'true') {
                message = SiaStrings.COTRACT_NOT_FOUND_MESSAGE;
                setErrorDetail(SiaStrings.COTRACT_NOT_FOUND_DETAIL_MESSAGE);
            }
            setError(message);
        }
    }, [commonDataStatus])

    useEffect(() => {
        const loadUserData = async (): Promise<void> => {
            //console.log(`useEffect [orgChart.idUserSF = ${orgChart?.idUserSF}, selectedUser = ${selectedUser.email}, selectedPeriod = ${selectedPeriod.month}] - ${moment(new Date()).format("HH:mm:ss")}`);
            if (!loading) {
                setLoading(true);
            }
            //Recupero tutte le action
            const actions: IActionType[] = cookieHelper.getApplicationCookies.getActionTypesCookie();
            //Recupero id dell'azione di timbratura
            const timbratureActions = actions.filter((a) => { return a.groupName === Constants.GROUP_NAME_TIMBRATURA });
            if (timbratureActions.length > 0) {
                setIdTimbraturaAction(timbratureActions[0].id);
            }
            //Recupero id azione di Pausa
            const breakActions = actions.filter((a) => { return a.groupName === Constants.GROUP_NAME_PAUSA });
            if (breakActions.length > 0) {
                setIdPausaAction(breakActions[0].id);
            }
            //Recupero id azione Pranzo
            const pranzoActions = actions.filter((a) => { return a.groupName === Constants.GROUP_NAME_PRANZO });
            if (pranzoActions.length > 0) {
                setIdPranzoAction(pranzoActions[0].id);
            }
            //Recupero id azioni assenze/permesso
            const otherIds = actions.filter((a) => {
                return a.groupName === Constants.GROUP_NAME_PERMESSO ||
                    a.groupName === Constants.GROUP_NAME_ALTRO ||
                    a.groupName === Constants.GROUP_NAME_FERIE ||
                    a.groupName === Constants.GROUP_NAME_MALATTIA ||
                    a.groupName === Constants.GROUP_NAME_INFORTUNIO
            }).map(action => action.id);
            setOtherActionIds(otherIds);

            //console.log("Carico contratti e user Data di " + selectedUser.email);
            await getSSFContracts(selectedUser.email);
            await selectUserData(selectedUser.idUserSF);

        }
        //Quando sono caricati i dati dell'utente e relativo orgChart carico tutti i dati da utilizzare nel mensile
        if (selectedUser && selectedUser.idUserSF !== "" && orgChart && orgChart.idUserSF && orgChart.idUserSF !== "") {
            loadUserData();
        } else {
            //Caso in cui � stata selezionata la X nella drop down degli utenti => imposto come utente selezionato l'utente loggato
            if (selectedUser === null && orgChart) {
                setSelectedUser({
                    idUserSF: orgChart.idUserSF,
                    email: orgChart.userName,
                    fullName: orgChart.fullName,
                    companyCountry: orgChart.companyCountry,
                    firstName: orgChart.firstName,
                    lastName: orgChart.lastName,
                    dateOfBirth: orgChart.dateOfBirth
                });
            }
        }
    }, [orgChart?.idUserSF, selectedUser, selectedPeriod]);

    useEffect(() => {
        //console.log(`userDataReady = ${userDataReady} - appState.userType = ${appState.userType} - ${moment(new Date()).format("HH:mm:ss")}`);
        if (userDataReady && appState.userType !== UserType.Unknown) {
            loadMonthlyRows();
            //Reset flag
            setLoading(false);
            setUserDataReady(false);
        }
    }, [userDataReady, appState.userType])

    useEffect(() => {
        if (userCanEdit) {
            const newHeader = {
                key: 'header',
                items: [
                    <div key="Giorno" className="newmonthly-table-header stickyCol" style={{ width: "9%" }}>GIORNO</div>
                    ,
                    <div key="Assistenze" className="newmonthly-table-header stickyCol" style={{ width: "1.5%" }}></div>
                    ,
                    <div key="Attivita" className="newmonthly-table-header stickyCol" style={{ width: "20%" }}>ATTIVITA'</div>
                    ,
                    <div key="Pause" className="newmonthly-table-header stickyCol" style={{ width: "7.5%" }}>PAUSE</div>
                    ,
                    <div key="Pranzo" className="newmonthly-table-header stickyCol" style={{ width: "7.5%" }}>PRANZO</div>
                    ,
                    <div key="Assenze/Permessi" className="newmonthly-table-header stickyCol" style={{ width: "18%" }} >ASSENZE/PERMESSI</div>
                    ,
                    <div key="Eccedenze" className="newmonthly-table-header stickyCol" style={{ width: "25%" }} >ECCEDENZE</div>
                    ,
                    <div key="Note" className="newmonthly-table-header stickyCol" style={{ width: "11%" }}>NOTE</div>
                ]
            }
            setTableHeaderSticky(newHeader);
        }
    }, [userCanEdit])

    useEffect(() => {
        setBorderColor(`${appState.teamsContext.theme === "default" ? 'rgb(237, 235, 233)' : '#edebe926'}`);
    }, [appState.teamsContext.theme])

    const loadUserActions = useCallback(async () => {
        let userActions;
        if (appState.idUserSF === selectedUser.idUserSF) {
            //Per l'utente loggato leggo le actions dal cookie
            userActions = await cookieHelper.setApplicationCookies.setUserActionsCookie(selectedUser.idUserSF);
        } else {
            //Per gli utenti selezionati dalla dropdown chiamo l'API
            userActions = await getSiaActionTypes(selectedUser.idUserSF);
        }

        const ddItems = userActions.actions.map((action) => {
            return {
                key: action.id.toString(),
                header: action.title,
                disabled: action.disabled
            };
        }).sort((a, b) => (a.header > b.header) ? 1 : ((b.header > a.header) ? -1 : 0));
        setUserActions(ddItems);
    }, [selectedUser.idUserSF]);

    const selectUserData = useCallback(async (idUserSF: string) => {
        //console.log(`selectUserData = ${idUserSF} - ${moment(new Date()).format("HH:mm:ss")}`);
        let tmpOrgChart: IOrgChartUser;
        let selectedUserOrgChart: IOrgChartUser;
        //{ email: orgChart.userName, idUserSF: orgChart.idUserSF, fullName: orgChart.fullName }
        const currentUserEmail: string = appState.debug ? appState.debugUser : appState.teamsContext.userPrincipalName?.toLowerCase() ?? 'unknown';
        const startDate: Date = new Date(selectedPeriod.year, selectedPeriod.month - 1, 1);
        const endDate: Date = new Date(selectedPeriod.year, selectedPeriod.month, 0, 23, 59);
        if (currentUserEmail === selectedUser.email) {
            //Per l'utente loggato salvo/leggo dallo storage
            selectedUserOrgChart = await cookieHelper.setApplicationCookies.setOrgChartCookie(selectedUser.email, startDate, endDate);
            //Aggiorno lo state solo se � cambiato il mese
            if (selectedUserOrgChart.startDate !== undefined && selectedUserOrgChart.endDate !== undefined && orgChart !== undefined && orgChart.startDate !== undefined && orgChart.endDate !== undefined){
                if (selectedUserOrgChart.startDate.toISOString() !== orgChart.startDate.toISOString() || selectedUserOrgChart.endDate.toISOString() !== orgChart.endDate.toISOString()){
                    setOrgChart(selectedUserOrgChart);        
                }
            }else{
                setOrgChart(selectedUserOrgChart);
            }
            
        } else {
            //Per gli utenti selezionati dalla drop down faccio la chiamata all'API
            //Se l'utente loggato ha come ruolo HR/Admin faccio una chiamata alle API
            if (appState.userType === UserType.HR || appState.userType === UserType.Admin) {
                tmpOrgChart = await getOrgChart(selectedUser.email, startDate, endDate) as IOrgChartUser;
                if ((tmpOrgChart as any) === '' || tmpOrgChart === undefined || tmpOrgChart === null) {
                    dispatch(appActions.setNotifications([{ type: Constants.notificationTypes.DANGER, message: `Contratto non attivo o non disponibile nel mese selezionato per l'utente ${selectedUser.fullName}` } as INotification]));
                    //Reset utente loggato
                    const currentUserOrgChart = await cookieHelper.setApplicationCookies.setOrgChartCookie(currentUserEmail, startDate, endDate);
                    setSelectedUser({ email: '', idUserSF: '', fullName: '', companyCountry: '', firstName: '', lastName: '', dateOfBirth: '' });
                    setSelectedUser({ email: currentUserOrgChart.userName, idUserSF: currentUserOrgChart.idUserSF, fullName: currentUserOrgChart.fullName, companyCountry: currentUserOrgChart.companyCountry, firstName: currentUserOrgChart.firstName, lastName: currentUserOrgChart.lastName, dateOfBirth : currentUserOrgChart.dateOfBirth });
                    return;
                }
                selectedUserOrgChart = findUserByEmailRecursive(selectedUser.email, tmpOrgChart as IOrgChartUser);
            } else {
                //Se � un responsabile/consultatore l'orgChart lo recupro dall'orgChart (dell'untente loggato). Contiene l'intera gerarchia.
                //leggo dal cookie e non uso orgChart dello state perch� gli users 'sottoposti' possono essere diversi ogni mese e quindi devo prendere l'orgChart del mese appena selezionato.
                //l'orgChart dello state � quello del mese selezionato in precedenza (o quello corrente la prima volta)
                const currentUserOrgChart = await cookieHelper.setApplicationCookies.setOrgChartCookie(currentUserEmail, startDate, endDate);
                //Aggiorno lo state solo se � cambiato il mese
                if (currentUserOrgChart.startDate !== undefined && currentUserOrgChart.endDate !== undefined && orgChart !== undefined && orgChart.startDate !== undefined && orgChart.endDate !== undefined){
                    if (currentUserOrgChart.startDate.toISOString() !== orgChart.startDate.toISOString() || currentUserOrgChart.endDate.toISOString() !== orgChart.endDate.toISOString()){
                        setOrgChart(currentUserOrgChart);        
                    }
                }

                //Recupero tutti gli utenti, anche quelli di secondo livello (es. Manager (Borin) -> Responsabile (Sacchitelli) -> utente ). L'array pu� contenere duplicati, ma in questo caso non importa 
                //perch� � sufficiente trovare la prima occorenza dell'utente selezionato nell dropDown degli utenti
                const allUsers = getAllUsers(currentUserOrgChart);

                selectedUserOrgChart = allUsers.filter((user) => {
                    return user.idUserSF === selectedUser.idUserSF;
                })[0];

                if (selectedUserOrgChart === undefined) {
                    dispatch(appActions.setNotifications([{ type: Constants.notificationTypes.DANGER, message: `Contratto non attivo o non disponibile nel mese selezionato per l'utente ${selectedUser.fullName}` } as INotification]));
                    //Reset utente loggato
                    setSelectedUser({ email: '', idUserSF: '', fullName: '', companyCountry: '', firstName: '', lastName: '', dateOfBirth: '' });
                    setSelectedUser({ email: currentUserOrgChart.userName, idUserSF: currentUserOrgChart.idUserSF, fullName: currentUserOrgChart.fullName, companyCountry: currentUserOrgChart.companyCountry, firstName: currentUserOrgChart.firstName, lastName: currentUserOrgChart.lastName, dateOfBirth : currentUserOrgChart.dateOfBirth });
                    return;                                
                }

                // const allUsers = getAllUsers(orgChart as IOrgChartUser);
                // selectedUserOrgChart = allUsers.filter((user) => {
                //     return user.idUserSF === selectedUser.idUserSF;
                // })[0];
            }
        }

        let initTasks: Promise<any>[] = [];
        initTasks.push(getSiaUserTimesheet(idUserSF, startDate, endDate));
        initTasks.push(getAnomalies(idUserSF, startDate, endDate));
        initTasks.push(getHolidays(idUserSF, startDate, endDate));
        initTasks.push(getAssistances(idUserSF, startDate, endDate));
        debugger;
        initTasks.push(cookieHelper.setApplicationCookies.setFestivitiesCookie(selectedUserOrgChart.placesOfWork, selectedPeriod.year));

        try {
            const initTasksResults = await Promise.all(initTasks);
            //Recupera timbrature da SIA per il periodo e l'utente selezionati
            let timesheetResponse: IUserTimesheetResponse = initTasksResults[0];
            setTimesheet(timesheetResponse);
            //Recupera le anomalie del periodo da SIA
            let anomaliesResponse = initTasksResults[1];
            setAnomalies(anomaliesResponse);
            //Recupera le ferie del mese
            let holidaysResponse = initTasksResults[2];
            setHolidays(holidaysResponse);
            //Recupera le assistenze del mese
            let assistancesResponse = initTasksResults[3];
            setAssistances(assistancesResponse.assistances);
            //Recupera festivit� della company dell'utente per l'anno selezionato
            const festivitiesResponse = initTasksResults[4];
            setFestivities(festivitiesResponse);

            await loadUserActions();

            setUserDataReady(true);
            //Se in precedenza c'� stato un errore faccio reset
            if (error !== "") {
                setError("");
            }
        } catch (error) {
            console.log((error as Error).message);
            setError(LOAD_ERRROR_MESSAGE);
        }
    }, [selectedPeriod, selectedUser]);

    const getSSFContracts = useCallback(async (email: string) => {
        //Recupera contratti da SSF per il periodo e l'utente selezionati     
        try {
            const ssfContracts = await getUserContracts(email, new Date(selectedPeriod.year, selectedPeriod.month - 1, 1), new Date(selectedPeriod.year, selectedPeriod.month, 0));
            setUserContracts(ssfContracts);
        } catch (exception) {
            console.log(`Errore in recupero contratti SSFF per l'utente ${email}`);            
        }

    }, [selectedPeriod, selectedUser]);

    const loadMonthlyRows = () => {
        try {
            //console.log("loadMonthlyRows");
            let tmpMonthlyRows: { key: string, items: any[] }[] = [];
            //Recupero tutte le action
            const actions: IActionType[] = cookieHelper.getApplicationCookies.getActionTypesCookie();
            const canEdit: boolean = appState.userType === UserType.HR || appState.userType === UserType.Admin || ((orgChart !== undefined && orgChart !== null) && orgChart.users.length > 0)
            if (userCanEdit !== canEdit) {
                setUserCanEdit(canEdit);
            }

            let tmpCsvRows: string[] = [];
            for (let index = 0; index < timesheet.days.length; index++) {
                const tsDay: ITimesheet = timesheet.days[index];
                const weekDay: number = tsDay.day.getDay();
                const dayAnomalies = anomalies.anomalies.filter(i => moment(i.date).format("YYYY-MM-DD") === moment(tsDay.day).format("YYYY-MM-DD"));
                const montlyRowInfo: IMonthlyRowInfo = createMonthlyRow(tsDay, actions, dayAnomalies, assistances, canEdit);

                if (weekDay === 1) {
                    //Inserisco la riga con il numero della settimana
                    const weeknumber = moment(tsDay.day, "MMDDYYYY").isoWeek();
                    tmpMonthlyRows.push({
                        key: `row_week_${weeknumber}`,
                        items: [{
                            key: `week_${index}`,
                            content: <Flex fill ><Text content={`Settimana ${weeknumber}`} size="larger" /></Flex>,
                            style: { width: "100%", backgroundColor: `${appState.teamsContext.theme === 'default' ? 'rgb(237, 235, 233,0.26)' : 'rgb(59, 58, 57, 0.64)'}` }
                        }]
                    });
                }
                tmpMonthlyRows.push({
                    key: `row_${moment(tsDay.day).format('DDMMYYYY')}`,
                    items: montlyRowInfo.items
                });
                tmpCsvRows.push(montlyRowInfo.csvRow);

            };
            setMonthlyRows(tmpMonthlyRows);
            setCsvRows(tmpCsvRows);
        } catch (error) {
            debugger;
            setError(LOAD_ERRROR_MESSAGE);
        }
    }

    const createMonthlyRow = (tsDay: ITimesheet, actions: IActionType[], dayAnomalies: IAnomaly[], assistances: IAssistance[], canEdit: boolean): IMonthlyRowInfo => {
        let items: any[] = [];
        let csvRow: string = "";
        let result: IMonthlyRowInfo = {
            items: [],
            csvRow: ""
        };

        const assStatuses: IAssistanceStatus[] = cookieHelper.getApplicationCookies.getAssistanceStatusesCookie();
        const idInAttesa: number = assStatuses.filter(as => { return as.name === 'In attesa' })[0].id;
        const index: number = new Date().getMilliseconds();
        const isWeekEnd: boolean = (tsDay.day.getDay() === 6 || tsDay.day.getDay() === 0) ? true : false;

        //Colonna con la data
        items.push(
            {
                key: `date_${index}`,
                content: <CalendarDate date={tsDay.day} anomalies={dayAnomalies} anomalyTypes={anomalyTypes} ticketRestaurant={tsDay.ticketRestaurant !== null ? tsDay.ticketRestaurant : false} />,
                style: { maxWidth: "9%" }
            });
        //Colonna assistenze
        items.push(
            {
                key: `assistance_${index}`,
                content: <MonthlyAssistances assistances={assistances.filter(ass => { return ass.status === idInAttesa })} actions={actions} date={tsDay.day} />,
                style: { maxWidth: "1.5%" }
            });
        //Colonna Attivit�
        items.push(
            {
                key: `activities_${index}`,
                content: <MonthlyActivities idUserSF={selectedUser.idUserSF} userActions={userActions} activities={tsDay.timesheet} actions={actions} festivities={festivities} date={tsDay.day} hoursPerDay={userContracts[0].hoursPerDay.asHours()} expectedWork={tsDay.expectedWork.hours()} idTimbraturaAction={idTimbraturaAction} idPranzoAction={idPranzoAction} idPausaAction={idPausaAction} onActivitiesChange={null} />,
                style: { textAlign: "center", minWidth: `${canEdit ? '20%' : '22%'}`, borderLeftColor: `${borderColor}`, borderLeftWidth: '0.0625rem' }
            });
        //Colonna Pausa
        items.push(
            {
                key: `break_${index}`,
                content: <MonthlyBreak editable={appState.userType === UserType.HR || appState.userType === UserType.Admin} activities={tsDay.timesheet} idBreakAction={idPausaAction} breakType="pausa" onActiviyChange={null} />,
                style: { textAlign: "center", minWidth: `${canEdit ? '7.5%' : '8%'}`, borderLeftColor: `${borderColor}`, borderLeftWidth: '0.0625rem' }
            });
        //Colonna Pranzo
        items.push(
            {
                key: `lunch_${index}`,
                content: <MonthlyBreak editable={appState.userType === UserType.HR || appState.userType === UserType.Admin} activities={tsDay.timesheet} idBreakAction={idPranzoAction} breakType="pranzo" onActiviyChange={null} />,
                style: { textAlign: "center", minWidth: `${canEdit ? '7.5%' : '8%'}`, borderLeftColor: `${borderColor}`, borderLeftWidth: '0.0625rem' }
            });
        //Colonna Assenze/Permesso
        //in apCsv salvo le info da visualizzare nel csv. Lo faccio sempre perch� in caso di creazione della riga csv dovrei fare esattamente le stesse operazioni
        let apCsv: string = "";
        const isFestivity: boolean = festivities.length === 0 ? false : festivities[0].dates.filter((f) => { return moment(f).format("DD/MM/YYYY") === moment(tsDay.day).format("DD/MM/YYYY"); }).length > 0;

        items.push(
            {
                key: `other_${index}`,
                content: !isFestivity ? <Flex fill className="otherMain" gap="gap.small" >
                    {
                        tsDay.timesheet.filter(t => {
                            return otherActionIds.indexOf(t.idActionType) >= 0;
                        }).map(element => {
                            var otherActions = actions.filter(a => a.id === element.idActionType);
                            if (otherActions.length === 1) {

                                let diff: string = "";
                                let unit: string = "";

                                //const hDiff: number = moment(element.endDate).diff(moment(element.startDate), 'hours');
                                const mDiff: number = moment(element.endDate).diff(moment(element.startDate), 'minutes');
                                //const diffDuration = moment.duration(mDiff, 'minutes');
                                // if (hDiff === 0) {
                                //     diff = mDiff;
                                //     unit = 'min'
                                // } else {
                                //     diff = hDiff;
                                //     unit = 'h';
                                // }

                                apCsv = mDiff > (userContracts[0].hoursPerDay.asHours() * 60) ? otherActions[0].title : `${moment(element.startDate).format("HH:mm")} ${otherActions[0].title} ${moment(element.endDate).format("HH:mm")}`;
                                return <React.Fragment key={`other_action_${element.id}`}>
                                    {/* <Clock20Regular /> */}
                                    {/* Se l'azione occupa l'intera giornata lavorativa non visualizzo inizio-fine e durata */}

                                    {mDiff > (userContracts[0].hoursPerDay.asHours() * 60) ?
                                        <>
                                            {/* Le ferie non vengono visualizzate il sabato e domenica */}
                                            {/* La malattia viene visualizzata il sabato o domenica solo per l'Italia */}
                                            {
                                                ((isWeekEnd === true && (otherActions[0].groupName === Constants.GROUP_NAME_FERIE || (otherActions[0].groupName === Constants.GROUP_NAME_MALATTIA) && selectedUser.companyCountry !== "ITA")) === false) &&
                                                <Flex fill className="c1" key={`other_${element.idActionType}`} hAlign="center">
                                                    <Text content={otherActions[0].title} />
                                                </Flex>
                                            }
                                        </>
                                        :
                                        <Flex column vAlign="center" key={`other_${element.idActionType}`} className="c2">
                                            <Flex gap="gap.small" vAlign="center" fill >
                                                <Clock16Regular /> <Text truncated content={`${convertMinutesToHours(mDiff)} - ${otherActions[0].title}`} />
                                                {/* {`${diff} ${unit} | ${moment(element.startDate).format("HH:mm")} ${moment(element.endDate).format("HH:mm")} | ${otherActions[0].title}`} */}
                                            </Flex>
                                            <Flex gap="gap.small" fill space="between">
                                                <Flex gap="gap.small">
                                                    <div style={{ color: "green" }}>
                                                        {/* <DoorArrowRight20Regular /> */}
                                                        <ArrowRightIcon circular bordered size="smallest" />
                                                    </div>
                                                    <Text content={moment(element.startDate).format("HH:mm")} />
                                                </Flex>
                                                <Flex gap="gap.small">
                                                    <Text content={moment(element.endDate).format("HH:mm")} />
                                                    <div style={{ color: "red" }}>
                                                        <ArrowLeftIcon circular bordered size="smallest" />
                                                    </div>
                                                </Flex>
                                            </Flex>
                                        </Flex>

                                    }
                                </React.Fragment>
                            } else {
                                return <span>Non identificato</span>
                            }

                        })
                    }
                </Flex> :
                    <Flex fill className="otherMain" gap="gap.small" ></Flex>
                ,
                style: { textAlign: "center", minWidth: `${canEdit ? '18%' : '24%'}`, borderLeftColor: `${borderColor}`, borderLeftWidth: '0.0625rem' }
            });

        //Colonna Eccedenze
        let showData = true;
        //Se � un responsabile non deve vedere/modificare i propri overtimes
        if ((orgChart !== undefined && orgChart !== null) && orgChart.users.length > 0) {
            if (selectedUser.idUserSF === appState.idUserSF) {
                showData = false;
            }
        }
        if (canEdit) {

            let mdo = {
                key: `overtimes_${index}`,
                style: { textAlign: "center", minWidth: "25%", borderLeftColor: `${borderColor}`, borderLeftWidth: '0.0625rem' }
            } as any;

            if (showData) {
                mdo.content = (tsDay.overtime !== undefined && tsDay.overtime !== null) ? <MonthlyDayOvertime idUserSF={selectedUser.idUserSF} idTimbraturaAction={idTimbraturaAction} timesheets={tsDay.timesheet} overtimeInformation={tsDay.overtime} userCountry={selectedUser.companyCountry} onOvertimeChange={null} /> : null;
            } else {
                mdo.content = <div />
            }

            items.push(mdo);
        }
        //Colonna Note
        let note: string = "";
        //const approvedAssistances = assistances.filter(ass => { return ass.status !== idInAttesa && moment(ass.startDate).format("YYYY-MM-DD") === moment(tsDay.day).format("YYYY-MM-DD") });
        // approvedAssistances.forEach((ass) => {
        //     note += ass.comment != null ? `${ass.comment}; ` : '';
        // });

        //const todayTimesheets1 = tsDay.timesheet.filter(t => (moment(t.startDate).format("YYYY-MM-DD") === moment(tsDay.day).format("YYYY-MM-DD") || moment(t.endDate).format("YYYY-MM-DD") === moment(tsDay.day).format("YYYY-MM-DD")));
        //Il commento lo prendo dal timesheet e non dalle assistenze approvate per due motivi:
        // 1) Quando l'assistenza � approvata il commento viene riportato anche sul timesheet
        // 2) Quando HR inserisce dal mensile non viene creata l'assistenza, ma direttamente il timesheet
        const todayTimesheets = tsDay.timesheet.filter(t => moment(t.startDate).date >= moment(tsDay.day).date && moment(t.endDate).date <= moment(tsDay.day).date);

        //Verifico se l'azione � malattia
        const idsMalattia = actions.filter(a => a.groupName === Constants.GROUP_NAME_MALATTIA).map(action => action.id);
        const isMalattia = todayTimesheets.filter(t => idsMalattia.includes(t.idActionType)).length > 0 ? true : false;

        let writeNote: boolean = true;

        if (isMalattia && isWeekEnd && selectedUser.companyCountry !== "ITA") {
            writeNote = false;
        }

        if (writeNote) {
            todayTimesheets.forEach((ts) => {
                note += ts.comment != undefined && !note.includes(ts.comment) ? `${ts.comment}; ` : '';
            });
        }
        items.push(
            {
                key: `note_${index}`,
                content: <Tooltip trigger={<div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}><Text truncated content={note} /></div>} content={note} />,
                style: { textAlign: "center", minWidth: `${canEdit ? '11%' : '27%'}`, borderLeftColor: `${borderColor}`, borderLeftWidth: '0.0625rem' }
            });

        if (appState.userType === UserType.HR || appState.userType === UserType.Admin || (orgChart !== undefined && orgChart.users.length > 0)) {
            //Creo la riga da aggiugnere al csv
            //Data
            csvRow += `${moment(tsDay.day).format("DD/MM/YYYY")};`;
            //Attivit� e Modalit� di lavoro
            csvRow += `${buildActivityCsvColumn(actions, tsDay.timesheet)};`;
            //Pause
            let pInfo: string = "";
            tsDay.timesheet.filter(t => t.idActionType === idPausaAction).forEach((a) => {
                pInfo += `${moment(a.startDate).format("HH:mm")} - ${moment(a.endDate).format("HH:mm")} `

            });
            csvRow += `${pInfo};`;
            //Pausa pranzo
            pInfo = "";
            tsDay.timesheet.filter(t => t.idActionType === idPranzoAction).forEach((a) => {
                pInfo += `${moment(a.startDate).format("HH:mm")} - ${moment(a.endDate).format("HH:mm")} `

            });
            csvRow += `${pInfo};`;
            //Assenze/permessi
            csvRow += `${apCsv};`;
            //Ore Dovute
            csvRow += `${tsDay.expectedWork.hours().toString().padStart(2, "0")}:${tsDay.expectedWork.minutes().toString().padStart(2, "0")} ;`;
            //Ore Presenza            
            csvRow += `${tsDay.actualWork.hours().toString().padStart(2, "0")}:${tsDay.actualWork.minutes().toString().padStart(2, "0")};`;
            //Ore riconosciute
            //prefix = tsDay.expectedWork.hours() < 0 || tsDay.expectedWork.minutes() < 0 ? ' -' : ' ';
            csvRow += `${tsDay.actualWork.asMinutes() > tsDay.expectedWork.asMinutes() ? (tsDay.expectedWork.hours().toString().padStart(2, "0") + ':' + tsDay.expectedWork.minutes().toString().padStart(2, "0")) : (tsDay.actualWork.hours().toString().padStart(2, "0") + ':' + tsDay.actualWork.minutes().toString().padStart(2, "0"))};`;
            //csvRow += `${tsDay.actualWork.asMinutes() > tsDay.expectedWork.asMinutes() ? (tsDay.expectedWork.hours().toString().replace("-", "").padStart(2, "0") + ':' + tsDay.expectedWork.minutes().toString().replace("-", "").padStart(2, "0")) : (tsDay.actualWork.hours().toString().replace("-", "").padStart(2, "0") + ':' + tsDay.actualWork.minutes().toString().replace("-", "").padStart(2, "0"))};`;
            //Differenza
            const minutesDiff: number = tsDay.actualWork.asMinutes() - tsDay.expectedWork.asMinutes();
            const diffDuration = moment.duration(minutesDiff, 'minutes');
            if (minutesDiff < 0) {
                csvRow += ` -${diffDuration.hours().toString().replace("-", "").padStart(2, "0")}:${diffDuration.minutes().toString().replace("-", "").padStart(2, "0")};`
            } else {
                csvRow += ` ${diffDuration.hours().toString().padStart(2, "0")}:${diffDuration.minutes().toString().padStart(2, "0")};`;
            }

            //csvRow += `${(tsDay.actualWork - tsDay.expectedWork).toFixed(2)};`;

            //Ore compensazione approvate
            let compensatedValue: number = tsDay.overtime?.compensatedMinutes ? tsDay.overtime?.compensatedMinutes : 0;
            csvRow += `${convertMinutesToHours(compensatedValue)};`;
            //Ore pagate
            let paidValue: number = tsDay.overtime?.paidMinutes ? tsDay.overtime?.paidMinutes : 0;
            csvRow += `${convertMinutesToHours(paidValue)};`;
            //Note
            csvRow += `${note};`
            csvRow += "\r\n";
        }

        result.items = items;
        result.csvRow = csvRow;

        return result;
    }

    const convertMinutesToHours = (minutes: number): string => {
        //let compensatedValue : string = tsDay.overtime?.compensatedMinutes ? tsDay.overtime?.compensatedMinutes.toString() : "0";
        const duration = moment.duration((minutes / 60), 'hours');
        return `${duration.hours().toString().padStart(2, "0")}:${duration.minutes().toString().padStart(2, "0")}`;
    }

    const buildActivityCsvColumn = (actions: IActionType[], timesheetActions: ITimesheetAction[]): string => {
        let row: string = "";
        const isFestivity: boolean = festivities.length === 0 ? false : festivities[0].dates.filter((f) => { return moment(f).format("DD/MM/YYYY") === moment(props.date).format("DD/MM/YYYY"); }).length > 0;
        if (isFestivity) {
            row += "FESTIVITA'";
        } else {
            //Recupero solo le azioni che devono essere presenti sul CSV 
            const actionsToFilter = actions.filter((action: IActionType) => {
                return action.groupName === Constants.GROUP_NAME_TIMBRATURA || action.groupName === Constants.GROUP_NAME_FERIE || action.groupName === Constants.GROUP_NAME_MALATTIA || action.groupName === Constants.GROUP_NAME_TRASFERTA;
            });
            //Filtro le attivit� del giorno per prendere solo quelle presenti in actionsToFilter
            const activities = timesheetActions.filter((activity: ITimesheetAction) => {
                const filtered = actionsToFilter.filter((action: IActionType) => {
                    return action.id === activity.idActionType;
                });
                return filtered.length > 0;
            });

            let wModes = "";
            activities.forEach((activity) => {
                let hDiff = 0;
                const activityTile = actionsToFilter.filter(a => a.id === activity.idActionType)[0].title;
                if (activity.startDate !== null && activity.endDate !== null) {
                    hDiff = moment(activity.endDate).diff(moment(activity.startDate), 'hours')
                }
                //Per le azioni che occupano l'intera gioranta diverse da timbratura non metto inizio e fine
                if (activity.idActionType !== idTimbraturaAction && hDiff > userContracts[0].hoursPerDay.asHours()) {
                    row += ` ${activityTile} `
                } else {
                    row += ` ${moment(activity.startDate).format("HH:mm")} ${activityTile} ${moment(activity.endDate).isValid() ? moment(activity.endDate).format("HH:mm") : ""} `;
                    const wModeName = workingModes.filter(a => a.id === activity.idWorkingMode);
                    if (wModeName.length === 1) {
                        wModes += ` ${wModeName[0].name} `;
                    }
                }
            });
            row += `; ${wModes} `;
        }
        return row;
    }

    //Rifaccio la query per aggiornare una singola riga modficata
    const refreshTableRow = async (day: Date): Promise<void> => {
        let tmpMonthlyRows: { key: string, items: any[] }[] = [...monthlyRows];
        let rowToModifyIndex: number = tmpMonthlyRows.findIndex((row) => row.key === `row_${moment(day).format('DDMMYYYY')}`);
        //Visualizzo loader sulla riga
        tmpMonthlyRows[rowToModifyIndex].items = [
            {
                key: "loaderrow",
                content: <Loader labelPosition="end" label={SiaStrings.LOADING} size="smallest" />,
                style: { maxWidth: "100%" }
            }];
        setMonthlyRows(tmpMonthlyRows);

        const actions: IActionType[] = cookieHelper.getApplicationCookies.getActionTypesCookie();
        let startDate: Date = new Date(day);
        let endDate: Date = new Date(day);
        startDate.setHours(0);
        startDate.setMinutes(0);
        startDate.setSeconds(0);
        endDate.setHours(23);
        endDate.setMinutes(59);
        endDate.setSeconds(59);

        let initTasks: Promise<any>[] = [];
        initTasks.push(getSiaUserTimesheet(selectedUser.idUserSF, startDate, endDate));
        initTasks.push(getAnomalies(selectedUser.idUserSF, startDate, endDate));
        initTasks.push(getAssistances(selectedUser.idUserSF, startDate, endDate));
        try {
            const initTasksResults = await Promise.all(initTasks);
            //Recupera timbrature da SIA per il giorno e l'utente selezionato
            const timesheetResponse = initTasksResults[0] as IUserTimesheetResponse;
            //Recupera le anomalie per il giorno e l'utente selezionato
            const anomaliesResponse = initTasksResults[1] as IAnomalyResponse;
            //Recupera le assistenze per il giorno e l'utente selezionato
            const assistancesResponse = initTasksResults[2] as IUserAssistanceResponse;
            const canEdit: boolean = appState.userType === UserType.HR || appState.userType === UserType.Admin || ((orgChart !== undefined && orgChart !== null) && orgChart.users.length > 0)
            const montlyRowInfo: IMonthlyRowInfo = createMonthlyRow(timesheetResponse.days[0], actions, anomaliesResponse.anomalies, assistancesResponse.assistances, canEdit);
            //Riassegno tmpMonthlyRows perch� altrimenti non viene aggiornato lo stato
            tmpMonthlyRows = [...monthlyRows];
            tmpMonthlyRows[rowToModifyIndex].items = [...montlyRowInfo.items];
            setMonthlyRows(tmpMonthlyRows);
            //Se in precedenza c'� stato un errore faccio reset
            if (error !== "") {
                setError("");
            }
        } catch (error) {
            debugger;
            console.log((error as Error).message);
            setError(LOAD_ERRROR_MESSAGE);
        }
    }

    const createCsv = () => {
        let csv: string = "Data;Attivita';Modalita' di lavoro;Pause;Pausa Pranzo;Assenze/Permessi;Ore Dovute;Ore Presenza;Ore Riconosciute;Differenza;Ore Compensazione approvate;Ore Pagamento Approvate;Note\r\n";
        csvRows.forEach(r => csv += r);
        var aLink = document.createElement('a');
        //Inverto nome e cognome
        //const splittedName = selectedUser.fullName.split(' ');
        //if (splittedName.length >= 2){
        //    const fName : string = splittedName.shift() as string;
        //    splittedName.push(fName);
        //}
        //const name = splittedName.length >= 2 ? splittedName.join(' ') : selectedUser.fullName;
        const name = selectedUser.lastName + ' ' + selectedUser.firstName;
        aLink.download = `${name}_${selectedUser.idUserSF}_${selectedPeriod.month}_${selectedPeriod.year}.csv`;
        aLink.href = 'data:text/csv;charset=UTF-8,' + encodeURIComponent(csv);
        var event = new MouseEvent('click');
        aLink.dispatchEvent(event);
    }

    if (error !== "") {
        return <Flex fill space="around" style={{ height: `${size.height}px` }}>
            <Warning label={error} details={errorDetail} />
        </Flex>
    }

    if (appState.isMobile) {
        return <Warning label={'Funzionalità non supportata su dispositivi mobile'} height={size.height} />
    }

    return <Flex column fill className="newmonthly-main-container" style={{ height: `${size.height}px` }}>
        {/* SELEZIONE UTENTE E PERIODO */}
        {(selectedUser.idUserSF !== '' && orgChart && orgChart.idUserSF !== "") &&
            <Flex id="header" gap="gap.small" fill column padding="padding.medium" styles={{ height: 'auto !important' }}>
                <span className="newmonthly-titles"><b>{selectedUser.fullName} </b> - {(userContracts && userContracts.length > 0) ? userContracts[0].roleName : ""}</span>
                <Flex fill vAlign="center" space="between" >
                    <Flex style={{ width: "75%" }} vAlign="center">
                        {(appState.userType === UserType.HR || appState.userType === UserType.Admin || ((orgChart !== undefined && orgChart !== null) && orgChart.users.length > 0)) &&
                            <Flex style={{ paddingTop: "3px" }}>
                                <UserSelector onSelectedUserChange={selectedUserChanged} disabled={loading} allUsers={true} loadCompany={true} period={selectedPeriod} />
                            </Flex>
                        }
                        <CustomPeriodSelector userType={appState.userType} onSelectedPeriodChange={setSelectedPeriod} disabled={loading} />
                    </Flex>
                    {/* Il saldo ferie � visibile SOLO agli utenti CH (user,hr e responsabili)*/}
                    {holidays !== null &&
                        (selectedUser.companyCountry === process.env.REACT_APP_CHE_CODE) &&
                        <MonthlyUserHolidays holidays={holidays} />
                    }
                    {/* Pulsante per visualizzare box di dettaglio visibile per HR e responsabile */}
                    {(appState.userType === UserType.HR || appState.userType === UserType.Admin || ((orgChart !== undefined && orgChart !== null) && orgChart.users.length > 0)) &&
                        <Flex gap="gap.small">
                            <MonthlyUserReport userId={selectedUser.idUserSF} userBirthDate={selectedUser.dateOfBirth ? selectedUser.dateOfBirth : ''} userCountry={selectedUser.companyCountry} period={selectedPeriod} />
                            <Button icon={<DownloadIcon />} disabled={loading} iconOnly title="Esporta in csv" onClick={(ev: any) => {
                                createCsv();
                            }} />
                        </Flex>
                    }

                </Flex>
            </Flex>
            // <Flex id="header" gap="gap.small" fill vAlign="center" space="between" >
            //     <Flex vAlign="center" gap="gap.small" column>
            //         <span className="newmonthly-titles"><b>{selectedUser.fullName} </b> - {(userContracts && userContracts.length > 0) ? userContracts[0].roleName : ""}</span>
            //         <CustomPeriodSelector userType={appState.userType} onSelectedPeriodChange={setSelectedPeriod} disabled={loading} />
            //         {(appState.userType === UserType.HR || appState.userType === UserType.Admin || ((orgChart !== undefined && orgChart !== null) && orgChart.users.length > 0)) &&
            //             <div style={{ width: '272px' }}>
            //                 <UserSelector onSelectedUserChange={setSelectedUser} disabled={loading} allUsers={true} />
            //             </div>
            //         }
            //     </Flex>
            //     {/* {holidays !== null &&
            //         <MonthlyUserHolidays holidays={holidays} />
            //     } */}
            // </Flex>
        }
        {loading &&
            <Flex fill space="around" style={{ height: `${size.height ?? 0 + 150}px` }}>
                <Loader label={SiaStrings.LOADING} style={{ paddingTop: '20px', paddingBottom: '20px' }} />
            </Flex>
        }
        {!loading &&
            <Flex fill column id="prova001" style={{ overflow: 'auto' }}>
                {/* Visualizzo le colone non come header della Table, in questo modo riesco a tenerle fisse mentre la tabella scrolla */}
                <Flex fill className="stickyHeaderContainer">
                    {tableHeaderSticky.items.map((colHeader) => { return colHeader })}
                </Flex>
                <Divider />
                <Flex fill id="tableContainer" style={{ overflow: 'auto' }} >
                    {(userContracts.length > 0) ?
                        <Table
                            style={{ width: '100%' }}
                            //Scommentare se si vuole utilizzare tableHeader al posto di tableHeaderSticky                            
                            // header={tableHeader}
                            //fare .map � necessario per fare in modo che le righe vengano (ri)create nello stesso "scope" del metodo return, altrimenti la funzione refreshTableRow richiamata dalla propriet� 
                            //onActivitiesChange del componente <MonthlyActivities/> avrebbe uno state diverso da quello del componente renderizzato. Ad esempio lo state monthlyRows avrebbe il valore PRECEDENTE
                            //a quando le righe vengono create. Per una prova � sufficiente sostiturie la propriet� rows con rows={monthlyRows} e constatare che quando si cambia periodo o utente e viene modificata una riga (quindi viene richiamata
                            //la funzione refreshTableRow) il valore di monthlyRows contiene ancora le righe del mese precedente 
                            rows={monthlyRows.map((row) => {
                                const newRow = { ...row };
                                // console.log(`ricreo row ${row.key}`);
                                let activitiesComponent = newRow.items.filter((item) => {
                                    return item.key.startsWith("activities_");
                                })[0];
                                if (activitiesComponent && activitiesComponent.content && activitiesComponent.content !== null) {
                                    activitiesComponent.content = <MonthlyActivities key={activitiesComponent.content.props.key} idUserSF={activitiesComponent.content.props.idUserSF}
                                        userActions={activitiesComponent.content.props.userActions} activities={activitiesComponent.content.props.activities}
                                        actions={activitiesComponent.content.props.actions}
                                        festivities={activitiesComponent.content.props.festivities} date={activitiesComponent.content.props.date}
                                        hoursPerDay={activitiesComponent.content.props.hoursPerDay} expectedWork={activitiesComponent.content.props.expectedWork}
                                        idTimbraturaAction={activitiesComponent.content.props.idTimbraturaAction}
                                        idPranzoAction={activitiesComponent.content.props.idPranzoAction}
                                        idPausaAction={activitiesComponent.content.props.idPausaAction}
                                        onActivitiesChange={refreshTableRow} />
                                }

                                let breakComponent = newRow.items.filter((item) => {
                                    return item.key.startsWith("break_");
                                })[0];
                                if (breakComponent && breakComponent.content && breakComponent.content !== null) {
                                    breakComponent.content = <MonthlyBreak key={breakComponent.content.props.key} editable={breakComponent.content.props.editable} activities={breakComponent.content.props.activities} idBreakAction={breakComponent.content.props.idBreakAction} breakType="pausa" onActiviyChange={refreshTableRow} />
                                }

                                let lunchComponent = newRow.items.filter((item) => {
                                    return item.key.startsWith("lunch_");
                                })[0];
                                if (lunchComponent && lunchComponent.content && lunchComponent.content !== null) {
                                    lunchComponent.content = <MonthlyBreak key={lunchComponent.content.props.key} editable={lunchComponent.content.props.editable} activities={lunchComponent.content.props.activities} idBreakAction={lunchComponent.content.props.idBreakAction} breakType="pranzo" onActiviyChange={refreshTableRow} />
                                }

                                let overtimeComponent = newRow.items.filter((item) => {
                                    return item.key.startsWith("overtimes_");
                                })[0];
                                if (overtimeComponent && overtimeComponent !== null && overtimeComponent.content && overtimeComponent.content !== null) {
                                    if (overtimeComponent.content.type === "div") {
                                        //Caso in cui il responsabile non deve vedere le proprie eccedenze
                                        overtimeComponent.content = <div />;
                                    } else {
                                        overtimeComponent.content = <MonthlyDayOvertime key={overtimeComponent.content.props.key} idUserSF={overtimeComponent.content.props.idUserSF} idTimbraturaAction={overtimeComponent.content.props.idTimbraturaAction} timesheets={overtimeComponent.content.props.timesheets} userCountry={overtimeComponent.content.props.userCountry} overtimeInformation={overtimeComponent.content.props.overtimeInformation} onOvertimeChange={refreshTableRow} />
                                    }
                                }

                                return newRow;
                            })}
                        />
                        :
                        <Flex fill space="around" style={{ height: `${size.height}px` }}>
                            <Warning label="Nessun contratto trovato per il periodo selezionato" />
                        </Flex>
                    }

                </Flex>
            </Flex>
        }
    </Flex>
}

export default NewMonthly;