import { Flex, Dropdown, Button, DownloadIcon, Checkbox, Tooltip } from "@fluentui/react-northstar";
import { IDropdownItem } from "../interfaces/IDropdownItem";

export interface IStaffFilterProps {
    userItems: IDropdownItem[];
    locationItems: IDropdownItem[];
    areaItems: IDropdownItem[];
    roleItems: IDropdownItem[];
    managerItems: IDropdownItem[];
    onFilterChanged: any;
    onDownloadClick: any;
}

const StaffFilters = ({ userItems, locationItems, areaItems, roleItems, managerItems, onFilterChanged, onDownloadClick }: IStaffFilterProps) => {
    const visible: boolean = userItems.length > 0 && locationItems.length > 0 && areaItems.length > 0 && roleItems.length > 0 && managerItems.length > 0 && onFilterChanged !== undefined;

    return <>
        {visible &&
            <Flex wrap hAlign="center" vAlign="center"  >
                <div className="staff-filter-field">
                    <Dropdown
                        search
                        fluid
                        clearable
                        items={userItems}
                        className="staff-filter-field"
                        placeholder="Seleziona o cerca utente"
                        noResultsMessage="Nessun risultato trovato."
                        onChange={(event: any, data: any) => {
                            const userId: string = data.value ? data.value.key as string : '';
                            onFilterChanged('userId', userId);
                        }}
                    />
                </div>
                <Dropdown
                    search
                    fluid
                    clearable
                    items={locationItems}
                    className="staff-filter-field"
                    placeholder="Sede"
                    noResultsMessage="Nessun risultato trovato."
                    onChange={(event: any, data: any) => {
                        const country: string = data.value ? data.value.key as string : '';
                        onFilterChanged('country', country);
                    }}
                />
                <Dropdown
                    search
                    fluid
                    clearable
                    items={areaItems}
                    placeholder="Area"
                    noResultsMessage="Nessun risultato trovato."
                    className="staff-filter-field"
                    onChange={(event: any, data: any) => {
                        const areaCode: string = data.value ? data.value.key as string : '';
                        onFilterChanged('areaCode', areaCode);
                    }}
                />
                <Dropdown
                    search
                    fluid
                    clearable
                    items={roleItems}
                    placeholder="Ruolo"
                    noResultsMessage="Nessun risultato trovato."
                    className="staff-filter-field"
                    onChange={(event: any, data: any) => {
                        const roleCode: string = data.value ? data.value.key as string : '';
                        onFilterChanged('roleCode', roleCode);
                    }}
                />
                <Dropdown
                    search
                    fluid
                    clearable
                    items={managerItems}
                    placeholder="Responsabile"
                    className="staff-filter-field"
                    noResultsMessage="Nessun risultato trovato."
                    onChange={(event: any, data: any) => {
                        const managerId: string = data.value ? data.value.key as string : '';
                        onFilterChanged('managerId', managerId);
                    }}
                />
                <Checkbox label="Mostra presenti" onChange={(ev: any, data: any) => {
                    onFilterChanged('state', data.checked);
                }} toggle />
                <Tooltip trigger={<Button icon={<DownloadIcon />} text iconOnly title="Esporta in csv" onClick={(ev: any) => {
                    onDownloadClick();
                    //var csv = "";
                    // for (var i = 0; i <= colleagues.length; i++) {

                    //     if (colleagues[i] !== undefined) {
                    //         csv += colleagues[i].key + ";";
                    //         csv += colleagues[i].header + ";";
                    //         csv += colleagues[i].department + ";";
                    //         csv += colleagues[i].role + ";";
                    //         csv += colleagues[i].headerMedia + ";";
                    //         csv += "\r\n";
                    //     }
                    // }
                    // var aLink = document.createElement('a');
                    // aLink.download = "Colleghi.csv";
                    // aLink.href = 'data:text/csv;charset=UTF-8,' + encodeURIComponent(csv);
                    // var event = new MouseEvent('click');
                    // aLink.dispatchEvent(event);
                }} />}
                content="Esporta in csv" />
            </Flex>
        }
    </>
}

export default StaffFilters;