export enum UserType {
    User = "USER",
    HR = "HR",
    Admin = "Admin",
    Unknown = "Unknown"
}

export enum AssistanceAction {
    Approve = "APPROVE",
    Reject = "REJECT",
    Delete = "DELETE"
}

export enum FilterType {
    Departments = "departments",
    Roles = "roles",
    Managers = "managers",
    Countries = "countries",
    State = "state"
}

export enum LoadCommonDataStaus {
    Loading = "LOADING",
    Success = "SUCCESS",
    Failed = "FAILED"
}

export enum ControlType {
    DateTimePicker = "datetimepicker",
    DatePicker = "datepicker",
    TextField = "textfield",
    Select = "select"
}

export enum LunchType {
    NonPrevista = 0,
    Manuale = 1,
    Automatica = 2
}