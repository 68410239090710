import "../scss/NewAssistence.scss";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useLoadCommonData from "../hooks/useLoadCommonData";
import { LoadCommonDataStaus, UserType } from "../constants/AppEnum";
import { ITab } from "../interfaces/ITab";
import { ISize } from '../interfaces/ISize';
import useWindowSize from '../hooks/useWindowSize';
import { IFetchResponse } from '../interfaces/IFetchResponse';
import * as CookiesHelper from '../helpers/CookiesHelper';
import { IAppState } from "../interfaces/IAppState";
import * as appActions from '../stores/actions/AppActions';
import { Flex, Text, Loader, Alert, InfoIcon } from "@fluentui/react-northstar";
import Warning from "./Warning";
import { getUserAssistances, getUserContracts } from '../helpers/Fetch';
import { INewAssistenceResponse, IAssistanceGroup } from "../interfaces/INewAssistenceResponse";
import NewAssistenceTabContent from "./NewAssistenceTabContent";
import UserSelector from "./UserSelector";
import { ISelectedUser } from "../interfaces/ISelectedUser";
import { SiaStrings } from "../strings/strings";

interface ITabProps {
    group: IAssistanceGroup;
}

const NewAssistence = (props: any) => {
    const appState: IAppState = useSelector((appState) => appState) as IAppState;

    const [tabs, setTabs] = useState<ITab[]>([]);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [error, setError] = useState('');
    const [userDataLoading, setUserDataLoading] = useState(false);
    const [selectedUser, setSelectedUser] = useState<ISelectedUser>({ email: '', idUserSF: '', fullName: '', companyCountry: '', lastName: '', firstName: '' });
    //Ore di lavoro previste da contratto
    const [hoursPerDayAsMinutes, setHoursPerDayAsMinutes] = useState(0);
    const size: ISize = useWindowSize();
    const commonDataStatus: LoadCommonDataStaus = useLoadCommonData();

    const loadUserData = async (idUserSF: string) => {
        const response: IFetchResponse = await getUserAssistances(idUserSF);
        if (response.status === 1) {
            const assGroups: INewAssistenceResponse = response.payload;
            let index: number = 0;
            if (assGroups.groups) {
                const tmpTabs = assGroups.groups.map((group: IAssistanceGroup) => {
                    const props: ITabProps = {
                        group: group
                    };
                    const nTab: ITab = {
                        label: group.label,
                        index: index,
                        content: props
                    };
                    index++;
                    return nTab;
                });
                setTabs(tmpTabs);
            } else {
                setTabs([]);
            }
        } else {
            setError(response.message ? response.message : 'Errore imprevisto');
        }


    }

    const onSelectedUserChanged = async () => {
        if (selectedUser && selectedUser.idUserSF && appState.userType !== UserType.Unknown) {
            try {
                setUserDataLoading(true);
                await loadUserData(selectedUser.idUserSF);
            } catch (error) {
                console.log(`Errore in caricamento dati utenti : ${error}`);
                setError('Errore in caricamento dati utenti');
            } finally {
                setUserDataLoading(false);
            }
        }
        setSelectedIndex(0);
    }

    useEffect(() => {
        (async () => {
            if (commonDataStatus === LoadCommonDataStaus.Success) {
                try {

                    if (appState.userType === UserType.Unknown) {
                        setError('Imposssibile recuperare la tipologia di utente');
                        return;
                    } else {
                        const userEmail = appState.debug ? appState.debugUser : appState.teamsContext.userPrincipalName?.toLowerCase();
                        const org = await CookiesHelper.setApplicationCookies.setOrgChartCookie(userEmail);
                        //Al primo caricamento imposto come utente selezionato l'utente loggato
                        setSelectedUser({ email: org.userName, idUserSF: org.idUserSF, fullName: org.fullName, companyCountry: org.companyCountry, firstName: org.firstName, lastName: org.lastName });
                        //Recupero le ore di contratto per l'utente loggato
                        const userContract = await CookiesHelper.setApplicationCookies.setUserContractsCookie(org.userName);
                        setHoursPerDayAsMinutes(userContract[0].hoursPerDay.asMinutes());
                        //Carico i gruppi con le tipologie di assistenze che l'utente può richiedere                    
                        if (appState.idUserSF) {
                            await loadUserData(appState.idUserSF);
                        }
                    }
                } catch (error) {
                    setError('Si è verificato un errore imprevisto nel caricamento delle assistenze');
                }
            } else if (commonDataStatus === LoadCommonDataStaus.Failed) {
                setError('Si è verificato un errore imprevisto nel caricamento delle assistenze');
            }
        })();
    }, [commonDataStatus])

    useEffect(() => {
        const run = async () => {
            await onSelectedUserChanged();
            //Recupero il contratto dell'utente selezionato
            const userContract = await getUserContracts(selectedUser.email);
            setHoursPerDayAsMinutes(userContract[0].hoursPerDay.asMinutes());
        }
        if (selectedUser && selectedUser.idUserSF && appState.userType !== UserType.Unknown) {
            run();
        }
    }, [selectedUser])


    const refreshUserData = async () => {
        await onSelectedUserChanged();
    }

    if (commonDataStatus === LoadCommonDataStaus.Loading || tabs.length === 0) {
        return <Flex fill hAlign="center"><Loader label={SiaStrings.LOADING} style={{ paddingTop: '20px', paddingBottom: '20px' }} size="large" /> </Flex>

    }

    if (error !== '') {
        debugger;
        return <Warning label={error} showReload={true} height={size.height} />
    }

    return <Flex column fill gap="gap.large" style={{ height: `${size.height}px` }} padding="padding.medium" >
        {(appState.userType === UserType.HR) &&
            <Flex column gap="gap.medium">
                <Flex column gap="gap.medium" >
                    <div className="assistances-titles noselect">Nuova assistenza per l'utente <b>{`${selectedUser !== null ? selectedUser.fullName : ''}`}</b></div>
                    <Flex gap="gap.small" vAlign="center" padding="padding.medium">
                        <span>Se devi inserire un'assistenza per un altro utente selezionalo dalla lista : </span>
                        <div style={{ width: '272px' }}>
                            <UserSelector onSelectedUserChange={setSelectedUser} />
                        </div>
                    </Flex>
                    {/* <div style={{ width: '272px' }}>
                        <UserSelector onSelectedUserChange={setSelectedUser} />
                    </div> */}
                </Flex>
            </Flex>
        }
        {tabs.length > 0 &&
            <ul className="inline">
                {tabs.map((tab: any, index) => {
                    let style = index === selectedIndex ? 'selected' : '';
                    return <li key={index} onClick={(el) => {

                        const props: ITabProps = {
                            group: tabs[index].content.group
                        };
                        const nTab: ITab = {
                            label: tabs[index].content.group.label,
                            index: index,
                            content: props
                        };

                        const tmpTabs = tabs.map((tab: ITab) => {
                            if (tab.index === index) {
                                return nTab;
                            } else {
                                return {
                                    ...tab, content: {
                                        group: tab.content.group
                                    }
                                };
                            }
                        });
                        const newSelectedIndex = tabs[index].index;
                        setTabs(tmpTabs);
                        setSelectedIndex(newSelectedIndex);
                    }}>
                        <div className={style}>
                            <Text weight={index === selectedIndex ? "bold" : "regular"}  >{tab.label}</Text>
                        </div>
                    </li>
                })}
            </ul>
        }
        <Flex column fill>
            {userDataLoading === true ?
                <Loader label={SiaStrings.LOADING} style={{ paddingTop: '20px', paddingBottom: '20px' }} />
                :
                <>
                    {selectedUser ?
                        <NewAssistenceTabContent key={tabs[selectedIndex].content.group.label} props={tabs[selectedIndex].content} selUser={selectedUser} refreshData={refreshUserData} hoursPerDayAsMinutes={hoursPerDayAsMinutes} />
                        :
                        <Alert info icon={<InfoIcon />} content="Selezionare un utente per inserire una nuova assistenza" />
                    }

                </>
            }
        </Flex>
    </Flex >
}

export default NewAssistence
