import React, { useState } from "react";
import { SiaStrings } from "../strings/strings";
import { Flex, Loader, Dialog, Alert, CloseIcon, Tooltip } from "@fluentui/react-northstar";
import { DoorArrowRight20Regular, DoorArrowLeft20Regular } from '@fluentui/react-icons';
import { IAppState } from "../interfaces/IAppState";
import { useSelector } from "react-redux";
import moment from 'moment';
import { ITimesheetAction } from "../interfaces/ITimesheetAction";
import TimeSelector from "./TimeSelector";
import { IActionTime } from "../interfaces/IActionTime";
import { UserType } from "../constants/AppEnum";
import { updateTimeSheet } from "../helpers/Fetch";
import { ISaveResult } from "../interfaces/ISaveResult";

export interface IMonthlyActivityStartEndTimeProps {
    type: string;
    action: ITimesheetAction;
    showTime: boolean;
    idTimbraturaAction: number;
    trasferteIds : number[];
    onTimesheetUpdated: any;
    onDialogClose: any;
}

const MonthlyActivityStartEndTime = (props: IMonthlyActivityStartEndTimeProps) => {
    const appState: IAppState = useSelector((appState) => appState) as IAppState;
    const canEdit: boolean = props.action.idActionType === props.idTimbraturaAction ||  props.trasferteIds.includes(props.action.idActionType);
    const [modifiedAction, setModifiedAction] = useState<ITimesheetAction>(props.action);
    const [saving, setSaving] = useState(false);
    const [saveMessage, setSaveMessage] = useState<ISaveResult>({ message: "", error: false });
    const [showDialog, setShowDialog] = useState(false);

    const hh: number = (props.action.startDate !== null) ? parseInt(moment(props.action.startDate).format("HH"))
        : (props.action.endDate !== null) ? parseInt(moment(props.action.endDate).format("HH")) : 0;

    const mm: number = (props.action.startDate !== null) ? parseInt(moment(props.action.startDate).format("mm"))
        : (props.action.endDate !== null) ? parseInt(moment(props.action.endDate).format("mm")) : 0;

    const [actionTime, setActionTime] = useState<IActionTime>({
        hours: hh,
        minutes: mm
    })

    const updateAction = (newTime: IActionTime): void => {
        if (newTime.hours !== undefined && newTime.minutes !== undefined) {
            let tmpAction: ITimesheetAction = { ...modifiedAction };
            const newDate: Date = props.type === "start" ? new Date(props.action.startDate as Date) : new Date(props.action.endDate as Date);
            newDate.setHours(newTime.hours);
            newDate.setMinutes(newTime.minutes);
            if (props.type === "start") {
                tmpAction.startDate = newDate;
            } else {
                tmpAction.endDate = newDate;
            }
            setModifiedAction(tmpAction);
            setActionTime(newTime);
        }
    }

    const updateUserTimeSheet = async (item: ITimesheetAction): Promise<void> => {
        setSaving(true);
        try {
            const response = await updateTimeSheet(item);
            if (response.status === 1) {
                setSaveMessage({ message: SiaStrings.TIMESHEET_UPDATED, error: false });
                debugger;
                props.onTimesheetUpdated(response.payload);
            } else {
                setSaveMessage({ message: SiaStrings.GENERIC_ERROR, error: true })
            }
        } catch (error) {
            setSaveMessage({ message: SiaStrings.GENERIC_ERROR, error: true })
        } finally {
            setSaving(false);
        }

    }

    const createChildControls = () => {
        return <>
            {props.type === "start" ?
                <>
                    <div className="action-time-element" style={{ color: `${props.type === 'start' ? 'green' : 'red'}`, marginRight: '3px' }}>
                        <Tooltip trigger={<DoorArrowRight20Regular />} content={`${props.action.header} - ${moment(props.action.startDate).format("HH:mm")}`} />
                    </div>
                    {props.showTime &&
                        <span className="action-time action-time-element">{moment(props.action.startDate).format("HH:mm")}</span>
                    }
                </>
                :
                <>
                    {props.showTime &&
                        <span className="action-time action-time-element" style={{ marginRight: '3px' }}>{moment(props.action.endDate).format("HH:mm")}</span>
                    }
                    <div className="action-time-element" style={{ color: `${props.type === 'start' ? 'green' : 'red'}` }}>
                        <Tooltip trigger={<DoorArrowLeft20Regular />} content={`${props.action.header} - ${moment(props.action.endDate).format("HH:mm")}`} />
                    </div>
                </>
            }
        </>
    }

    return <Flex gap="gap.medium">
        <Flex gap="gap.small">
            {/* La modifica è abilitata solo per l'azione di timbratura se l'utente è HR o Admin */}
            {canEdit && (appState.userType === UserType.HR || appState.userType === UserType.Admin) ?
                <>
                    <Dialog
                        style={{ maxWidth: "400px" }}
                        cancelButton={{ content: "Chiudi", disabled: saving }}
                        confirmButton={{ content: "Salva", disabled: saving }}
                        closeOnOutsideClick={false}
                        open={showDialog}
                        headerAction={{ icon: <CloseIcon />, title: 'Close', onClick: () => setShowDialog(false) }}
                        content={
                            <>
                                {saving ?
                                    <Loader size="small" label="Aggiornamento in corso..." style={{ paddingTop: '20px', paddingBottom: '20px' }} />
                                    :
                                    <Flex gap="gap.small" column>
                                        <TimeSelector time={actionTime} onTimeModified={updateAction} showLabel={true} />
                                        {saveMessage.message !== "" &&
                                            <Alert content={saveMessage.message} success={!saveMessage.error} danger={saveMessage.error} />
                                        }
                                    </Flex>
                                }
                            </>
                        }
                        onConfirm={async () => {
                            if (saveMessage.message !== "") {
                                setSaveMessage({ message: "", error: false });
                            }
                            await updateUserTimeSheet(modifiedAction);
                        }}
                        onCancel={() => {
                            setShowDialog(false);
                            const date = (props.action.startDate && props.action.startDate !== null) ? props.action.startDate : props.action.endDate;
                            props.onDialogClose(date);
                        }}
                        onOpen={() => {
                            if (saveMessage.message !== "") {
                                setSaveMessage({ message: "", error: false });
                            }
                            setShowDialog(true);
                        }}
                        header={`Modifica ${props.type === "start" ? "Ingresso" : "Uscita"}`}
                        trigger={
                            <Flex gap="gap.small" style={{ cursor: "pointer" }} >
                                {createChildControls()}
                                {/* <div style={{ color: `${props.type === 'start' ? 'green' : 'red'}` }}>
                                    {props.type === "start" ? <DoorArrowRight20Regular /> : <DoorArrowLeft20Regular />}
                                </div>
                                <Text content={props.type === "start" ? moment(props.action.startDate).format("HH:mm") : moment(props.action.endDate).format("HH:mm")} /> */}
                            </Flex>
                        }
                    />
                </>
                :
                <>
                    {createChildControls()}
                    {/* {props.type === "start" ?
                        <>
                            <div style={{ color: `${props.type === 'start' ? 'green' : 'red'}` }}>
                                <DoorArrowRight20Regular />
                            </div>
                            <span>{moment(props.action.startDate).format("HH:mm")}</span>
                        </>
                        :
                        <>
                            <span>{moment(props.action.endDate).format("HH:mm")}</span>
                            <div style={{ color: `${props.type === 'start' ? 'green' : 'red'}` }}>
                                <DoorArrowLeft20Regular />
                            </div>
                        </>
                    } */}
                </>
            }
        </Flex>
    </Flex>
}

export default MonthlyActivityStartEndTime;