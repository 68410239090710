import { ControlType } from "../constants/AppEnum";

export interface IAssistanceDateControls {
    startDateControlId: string;
    endDateControlId: string;
}

export interface IAssistanceDateRange {
    minDate: Date | string | undefined;
    maxDate: Date | string | undefined;
}

export interface IAssistanceDates {
    startDate: Date | string | undefined;
    endDate: Date | string | undefined;
}

export interface IControlProps {
    controlId: string;
    value: string;
    type: ControlType;
}

export interface IOverlappedItemsDialog {
    title: string;
    messages: string[];
}

export interface IReplaceOption {
    name: string;
    key: string;
    label: string;
    value: string;
}

export enum OverlappedItemsType {
    Timesheet = "Timesheet",
    Assistances = "Assistances",
    All = "All"
}