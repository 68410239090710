
import { IEmployee } from '../../interfaces/IEmployee';
import { IAppState } from '../../interfaces/IAppState';
import { ITimesheetAction } from '../../interfaces/ITimesheetAction';
import { INotification } from '../../interfaces/INotification';
import * as microsoftTeams from "@microsoft/teams-js";
import * as cookieHelper from '../../helpers/CookiesHelper';
import { UserType } from '../../constants/AppEnum';

const AppState: IAppState = {
    teamsContextIsInitialized: false,
    teamsContext: {} as microsoftTeams.Context,
    ssfIsLoaded: false,
    ssfJobs: [] as Array<IEmployee>,
    idUserSF: null,
    selectedJob: cookieHelper.getApplicationCookies.getSelectedJobCookie(),
    todayStatusIsLoaded: false,
    todayStatus: [] as Array<ITimesheetAction>,
    isRegistering: false,
    actionToRegister: {} as ITimesheetAction,
    currentWorkingMode: cookieHelper.getApplicationCookies.getCurrentWorkingModeCookie(),
    notifications: [] as Array<INotification>,
    userCanBadge: true,
    debug: process.env.REACT_APP_SIA_DEBUG === "true",
    debugUser: process.env.REACT_APP_SIA_DEBUG_USER !== undefined ? process.env.REACT_APP_SIA_DEBUG_USER : '', 
    timbraturaAction: null,
    pausaAction: null,
    pranzoAction: null,
    userType: UserType.Unknown,
    isMobile : false
}
export default AppState;