import '../scss/Warning.scss';
import { Flex, Button, Text } from '@fluentui/react-northstar';
import { PeopleAlertIcon } from '@fluentui/react-icons-mdl2';

const Warning = (props: any) => {
      return <Flex column hAlign="center" vAlign="center" fill gap="gap.small" style={{ height: `${props.height ? props.height + 'px' : '100%'}` }} >
            <div className="warning-title">{props.label}</div>
            {props.details &&
                  <Text content={props.details} />
            }

            <PeopleAlertIcon className="warning-peolpe-alert-icon" />
            {props.showReload &&
                  <Button primary content="Ricarica" onClick={(event, data) => {
                        window.location.reload();
                        return false;
                  }} />
            }
      </Flex>
}
export default Warning;