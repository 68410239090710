import axios from 'axios';
import { IFetchResponse } from '../interfaces/IFetchResponse';
import { IActionTypeResponse } from '../interfaces/IActionTypeResponse';
import { IUserTimesheetResponse } from '../interfaces/IUserTimesheetResponse';
import { IWorkingModeResponse } from '../interfaces/IWorkingModeResponse';
import { ITimesheetAction } from '../interfaces/ITimesheetAction';
import { IOrgChartUser } from '../interfaces/IOrgChartUser';
import { IFestivityResponse } from '../interfaces/IFestivityResponse';
import { IAnomalyTypeResponse } from '../interfaces/IAnomalyTypeResponse';
import { IAssistanceStatusResponse } from '../interfaces/IAssistanceStatusResponse';
import { IAnomalyResponse } from '../interfaces/IAnomalyResponse';
import { IUserResponse } from '../interfaces/IUserResponse';
import { IHoliday } from '../interfaces/IHoliday';
import { IOvertime } from '../interfaces/IOvertime';
import { IUserAssistanceResponse } from '../interfaces/IUserAssistanceResponse';
import { mapSsfTimesheetsToTimesheets } from '../helpers/SuccessFactorsHelper';
import * as Constants from '../constants/AppConstants';
import moment from 'moment';
import { IOvertimeResponse } from '../interfaces/IOvertimeResponse';
import { ICalendarResponse } from '../interfaces/ICalendarResponse';
import { mapCalendars } from './SiaHelper';
import { IUserReportResponse } from '../interfaces/IUserReportResponse';
import { IReportExportInfo } from '../interfaces/IReportExportInfo';
import { FilterType, UserType } from '../constants/AppEnum';
import { IAssistanceVerifyRequest } from '../interfaces/IAssistanceVerifyRequest';
import { IOvertimeRecovery } from '../interfaces/IOvertimeRecovery';
import { ISummaryBoxResponse } from '../interfaces/ISummaryBoxResponse';


export const revocaAssistance = async (assistanceId: number, revocaMotivation: string): Promise<IFetchResponse> => {
  let url: string = `assistances/revoke/${assistanceId}`;

  try {
    const req = { comment: revocaMotivation }

    const response = await axios.delete(process.env.REACT_APP_SIA_API_URI + url, { data: req });
    return { status: 1, message: null, payload: response.data };
  } catch (error: any) {
    const errorMessage: string = error?.response?.data?.message ? error.response.data.message : `Errore in Revoca assistenza`;
    console.error(`revocaAssistance error : ${errorMessage}`);
    return { status: 0, message: `Errore nel revocare l'assistenza`, payload: error } as IFetchResponse;
  }
}

export const getUserContracts = async (email: string | null | undefined, fromDate: Date | null | undefined = null, toDate: Date | null | undefined = null): Promise<any> => {

  let url: string = "users/contracts";
  if (email !== "" && email !== null) {
    url = `${url}?email=${email}`;
  }

  //Passando la data attuale come start e end date viene restitutito il contratto attuale

  url = `${url}&startDate=${fromDate != null ? encodeURIComponent(moment(fromDate).format()) : encodeURIComponent(moment(new Date()).format())}`;
  url = `${url}&endDate=${toDate != null ? encodeURIComponent(moment(toDate).format()) : encodeURIComponent(moment(new Date()).format())}`;

  let result: any = {};
  try {
    const response = await axios.get(process.env.REACT_APP_SIA_API_URI + url);
    result = response.data.contracts;
    //Imposto il valore corretto per i campi data
    result.forEach((contract: any, index: number) => {
      contract.createdDateTime = (contract.createdDateTime !== null && contract.createdDateTime !== undefined) ? new Date(contract.createdDateTime) : null;
      contract.startDate = (contract.startDate !== null && contract.startDate !== undefined) ? new Date(contract.startDate) : null;
      contract.endDate = (contract.endDate !== null && contract.endDate !== undefined) ? new Date(contract.endDate) : null;
      contract.holidayAmountStartDate = (contract.holidayAmountStartDate !== null && contract.holidayAmountStartDate !== undefined) ? new Date(contract.holidayAmountStartDate) : null;
      contract.hoursPerDay = moment.duration(contract.hoursPerDay);
      contract.hoursPerSaturday = moment.duration(contract.hoursPerSaturday);
    });

  } catch (error: any) {
    const errorMessage: string = error?.response?.data?.message ? error.response.data.message : 'Errore in recupero contratti utente';
    console.error(`getEmpJob error : ${errorMessage}`)
    throw new Error("Si è verificato un errore imprevisto nel recupero dei contratti dell'utente.");
  }

  return result;
}

export const getOrgChart = async (email: string | null | undefined, startDate?: Date, endDate?: Date): Promise<IOrgChartUser> => {
  let url: string = "users/chart";
  if (email !== "" && email !== null) {
    url = `${url}?email=${email}`;
  }

  let prefix = "";

  if (startDate !== null && startDate !== undefined) {
    prefix = url.indexOf("?") >= 0 ? "&" : "?";
    url += `${prefix}startDate=${encodeURIComponent(moment(startDate).format())}`;
  }

  if (endDate !== null && endDate !== undefined) {
    prefix = url.indexOf("?") >= 0 ? "&" : "?";
    url += `${prefix}enddate=${encodeURIComponent(moment(endDate).format())}`;
  }

  try {
    const response = await axios.get(process.env.REACT_APP_SIA_API_URI + url);
    return response.data;
  } catch (error: any) {
    const errorMessage: string = error?.response?.data?.message ? error.response.data.message : 'Errore in recupero org chart utente';
    console.error(`getOrgChart error : ${errorMessage}`)
    throw new Error("Si è verificato un errore imprevisto nel recupero dell'organigramma dell'utente.");
  }

}

export const getSiaWorkingModes = async (): Promise<IWorkingModeResponse> => {
  const url: string = "workingmodes";
  try {
    const response = await axios.get(process.env.REACT_APP_SIA_API_URI + url);
    return response.data;
  } catch (error: any) {
    const errorMessage: string = error?.response?.data?.message ? error.response.data.message : 'Errore in recupero working modes';
    console.error(`getSiaWorkingModes error : ${errorMessage}`)
    throw new Error("Si è verificato un errore imprevisto nel recupero delle modalità di lavoro.");
  }
}

export const getSiaActionTypes = async (idUserSF: string | null, calendar: boolean = false): Promise<IActionTypeResponse> => {
  let url: string = "actions";
  if (idUserSF !== null && idUserSF !== '') {
    url += `?idUserSF=${idUserSF}`;
  }

  if (calendar) {
    url += `${(idUserSF !== null && idUserSF !== '') ? '&calendar=true' : '?calendar=true'}`;
  }

  let result: IActionTypeResponse = {} as IActionTypeResponse;
  try {
    const response = await axios.get(process.env.REACT_APP_SIA_API_URI + url, {});
    result = response.data;
    // result.actions.forEach(element => {
    //   element.title = element.groupName === Constants.GROUP_NAME_TIMBRATURA ? 'Lavoro' : element.title;
    // });
  } catch (error: any) {
    const errorMessage: string = error?.response?.data?.message ? error.response.data.message : 'Errore in recupero action types';
    console.error(`getSiaActionTypes error : ${errorMessage}`)
    throw new Error("Si è verificato un errore imprevisto nel recupero delle tipologie di azioni.");
  }
  return result;
}


export const getSiaActionMonthlyPage = async (): Promise<IActionTypeResponse> => {
  let url: string = "actions/monthlypage";

  let result: IActionTypeResponse = {} as IActionTypeResponse;
  try {
    const response = await axios.get(process.env.REACT_APP_SIA_API_URI + url, {});
    result = response.data;
    result.actions.forEach(element => {
      element.title = element.groupName === Constants.GROUP_NAME_TIMBRATURA ? 'Lavoro' : element.title;
    });
  } catch (error: any) {
    const errorMessage: string = error?.response?.data?.message ? error.response.data.message : 'Errore in recupero monthly page action types';
    console.error(`getSiaActionMonthlyPage error : ${errorMessage}`)
    throw new Error("Si è verificato un errore imprevisto nel recupero delle tipologie di azioni.");
  }
  return result;
}

export const getSiaUserTimesheet = async (idUserSF: string, startDate: Date, endDate: Date | null): Promise<IUserTimesheetResponse> => {
  let url: string = `timesheet?userId=${idUserSF}&startdate=${encodeURIComponent(moment(startDate).format())}`;
  if (endDate) {
    url += `&enddate=${encodeURIComponent(moment(endDate).format())}`;
  }

  let tsResponse: IUserTimesheetResponse = {
    days: []
  };

  try {
    let response = await axios.get(process.env.REACT_APP_SIA_API_URI + url);
    tsResponse.days = mapSsfTimesheetsToTimesheets(response.data.days);
  } catch (error: any) {
    const errorMessage: string = error?.response?.data?.message ? error.response.data.message : 'Errore in recupero timesheet utente';
    console.error(`getSiaUserTimesheet error : ${errorMessage}`)
    throw new Error("Si è verificato un errore imprevisto nel recupero degli orari.");
  }
  return tsResponse;
}

export const getSiaToday = async (idUserSF: string, day: Date): Promise<Array<ITimesheetAction>> => {
  var userTimesheet: IUserTimesheetResponse = await getSiaUserTimesheet(idUserSF, day, null) as IUserTimesheetResponse;
  if (userTimesheet && userTimesheet.days.length === 1) {
    return userTimesheet.days[0].timesheet;
  } else {
    return [];
  }
}

export const postTimesheet = async (actionToRegister: ITimesheetAction, admin: boolean = false): Promise<IFetchResponse> => {
  let action: any = { ...actionToRegister };
  let url: string = "timesheet";
  if (admin) {
    url += "/admin";

  }
  action.startDate = action.startDate ? moment(action.startDate).format() : null;
  action.endDate = action.endDate ? moment(action.endDate).format() : null;
  debugger;
  if (actionToRegister.id === null) {
    //POST per creazione    
    //return await axios.post(process.env.REACT_APP_SIA_API_URI + url, actionToRegister)
    return await axios.post(process.env.REACT_APP_SIA_API_URI + url, action)
      .then(response => {
        const data: ITimesheetAction = {
          id: response.data.id,
          comment: response.data.comment,
          idActionType: response.data.idActionType,
          idWorkingMode: response.data.idWorkingMode,
          startDate: new Date(response.data.startDate),
          endDate: response.data.endDate ? new Date(response.data.endDate) : null,
          header: response.data.header ? response.data.header : null,
        };
        debugger;
        var fetchResponse: IFetchResponse = { status: 1, message: null, payload: data };
        return fetchResponse;
      })
      .catch((error: any) => {
        debugger;
        const errorMessage: string = error?.response?.data?.message ? error.response.data.message : 'Errore in inserimento timesheet';
        console.error(`postTimesheet error : ${errorMessage}`)
        var fetchResponse: IFetchResponse = { status: 0, message: errorMessage, payload: error };
        return fetchResponse;
      });
  } else {
    //PUT per aggiornamento 
    url += `/${actionToRegister.id}`;
    const data = {
      EndDate: action.endDate ? moment(action.endDate).format() : null,
      Comment: actionToRegister.comment
    };
    return await axios.put(process.env.REACT_APP_SIA_API_URI + url, data)
      .then(response => {
        debugger;
        const data: ITimesheetAction = {
          id: response.data.id,
          comment: response.data.comment,
          idActionType: response.data.idActionType,
          idWorkingMode: response.data.idWorkingMode,
          startDate: new Date(Date.parse(response.data.startDate)),
          endDate: response.data.endDate ? new Date(Date.parse(response.data.endDate)) : null,
          header: response.data.header ? response.data.header : null,
        };
        var fetchResponse: IFetchResponse = { status: 1, message: null, payload: data };
        return fetchResponse;
      })
      .catch(error => {
        debugger;
        const errorMessage: string = error?.response?.data?.message ? error.response.data.message : 'Errore in aggiornamento timesheet';
        console.error(`postTimesheet error : ${errorMessage}`)
        var fetchResponse: IFetchResponse = { status: 0, message: errorMessage, payload: error };
        return fetchResponse;
      });
  }
}

export const postCalendar = async (actionToRegister: ITimesheetAction, admin: boolean = false): Promise<IFetchResponse> => {
  let action: any = { ...actionToRegister };
  let url: string = "calendar";
  if (admin) {
    url += "/admin";
  }
  action.startDate = action.startDate ? moment(action.startDate).format() : null;
  action.endDate = action.endDate ? moment(action.endDate).format() : null;
  return await axios.post(process.env.REACT_APP_SIA_API_URI + url, action)
    .then(response => {
      const data: ITimesheetAction = {
        id: response.data.id,
        comment: response.data.comment,
        idActionType: response.data.idActionType,
        idWorkingMode: response.data.idWorkingMode,
        startDate: new Date(response.data.startDate),
        endDate: response.data.endDate ? new Date(response.data.endDate) : null,
        header: response.data.header ? response.data.header : null,
      };
      var fetchResponse: IFetchResponse = { status: 1, message: null, payload: data };
      return fetchResponse;
    })
    .catch(error => {
      const errorMessage: string = error?.response?.data?.message ? error.response.data.message : 'Errore in inserimento calendario';
      console.error(`postCalendar error : ${errorMessage}`);
      var fetchResponse: IFetchResponse = { status: 0, message: errorMessage, payload: error };
      return fetchResponse;
    });
}

export const deleteCalendar = async (id: number): Promise<IFetchResponse> => {
  let url: string = `calendar/${id}`;
  let result: IFetchResponse = { status: null, message: null, payload: null };
  try {
    let response = await axios.delete(process.env.REACT_APP_SIA_API_URI + url);
    result = response.data;
    return result;
  } catch (error: any) {
    const errorMessage: string = error?.response?.data?.message ? error.response.data.message : 'Errore in cancellazione calendario';
    console.error(`deleteCalendar error : ${errorMessage}`)
    throw new Error(errorMessage);
  }
}

export const getFestivities = async (placesOfWork: string, year: number | null | undefined): Promise<IFestivityResponse> => {
  let url: string = `festivities?placesOfWork=${placesOfWork}`;
  if (year !== null) {
    url += `&year=${year}`;
  }
  return await axios.get(process.env.REACT_APP_SIA_API_URI + url)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      const errorMessage: string = error?.response?.data?.message ? error.response.data.message : 'Errore in recupero festività';
      console.error(`getFestivities error : ${errorMessage}`);
      throw new Error(errorMessage);
    });
}

export const getAnomalyTypes = async (): Promise<IAnomalyTypeResponse> => {
  const url: string = "anomalies/types";
  return await axios.get(process.env.REACT_APP_SIA_API_URI + url)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      const errorMessage: string = error?.response?.data?.message ? error.response.data.message : 'Errore in recupero tipo anomalie';
      console.error(`getAnomalyTypes error : ${errorMessage}`);
      throw new Error(errorMessage);
    });

}

export const getAnomalies = async (idUserSF: string, startDate: Date, endDate?: Date): Promise<IAnomalyResponse> => {
  let url: string = `anomalies?userId=${idUserSF}&startDate=${encodeURIComponent(moment(startDate).format())}`;
  if (endDate != null) {
    url = `${url}&endDate=${encodeURIComponent(moment(endDate).format())}`;
  }
  let result: IAnomalyResponse = {} as IAnomalyResponse;
  try {
    let response = await axios.get(process.env.REACT_APP_SIA_API_URI + url);
    result = response.data;
    result.anomalies.forEach(element => {
      element.date = new Date(element.date);
    });
  } catch (error: any) {
    const errorMessage: string = error?.response?.data?.message ? error.response.data.message : 'Errore in recupero anomalie';
    console.error(`getAnomalies error : ${errorMessage}`)
    throw new Error("Si è verificato un errore imprevisto nel recupero delle anomalie.");
  }

  return result;

}

export const getAssistanceStatuses = async (): Promise<IAssistanceStatusResponse> => {
  const url: string = "assistances/status";
  return await axios.get(process.env.REACT_APP_SIA_API_URI + url)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      const errorMessage: string = error?.response?.data?.message ? error.response.data.message : 'Errore in recupero stato assistenze';
      console.error(`getAssistanceStatuses error : ${errorMessage}`);
      throw new Error(errorMessage);
    });
}

export const getHolidays = async (idUserSF: string, startDate?: Date, endDate?: Date, allowFutureDate?: boolean): Promise<IHoliday> => {
  let url: string = `holidays?userId=${idUserSF}`;
  if (startDate != null) {
    url = `${url}&startDate=${encodeURIComponent(moment(startDate).format())}`;
  }
  if (endDate != null) {
    url = `${url}&endDate=${encodeURIComponent(moment(endDate).format())}`;
  }
  if (allowFutureDate != null){
    url = `${url}&allowFutureDate=${allowFutureDate}`;
  }

  let result: IHoliday = {} as IHoliday;
  try {
    let response = await axios.get(process.env.REACT_APP_SIA_API_URI + url);
    result = {
      earned: moment.duration(response.data.earned),
      remaining: moment.duration(response.data.remaining),
      used: moment.duration(response.data.used)
    }
    return result;
  } catch (error: any) {
    const errorMessage: string = error?.response?.data?.message ? error.response.data.message : 'Errore in recupero ferie';
    console.error(`getHolidays error : ${errorMessage}`);
    throw new Error("Si è verificato un errore imprevisto nel recupero dei giorni di ferie.");
  }
}

export const getAssistances = async (idUserSF: string, startDate: Date, endDate?: Date): Promise<IUserAssistanceResponse> => {
  let url: string = `assistances?userId=${idUserSF}&startDate=${encodeURIComponent(moment(startDate).format())}`;
  if (endDate != null) {
    url = `${url}&endDate=${encodeURIComponent(moment(endDate).format())}`;
  }

  let result: IUserAssistanceResponse = {} as IUserAssistanceResponse;
  try {
    let response = await axios.get(process.env.REACT_APP_SIA_API_URI + url);
    result = response.data;
    result.assistances.forEach(element => {
      element.startDate = element.startDate ? new Date(element.startDate) : null;
      element.endDate = element.endDate ? new Date(element.endDate) : null;
      element.createdOn = new Date(element.createdOn)
      element.updatedOn = element.updatedOn ? new Date(element.updatedOn) : null;
    });
  } catch (error: any) {
    const errorMessage: string = error?.response?.data?.message ? error.response.data.message : 'Errore in recupero assistenze';
    console.error(`getAssistances error : ${errorMessage}`);
    throw new Error("Si è verificato un errore imprevisto nel recupero delle assistenze.");
  }
  return result;
}

export const getAssistancesToApprove = async (startDate: Date, endDate: Date) => {
  let url: string = `assistances/pending?startDate=${encodeURIComponent(moment(startDate).format())}&endDate=${encodeURIComponent(moment(endDate).format())}`;
  let result: IUserAssistanceResponse = {} as IUserAssistanceResponse;
  try {
    let response = await axios.get(process.env.REACT_APP_SIA_API_URI + url);
    result = response.data;
    result.assistances.forEach(element => {
      element.startDate = element.startDate ? new Date(element.startDate) : null;
      element.endDate = element.endDate ? new Date(element.endDate) : null;
      element.createdOn = new Date(element.createdOn)
      element.updatedOn = element.updatedOn ? new Date(element.updatedOn) : null;
    });
  } catch (error: any) {
    const errorMessage: string = error?.response?.data?.message ? error.response.data.message : 'Errore in recupero assistenze da approvare';
    console.error(`getAssistancesToApprove error : ${errorMessage}`);
    throw new Error("Si è verificato un errore imprevisto nel recupero delle assistenze.");
  }
  return result;

}

export const updateAssistance = async (assistanceId: number, assistanceStatus: number): Promise<IFetchResponse> => {

  let url: string = `assistances/${assistanceId}`;
  try {
    const data = {
      Status: assistanceStatus
    };
    let response = await axios.put(process.env.REACT_APP_SIA_API_URI + url, data);
    return { status: 1, message: null, payload: response.data };
  } catch (error: any) {
    const errorMessage: string = error?.response?.data?.message ? error.response.data.message : 'Errore in aggiornamento assistenza';
    console.error(`updateAssistance error : ${errorMessage}`);
    return { status: 0, message: `Si è verificato un errore imprevisto nell'aggiornamento dell'assistenza.`, payload: error } as IFetchResponse;
  }
}
//DA SOSTITUIRE CON QUELLO SOPRA
// export const updateAssistance = async (assistanceId: number, assistanceStatus: number): Promise<IFetchResponse> => {

//   let url: string = `assistances/${assistanceId}`;
//   let result: IFetchResponse = { status: null, message: null, payload: null };
//   try {
//     const data = {
//       Status: assistanceStatus
//     };
//     let response = await axios.put(process.env.REACT_APP_SIA_API_URI + url, data);
//     result = response.data;
//     return result;
//   } catch (error: any) {
//     const errorMessage: string = error?.response?.data?.message ? error.response.data.message : 'Errore in aggiornamento assistenza';
//     console.error(`updateAssistance error : ${errorMessage}`);
//     throw new Error("Si è verificato un errore imprevisto nell'aggiornamento dell'assistenza.");
//   }
// }
export const deleteAssistance = async (assistanceId: number): Promise<IFetchResponse> => {

  let url: string = `assistances/${assistanceId}`;
  try {

    let response = await axios.delete(process.env.REACT_APP_SIA_API_URI + url);
    return { status: 1, message: null, payload: response.data };
  } catch (error: any) {
    const errorMessage: string = error?.response?.data?.message ? error.response.data.message : 'Errore in cancellazione assistenza';
    console.error(`deleteAssistance error : ${errorMessage}`);
    return { status: 0, message: `Si è verificato un errore imprevisto nella cancellazione dell'assistenza.`, payload: error } as IFetchResponse;
    //throw new Error("Si è verificato un errore imprevisto nella cancellazione dell'assistenza.");
  }
}

export const getUsers = async (country?: string, startDate?: Date, endDate?: Date): Promise<IUserResponse> => {
  let url: string = (country && country !== '') ? `users?country=${country}` : 'users';

  if (startDate !== undefined && endDate !== undefined) {
    url += `&startDate=${encodeURIComponent(moment(startDate).format())}&endDate=${encodeURIComponent(moment(endDate).format())}`;
  }

  let result: IUserResponse = {} as IUserResponse;
  try {
    let response = await axios.get(process.env.REACT_APP_SIA_API_URI + url);
    result = response.data;
    return result;
  } catch (error: any) {
    const errorMessage: string = error?.response?.data?.message ? error.response.data.message : 'Errore in recupero utenti';
    console.error(`getUsers error : ${errorMessage}`);
    throw new Error("Si è verificato un errore imprevisto nel recupero degli utenti.");
  }

}

export const getOvertimesMin = async (country: string): Promise<number> => {
  let url: string = `timesheet/overtime/getovertimemin?country=${country}`;
  let result: number = 0;
  try {
    let response = await axios.get(process.env.REACT_APP_SIA_API_URI + url);
    result = response.data;
    return result;
  } catch (error: any) {
    const errorMessage: string = error?.response?.data?.message ? error.response.data.message : 'Errore in recupero overtimes min.';
    console.error(`getOvertimesMin error : ${errorMessage}`);
    throw new Error("Si è verificato un errore imprevisto nel recupero delgli Overtimes Min.");
  }
}

export const getOvertimes = async (userId: string, startDate: Date, endDate: Date | null): Promise<IOvertimeResponse> => {
  let url: string = `timesheet/overtime?userId=${userId}&startDate=${encodeURIComponent(moment(startDate).format())}`;
  if (endDate !== null) {
    url += `&endDate=${encodeURIComponent(moment(endDate).format())}`;
  }

  let result: IOvertimeResponse = {
    days: []
  } as IOvertimeResponse;
  try {
    const response = await axios.get(process.env.REACT_APP_SIA_API_URI + url);
    result = response.data;
  } catch (error: any) {
    const errorMessage: string = error?.response?.data?.message ? error.response.data.message : 'Errore in recupero straordinari';
    console.error(`getOvertimes error : ${errorMessage}`);
    throw new Error("Si è verificato un errore imprevisto nel recupero degli straordinari.");
  }
  return result;
}

export const updateOvertime = async (overtime: IOvertime): Promise<IFetchResponse> => {
  let url: string = `timesheet/overtime/${overtime.id}`;

  try {
    const data = {
      compensatedMinutes: overtime.compensatedMinutes,
      paidMinutes: overtime.paidMinutes,
      elasticClauseMinutes: overtime.elasticClauseMinutes
    };
    let response = await axios.put(process.env.REACT_APP_SIA_API_URI + url, data);
    return { status: 1, message: null, payload: response.data };
  } catch (error: any) {
    const errorMessage: string = error?.response?.data?.message ? error.response.data.message : 'Errore in aggiornamento straordinario';
    console.error(`updateOvertime [id ${overtime.id}] error : ${errorMessage}`);
    return { status: 0, message: `Si è verificato un errore imprevisto nell'aggiornamento dello straordinario.`, payload: error } as IFetchResponse;
  }
}

export const updateTimeSheet = async (timeSheet: ITimesheetAction): Promise<IFetchResponse> => {
  let url: string = `timesheet/admin/${timeSheet.id}`;
  try {
    const data = {
      startDate: (timeSheet.startDate !== null && timeSheet.startDate !== undefined) ? moment(timeSheet.startDate).format() : null,
      endDate: (timeSheet.endDate !== null && timeSheet.endDate !== undefined) ? moment(timeSheet.endDate).format() : null,
      comment: (timeSheet.comment !== undefined && timeSheet.comment !== '') ? timeSheet.comment : ''
    };
    let response = await axios.put(process.env.REACT_APP_SIA_API_URI + url, data);
    return { status: 1, message: null, payload: response.data };
  } catch (error: any) {
    const errorMessage: string = error?.response?.data?.message ? error.response.data.message : 'Errore in aggiornamento timesheet';
    console.error(`updateTimeSheet error : ${errorMessage}`);
    return { status: 0, message: "Si è verificato un errore imprevisto nell'aggiornamento del timesheet.", payload: null };
    //throw new Error("Si è verificato un errore imprevisto nell'aggiornamento del timesheet.");
  }
}

export const deleteTimeSheet = async (id: number): Promise<IFetchResponse> => {
  let url: string = `timesheet/${id}`;

  try {
    let response = await axios.delete(process.env.REACT_APP_SIA_API_URI + url);
    return { status: 1, message: null, payload: response.data };
  } catch (error: any) {
    const errorMessage: string = error?.response?.data?.message ? error.response.data.message : 'Errore in cancellazione timesheet';
    console.error(`deleteTimeSheet error : ${errorMessage}`);
    return { status: 0, message: "Si è verificato un errore imprevisto nella cancellazione del timesheet", payload: null };
  }
}

export const getCalendar = async (userId: string, startDate: Date, endDate: Date | null): Promise<ICalendarResponse> => {
  let url: string = `calendar?userId=${userId}&startDate=${encodeURIComponent(moment(startDate).format())}`;
  if (endDate !== null) {
    url += `&endDate=${encodeURIComponent(moment(endDate).format())}`;
  }

  let tsResponse: ICalendarResponse = {
    days: []
  };

  try {
    let response = await axios.get(process.env.REACT_APP_SIA_API_URI + url);
    tsResponse = mapCalendars(response.data as ICalendarResponse);
  } catch (error: any) {
    const errorMessage: string = error?.response?.data?.message ? error.response.data.message : 'Errore in recupero calendario';
    console.error(`getCalendar error : ${errorMessage}`)
    throw new Error("Si è verificato un errore imprevisto nel recupero del calendario.");
  }
  return tsResponse;
}

export const getUserReport = async (userId: string, startDate: Date, endDate: Date): Promise<IFetchResponse> => {
  let url: string = `report?userId=${userId}&startDate=${encodeURIComponent(moment(startDate).format())}&endDate=${encodeURIComponent(moment(endDate).format())}`;
  try {
    const response = await axios.get(process.env.REACT_APP_SIA_API_URI + url);
    const data: IUserReportResponse = {
      report: response.data.report
    };
    var fetchResponse: IFetchResponse = { status: 1, message: null, payload: data };
    return fetchResponse;
  } catch (error: any) {
    const errorMessage: string = error?.response?.data?.message ? error.response.data.message : `Errore in recupero report per utente ${userId}`;
    console.error(`getUserReport error : ${errorMessage}`)
    return { status: 0, message: `Errore in recupero report per utente ${userId}`, payload: error } as IFetchResponse;
  }
}

export const exportReportStart = async (userIds: string[], startDate: Date, endDate: Date): Promise<IReportExportInfo> => {
  let result: IReportExportInfo = {
    fileName: `Rapportino_${moment(new Date()).format('DD-MM-yyyy HH:mm')}.csv`,
    status: '',
    reportExportId: '',
    rowToExport: 0,
    rowExported: 0
  }

  //let url: string = 'report/ExportReportStart'; //?userId=${userId}&startDate=${encodeURIComponent(moment(startDate).format())}&endDate=${encodeURIComponent(moment(endDate).format())}`;
  let url: string = `report/ExportReportStart?typeExport=1&startDate=${encodeURIComponent(moment(startDate).format())}&endDate=${encodeURIComponent(moment(endDate).format())}`;

  try {
    const response: any = await axios.post(process.env.REACT_APP_SIA_API_URI + url, userIds);
    result.reportExportId = response.data.reportExportId;
    result.rowExported = response.data.rowExported;
    result.rowToExport = response.data.rowToExport;
    result.status = response.data.status;

  } catch (error: any) {
    const errorMessage: string = error?.response?.data?.message ? error.response.data.message : 'Errore in avvio esportazione report';
    console.error(`exportReportStart error : ${errorMessage}`)
    throw new Error("Si è verificato un errore imprevisto in avvio esportazione report.");
  }
  return result;
}

export const exportReportCheck = async (reportExportId: string): Promise<IReportExportInfo> => {
  let result: IReportExportInfo = {
    fileName: `Rapportino_${moment(new Date()).format('DD-MM-yyyy HH:mm')}.csv`,
    status: '',
    reportExportId: '',
    rowToExport: 0,
    rowExported: 0
  }
  const url = `report/exportReportCheck?reportExportId=${reportExportId}`;
  try {
    const response = await axios.get(process.env.REACT_APP_SIA_API_URI + url);
    result.reportExportId = response.data.reportExportId;
    result.rowExported = response.data.rowExported;
    result.rowToExport = response.data.rowToExport;
    result.status = response.data.status;

  } catch (error: any) {
    const errorMessage: string = error?.response?.data?.message ? error.response.data.message : 'Errore in controllo stato report';
    console.error(`exportReportCheck error : ${errorMessage}`)
    throw new Error("Si è verificato un errore imprevisto nel controllo dello stato del report.");
  }
  return result;
}

export const exportReport = async (reportExportId: string): Promise<IFetchResponse> => {
  let url: string = `report/ExportReport?reportExportId=${reportExportId}`;

  try {
    const response = await axios.get(process.env.REACT_APP_SIA_API_URI + url);
    debugger;
    if (response.data.error === '') {
      return { status: 1, message: null, payload: response.data.fileContent };
    } else {
      console.error(`exportReport error : ${response.data.error}`);
      return { status: 0, message: response.data.error, payload: null };
    }

  } catch (error: any) {
    const errorMessage: string = error?.response?.data?.message ? error.response.data.message : `Errore in recupero report ${reportExportId}`;
    console.error(`exportReport error : ${errorMessage}`);
    return { status: 0, message: `Errore in recupero report ${reportExportId}`, payload: error } as IFetchResponse;
  }
}

export const getUsersOnline = async (): Promise<IFetchResponse> => {
  let url: string = `users/online`;
  try {
    const response = await axios.get(process.env.REACT_APP_SIA_API_URI + url);
    return { status: 1, message: null, payload: response.data };
  } catch (error: any) {
    const errorMessage: string = error?.response?.data?.message ? error.response.data.message : `Errore in recupero utenti online`;
    console.error(`getUsersOnline error : ${errorMessage}`);
    return { status: 0, message: `Errore in recupero utenti online`, payload: error } as IFetchResponse;
  }
}

export const getUsersStatus = async (country: string): Promise<IFetchResponse> => {
  let url: string = `users/usersStatus?country=${country}`;
  debugger;
  try {
    const response = await axios.get(process.env.REACT_APP_SIA_API_URI + url);
    return { status: 1, message: null, payload: response.data };
  } catch (error: any) {
    const errorMessage: string = error?.response?.data?.message ? error.response.data.message : `Errore in recupero stato utenti`;
    console.error(`getUsersStatus error : ${errorMessage}`);
    return { status: 0, message: `Errore in recupero stato utenti`, payload: error } as IFetchResponse;
  }
}

export const getUserPicture = async (email: string, size: number = 0): Promise<IFetchResponse> => {
  let url: string = `users/picture?user=${email}&size=${size}`;
  try {
    const response = await axios.get(process.env.REACT_APP_SIA_API_URI + url);
    return { status: 1, message: null, payload: response.data.users };
  } catch (error: any) {
    const errorMessage: string = error?.response?.data?.message ? error.response.data.message : `Errore in recupero foto utente ${email}`;
    console.error(`getUserPicture error : ${errorMessage}`);
    return { status: 0, message: `Errore in recupero foto utente ${email}`, payload: error } as IFetchResponse;
  }
}


export const getFilterValues = async (filterName: FilterType, country: string = ''): Promise<IFetchResponse> => {
  let url: string = `${filterName}`;

  if (filterName === FilterType.Managers) {
    url = 'users/managers';
  }

  if (country !== '') {
    url += `?country=${country}`;
  }

  try {
    const response = await axios.get(process.env.REACT_APP_SIA_API_URI + url);
    return { status: 1, message: null, payload: response.data[filterName] };
  } catch (error: any) {
    const errorMessage: string = error?.response?.data?.message ? error.response.data.message : `Errore in recupero ${filterName}`;
    console.error(`getFilterValues error : ${errorMessage}`);
    return { status: 0, message: `Errore in recupero ${filterName}`, payload: error } as IFetchResponse;
  }
}

export const getUserStatus = async (userId: string = '', roleId: string = '', departmentId: string = '', countryId: string = '', managerId: string = '', state: boolean = false, take: number, nextToken: string = ''): Promise<IFetchResponse> => {

  let url: string = '';
  if (nextToken !== '') {
    url = nextToken;
  } else {
    url = 'users/usersStatus';
    if (userId !== '') {
      url += `?userId=${userId}`;
    }
    if (roleId !== '') {
      url += url.indexOf('?') !== -1 ? `&role=${roleId}` : `?role=${roleId}`;
    }
    if (departmentId !== '') {
      url += url.indexOf('?') !== -1 ? `&department=${departmentId}` : `?department=${departmentId}`;
    }
    if (countryId !== '') {
      url += url.indexOf('?') !== -1 ? `&country=${countryId}` : `?country=${countryId}`;
    }
    if (managerId !== '') {
      url += url.indexOf('?') !== -1 ? `&managerId=${managerId}` : `?managerId=${managerId}`;
    }

    if (state === true) {
      url += url.indexOf('?') !== -1 ? `&state=2` : `?state=2`;
    }

    // if (companyCode !== '') {
    //   url += url.indexOf('?') !== -1 ? `&companyCode=${companyCode}` : `?companyCode=${companyCode}`;
    // }
    if (take > 0) {
      url += url.indexOf('?') !== -1 ? `&take=${take}` : `?take=${take}`;
    }
  }

  try {
    const response = await axios.get(url.startsWith('users/') ? process.env.REACT_APP_SIA_API_URI + url : url);
    return { status: 1, message: null, payload: response.data };
  } catch (error: any) {
    const errorMessage: string = error?.response?.data?.message ? error.response.data.message : `Errore in recupero informazioni utenti`;
    console.error(`getUserStatus error : ${errorMessage}`);
    return { status: 0, message: `Errore in recupero informazioni utenti`, payload: error } as IFetchResponse;
  }


}

export const ClearServiceCache = async (): Promise<IFetchResponse> => {
  try {
    const url: string = `${process.env.REACT_APP_SIA_API_URI}cache`;
    console.log(`chiamata servizio cache : ${url}`);
    const response = await axios.get(url);
    return { status: 1, message: null, payload: response.data };
  } catch (error: any) {
    console.log(error);
    const errorMessage: string = error?.response?.data?.message ? error.response.data.message : `Errore in cancellazione cache`;
    console.error(`ClearServiceCache error : ${errorMessage}`);
    return { status: 0, message: `Errore in cancellazione cache del servizio`, payload: error } as IFetchResponse;
  }
}

export const getUserRole = async (): Promise<IFetchResponse> => {
  try {
    const url = "users/role";
    const response = await axios.get(process.env.REACT_APP_SIA_API_URI + url);
    let ut: UserType = UserType.User;
    switch (response.data.role) {
      case "USER":
        ut = UserType.User;
        break;
      case "HR":
        ut = UserType.HR;
        break;
      case "ADMIN":
        ut = UserType.Admin;
        break;
      default:
        ut = UserType.Unknown;
        break;
    }
    return { status: 1, message: null, payload: ut };
  } catch (error: any) {
    const errorMessage: string = error?.response?.data?.message ? error.response.data.message : `Errore in recupero ruolo utente`;
    console.error(`GetUserRole error : ${errorMessage}`);
    return { status: 0, message: `Errore in recupero ruolo utente`, payload: error } as IFetchResponse;
  }
}

export const getUserAssistances = async (userId: string): Promise<IFetchResponse> => {
  let url: string = `assistances/getForm?userId=${userId}`;

  try {
    const response = await axios.get(process.env.REACT_APP_SIA_API_URI + url);
    return { status: 1, message: null, payload: response.data };
  } catch (error: any) {
    const errorMessage: string = error?.response?.data?.message ? error.response.data.message : `Errore in recupero nuove assistenze per l'utente`;
    console.error(`getUserAssistances error : ${errorMessage}`);
    return { status: 0, message: `Errore in recupero nuove assistenze per l'utente`, payload: error } as IFetchResponse;
  }
}

export const verifyAssistance = async (request: IAssistanceVerifyRequest): Promise<IFetchResponse> => {
  let url: string = `assistances/verify`;

  try {
    const response = await axios.post(process.env.REACT_APP_SIA_API_URI + url, request);
    return { status: 1, message: null, payload: response.data };
  } catch (error: any) {
    const errorMessage: string = error?.response?.data?.message ? error.response.data.message : `Errore in verifica assistenza`;
    console.error(`verifyAssistance error : ${errorMessage}`);
    return { status: 0, message: `Errore in verifica assistenza`, payload: error } as IFetchResponse;
  }
}

export const addAssistance = async (request: any): Promise<IFetchResponse> => {
  let url: string = `assistances`;

  try {
    const response = await axios.post(process.env.REACT_APP_SIA_API_URI + url, request);
    return { status: 1, message: null, payload: response.data };
  } catch (error: any) {
    const errorMessage: string = error?.response?.data?.message ? error.response.data.message : `Errore in salvataggio assistenza`;
    console.error(`addAssistance error : ${errorMessage}`);
    return { status: 0, message: `Errore in salvataggio assistenza`, payload: error } as IFetchResponse;
  }
}

export const addOvertimeRecovery = async (overtimeRecovery: IOvertimeRecovery): Promise<IFetchResponse> => {
  let url: string = 'overtimeRecovery';
  try {
    let response = await axios.post(process.env.REACT_APP_SIA_API_URI + url, overtimeRecovery);
    return { status: 1, message: null, payload: response.data };
  } catch (error: any) {
    const errorMessage: string = error?.response?.data?.message ? error.response.data.message : 'Errore in aggiunta overtime recovery';
    console.error(`addOvertimeRecovery [data ${overtimeRecovery.overtimeDay}] error : ${errorMessage}`);
    return { status: 0, message: `Errore in aggiunta overtime recovery.`, payload: error } as IFetchResponse;
  }
}

export const getOvertimeRecovery = async (userId?: string, startDate?: Date, endDate?: Date): Promise<IFetchResponse> => {

  let url: string = `overtimeRecovery`;

  let queryString: string = '';

  if (userId) {
    queryString += `?userId=${userId}`
  }

  if (startDate) {
    const sd: string = `startDate=${encodeURIComponent(moment(startDate).format())}`;
    queryString += queryString === '' ? `?${sd}` : `&${sd}`;
  }

  if (endDate) {
    const ed: string = `endDate=${encodeURIComponent(moment(endDate).format())}`;
    queryString += queryString === '' ? `?${ed}` : `&${ed}`;
  }
  if (queryString !== '') {
    url += queryString;
  }

  try {
    debugger;
    const response = await axios.get(process.env.REACT_APP_SIA_API_URI + url);
    return { status: 1, message: null, payload: response.data };
  } catch (error: any) {
    const errorMessage: string = error?.response?.data?.message ? error.response.data.message : `Errore in recupero nuove assistenze per l'utente`;
    console.error(`getOvertimeRecovery error : ${errorMessage}`);
    return { status: 0, message: `Errore in recupero nuove assistenze per l'utente`, payload: error } as IFetchResponse;
  }
}

export const getSummaryBox = async (userId?: string, startDate?: Date, endDate?: Date): Promise<IFetchResponse> => {

  let url: string = `timesheet/summarybox`;

  let queryString: string = '';

  if (userId) {
    queryString += `?userId=${userId}`
  }

  if (startDate) {
    const sd: string = `startDate=${encodeURIComponent(moment(startDate).format())}`;
    queryString += queryString === '' ? `?${sd}` : `&${sd}`;
  }

  if (endDate) {
    const ed: string = `endDate=${encodeURIComponent(moment(endDate).format())}`;
    queryString += queryString === '' ? `?${ed}` : `&${ed}`;
  }
  if (queryString !== '') {
    url += queryString;
  }

  try {
    const response = await axios.get(process.env.REACT_APP_SIA_API_URI + url);
    const result = {
      summaryBox: {
        earnedHolidays: moment.duration(response.data.summaryBox.earnedHolidays),
        leaveHours: moment.duration(response.data.summaryBox.leaveHours),
        usedHolidays: moment.duration(response.data.summaryBox.usedHolidays),
        remainingHolidays: moment.duration(response.data.summaryBox.remainingHolidays),
        lackHours: moment.duration(response.data.summaryBox.lackHours),
        workingMonthlyHours: response.data.summaryBox.workingMonthlyHours,
        overtimeMonthlyToPayHours: response.data.summaryBox.overtimeMonthlyToPayHours,
        timeSlotsAdditional: moment.duration(response.data.summaryBox.timeSlotsAdditional),
        timeSlotsOvertime: moment.duration(response.data.summaryBox.timeSlotsOvertime),
        lastHolidaysCalcDate: response.data.summaryBox.lastHolidaysCalcDate,
        ticketRestaurantCount: response.data.summaryBox.ticketRestaurantCount
      }
    } as ISummaryBoxResponse;
    return { status: 1, message: null, payload: result };
  } catch (error: any) {
    debugger;
    const errorMessage: string = error?.response?.data?.message ? error.response.data.message : `Errore in recupero riepilogo mensile per l'utente`;
    console.error(`getSummaryBox error : ${errorMessage}`);
    return { status: 0, message: `Errore in recupero riepilogo mensile per l'utente`, payload: error } as IFetchResponse;
  }
}

export const getDaily = async (): Promise<IFetchResponse> => {
  const url: string = `timesheet/daily`;
  try {
    const response = await axios.get(process.env.REACT_APP_SIA_API_URI + url);
    return { status: 1, message: null, payload: response.data };
  } catch (error: any) {
    const errorMessage: string = error?.response?.data?.message ? error.response.data.message : `Errore in recupero orario giornaliero dell'utente`;
    console.error(`geDaily error : ${errorMessage}`);
    return { status: 0, message: `Errore in recupero orario giornaliero dell'utente`, payload: error } as IFetchResponse;
  }
}

