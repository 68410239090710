import UserGenericPic from '../imgs/user-generic.png';
const EmployeePic = (props:any) => {

    var pic = props.pic;
    var imgSide = 85;
    var maxWidth  = imgSide;
    var maxHeight  = imgSide;
    var imgWidth = imgSide;
    var imgHeight = imgSide;
    var marginTop = '0px';
    var marginLeft = '0px';
    var ratio = 0;

    if(pic!==null && pic!==undefined){
       
        if(pic.width>pic.height)
        {
            ratio = maxHeight / pic.height; 
            imgHeight = maxHeight;
            imgWidth = pic.width * ratio
            marginLeft = '-'+parseInt((Math.abs(maxWidth-imgWidth) / 2).toString(), 10).toString()+'px';
        }
        else if(pic.width<pic.height)
        {
            ratio = maxWidth / pic.width; 
            imgWidth = maxWidth;
            imgHeight = pic.height * ratio;
            marginTop = '-'+parseInt((Math.abs(maxHeight-imgHeight) / 2).toString(), 10).toString()+'px';
        }
    }

    return (pic!==null && pic!==undefined) ? 
    <div className="badge-pic noselect"><img src={"data:image/jpeg;base64, "+pic.photo} width={imgWidth} height={imgHeight} style={{marginTop:marginTop, marginLeft:marginLeft}} alt="Employee" className="noselect" draggable="false" /></div>
    :
    <div className="badge-pic noselect"><img src={UserGenericPic} alt="Employee" className="noselect" draggable="false" /></div>
}

export default EmployeePic;